import React  from "react";
// import axios from 'axios';
// import Cookies from 'js-cookie';

// import {GiFactory} from "react-icons/gi";

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

// import {GoDashboard, GoPerson, GoQuestion, GoArrowBoth, GoOrganization, GoArchive, GoClippy} from 'react-icons/go';

// import {GoDashboard, GoPerson, GoQuestion, GoArrowBoth, GoOrganization, GoArchive, GoClippy} from 'react-icons/go';
class Broker extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            server:"",
        };
        this.server = this.server.bind(this);
        this.komunikat = this.komunikat.bind(this);
        this.dumpState = this.dumpState.bind(this);
    
    }
    server = () => { confirmAlert({  customUI: ({ onClose }) => { return ( <div className='custom-ui'><h3>Komunikat serwera</h3><p>{this.state.server}</p> <button className="btn btn-danger btn-sm" onClick={onClose} >Zamknij</button>  </div> ); } });}

    komunikat(ev)
    {
        //console.log("KOMUNIKAT", ev);
        this.setState({server:ev});
        var self = this;
        setTimeout(function(){self.server();}, 50);
    }
    dumpState()
    {
        console.log(this.props);
    }
    

    IsJsonString(str) {try {JSON.parse(str);} catch (e) {return false;}return true;}
    isBoolean(b){if (b==="true"){return true;}else{return false;}}
    
    render(){
       
        return(  
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h3>Usługi niezalenego brokera</h3>
                        <hr classsName="prettyhr"></hr>
                    </div>
                </div>
            </div>               
            )
    }
                        
                        
        
    
}


export default Broker;