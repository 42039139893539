import React  from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axios from 'axios';
import security from './common/security.js';
import Logo from './../img/hbbussines.png';
import Cookies from 'js-cookie';

import Loader from './loader.js';

import Setup from './ustawienia.js';
import Aiteacher from './teacher/Aiteacher.js';

class Teacher extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ready:false,
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.logout = this.logout.bind(this);
    }

    default(){return (<Aiteacher />);}
    ustawienia(){return (<Setup />);}


    componentDidMount() {
        this.setState({ready:true});
        var self = this;
        setInterval(function(){ self.ask('respirator') }, 60000);
    }
ask(resource, data = null)
      {
            var self = this;
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'sprzedaz');
            params.append('command', resource);
            params.append('data', data);
            params.append('token', Cookies.get("Experimental"));
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
              console.log(response.data.data);
            if (response.data.status==="OK")
            {
            //self.setState({loading:false});
                switch (resource){
                case 'respirator':  console.log(response.data.data);return;
                default: return;
                }
                
            }
            else if(response.data.status==="TEST")
            {
            console.log(response.data);
            }
            else if (response.data.status==="err")
            {
               //self.setState({server: response.data.desc});self.server();
            }
            self.setState({odpowiedz:response.data.desc});
            }).catch(function (error) {console.log(error);});
            
        }

logout()
{
    const params = new URLSearchParams(); params.append('asc',security.securityKey());
    params.append('section', 'seller');
    params.append('command', 'logout');
    params.append('token', Cookies.get("Experimental"))
    //var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
        console.log(response.data);
        //window.location.reload();
        Cookies.remove("Experimental");
        window.location.replace(window.location.protocol+ '//' + window.location.host);
    }).catch(function (error) {console.log(error);});
}
    render(){
        document.title ="HomeBank | Teacher";
       if (this.state.ready) 
    {
        return (
            <div>
                <Router>
                    <div>
                    <nav className="navbar navbar-expand-sm  bg-dark navbar-dark navbar-inverse">
                    <Link to="/"><img className="applogo"  src={Logo} alt="logo"/></Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="collapsibleNavbar">

                            <ul className="navbar-nav">
                            
                            
                                
                                <li className="nav-item">
                                    <Link className="nav-link"  to="/">Przychodzące</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link"  to="/setup/">Ustawienia</Link>
                                </li>
                            
                                <li className="nav-item">
                                    <Link className="nav-link" onClick={this.logout}  to="/">Wyloguj</Link>
                                </li>
                                
                            </ul>
                            </div>
                        </nav>
                        <Route exact path="/"  component={this.default} />
                        <Route path="/setup/" component={this.ustawienia} />
                        <Route path="/contact/" component={this.contact} /> 
                        

                    </div>
                </Router>
            </div>
        );
    }
    else
    {
        return (<Loader/>);
    }


}

}

export default Teacher;