function analiza(e)
{
    var odp = "<h5>Analiza</h5>";
    if (IsJsonString(e))
    {
        var content = JSON.parse(e);
        var firmForm = ['firmRatal', 'firmObrot', 'firmLeasing', 'firmFactoring'];
        var indForm = ['hipMal', 'hipInd'];
        if (firmForm.includes(content.formID))
        {
            var zd = zdolnoscFirmy(content);
            var kwota = checked(numberWithCommas(content.kwota));
            var rat = checked(numberWithCommas(content.rat));
            var swr = (symulujRate(content.kwota, rat));
            odp += "<div>Analiza klienta firmowego<table class='historiawniosku'><thead><tr><td>Parametr</td><td>Wartość</td></tr></thead><tbody>";
            odp += addRow("Średnia miesięczna zdolność kredytowa",numberWithCommas(zd)); 
            odp += addRow("Wnioskowana kwota", kwota);   
            odp += addRow("Ilość rat", rat);
            odp += addRow("Symulowana wielkość raty (oprocentowanie w skali roku: 5%)", numberWithCommas(swr));
            odp += addRow("Po opłaceniu raty pozostaje" , colorize(parseInt(zd) - parseInt(swr)));
            odp+="</tbody></table><div>"; 
        }

        if (indForm.includes(content.formID))
        {
            var zd = liczIndywidualnie(content);
            odp += "<div>Analiza klienta firmowego<table class='historiawniosku'><thead><tr><td>Parametr</td><td>Wartość</td></tr></thead><tbody>";
            if (czyZalega(content))
            {
                odp += addRow("Odmowa z powodu opóźnień większych ni 30 dni", "<span class='analiza red'>Tak</span>");
            }
            var check18 = czyPelnoletni(content);
            if (check18.length!==0)
            {
                for (var x = 0; x<check18.length; x++)
                odp += addRow("Odmowa z powodu braku wymaganego wieku (18 lat)","<span class='analiza red'>"+ check18[x] +"</span>");
            }
            odp += addRow("Wysokość wkładu własnego", checkWkladWlasny(content));
            odp += addRow("Miesięczna zdolność" , colorize(zd));
            odp += addRow("Maksymalny okres kredytowania (w latach)" , maxokres(content));
            odp += addRow("Maksymalna wysokość raty (wtytczna KNF)" , numberWithCommas(maxRata(content.dochodnetto, zd)));
            odp += addRow("Maksymalna wysokość kredytu (wytyczna KNF)" , numberWithCommas(maxKredyt(content.dochodnetto)));
            odp += addRow("Maksymalna kwota kredytu do spłaty (obliczenia)" , numberWithCommas(MaxCreditBrutto(content)));
            odp += addRow("Symulacja maksymalnej kwoty zaciągniętego kredytu (obliczenia)" , numberWithCommas(MaxCreditNetto(content)));
            odp += addRow("Spełnienie załoeń formalnych" , kredytSieMiesci(content));


            odp+="</tbody></table><div>"; 
        }

        odp +="<div>Analiza została wykonana</div>"
    }
    else
    {
        odp +="<div>Jeszcze nie dokonywano analizy</div>"
        
    }
    return odp;
}

function liczIndywidualnie(d)
{
    var dochod = parseInt(d.dochodnetto);
    var limity = parseInt(d.limity) * 0.05;
    var raty = parseInt(d.raty);
    var wydatki = parseInt(d.wydatki);
    var stancywilny = parseInt(d.cywilny);
    var dzieci = parseInt(d.dzieci);
    var koszty = 0;
    if (stancywilny===1) {koszty += 1000;} else{koszty += 800;}
    koszty += dzieci*400;
    koszty += limity + raty;
    if (koszty<wydatki) {koszty = wydatki;}
    return (dochod-koszty);
    
}
function zdolnoscFirmy(e)
{
    //console.log("DaneFirmy",e);
}
function checkWkladWlasny(d)
{
    var cena = parseInt(d.cena);
    var wklad = parseInt(d.wkladwlasny);
    var st = wklad/cena;
    if (st>=0.2){return "Wysokość wkładu własnego jest odpowiednia. Kredytowana kwota wynosi " + numberWithCommas((cena - wklad));}
    if (st>=0.1){return "Bank może zarządać dodatkowego zabezpieczenia. Kredytowana kwota wynosi " + numberWithCommas((cena - wklad));}
    return "Wysokość wkładu własnego jest niewystarczająca, stanowi " +Math.round((st)*100) +"wartości nieruchomości.";
}
function czyZalega(d)
{
    if (exists(d.historia))
    {
        if (parseInt(d.historia) === 2)
        return true;
    }
    return false;
}

function maxokres(d)
{
    var birthDate = new Date(d.urodzony);
    var now = new Date();
    var wiek =now - birthDate;
    wiek = wiek/1000/60/60/24/30.5/12;
    var maxOkres = Math.round(65-wiek);
    if (maxOkres>35){maxOkres = 35;}
    return maxOkres;
}

function maxRata(a,b)
{
    //od średniej krajowej
    var mr = 0;
    if (a>5213.27){mr =  a*0.6;}else{mr =  a*0.5;}
    //if b>
    return mr;
}
function maxKredyt(a)
{
    return a*50;
}

function MaxCreditBrutto(d)
{
    var mr = maxRata(d.dochodnetto, 0);
    var mo = maxokres(d);
    var mk = mo * mr * 12;
    if (d.dochodnetto * 50<mk){mk= d.dochodnetto * 50;}
    return mk;
}

function MaxCreditNetto(d)
{
    var mo = maxokres(d);
    var mkb = MaxCreditBrutto(d);
    var pro = 0.05;

    var odsetki = mo * pro;
    var odsetkiikwota = odsetki + 1;
    var mcn = mkb/odsetkiikwota;
    return Math.round(mcn);
}

function kredytSieMiesci(d)
{
    var mkn = MaxCreditNetto(d);
    var odp = "";
    var kw = mkn - parseInt(d.cena);
    //console.log("SIĘ MIEŚCI", mkn, kw, d.cena);
    var pro = 0
    if (mkn>0){pro=d.cena/mkn;}
    if (mkn> d.cena){odp="Wybrana wartość nieruchomości jest w zasięgu moliwości klienta. <br>Dostępne dodatkowe środki na kredyt w wysokości " + numberWithCommas(mkn-parseInt(d.cena))}
    else
    {
        odp = "Dochody klienta nie spełniają zakładanych przez KNF wytycznych. Maksymalna kwota to " + numberWithCommas(mkn);
    }
    return odp;
}
function lz(a){if (a>9){return a;}else{return "0" +a;}}
function czyPelnoletni(d)
{
    var odp=[];
    if (exists(d.urodzona))
    {
        var birth1Date = new Date(d.urodzona);
        var birth2Date = new Date(d.urodzony);
        var now = new Date();
        var pelnoletni = new Date((now.getFullYear()-18)+"-" + lz(now.getMonth()+1) + "-" + lz(now.getDate()+1));
        if (birth2Date<pelnoletni){//console.log("ON PEŁNOLETNI"); }else{//console.log("ONA NIE PEŁNOLETNI");
        odp.push(d.imienazwisko1 + " jest osobą niepełnoletnią");}
        if (birth1Date<pelnoletni){//console.log("ONA PEŁNOLETNIA"); }else{//console.log("ONA NIE PEŁNOLETNIA");
        odp.push(d.imienazwisko1 + " jest osobą niepełnoletnią");}
    }
    else
    {
        var birth2Date = new Date(d.urodzony);
        var now = new Date();
        var pelnoletni = new Date((now.getFullYear()-18)+"-" + lz(now.getMonth()+1) + "-" + lz(now.getDate()+1));
        if (birth2Date<pelnoletni){//console.log("ON PEŁNOLETNI"); }else{//console.log("ON NIE PEŁNOLETNI");
        odp.push(d.imienazwisko + " jest osobą niepełnoletnią");}
    }
    return odp;
}
function colorize(a)
{
    if (parseFloat(a)>=0)
    {
        return "<span class='analiza green'>"+ numberWithCommas(a) + "</span>";
    }
    else
    {
        return "<span class='analiza red'>"+ numberWithCommas(a) + "</span>";
    }
    return "WTF";
}
function symulujRate(kw, rat)
{
    var oprocentowanie = 0.05;
    var lat = parseFloat(rat)/12;
    var doSplaty = (parseFloat(kw) * oprocentowanie * lat) + parseFloat(kw);
    var rata = (doSplaty/rat);
    return rata;
}
function addRow(a,b)
{
    if (b.toString!=="")
    {return "<tr><td>"+ a +"</td><td>"+b+"</td></tr>";}
}

function checked(e)
{
    if (exists(e)){return e;}else {return "";}
}
function exists(e)
{
    if (typeof e !=='undefined'){return true;}else{return false;}
}
function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
function spotkanie(e)
{
    var odp = "Wyznaczono termin spotkania w banku na dzień: <b>" + e.data + "</b><br><i>Miejsce spotkania: " + e.adres + "</i>";
    odp = san(odp);
    return odp;
}

function dokumenty(e)
{
    var ss = e;var odp = "";
    if (IsJsonString(e))
    {
        ss=JSON.parse(e)
    }
    odp = "Zmiana dokumentów z <span class='red'>" + ss.bylo.dokumenty.join(', ');
    odp += "</span> na <span class='green'>" + ss.jest.dokumenty.join(', ');
    return odp;
}

function bylojestTabela(data)
{
    var x = "";
    if (IsJsonString(data)){x=JSON.parse(data)}else{return "Dane uszkodzone";}
    if (x.bylo === x.jest){return "Dokonano zapisu, ale nie dokonano zmian";}
    else
    {
        var odp = "";
        var keys1=Object.keys(x.bylo);
        for (var a = 0; a<keys1.length; a++)
        {
            if (x.bylo[keys1[a]]!==x.jest[keys1[a]])
            {
                odp +="<div class='changeRow'>Wartość '<i>"+parseKeys(keys1[a]) + "</i>' zmieniono z <span class='red'>" + x.bylo[keys1[a]] + "</span> na <span class='green'>" +x.jest[keys1[a]]+"</span></div>";
            }
        }
        if (odp.length===0){odp = "Nie wprowadzono zmian";}
       
        return odp;
    }
}


function parseKeys(key)
{
    
    var odp =key;
    if (odp.includes("brutto")){odp = "Brutto " + key.substr(6,4);}
    if (odp.includes("koszty")){odp = "Koszty " + key.substr(6,4);}
    if (odp.includes("amortyzacja")){odp = "Amortyzacja " + key.substr(11,4);}
    return odp;
}

function symulacjaKredytu(data)
{

}
function zdolnosc(data)
{
    
    var miesiecznie = 0
    var miesiecznie0 = 0;
    var miesiecznie1 = 0;
    var miesiecznie2 = 0;
    
    var datar = new Date(data.datar);
    var oggi = new Date();
    var klucze = Object.keys(data);
    if (datar.getFullYear()<=oggi.getFullYear())
    { 
        miesiecznie0 = (parseInt(data["brutto" +oggi.getFullYear().toString()]) + parseInt(data["amortyzacja"+ oggi.getFullYear().toString()]) - parseInt(data["koszty"+ oggi.getFullYear().toString()]));
        miesiecznie0 = miesiecznie0/(oggi.getMonth()+1)

        if (isNaN(miesiecznie0)){miesiecznie0=0;}
    }
    if (datar.getFullYear()<=(oggi.getFullYear()-1))
    {
        miesiecznie1 = (data["brutto"+ (oggi.getFullYear()-1)] + data["amortyzacja"+ (oggi.getFullYear()-1)] - data["koszty"+ (oggi.getFullYear()-1)])/(12);
        if (isNaN(miesiecznie1)){miesiecznie1=0;}
    }
    if (datar.getFullYear()<=(oggi.getFullYear()-2))
    {
        miesiecznie2 = (data["brutto"+ (oggi.getFullYear()-2)] + data["amortyzacja"+ (oggi.getFullYear()-2)] - data["koszty"+ (oggi.getFullYear()-2)])/(12);
        if (isNaN(miesiecznie2)){miesiecznie2=0;}
    }
    miesiecznie = (miesiecznie0 + miesiecznie1 + miesiecznie2)/3;
    // if (isNaN(miesiecznie)) {miesiecznie = 0;}
    // return data["brutto"+ (oggi.getFullYear())] +" + "+ data["amortyzacja"+ oggi.getFullYear()] + "-" + data["koszty"+ oggi.getFullYear()] + "/" + (oggi.getMonth());
    return Math.floor(miesiecznie);
}

function san(str)
{
    ////console.log("TO_SAN",str);
    var odp = str;
    if (typeof odp !=='undefined'){
    var unic = ["u0119","u00f3","u0105","u015b","u0142","u017c","u017a","u0107","u0144","u0118","u00d3","u0104","u015a","u0141","u017b","u0179","u0106","u0143"];
    var lett = ["ę","ó","ą","ś","ł","ż","ź","ć","ń","Ę","Ó","Ą","Ś","Ł","Ż","Ź","Ć","Ń"]
    for (var a = 0; a<unic.length; a++)
    {
        if (odp.length>0)
        {
            odp = odp.split(unic[a]).join(lett[a]);
        }
        else
        {
            odp = odp;
        }
        
        
    }}
    return odp;
}

function Informacja(str)
{
    if (typeof str =="string")
    {if (str===""){return "Pracownik nie wprowadził komunikatu";}else{return str;}}
    else {return "Pracownik nie wprowadził komunikatu";}
}

function numberWithCommas(x) {
    if (typeof x === "string")
   { if (x.length>0)
   { return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");}
   else {return "";}} else {return "";}
}
function IsJsonString(str) { try { JSON.parse(str); } catch (e) { return false; } return true;}
function twoDimmensional(f, ksiegowosc)
{
            var dzielnik =[1,2,3,4,5];
            var rys = [];
            var wierszy = f.length/dzielnik[parseInt(ksiegowosc)];
            //console.log("Wierszy",wierszy);
            for (var a = 0; a<wierszy; a++)
            {
                if (parseInt(ksiegowosc)===4){rys.push([f[a*5+0],f[a*5+1],f[a*5+2],f[a*5+3],f[a*5+4]]);}
                if (parseInt(ksiegowosc)===1){rys.push([f[a*2+0],f[a*2+1]]);}
                if (parseInt(ksiegowosc)===2){rys.push([f[a*3+0],f[a*3+1],f[a*3+2]]);}
                if (parseInt(ksiegowosc)===3){rys.push([f[a*4+0],f[a*4+1],f[a*4+2],f[a*4+3]]);}
            }
            return rys;
}

module.exports = {
    numberWithCommas,
    Informacja,
    IsJsonString,
    san,
    zdolnosc,
    bylojestTabela,
    dokumenty,
    spotkanie,
    exists,
    analiza,
    twoDimmensional,
}
