import React  from "react";
import axios from 'axios';
import security from './../common/security.js';

import Cookies from 'js-cookie';
import Loader from './../loader.js';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import brainjs from 'brainjs';


// eslint-disable-next-line
import {IoIosSchool, IoIosStar, IoIosStarOutline, IoIosStarHalf, IoIosConstruct, IoIosEasel } from "react-icons/io";
//
import {FaPowerOff} from "react-icons/fa";
class Aiteacher extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
            ready:false, server:'',wnioski:[],page:0,dane:[],idd:0,sql:"",sqreq:[],control:'{"0":"1","1":"1","2":"0"}',
            engine:[],
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.ask = this.ask.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.brain = this.brain.bind(this);
        this.testBrain = this.testBrain.bind(this);
        this.pobierz = this.pobierz.bind(this);
        this.normalizeFirma = this.normalizeFirma.bind(this);
    }
server = () => { confirmAlert({  customUI: ({ onClose }) => { return ( <div className='custom-ui'><h3>Komunikat serwera</h3><p>{this.state.server}</p> <button className="btn btn-danger btn-sm" onClick={onClose} >Zamknij</button>  </div> ); } });}
submit = () => {
    confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h3>Wymagane potwierdzenie</h3>
              <p>Operacja, którą chcesz wykonać jest nieodwracalna i wpływa na strukturę danych. Czy chcesz skasować tą pozycję?</p>
              <button className="btn btn-secondary btn-sm" onClick={onClose}>Anuluj</button>
              &nbsp;
              <button
                className="btn btn-danger btn-sm"
                onClick={() => {
                  this.ask("deleteWniosek", this.state.idd);
                  onClose();
                }}
              >
                Usuń
              </button>
            </div>
          );
        }
      });}



    testBrain()
    {
        var net = new brainjs.NeuralNetwork();
        net.fromJSON(JSON.parse(this.state.engine));
        var zwraca = net.run(JSON.parse(this.state.control));
        //console.log("DANE-WYNIK",net,JSON.parse(this.state.control), zwraca);
    }
      
       
    
    brain() 
    {
        //PREPARE DATA
        // FIND MAX
                // var max = 0;
                // for (var a = 0; a<odp.length; a++)
                // {
                //     if (odp[a].wartosc>max){max=odp[a].wartosc;}
                // }
                // var doliczenia = [odp[0].wartosc/max, odp[1].wartosc/max, odp[2].wartosc/max];
        ////console.log("ZNORMALIZOWANE", doliczenia);
        var net = new brainjs.NeuralNetwork();
        //this.state.sqlreq;
        const trainingData = [
            {input:[0, 0, 0], output:[1,0,0,0,0,0,0]},
            {input:[0, 0, 1], output:[0,0,0,0,1,0,0]},
            {input:[0, 1, 1], output:[0,0,0,0,0,1,0]},
            {input:[1, 0, 0], output:[0,1,0,0,0,0,0]},
            {input:[1, 0, 1], output:[0,0,0,0,0,1,0]},
            {input:[1, 1, 0], output:[0,0,1,0,0,0,0]},
            {input:[1, 1, 1], output:[0,0,0,1,0,0,0]},
            ];
        // const trainingData = this.state.edycja.dane;
            //var count = 0;
            //console.log("TRAININGDATA", trainingData);
            net.train(trainingData);
            
            var zwraca = net.run([1,1,1]);
            var json = net.toJSON(); 
            //console.log("NET-JSON",trainingData, net, json, zwraca);
            this.ask("saveItem", JSON.stringify({id:this.state.edycja.id, data:json}));
           // //console.log("DANE-WYNIK",JSON.parse(this.state.edycja.dane),JSON.parse(this.state.control), zwraca);

            // var zwraca = net.run(doliczenia);
            //  //console.log("ZWRACA", zwraca);
            // max =0;
            // for (a=0; a<zwraca.length; a++)
            // {
            //     if (zwraca[a]>max){max = zwraca[a]; count = a;//console.log("NOWY A MAX", a, max, zwraca[a]);}
            // }

            // var perspektywa = count/3; 
            // if (perspektywa <0.7){this.setState({opis:"Sposób zarządzania Twoją firmą wymaga zmian. Jak widzisz wyniki finansowe mają złą tendencję. Jeśli jednak już teraz wprowadzisz konieczne zmiany może być zupełnie inny."});}
            // if (perspektywa >0.7 && perspektywa<1.3){this.setState({opis:"Wiele wskazuje na to, że osiągnąłeś stabilność w Twojej firmie. Warto jest to wykorzystać, by popchnąć firmę w kierunku rozwoju. Doświadczenie podpowiada, że często stabilna perspektywa dnia dzisiejszego jest pokusą do tego, by nie obserwować zmian rynkowych i poczynań konkurencji. Warto jest zwracać na to uwagę, by nie popaść w rutynę i nie przegapić ważnego momentu w Twojej branży"});}
            // if (perspektywa >1.3){this.setState({opis:"Jeśli utrzymasz takie tempo rozwoju i unikniesz błędów w zarządzaniu a rynek dla Twojej firmy się nie nasyci, Twój wynik na koniec przyszłego roku może być imponujący."});}
            // ////console.log("PERSPEKTYWA:", perspektywa, odp[2], odp[2].wartosc * perspektywa, max);
            // var pewnosc = parseInt(max*100); 
            // this.setState({pewnosc:pewnosc});
            // return odp[2].wartosc * perspektywa ;
    }
    normalizeFirma(data)
    {
        for (var a = 0; a<data.length; a++)
        {
            
        }
    }

gwiazdki(d)
{
    if (parseInt(d)===parseInt(5)){return(<span><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /></span>);}
    else if (parseInt(d)===parseInt(4)){return(<span><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStarOutline /></span>);}
    else if (parseInt(d)===parseInt(3)){return(<span><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /></span>);}
    else if (parseInt(d)===parseInt(2)){return(<span><IoIosStar /><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></span>);}
    else if (parseInt(d)===parseInt(1)){return(<span><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></span>);}
    else {return(<span><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></span>);}
}
onof(d){if (parseInt(d)===parseInt(1)){return (<FaPowerOff className="green"/>);}else{return (<FaPowerOff className="red"/>);}}
onof2(d){if (parseInt(d)===parseInt(1)){return "Wyłącz";}else{return "Włącz";}}

style(d){if (parseInt(d)===parseInt(1)){return "alg mai oso";}else if(parseInt(d)===parseInt(2)){return "alg mai fir";}else {return "alg mai"}}
componentDidMount() {
    this.ask('getList', this.props.zakres);
    this.setState({ready:true});
}

handleButton(ev)
{
    var a = ev.target.dataset;
    if (a.op==="przejmij"){this.ask('opiekuje', a.ide);}
    if (a.op==="edycja"){this.ask("edycja", a.id)}
    if (a.op==="wlacz"){this.ask("wlacz",JSON.stringify({id:a.id, status:a.status}));}
    if (a.op==="home"){this.setState({page:0});}
    if (a.op==="sql"){this.setState({sql:ev.target.value});}
    if (a.op==="sqreq"){this.ask("sqreq", this.state.sql);}
    if (a.op==='pobierz'){this.ask("getFirmData", this.state.sql);}
    if (a.op==='wczytaj'){this.setState({sqlreq: JSON.parse(this.state.edycja.dane)})}
    if (a.op==='control'){this.setState({control:ev.target.value});}
    if (a.op==='uruchom'){this.testBrain();}
    if (a.op==='state'){}
}

ask(resource, data = null)
      {
            var self = this;
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'nauczyciel');
            params.append('command', resource);
            params.append('data', data);
            params.append('token', Cookies.get("Experimental"));
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
              console.log(response.data.data);
            if (response.data.status==="OK")
            {
            //self.setState({loading:false});
                switch (resource){
                case 'getList': self.setState({wnioski: response.data.data, engine:response.data.data.dane});break;
                case 'edycja': self.setState({edycja: response.data.data, page:1,engine:response.data.data.dane});break;
                case 'wlacz': self.ask("getList");;break;
                case 'sqreq':self.setState({sqlreq:response.data.data});break;
                case 'getFirmData':self.setState({sqlreq:response.data.data});break;
                case 'getFirmaData':self.normalizeFirma(response.data.data);break;
                case 'saveItem':self.setState({server:response.data.desc});self.server();break;
                default: break;
                }
                
            }
            else if(response.data.status==="TEST")
            {
            console.log(response.data);
            }
            else if (response.data.status==="err")
            {
               self.setState({server: response.data.desc});self.server();
            }
            self.setState({odpowiedz:response.data.desc});
            }).catch(function (error) {console.log(error);});
            
        }
    pobierz(ev)
    {
        switch(ev.target.dataset.op)
        {
            case 'pobierz':this.ask("getFirmaData", null); break;
            default:break;
        }
    }
    render(){
       if (this.state.ready) 
    {
        if (this.state.page===0)
        {
            return (
                <div>
                <div className="container">
                        <div className="row">
                            <div className="col-sm-12" >
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><span data-op="home" onClick={this.handleButton}>Lista</span></li>
                                    </ol>
                                </nav>
                            
                            </div>
                        </div>
                    </div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3>Silniki Sztucznej Inteligencji</h3>
                            <hr className="prettyhr"></hr>
                            {this.state.wnioski.map((key)=>(
                                <div className={this.style(key.user_role)} key={key.ide}>
                                    <div className="alg tit">{key.title} <span className="alg ono">{this.onof(key.active)}</span></div>
                                    <div className="alg dat"> {key.data} <br></br>
                                            <span className="alg li" data-id={key.ide} data-op="edycja" onClick={this.handleButton}>Edycja</span>
                                            <span className="alg li" data-id={key.ide} data-op="wlacz" data-status={key.active} onClick={this.handleButton}>{this.onof2(key.active)}</span>
                                            
                                           </div>
                                    <div className="alg aut">{key.autor}</div>
                                    <div className="alg opi" >{key.opis}</div>
                                   
                                    <div className="alg oce">{this.gwiazdki(key.ocena)}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            );
        }
        else if (this.state.page===1)
        {
            return (<div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><span data-op="home" onClick={this.handleButton}>Lista</span></li>
                                        <li className="breadcrumb-item">Edycja Silnika</li>
                                    </ol>
                                </nav>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" data-op="sql" value={this.state.sql} onChange={this.handleButton}/>
                                    <div className="input-group-append">
                                        <span className="input-group-text btn" data-op="sqreq" onClick={this.handleButton}>SQL</span>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Control:</label>
                                    <textarea className="form-control" rows="5" data-op="control" onChange={this.handleButton} value={this.state.control}></textarea>
                                </div> 
                            </div>
                            <div className="col-sm-6">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-secondary" data-op="pobierz" onClick={this.pobierz}>Pobierz</button>
                                </div>
                                <div className="btn-group">
                                    <button type="button" className="btn btn-primary" onClick={this.brain}>Uruchom</button>
                                    <button type="button" className="btn btn-primary" data-op="pobierz" onClick={this.handleButton}>Pobierz dane</button>
                                    <button type="button" className="btn btn-primary" data-op="wczytaj" onClick={this.handleButton}>Wczytaj silnik</button>
                                    <button type="button" className="btn btn-primary" data-op="uruchom" onClick={this.handleButton}>TEST</button>
                                    <button type="button" className="btn btn-primary" data-op="state" onClick={this.handleButton}>STATE</button>

                                </div> 
                                {this.state.engine}
                            </div>
                        </div>
                    </div>
                </div>);
        }
        
    }
    else
    {
        return (<div><Loader/></div>);
    }


}

}

export default Aiteacher;