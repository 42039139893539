import React  from "react";
import axios from 'axios';
import security from './../common/security.js';
import Cookies from 'js-cookie';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 



class Listabankow extends React.Component{
    constructor(props) {
        super(props);
        this.state = { products:[], edit:"none", nNazwa:"", nStatus:"", nId:0,nLogo:'',pliki:[], obrazy:[],
        desc:"",title:"", server:'', nPytanie:'', nOdpowiedz:'', nWidocznosc:0, nEnabled:1, pictures: [] ,nToken:"",
        nnLogo:"/sysimg/mbank.png",
        files: [{
            source: 'index.html',
            options: {
                type: 'local'
            }
        }]
            
        };
        this.componentDidMount = this.componentDidMount.bind(this);this.znajdzobraz = this.znajdzobraz.bind(this);
        this.ask = this.ask.bind(this);
        this.chandleVal = this.chandleVal.bind(this);
        this.edytuj = this.edytuj.bind(this);
        this.server = this.server.bind(this);
        this.handleInit = this.handleInit.bind(this);
        this.debug = this.debug.bind(this);
        this.generateToken = this.generateToken.bind(this);
    }
    debug()
    {
        console.log(this.state);
        this.ask("loadPicture", JSON.stringify(this.state.pictures));
    }
    handleInit() {
        console.log('FilePond instance has initialised', this.pond);
    }

    submit = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h3>Wymagane potwierdzenie</h3>
                  <p>Operacja, którą chcesz wykonać jest nieodwracalna i wpływa na strukturę danych. Czy chcesz skasować tą pozycję?</p>
                  <button className="btn btn-secondary btn-sm" onClick={onClose}>Anuluj</button>
                  &nbsp;
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => {
                      this.ask("deleteExistedBank", this.state.nId);
                      onClose();
                    }}
                  >
                    Usuń
                  </button>
                </div>
              );
            }
          });}

    server = () => { confirmAlert({  customUI: ({ onClose }) => { return ( <div className='custom-ui'><h3>Komunikat serwera</h3><p>{this.state.server}</p> <button className="btn btn-danger btn-sm" onClick={onClose} >Zamknij</button>  </div> ); } });}
    
    ask(resource, data)
      {
            var self = this;
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'admin');
            params.append('command', resource);
            params.append('data', data);
            params.append('token', Cookies.get("Experimental"));
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
              console.log(response.data.data);
              if (response.data.status==="OK")
              {
                //self.setState({loading:false}); 
                  switch (resource){
                    case 'getBankList': self.setState({products: response.data.data});break;
                    case 'getFileList': self.setState({pliki: response.data.data});break;
                    case 'saveBankItem':self.ask('getBankList', null);self.setState({server: response.data.desc});self.server();break;
                    case 'deleteExistedBank':self.ask('getBankList', null);self.setState({server: response.data.desc});self.server();break;
                      default: break;
                  }
                 
              }
              else if(response.data.status==="TEST")
              {
                console.log(response.data);
              }
              else if (response.data.status==="err")
              {
                self.setState({server:response.data.desc});
                self.server();
                  
              }
              self.setState({odpowiedz:response.data.desc});
             }).catch(function (error) {console.log(error);});
        }
    componentDidMount(){
        this.ask('getBankList', null);
        this.ask('getFileList', null);
       
    }
    znajdzobraz(obraz)
    {
        var ob = this.state.pliki;
        var znaleziony = "", counter=0;
        for (var a = 0; a<ob.length;a++)
        {
            if (ob[a].alt.includes(obraz))
            {console.log(ob[a]);counter++;znaleziony =ob[a] }
        }
        if (counter===1){
            this.setState({nLogo: znaleziony.alt, nnLogo:znaleziony.url});
        }
    }
    chandleVal(ev)
    {
        var name = ev.target.name; 
        ////console.log("PROPS",this.props);
        if (name==="nLogo")   {this.znajdzobraz(ev.target.value); this.setState({nLogo  :ev.target.value});}
        if (name==="nInfo") {this.setState({nInfo  :ev.target.value});}
        if (name==="nNazwa"){this.setState({nNazwa :ev.target.value});}
        if (name==="nStatus"){this.setState({nStatus:ev.target.value});}
        if (name==="anuluj") {this.setState({edit   :"none"});}
        if (name==="nowy") {this.setState({nId:"NEW", edit :"block", nnLogo:"", nOdpowiedz:"", nWidocznosc:0});}
        if (name==="zapisz") {
            this.ask('saveBankItem', JSON.stringify({
                logo: this.state.nnLogo,
                nazwa: this.state.nNazwa,
                info: this.state.nInfo,
                id: this.state.nId,
                key:this.state.nToken
                
            }));
            this.setState({nId:0, edit   :"none"});}
            if (name==="usun") {
                console.log(ev.target.dataset);
                this.setState({nId:ev.target.dataset.id, edit   :"none"});
                this.submit();
            }
       
    }
    edytuj(ev)
    {
        var prod = this.state.products;
        for (var a =0; a<prod.length; a++)
        {
            if (parseInt(prod[a].id)===parseInt(ev.target.dataset.id))
            {
                console.log(prod[a]);
                var llogo = window.location.protocol +"//"+ window.location.hostname +'/sysimg/defaultbank.png';
                if (prod[a].logo!==""){llogo = prod[a].logo;}
                this.setState({nId: prod[a].id, nNazwa:prod[a].nazwa, nInfo:prod[a].informacje, nnLogo: llogo, nLogo:"", nToken:prod[a].key, edit:"block"});
            }
        }
        
    }

    generateToken()
    {
        var length = 50;
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        this.setState({nToken:result});
    }
    render(){
    
     
       return (<div className="container">
           <div className="row">
               
               <div className="col-sm-6">
                    <table style={{width:"100%"}}>
                        <tbody>
                        {this.state.products.map((key)=>(
               <tr key={key.id}><td>{key.nazwa}</td><td>
                    <div className="btn-group">
                        <button className="btn btn-secondary btn-sm" data-id={key.id} onClick={this.edytuj}>Edytuj</button>
                        <button className="btn btn-danger btn-sm" data-id={key.id} name="usun" onClick={this.chandleVal}>Usuń</button>
                    </div>
                </td></tr>
           ))}
           <tr><td></td><td><button className="btn btn-success btn-sm" name="nowy"  onClick={this.chandleVal}>Nowy</button></td></tr>

                        </tbody>
                    </table>
               </div>
               <div className="col-sm-6">
                   <div style={{display: this.state.edit}}>
                       <p onClick={this.debug}>DEBUG FITCHER:</p>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Logo:</label>
                                    <input type="text" className="form-control" name="nLogo" value={this.state.nLogo} onChange={this.chandleVal}/> 
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <img src={this.state.nnLogo} className="setka" alt="logo" />
                            </div>
                        </div>
                       
                        <div className="form-group">
                            <label>Nazwa banku:</label>
                            <input type="text" className="form-control" name="nNazwa" value={this.state.nNazwa} onChange={this.chandleVal}/> 
                        </div>
                        <div className="form-group">
                            <label>Token dostępowy banku:</label>
                            <div className="form-control">{this.state.nToken}</div>
                        </div>
                        <div className="form-group">
                            <label >Informacje:</label>
                            <textarea className="form-control" rows="9" name="nInfo" value={this.state.nInfo} onChange={this.chandleVal}></textarea>
                        </div> 
                        
                        

                        <div className="btn-group">
                            <button className="btn btn-danger    btn-sm" name="zapisz"  onClick={this.generateToken}>Generuj token</button>
                            <button className="btn btn-secondary btn-sm" name="zapisz"  onClick={this.chandleVal}>Zapisz zmiany</button>
                            <button className="btn btn-secondary btn-sm" name="anuluj"  onClick={this.chandleVal}>Anuluj</button>
                        </div>
                   </div>
               </div>
           </div>
        </div>)
    }
}


export default Listabankow;