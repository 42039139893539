import React  from "react";
import ProductList from "./productList";
// import axios from 'axios';
// import Cookies from 'js-cookie';
import XML from './../common/xmlparser.js';




class Privkantor extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ready:false, faq:[],values:{},powiaty:[],gminy:[],
        };
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        // this.componentDidMount = this.componentDidMount.bind(this);
        // this.rysuj = this.rysuj.bind(this);
        // this.changeVal = this.changeVal.bind(this);
        // this.debug = this.debug.bind(this);
        // this.loadPowiat = this.loadPowiat.bind(this);
        // this.loadGmina = this.loadGmina.bind(this);
        // this.ask = this.ask.bind(this);

    }
    IsJsonString(str) { try { JSON.parse(str); } catch (e) { return false; } return true;}
    componentDidUpdate()
    {
        if (this.IsJsonString(this.props.kursy)){console.log(JSON.parse(this.props.kursy))}
    }
    // ask(resource, data = null)
    // {
    //       var self = this;
    //       const params = new URLSearchParams();
    //       params.append('section', 'user');
    //       params.append('command', resource);
    //       params.append('data', data);
    //       params.append('token', Cookies.get("Experimental"));
    //       axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
    //         //console.log(response.data.data);
    //       if (response.data.status==="OK")
    //       {
    //       //self.setState({loading:false});
    //           switch (resource){
    //           case 'loadGmina':  self.setState({gminy: response.data.data, ready:true});self.analiseUser(response.data.data); return;
      
    //           default: return;
    //           }
              
    //       }
    //       else if(response.data.status==="TEST")
    //       {
    //       console.log(response.data);
    //       }
    //       else if (response.data.status==="err")
    //       {
    //          //self.setState({server: response.data.desc});self.server();
    //       }
    //       self.setState({odpowiedz:response.data.desc});
    //       }).catch(function (error) {console.log(error);});
          
    //   }

 
    render(){
        // this.xml2json();
        if (typeof this.props.xml['@attributes'] !== "undefined")
        {
        var dane = XML.rebulidObject(this.props.xml.kategoria)
        return (
        <div className="container">
           
            <div className="row">
            
            {dane.map((key, i)=>(
                <div  className="col-sm-12" key={i}>  
                    <ProductList clientSelection={this.props.clientSelection} produkt={key} baseurl={this.props.xml['@attributes']['base-url']} drawable={this.props.drawable} include={this.props.include} displayh3={{display:"none"}} displayh5={{display:"none"}}/>
                    
                </div>
            ))}
            </div>
         </div>       
    )
            }else{
                return (<div className="minHeight">Oczekuję na dane .... 9</div>)
            }

}

    }




export default Privkantor;