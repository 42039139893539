import React from 'react';

class Termometr extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ready:false, oferty:[],
        };
       
    }



    render(){
        var procent =this.props.procent+"%";
        var style ={marginLeft:procent};
        return (
            <div className="termContainer">
                <h3>Twoja zdolność kredytowa wynosi {this.props.kwota}</h3>
                <div className="termometr term"><span style={style}></span></div>
                
                <table className="setka zd">
                    <tbody>
                    <tr>
                        <td>Brak zdolności</td>
                        <td>Słaba</td>
                        <td>Średnia</td>
                        <td>Dobra</td>
                        <td>Doskonała</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    
        }
}

export default Termometr;