import React from "react";
import axios from 'axios'; 
import security from './../common/security.js';
import Cookies from 'js-cookie';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import brainjs from 'brainjs';

import {version, normalizeFirma} from './../common/withai.js';
import analizeuser from './../common/analizapostow.js';
import normalizer from './../common/ainormalization.js';

class Aintelligence extends React.Component{

    constructor(props) {
        super(props);
        this.state = {maillist:[],silniki:[],server:"", version:"",status:"Bezczynny",douczenia:{},algoritmID:0,
        currentID:"0", gus:[], szybkaHipotekaIndywidualna:[], wolnaHipotekaIndywidualna:[], duzyKartaPodatkowa:[],
        duzydoRyczaltu:[], duzydoKPiR:[],duzydoPK:[],
    };
       
        this.ask =  this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.buttonRouter = this.buttonRouter.bind(this);
        this.home = this.home.bind(this);
        this.runFirmEngine = this.runFirmEngine.bind(this);
        this.nauka = this.nauka.bind(this);
        this.nauka2 = this.nauka2.bind(this);
        this.naukaDlaOsob = this.naukaDlaOsob.bind(this);
        this.szybkiHipotekaIndywidualna = this.szybkiHipotekaIndywidualna.bind(this);
        this.runszybkaHipotekaIndywidualna = this.runszybkaHipotekaIndywidualna.bind(this);
        this.wolnaHipotekaIndywidualna = this.wolnaHipotekaIndywidualna.bind(this);
        this.runwolnaHipotekaIndywidualna = this.runwolnaHipotekaIndywidualna.bind(this);
        this.duzyKartaPodatkowa = this.duzyKartaPodatkowa.bind(this);
        this.runduzyKartaPodatkowa = this.runduzyKartaPodatkowa.bind(this);
        this.duzydoRyczaltu = this.duzydoRyczaltu.bind(this);
        this.runduzydoRyczaltu = this.runduzydoRyczaltu.bind(this);
        this.duzydoKPiR = this.duzydoKPiR.bind(this);
        this.runduzydoKPiR = this.runduzydoKPiR.bind(this);
        this.duzydoPK = this.duzydoPK.bind(this);
        this.runduzydoPK = this.runduzydoPK.bind(this);
    }
    home(){ this.setState({page:0});  }
    server = () => { confirmAlert({  customUI: ({ onClose }) => { return ( <div className='custom-ui'><h3>Komunikat serwera</h3><p>{this.state.server}</p> <button className="btn btn-danger btn-sm" onClick={onClose} >Zamknij</button>  </div> ); } });}
    submit = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
            return (
                <div className='custom-ui'>
                <h3>Wymagane potwierdzenie</h3>
                <p>Operacja, którą chcesz wykonać jest nieodwracalna i wpływa na strukturę danych. Czy chcesz skasować tą pozycję?</p>
                <button className="btn btn-secondary btn-sm" onClick={onClose}>Anuluj</button>
                &nbsp;
                <button
                    className="btn btn-danger btn-sm"
                    onClick={() => {
                    this.ask("deleteWniosek", this.state.idd);
                    onClose();
                    }}
                >
                    Usuń
                </button>
                </div>
            );
            }
        });}
        
    runFirmEngine()
    {
        ////console.log("RUN FIRM ENGINE");
        var self = this;
        setTimeout(() => {
           self.setState({douczenia: normalizeFirma(self.state.data), status: "Dane do nauki przygotowane"});
        }, 200);
        setTimeout(()=>{self.nauka();},500)
    }
    szybkiHipotekaIndywidualna()
    {
        this.ask("szybkaHipotekaIndywidualna", null);
    }
    duzyKartaPodatkowa()
    {
        this.ask("duzyKartaPodatkowa", null);
    }
    runduzyKartaPodatkowa()
    {
        var surowe = this.state.duzyKartaPodatkowa;
        var dane = normalizer.normalizeduzyKartaPodatkowa(surowe);
        this.setState({douczenia: dane});
        this.nauka2();
        //console.log("NORMALIZED",dane);
    }
    duzydoRyczaltu()
    {
        this.ask("duzydoRyczaltu", null);
    }
    runduzydoRyczaltu()
    {
        var surowe = this.state.duzydoRyczaltu;
        var dane = normalizer.normalizeDuzyDoRyczaltu(surowe);
        this.setState({douczenia:dane});
        this.nauka2();
        //console.log("NORMALIZED", dane);
    }
    duzydoKPiR()
    {
        this.ask("duzydoKPiR", null);
    }
    runduzydoKPiR()
    {
        var surowe = this.state.duzydoKPiR;
        var dane = normalizer.normalizeDuzyDoKPiR(surowe);
        this.setState({douczenia:dane});
        this.nauka2();
        //console.log("NORMALIZED", dane);
    }
    duzydoPK()
    {
        this.ask('duzydoPK', null);
    }

    runduzydoPK()
    {
        var surowe = this.state.duzydoPK;
        var dane = normalizer.normalizeDuzyDoPK(surowe);
        this.setState({douczenia:dane});
        this.nauka2();
    }
    runszybkaHipotekaIndywidualna()
    {
        var surowe = this.state.szybkaHipotekaIndywidualna;
        // WZOR "{"wartosc":"123","urodzony":"2019-12-04","cywilny":0,"lat":"24","zobowiazania":"777",
        // "linia":"888","dzieci":"9","nautrzymaniu":"10","wklad":"333","dochod":"666","stan":"1"}"
        var dane = normalizer.normalizehipoteczny(surowe);
        //console.log("NORMALIZED", dane); 
        this.setState({douczenia:dane});
        this.nauka2();
        //this.ask("ZapiszAI", JSON.stringify());
    }


    wolnaHipotekaIndywidualna()
    {
        this.ask('wolnaHipotekaIndywidualna', null);
    }
    runwolnaHipotekaIndywidualna()
    {
        
        var surowe = this.state.wolnaHipotekaIndywidualna;
        //console.log("WH PROCEDED", surowe);
        var dane = normalizer.normalizeDuzyhipoteczny(surowe);
        this.setState({douczenia:dane});
        this.nauka2();
        dane = normalizer.normalizeDuzyhipoteczny(surowe);
    }
    nauka2()
    {
        this.setState({status: "Trwa proces uczenia. To może zająć więcej czasu w zależności od ilości dostępnych danych "});
        var net = new brainjs.NeuralNetwork({hiddenLayers: [5]});
        //console.log("DO UCZENIA", this.state.douczenia);
        var douczenia = this.state.douczenia.dane;
        net.train(douczenia);
        console.log(douczenia,net.toJSON());
        //console.log(net.run([0,1,0,0,0,0.9]));
        this.setState({status: "Zapisywanie danych"});
        console.log(this.state);
        this.ask("saveAiEngine", JSON.stringify({silnik:{kozak:this.state.douczenia.kozak, data:net.toJSON()}, id:this.state.currentID}));
    }
    nauka()
    {
        this.setState({status: "Trwa proces uczenia. To może zająć więcej czasu w zależności od ilości dostępnych danych "});
        var net = new brainjs.NeuralNetwork({hiddenLayers: [5]});
        var douczenia = this.state.douczenia
        net.train(douczenia);
        console.log(douczenia,net.toJSON());
        //console.log(net.run([0,1,0,0,0,0.9]));
        this.setState({status: "Zapisywanie danych"});
        console.log(this.state);
        this.ask("saveAiEngine", JSON.stringify({silnik:net.toJSON(), id:this.state.currentID}));
    }
    naukaDlaOsob(dane)
    {
        //console.log("DANE",dane);
        this.setState({status: "Trwa proces uczenia. To może zająć więcej czasu w zależności od ilości dostępnych danych "});
        var net = new brainjs.NeuralNetwork();
        net.train(dane);
        //console.log("ALGORYTM",net.toJSON());
        this.ask("saveAiEngine", JSON.stringify({silnik:net.toJSON(), id:this.state.currentID}));
        
    }
      ask(resource, data=null)
      {
            var self = this;
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'admin');
            params.append('command', resource);
            params.append('data', data);
            params.append('token', Cookies.get("Experimental"));
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
              console.log(response.data.data);
              if (response.data.status==="OK")
              {
                self.setState({loading:false});
                  switch (resource){
                      case 'getSilniki': self.setState({maillist: response.data.data});return;
                      case 'aiGetFirmData':self.setState({data:response.data.data, status:"Dane wczytane"});self.runFirmEngine();break;
                      case 'saveAiEngine': self.setState({status: "Bezczynny", server:"Zapisano pomyślnie"});self.server(); self.ask("getSilniki"); break;
                      case 'getGus':self.setState({gus:response.data.data});break;
                      case 'aiGetUsersData':console.log(self.state.gus);self.setState({status:"Dane wczytane"});self.naukaDlaOsob(analizeuser.prepareData(self.state.gus, response.data.data));break;
                      case 'szybkaHipotekaIndywidualna':self.setState({szybkaHipotekaIndywidualna: response.data.data});self.runszybkaHipotekaIndywidualna();break;
                      case 'wolnaHipotekaIndywidualna':self.setState({wolnaHipotekaIndywidualna: response.data.data});self.runwolnaHipotekaIndywidualna();break;
                      case 'duzyKartaPodatkowa':self.setState({duzyKartaPodatkowa:response.data.data}); self.runduzyKartaPodatkowa();break;
                      case 'duzydoRyczaltu':self.setState({duzydoRyczaltu:response.data.data});self.runduzydoRyczaltu();break;
                      case 'duzydoKPiR':self.setState({duzydoKPiR: response.data.data}); self.runduzydoKPiR();break;
                      case 'duzydoPK':self.setState({duzydoPK:response.data.data});self.runduzydoPK();break;
                      case 'switchAI':self.ask('getSilniki', null); break;
                      default: return;
                  }
                 
              }
              self.setState({odpowiedz:response.data.desc});
             }).catch(function (error) {console.log(params,resource, error); self.setState({odpowiedz:"ERR"});});
      }
      componentDidMount(){
            this.ask("getSilniki", null); 
            this.ask("getGus", null); 
            this.setState({version: version});
        
      }
      buttonRouter(ev)
      {
          console.log(ev.target.dataset);
          var action = ev.target.dataset.action;
          var userId = ev.target.dataset.id;
          var dataset = ev.target.dataset;
          this.setState({algoritmID:ev.target.dataset.id});
          if (action==='info'){this.setState({page:1}); this.askk('getUserInfo', userId) }
          if (ev.target.dataset.opis==="Szybki do hipotek indywidualnych"){this.szybkiHipotekaIndywidualna();}
          if (ev.target.dataset.opis==="Duży do hipotek indywidualnych"){this.wolnaHipotekaIndywidualna();}
          if (ev.target.dataset.opis==="Duży do Karty Podatkowej"){this.duzyKartaPodatkowa();}
          if (ev.target.dataset.opis==="Duży do Ryczałtu"){this.duzydoRyczaltu();}
          if (ev.target.dataset.opis==="Duży do KPiR"){this.duzydoKPiR()}
          if (ev.target.dataset.opis==="Duży do pełnej księgowości"){this.duzydoPK()}
          if (ev.target.dataset.opis==="prezelacz"){this.ask("switchAI", JSON.stringify(ev.target.dataset))}
          
          if (action==="EditItem")
          {
              this.setState({currentID: userId});
              if (dataset.role==="2")
              {
                  
                  this.ask("aiGetFirmData",null);
              }
              if (dataset.role==="1")
              {
                  this.ask("aiGetUsersData", null);
              }
          }
         
      }
      
    render(){
    return (<div className="container">
        <div className="row">
            <div className="col-sm-12">
            <h3>Zarządzanie sztuczną inteligencją</h3>
            <div>
                STATUS: <span>{this.state.status}</span>
            </div>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <td>Aktualizował:</td>
                        <td>Opis</td>
                        <td>Data aktualizacji silnika</td>
                        <td>Operacje</td>
                    </tr>
                </thead>
                <tbody>

               
                    {this.state.maillist.map((key)=>(
                        <tr key={key.id}>
                            <td>{key.creator}</td>
                            <td>{key.widoczny}</td>
                            <td>{key.data}</td>
                            <td>
                                <div className="btn-group">
                                    <button className="btn btn-secondary btn-sm" onClick={this.buttonRouter} data-action="EditItem" data-id={key.id} data-role={key.user_role} data-opis={key.title}>Aktualizuj</button> 
                    <button className={(parseInt(key.active)===1) ? "btn btn-success btn-sm" :"btn btn-danger btn-sm"} onClick={this.buttonRouter} data-id={key.id} data-active={key.active} data-opis="prezelacz" >{(parseInt(key.active)===1) ? "Włączony" :"Wyłączony"} </button>
                                </div>    
                            </td>
                        </tr>
                    ))}
                        </tbody>
                </table>
            </div>
        </div>
        
    </div>)
      
}
}


export default Aintelligence;