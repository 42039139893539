import React  from "react";
import axios from 'axios';
import security from './security.js';
import Cookies from 'js-cookie';
import Termometr from './../common/termometr.js';
import checkIntegrity from './liczhipind.js';
import Creditofertsnologin from './../common/creditofertsnologin.js';
import strzalka from './../../img/strzalka.png';
import strzalkaL from './../../img/strzalkaR.png';



class UniversalResponseForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ready:false, checkNieruchomosc:{error:[], info:[], source:"code"},reCalc:true,
            checkZgody:{error:[], info:[], source:"code"},checkFinanse:{error:[],info:[], zdolnosc:0, procent:0, source:"code"}
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.changeVal = this.changeVal.bind(this);
        this.ask = this.ask.bind(this);
        
    }
    IsJsonString(str) { try { JSON.parse(str); } catch (e) { return false; } return true;}
    ask(resource, data = null)
    {
          var self = this;
          const params = new URLSearchParams(); params.append('asc',security.securityKey());
          params.append('section', 'user');
          params.append('command', resource);
          params.append('data', data);
          params.append('token', Cookies.get("Experimental"));
          axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            //console.log(response.data.data);
          if (response.data.status==="OK")
          {
          //self.setState({loading:false});
              switch (resource){
              case 'loadGmina':  self.setState({gminy: response.data.data, ready:true});self.analiseUser(response.data.data); return;
      
              default: return;
              }
              
          }
          else if(response.data.status==="TEST")
          {
          console.log(response.data);
          }
          else if (response.data.status==="err")
          {
             //self.setState({server: response.data.desc});self.server();
          }
          self.setState({odpowiedz:response.data.desc});
          }).catch(function (error) {console.log(error);});
          
      }

    
    componentDidMount() {
        this.setState({ready:true});
     
    }
    componentDidUpdate(){
        if (this.props)
        var nieruchomosc = checkIntegrity.checkNieruchomosc(this.props.form);
        //reset Analytics
        if (this.state.checkNieruchomosc.source==="calc" && this.props.page===11){this.setState({checkNieruchomosc:{error:[], info:[], source:"code"}});}
        if (this.state.checkZgody.source==="calc" && this.props.page===11){this.setState({checkZgody:{error:[], info:[], source:"code"}});}
        if (this.state.checkFinanse.source==="calc" && this.props.page===11){this.setState({checkFinanse:{error:[],info:[], zdolnosc:0, procent:0, source:"code"}});}
        if (this.state.checkNieruchomosc.source==="code" && this.props.page===12){this.setState({checkNieruchomosc:nieruchomosc});}
        if (this.state.checkZgody.source==="code" && this.props.page===12){ var zgody=checkIntegrity.checkZgody(this.props.form);  this.setState({checkZgody:zgody});}
        if (this.state.checkFinanse.source==="code" && this.props.page===12){var finanse = checkIntegrity.checkFinanse(this.props.form); this.setState({checkFinanse:finanse});}
        
        
        //console.log("UPDATED");
    }

    changeVal(ev)
    {
        console.log(ev.target.dataset);       
    }

    render(){
       if (this.props.page===12) 
    {
        if (this.state.ready)
        {
            return (
            <div>
                <h3>Analiza danych</h3>
                <div className="OdpForm">
                    Formularz wypełniono poprawnie
                    <button className="btn btn-primary" data-action="wroc" onClick={this.props.read}>Powrót do formularza</button>
                </div>
                <img className="nextStepR" src={strzalka} alt=""/>
                <Termometr procent={this.state.checkFinanse.procent} kwota={this.state.checkFinanse.zdolnosc}/>
                
                <br></br>
                <div>
                    <div className="LnextStep"><img className="nextStepL" src={strzalkaL} alt="" /></div>
                    <div className="RnextStep"> <Creditofertsnologin dane={this.props.kredyty} xml={this.props.xml} showOnly={this.props.showOnly} /></div>
                </div>
            </div>
           
        );}
            
            
        else
        {
            return (<div>Oczekiwanie na dane...</div>)
        }
    }
    else
    {
        return (<span></span>);
    }


}

}

export default UniversalResponseForm;