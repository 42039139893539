function validateZgoda(zg, val)
{
   if (parseInt(val)===1){return true;}else{return false;}
}
function validateTel(tel)
{
if (tel.length===0){return false;}
  var patt1 = /[1-9]/g;
  var result = tel.match(patt1);

  if (result===null){return false;}
  if (result.join("").length===9){return true;}else
    {return false;}
}

function validateKod(f){
    var tab=[];tab=f.split('-');
    if(tab.length!==2||tab[0].length!==2||tab[0]<0||tab[0]>99||tab[1].length!==3||tab[1]<0||tab[1]>999){return false;}else{return true;}
    }

function san(str)
{

    var odp = str;
    if (typeof odp !=='undefined'){
    var unic = ["u0119","u00f3","u0105","u015b","u0142","u017c","u017a","u0107","u0144","u0118","u00d3","u0104","u015a","u0141","u017b","u0179","u0106","u0143"];
    var lett = ["ę","ó","ą","ś","ł","ż","ź","ć","ń","Ę","Ó","Ą","Ś","Ł","Ż","Ź","Ć","Ń"]
    for (var a = 0; a<unic.length; a++)
    {
        if (odp.length>0){odp = odp.split(unic[a]).join(lett[a]);}
    }}
    return odp;
}

function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validatenip(nip) {
    var nipWithoutDashes = nip.replace(/-/g,"");
    var reg = /^[0-9]{10}$/;
    if(reg.test(nipWithoutDashes) === false) {
        return false;}
    else
    {
        var digits = (""+nipWithoutDashes).split("");
        var checksum = (6*parseInt(digits[0]) + 5*parseInt(digits[1]) + 7*parseInt(digits[2]) + 2*parseInt(digits[3]) + 3*parseInt(digits[4]) + 4*parseInt(digits[5]) + 5*parseInt(digits[6]) + 6*parseInt(digits[7]) + 7*parseInt(digits[8]))%11;
         
        return (parseInt(digits[9])===parseInt(checksum));
    }
}

function mod97(string) {
    var checksum = string.slice(0, 2), fragment;
    for (var offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
}

function isValidIBANNumber(input) {
    var CODE_LENGTHS = {
        AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
        CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
        FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
        HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
        LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
        MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
    };
    var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
            code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
            digits;
    // check syntax and length
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return letter.charCodeAt(0) - 55;
    });
    // final check
    return mod97(digits);
}


function validateDO(numer)
{
if (numer === null || numer.length !== 9) {return false;}
 
numer = numer.toUpperCase();
var letterValues = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
function getLetterValue(letter){return letterValues.indexOf(letter)}
 
for (var i=0; i<3; i++)
if (getLetterValue(numer[i]) < 10 || numer[i] === 'O' || numer === 'Q')
return false;
for (i=3; i<9; i++)
if (getLetterValue(numer[i]) < 0 || getLetterValue(numer[i]) > 9)
return false;

var sum = 7 * getLetterValue(numer[0]) +
3 * getLetterValue(numer[1]) +
1 * getLetterValue(numer[2]) +
7 * getLetterValue(numer[4]) +
3 * getLetterValue(numer[5]) +
1 * getLetterValue(numer[6]) +
7 * getLetterValue(numer[7]) +
3 * getLetterValue(numer[8]);
sum %= 10;
if (sum !== getLetterValue(numer[3])) {return false;}else{return true;}
} 


function isValidPesel(pesel) {
    let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
    let sum = 0;
    console.log(pesel);
    var controlNumber = parseInt(pesel.toString().substring(10, 11));
    for (let i = 0; i < weight.length; i++) {
        sum += (parseInt(pesel.toString().substring(i, i + 1)) * weight[i]);
    }
    sum = sum % 10;
    return 10 - sum === controlNumber;
}


function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function IsJsonString(str) { try { JSON.parse(str); } catch (e) { return false; } return true;}

module.exports = {
    numberWithCommas,
    IsJsonString,
    isValidPesel,
    validateDO,
    isValidIBANNumber,
    validatenip,
    validateEmail,
    san,
    validateKod,
    validateTel,
    validateZgoda,
}
