import React  from "react";
import axios from 'axios';
import security from './../common/security.js';
import Cookies from 'js-cookie';

import ReactTable from 'react-table';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

class Readcontactform extends React.Component{
    constructor(props) {
        super(props);
        this.state = { page:0, data:[], dane:[],loading:true, user:{}, opis:"", status:""
           
        };
        
        
        this.componentDidMount = this.componentDidMount.bind(this);
        this.ask = this.ask.bind(this);
        this.askk = this.askk.bind(this);
        this.home = this.home.bind(this);
        this.getLista = this.getLista.bind(this);
        this.MyCell = this.MyCell.bind(this);
        this.buttonRouter = this.buttonRouter.bind(this);
        this.zmianStatus = this.zmianStatus.bind(this);
        this.changeState = this.changeState.bind(this);
        this.loadChanges = this.loadChanges.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
      }
    home(){
        this.loadChanges();
        this.setState({page:0, server:""});
    }

    server = () => { confirmAlert({  customUI: ({ onClose }) => { return ( <div className='custom-ui'><h3>Komunikat serwera</h3><p>{this.state.server}</p> <button className="btn btn-danger btn-sm" onClick={onClose} >Zamknij</button>  </div> ); } });}

    san(str)
    {
        var odp = str;
        if (typeof odp !=='undefined'){
        var unic = ["u0119","u00f3","u0105","u015b","u0142","u017c","u017a","u0107","u0144","u0118","u00d3","u0104","u015a","u0141","u017b","u0179","u0106","u0143"];
        var lett = ["ę","ó","ą","ś","ł","ż","ź","ć","ń","Ę","Ó","Ą","Ś","Ł","Ż","Ź","Ć","Ń"]
        for (var a = 0; a<unic.length; a++)
        {
            odp = odp.split(unic[a]).join(lett[a]);
        }}
        return odp;
    }
    deleteItem()
    {
        this.ask("deleteContactForm", this.state.user.id);
    }
    changeState(ev)
    {
        //console.log(ev.target.value);
        var temp = this.state.user;
        temp.status = ev.target.value;
        this.setState({status:temp});
    }
    zmianStatus(){
        //console.log(this.state.user);
        this.ask('changeStatus', JSON.stringify({id:this.state.user.id, status:this.state.user.status}));
    }
    loadChanges()
    {
        this.ask('Readcontactform', null);
    }
    ask(resource, par)
    {
        var self = this;
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
        params.append('section', 'sprzedaz');
        params.append('command', resource);
        params.append('data',par );
        params.append('token', Cookies.get("Experimental"));
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
          console.log(response.data.data);
          if (response.data.status==="OK")
          {
            self.setState({loading:false});
              switch (resource){ 
                  case 'Readcontactform': self.setState({dane: response.data.data, page:0});break;
                  case 'getUserFormInfo': 
                    self.setState({user: response.data.data,
                        opis: response.data.data.tresc.length
                    });
                    break;
                case 'changeStatus':self.setState({server: response.data.desc});self.server(); break;
                case 'deleteContactForm': 
                    self.ask("Readcontactform", null);
                break;
                  default: return;
              }
             
          }
          self.setState({odpowiedz:response.data.data});
         }).catch(function (error) {console.log(error); self.setState({server: "Wystąpił błąd serwera"});self.server();});}
         

        buttonRouter(ev)
        {
            console.log(ev.target.dataset);
            if (ev.target.dataset.action==='info')
            {
                this.ask('getUserFormInfo', ev.target.dataset.id);
                this.setState({page:1});
            }
        }
        MyCell({ value, columnProps: { rest: { somefunc } } }) {
            return (
                <button type="button" className="btn btn-secondary btn-sm" data-id={value} data-action="info" onClick={this.buttonRouter}>Więcej</button>
                   
            )
          }
    componentDidMount()
    {
        this.askk();
    }
    askk()
    {
        if (this.state.loading===true)
        { 
            console.log(this.state.loading);
            this.setState({loading:false});
            this.ask('Readcontactform', null);
            
        }
    }
    getLista()
    {
        console.log(this.state);
    }
    parseUser(mail, id)
    {
        if (parseInt(id)===0)
        {
            return "Użytkownik niezalogowany";
        }
        else
        {
            return "Login: " + mail;
        }
    }
    render(){
        if (this.state.dane.length===0)
        {
            return (<div onClick={this.getLista}>Brak nieprzeczytanych wiadomości</div>)
        }
        else
        {
            if (this.state.page===0)
            {var userlist = this.state.dane;
            const columns = [
           
                {
              Header: 'Data',
              accessor: 'data',
              filterMethod: (filter, row) =>
                      row[filter.id].startsWith(filter.value) ||
                      row[filter.id].endsWith(filter.value)
            },
            {
              Header: 'Użytkownik',
              accessor: 'nazwa',
              filterMethod: (filter, row) =>
                      row[filter.id].startsWith(filter.value) ||
                      row[filter.id].endsWith(filter.value)
            },
            {
              Header: 'kontakt',
              accessor: 'kontakt',
              filterMethod: (filter, row) =>
                      row[filter.id].startsWith(filter.value) ||
                      row[filter.id].endsWith(filter.value)
            },
            {
                Header: 'temat',
                accessor: 'temat',
                filterMethod: (filter, row) =>
                        row[filter.id].startsWith(filter.value) ||
                        row[filter.id].endsWith(filter.value)
              },
            {
              Header: '#',
              accessor: 'id', // String-based value accessors!
              Cell: this.MyCell, getProps: () => ({ })
            },];

        return (<div> 
            <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><span onClick={this.home}>Lista</span></li>
                                    </ol>
                                </nav>
            <h3 onClick={this.getLista}>Lista formularzy kontaktowych</h3>
                        <ReactTable
                            data={userlist}
                            
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                            columns={columns}
                            loading={this.state.loading}
                            onFetchData={this.askk} 
                            previousText={'Poprzednia'}
                            nextText={'Następna'}
                            loadingText={ 'Ładowanie danych...'}
                            noDataText={ 'Nie znaleziono danych'}
                            pageText={'Strona'}
                            ofText={'z'}
                            rowsText={'wierszy'}
                            
                            // Accessibility Labels
                            pageJumpText={'skocz do strony'}
                            rowsSelectorText= {'wierszy na stronę'}
                            defaultPageSize={10}
                        />
            </div>);}
            else if (this.state.page===1)
            {
                var u = this.state.user
                return (<div>
                    <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><span onClick={this.home}>Lista</span></li>
                                        <li className="breadcrumb-item">Treść</li>
                                    </ol>
                                </nav>

                   <div className="row">
                       <div className="col-sm-9">
                            <div className="contact header">Data wysłania: {u.data} <br></br>
                            Kontakt zwrotny: {u.kontakt} Autor: {this.parseUser(u.mail, u.user_id)} </div>
                       </div>
                       <div className="col-sm-3">
                           <label>Status</label>
                           <select className="form-control" onChange={this.changeState} value={this.state.user.status}>
                               <option value="NEW">Nowy</option>
                               <option value="WORK">W trakcie</option>
                               <option value="END">Zakończony</option>
                           </select>
                       </div>
                   </div>

                    
                    <div className="contact subject">Temat: {u.temat}</div>
                    <div className="contact text">{u.tresc}
                    <div className="contact-footer"> {this.state.opis} znaków.</div>
                    </div>
                    <div>
                        <button className="btn btn-secondary btn-sm" onClick={this.zmianStatus}>Zapisz zmiany</button>
                        {"   "}
                        <button className="btn btn-danger btn-sm" onClick={this.deleteItem}>Usuń</button>
                    </div>
                </div>)
            }
            else{
                console.log(this.state.page);
                return (<div onClick={this.getLista}>Coś się pojebawszy...</div>)
            }
        }
}

}
export default Readcontactform;