var woj0 = '[]';
var woj1 = '[{"powiat":"Powiat bolesławiecki"},{"powiat":"Powiat dzierżoniowski"},{"powiat":"Powiat głogowski"},{"powiat":"Powiat górowski"},{"powiat":"Powiat jaworski"},{"powiat":"Powiat jeleniogórski"},{"powiat":"Powiat kamiennogórski"},{"powiat":"Powiat kłodzki"},{"powiat":"Powiat legnicki"},{"powiat":"Powiat lubański"},{"powiat":"Powiat lubiński"},{"powiat":"Powiat lwówecki"},{"powiat":"Powiat m. Jelenia Góra"},{"powiat":"Powiat m. Legnica"},{"powiat":"Powiat m. Wałbrzych"},{"powiat":"Powiat m. Wrocław"},{"powiat":"Powiat milicki"},{"powiat":"Powiat oleśnicki"},{"powiat":"Powiat oławski"},{"powiat":"Powiat polkowicki"},{"powiat":"Powiat średzki"},{"powiat":"Powiat strzeliński"},{"powiat":"Powiat świdnicki"},{"powiat":"Powiat trzebnicki"},{"powiat":"Powiat wałbrzyski"},{"powiat":"Powiat wołowski"},{"powiat":"Powiat wrocławski"},{"powiat":"Powiat ząbkowicki"},{"powiat":"Powiat zgorzelecki"},{"powiat":"Powiat złotoryjski"}]';
var woj2 ='[{"powiat":"Powiat aleksandrowski"},{"powiat":"Powiat brodnicki"},{"powiat":"Powiat bydgoski"},{"powiat":"Powiat chełmiński"},{"powiat":"Powiat golubsko-dobrzyński"},{"powiat":"Powiat grudziądzki"},{"powiat":"Powiat inowrocławski"},{"powiat":"Powiat lipnowski"},{"powiat":"Powiat m. Bydgoszcz"},{"powiat":"Powiat m. Grudziądz"},{"powiat":"Powiat m. Toruń"},{"powiat":"Powiat m. Włocławek"},{"powiat":"Powiat mogileński"},{"powiat":"Powiat nakielski"},{"powiat":"Powiat radziejowski"},{"powiat":"Powiat rypiński"},{"powiat":"Powiat sępoleński"},{"powiat":"Powiat świecki"},{"powiat":"Powiat toruński"},{"powiat":"Powiat tucholski"},{"powiat":"Powiat wąbrzeski"},{"powiat":"Powiat włocławski"},{"powiat":"Powiat żniński"}]';
var woj3 ='[{"powiat":"Powiat bialski"},{"powiat":"Powiat biłgorajski"},{"powiat":"Powiat chełmski"},{"powiat":"Powiat hrubieszowski"},{"powiat":"Powiat janowski"},{"powiat":"Powiat kraśnicki"},{"powiat":"Powiat krasnostawski"},{"powiat":"Powiat lubartowski"},{"powiat":"Powiat lubelski"},{"powiat":"Powiat łęczyński"},{"powiat":"Powiat łukowski"},{"powiat":"Powiat m. Biała Podlaska"},{"powiat":"Powiat m. Chełm"},{"powiat":"Powiat m. Lublin"},{"powiat":"Powiat m. Zamość"},{"powiat":"Powiat opolski"},{"powiat":"Powiat parczewski"},{"powiat":"Powiat puławski"},{"powiat":"Powiat radzyński"},{"powiat":"Powiat rycki"},{"powiat":"Powiat świdnicki"},{"powiat":"Powiat tomaszowski"},{"powiat":"Powiat włodawski"},{"powiat":"Powiat zamojski"}]';
var woj4 ='[{"powiat":"Powiat gorzowski"},{"powiat":"Powiat krośnieński"},{"powiat":"Powiat m. Gorzów Wielkopolski"},{"powiat":"Powiat m. Zielona Góra"},{"powiat":"Powiat międzyrzecki"},{"powiat":"Powiat nowosolski"},{"powiat":"Powiat słubicki"},{"powiat":"Powiat strzelecko-drezdenecki"},{"powiat":"Powiat sulęciński"},{"powiat":"Powiat świebodziński"},{"powiat":"Powiat wschowski"},{"powiat":"Powiat żagański"},{"powiat":"Powiat żarski"},{"powiat":"Powiat zielonogórski"}]';
var woj5 ='[{"powiat":"Powiat bełchatowski"},{"powiat":"Powiat brzeziński"},{"powiat":"Powiat kutnowski"},{"powiat":"Powiat łaski"},{"powiat":"Powiat łęczycki"},{"powiat":"Powiat łódzki wschodni"},{"powiat":"Powiat łowicki"},{"powiat":"Powiat m. Łódź"},{"powiat":"Powiat m. Piotrków Trybunalski"},{"powiat":"Powiat m. Skierniewice"},{"powiat":"Powiat opoczyński"},{"powiat":"Powiat pabianicki"},{"powiat":"Powiat pajęczański"},{"powiat":"Powiat piotrkowski"},{"powiat":"Powiat poddębicki"},{"powiat":"Powiat radomszczański"},{"powiat":"Powiat rawski"},{"powiat":"Powiat sieradzki"},{"powiat":"Powiat skierniewicki"},{"powiat":"Powiat tomaszowski"},{"powiat":"Powiat wieluński"},{"powiat":"Powiat wieruszowski"},{"powiat":"Powiat zduńskowolski"},{"powiat":"Powiat zgierski"}]';
var woj6 ='[{"powiat":"Powiat bocheński"},{"powiat":"Powiat brzeski"},{"powiat":"Powiat chrzanowski"},{"powiat":"Powiat dąbrowski"},{"powiat":"Powiat gorlicki"},{"powiat":"Powiat krakowski"},{"powiat":"Powiat limanowski"},{"powiat":"Powiat m. Kraków"},{"powiat":"Powiat m. Nowy Sącz"},{"powiat":"Powiat m. Tarnów"},{"powiat":"Powiat miechowski"},{"powiat":"Powiat myślenicki"},{"powiat":"Powiat nowosądecki"},{"powiat":"Powiat nowotarski"},{"powiat":"Powiat olkuski"},{"powiat":"Powiat oświęcimski"},{"powiat":"Powiat proszowicki"},{"powiat":"Powiat suski"},{"powiat":"Powiat tarnowski"},{"powiat":"Powiat tatrzański"},{"powiat":"Powiat wadowicki"},{"powiat":"Powiat wielicki"}]';
var woj7 ='[{"powiat":"Powiat białobrzeski"},{"powiat":"Powiat ciechanowski"},{"powiat":"Powiat garwoliński"},{"powiat":"Powiat gostyniński"},{"powiat":"Powiat grodziski"},{"powiat":"Powiat grójecki"},{"powiat":"Powiat kozienicki"},{"powiat":"Powiat legionowski"},{"powiat":"Powiat lipski"},{"powiat":"Powiat łosicki"},{"powiat":"Powiat m. Ostrołęka"},{"powiat":"Powiat m. Płock"},{"powiat":"Powiat m. Radom"},{"powiat":"Powiat m. Siedlce"},{"powiat":"Powiat m. st. Warszawa"},{"powiat":"Powiat makowski"},{"powiat":"Powiat miński"},{"powiat":"Powiat mławski"},{"powiat":"Powiat nowodworski"},{"powiat":"Powiat ostrołęcki"},{"powiat":"Powiat ostrowski"},{"powiat":"Powiat otwocki"},{"powiat":"Powiat piaseczyński"},{"powiat":"Powiat płocki"},{"powiat":"Powiat płoński"},{"powiat":"Powiat pruszkowski"},{"powiat":"Powiat przasnyski"},{"powiat":"Powiat przysuski"},{"powiat":"Powiat pułtuski"},{"powiat":"Powiat radomski"},{"powiat":"Powiat siedlecki"},{"powiat":"Powiat sierpecki"},{"powiat":"Powiat sochaczewski"},{"powiat":"Powiat sokołowski"},{"powiat":"Powiat szydłowiecki"},{"powiat":"Powiat warszawski zachodni"},{"powiat":"Powiat węgrowski"},{"powiat":"Powiat wołomiński"},{"powiat":"Powiat wyszkowski"},{"powiat":"Powiat żuromiński"},{"powiat":"Powiat zwoleński"},{"powiat":"Powiat żyrardowski"}]';
var woj8 ='[{"powiat":"Powiat brzeski"},{"powiat":"Powiat głubczycki"},{"powiat":"Powiat kędzierzyńsko-kozielski"},{"powiat":"Powiat kluczborski"},{"powiat":"Powiat krapkowicki"},{"powiat":"Powiat m. Opole"},{"powiat":"Powiat namysłowski"},{"powiat":"Powiat nyski"},{"powiat":"Powiat oleski"},{"powiat":"Powiat opolski"},{"powiat":"Powiat prudnicki"},{"powiat":"Powiat strzelecki"}]';
var woj9 ='[{"powiat":"Powiat bieszczadzki"},{"powiat":"Powiat brzozowski"},{"powiat":"Powiat dębicki"},{"powiat":"Powiat jarosławski"},{"powiat":"Powiat jasielski"},{"powiat":"Powiat kolbuszowski"},{"powiat":"Powiat krośnieński"},{"powiat":"Powiat leski"},{"powiat":"Powiat leżajski"},{"powiat":"Powiat lubaczowski"},{"powiat":"Powiat łańcucki"},{"powiat":"Powiat m. Krosno"},{"powiat":"Powiat m. Przemyśl"},{"powiat":"Powiat m. Rzeszów"},{"powiat":"Powiat m. Tarnobrzeg"},{"powiat":"Powiat mielecki"},{"powiat":"Powiat niżański"},{"powiat":"Powiat przemyski"},{"powiat":"Powiat przeworski"},{"powiat":"Powiat ropczycko-sędziszowski"},{"powiat":"Powiat rzeszowski"},{"powiat":"Powiat sanocki"},{"powiat":"Powiat stalowowolski"},{"powiat":"Powiat strzyżowski"},{"powiat":"Powiat tarnobrzeski"}]';
var woj10 ='[{"powiat":"Powiat augustowski"},{"powiat":"Powiat białostocki"},{"powiat":"Powiat bielski"},{"powiat":"Powiat grajewski"},{"powiat":"Powiat hajnowski"},{"powiat":"Powiat kolneński"},{"powiat":"Powiat łomżyński"},{"powiat":"Powiat m. Białystok"},{"powiat":"Powiat m. Łomża"},{"powiat":"Powiat m. Suwałki"},{"powiat":"Powiat moniecki"},{"powiat":"Powiat sejneński"},{"powiat":"Powiat siemiatycki"},{"powiat":"Powiat sokólski"},{"powiat":"Powiat suwalski"},{"powiat":"Powiat wysokomazowiecki"},{"powiat":"Powiat zambrowski"}]';
var woj11 ='[{"powiat":"Powiat bytowski"},{"powiat":"Powiat chojnicki"},{"powiat":"Powiat człuchowski"},{"powiat":"Powiat gdański"},{"powiat":"Powiat kartuski"},{"powiat":"Powiat kościerski"},{"powiat":"Powiat kwidzyński"},{"powiat":"Powiat lęborski"},{"powiat":"Powiat m. Gdańsk"},{"powiat":"Powiat m. Gdynia"},{"powiat":"Powiat m. Słupsk"},{"powiat":"Powiat m. Sopot"},{"powiat":"Powiat malborski"},{"powiat":"Powiat nowodworski"},{"powiat":"Powiat pucki"},{"powiat":"Powiat słupski"},{"powiat":"Powiat starogardzki"},{"powiat":"Powiat sztumski"},{"powiat":"Powiat tczewski"},{"powiat":"Powiat wejherowski"}]';
var woj12 ='[{"powiat":"Powiat będziński"},{"powiat":"Powiat bielski"},{"powiat":"Powiat bieruńsko-lędziński"},{"powiat":"Powiat cieszyński"},{"powiat":"Powiat częstochowski"},{"powiat":"Powiat gliwicki"},{"powiat":"Powiat kłobucki"},{"powiat":"Powiat lubliniecki"},{"powiat":"Powiat m. Bielsko-Biała"},{"powiat":"Powiat m. Bytom"},{"powiat":"Powiat m. Chorzów"},{"powiat":"Powiat m. Częstochowa"},{"powiat":"Powiat m. Dąbrowa Górnicza"},{"powiat":"Powiat m. Gliwice"},{"powiat":"Powiat m. Jastrzębie-Zdrój"},{"powiat":"Powiat m. Jaworzno"},{"powiat":"Powiat m. Katowice"},{"powiat":"Powiat m. Mysłowice"},{"powiat":"Powiat m. Piekary 12"},{"powiat":"Powiat m. Ruda Śląska"},{"powiat":"Powiat m. Rybnik"},{"powiat":"Powiat m. Siemianowice 12"},{"powiat":"Powiat m. Sosnowiec"},{"powiat":"Powiat m. Świętochłowice"},{"powiat":"Powiat m. Tychy"},{"powiat":"Powiat m. Zabrze"},{"powiat":"Powiat m. Żory"},{"powiat":"Powiat mikołowski"},{"powiat":"Powiat myszkowski"},{"powiat":"Powiat pszczyński"},{"powiat":"Powiat raciborski"},{"powiat":"Powiat rybnicki"},{"powiat":"Powiat tarnogórski"},{"powiat":"Powiat wodzisławski"},{"powiat":"Powiat zawierciański"},{"powiat":"Powiat żywiecki"}]';
var woj13 ='[{"powiat":"Powiat buski"},{"powiat":"Powiat jędrzejowski"},{"powiat":"Powiat kazimierski"},{"powiat":"Powiat kielecki"},{"powiat":"Powiat konecki"},{"powiat":"Powiat m. Kielce"},{"powiat":"Powiat opatowski"},{"powiat":"Powiat ostrowiecki"},{"powiat":"Powiat pińczowski"},{"powiat":"Powiat sandomierski"},{"powiat":"Powiat skarżyski"},{"powiat":"Powiat starachowicki"},{"powiat":"Powiat staszowski"},{"powiat":"Powiat włoszczowski"}]';
var woj14 ='[{"powiat":"Powiat bartoszycki"},{"powiat":"Powiat braniewski"},{"powiat":"Powiat działdowski"},{"powiat":"Powiat elbląski"},{"powiat":"Powiat ełcki"},{"powiat":"Powiat giżycki"},{"powiat":"Powiat gołdapski"},{"powiat":"Powiat iławski"},{"powiat":"Powiat kętrzyński"},{"powiat":"Powiat lidzbarski"},{"powiat":"Powiat m. Elbląg"},{"powiat":"Powiat m. Olsztyn"},{"powiat":"Powiat mrągowski"},{"powiat":"Powiat nidzicki"},{"powiat":"Powiat nowomiejski"},{"powiat":"Powiat olecki"},{"powiat":"Powiat olsztyński"},{"powiat":"Powiat ostródzki"},{"powiat":"Powiat piski"},{"powiat":"Powiat szczycieński"},{"powiat":"Powiat węgorzewski"}]';
var woj15 ='[{"powiat":"Powiat chodzieski"},{"powiat":"Powiat czarnkowsko-trzcianecki"},{"powiat":"Powiat gnieźnieński"},{"powiat":"Powiat gostyński"},{"powiat":"Powiat grodziski"},{"powiat":"Powiat jarociński"},{"powiat":"Powiat kaliski"},{"powiat":"Powiat kępiński"},{"powiat":"Powiat kolski"},{"powiat":"Powiat koniński"},{"powiat":"Powiat kościański"},{"powiat":"Powiat krotoszyński"},{"powiat":"Powiat leszczyński"},{"powiat":"Powiat m. Kalisz"},{"powiat":"Powiat m. Konin"},{"powiat":"Powiat m. Leszno"},{"powiat":"Powiat m. Poznań"},{"powiat":"Powiat międzychodzki"},{"powiat":"Powiat nowotomyski"},{"powiat":"Powiat obornicki"},{"powiat":"Powiat ostrowski"},{"powiat":"Powiat ostrzeszowski"},{"powiat":"Powiat pilski"},{"powiat":"Powiat pleszewski"},{"powiat":"Powiat poznański"},{"powiat":"Powiat rawicki"},{"powiat":"Powiat słupecki"},{"powiat":"Powiat średzki"},{"powiat":"Powiat śremski"},{"powiat":"Powiat szamotulski"},{"powiat":"Powiat turecki"},{"powiat":"Powiat wągrowiecki"},{"powiat":"Powiat wolsztyński"},{"powiat":"Powiat wrzesiński"},{"powiat":"Powiat złotowski"}]';
var woj16='[{"powiat":"Powiat białogardzki"},{"powiat":"Powiat choszczeński"},{"powiat":"Powiat drawski"},{"powiat":"Powiat goleniowski"},{"powiat":"Powiat gryficki"},{"powiat":"Powiat gryfiński"},{"powiat":"Powiat kamieński"},{"powiat":"Powiat kołobrzeski"},{"powiat":"Powiat koszaliński"},{"powiat":"Powiat łobeski"},{"powiat":"Powiat m. Koszalin"},{"powiat":"Powiat m. Świnoujście"},{"powiat":"Powiat m. Szczecin"},{"powiat":"Powiat myśliborski"},{"powiat":"Powiat policki"},{"powiat":"Powiat pyrzycki"},{"powiat":"Powiat sławieński"},{"powiat":"Powiat stargardzki"},{"powiat":"Powiat świdwiński"},{"powiat":"Powiat szczecinecki"},{"powiat":"Powiat wałecki"}]';

function geo(id)
{
    var odp;
    switch (id)
    {
        case '0':odp = JSON.parse(woj0);break;
        case '1':odp = JSON.parse(woj1);break;
        case '2':odp = JSON.parse(woj2);break;
        case '3':odp = JSON.parse(woj3);break;
        case '4':odp = JSON.parse(woj4);break;
        case '5':odp = JSON.parse(woj5);break;
        case '6':odp = JSON.parse(woj6);break;
        case '7':odp = JSON.parse(woj7);break;
        case '8':odp = JSON.parse(woj8);break;
        case '9':odp = JSON.parse(woj9);break;
        case '10':odp = JSON.parse(woj10);break;
        case '11':odp = JSON.parse(woj11);break;
        case '12':odp = JSON.parse(woj12);break;
        case '13':odp = JSON.parse(woj13);break;
        case '14':odp = JSON.parse(woj14);break;
        case '15':odp = JSON.parse(woj15);break;
        case '16':odp = JSON.parse(woj16);break;
        default:break;
    }
    
    return odp;

}
module.exports = {
   geo,

}