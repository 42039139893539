export function engpol(str)
{
    var odp="";
    switch (str)
    {
        case "true": odp = "Tak";break;
        case "false":odp = "Nie";break;
        case false:odp = "Nie";break;
        default:odp=""; break;
    }
    return odp;
}

export function  slownik(str)
{
    var odp = "";
    switch (str)
    {
        case "FB": odp = "Facebook";break;
        default:odp="Uwierzytelnienie poprzez hasło i adres mail";break;
    }
    return odp;
}
export function sanFoto(str){if (str.length>0){return str;}else{return window.location.protocol+"//"+window.location.hostname+'/sysimg/defaultuser.png';}}
export function doswiadczenie(s){if (s.length===0){return "Brak zapisanego doświadczenia";}else{return "";}}
export function wyksztalcenie(n)
{
 var odp =["Brak odpowiedzi", "Podstawowe", "Średnie", "Średnie techniczne", "Wyższe", "Wyższe techniczne", "Stopień naukowy"];
return odp[parseInt(n)];
}

export function stanCywilny(n)
{
    var odp = ['Brak odpowiedzi', 'Kawaler/Panna', 'W związku małżeńskim', 'W wolnym związku', 'Wdowiec/Wdowa', 'Rozwiedziony'];
    return odp[parseInt(n)];
}
