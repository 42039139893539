import React  from "react";
import axios from 'axios';
import security from './../common/security.js';
import Cookies from 'js-cookie';

import ReactTable from 'react-table';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Renderwniosek from './../seller/renderwniosek.js';
import AF from './../common/analizafirm.js';

import normalizer from './../common/ainormalization.js';
import brainjs from 'brainjs';
import AiSmart from './aismart.js';
import {IoIosAlbums} from "react-icons/io";
class Konsultacje extends React.Component{
    constructor(props) {
        super(props);
        this.state = { page:0, data:[], dane:[],loading:true, user:{}, opis:"", status:"",formID:"", formularz:{},
        statusy:[],
        StyleSheet:{datapanel:{display:"none"}, messagepanel:{display:"none"}, endpanel:{display:"none"}},
        analiza:"<h5>Analiza</h5><div>Jeszcze nie dokonywano analizy</div>", engines:[],
        smart:{}, deepth:{},
           
        };
        
        
        this.componentDidMount = this.componentDidMount.bind(this);
        // this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.ask = this.ask.bind(this);
        this.askk = this.askk.bind(this);
        this.home = this.home.bind(this);
        this.getLista = this.getLista.bind(this);
        this.MyCell = this.MyCell.bind(this);
        this.buttonRouter = this.buttonRouter.bind(this);
        this.zmianStatus = this.zmianStatus.bind(this);
        this.changeState = this.changeState.bind(this);
        this.loadChanges = this.loadChanges.bind(this);
        this.saveState = this.saveState.bind(this);
        this.drawWyniki = this.drawWyniki.bind(this);
        this.analiza = this.analiza.bind(this);
        this.oblicz = this.oblicz.bind(this);
        this.selectEngine = this.selectEngine.bind(this);
        this.beginAnalisys = this.beginAnalisys.bind(this);
        this.downloadDocument = this.downloadDocument.bind(this);
      }

   
    home(){
        this.loadChanges();
        this.setState({page:0, server:"", analiza:AF.analiza("NULL"), StyleSheet:{datapanel:{display:"none"}, endpanel:{display:"none"},messagepanel:{display:"none"}}});
    }

    server = () => { confirmAlert({  customUI: ({ onClose }) => { return ( <div className='custom-ui'><h3>Komunikat serwera</h3><p>{this.state.server}</p> <button className="btn btn-danger btn-sm" onClick={onClose} >Zamknij</button>  </div> ); } });}
    downloadDocument(ev)
    {
        console.log(ev.target.dataset);
        this.ftp('sellerDownload', JSON.stringify({user:ev.target.dataset.user, file:ev.target.dataset.document}));



        var name = ev.target.dataset.document;
        var server = window.location.protocol+ "//" + window.location.hostname;
        // //console.log("FOR DOWNLOAD",ev.target.dataset);
       
        setTimeout(() => {
            const response = {
              file: server + "/download/" + name,
            };
            window.open(response.file);

          }, 1000);
        var self = this;
        setTimeout(() => {
            self.ftp("unlink", name );
        }, 20000);








    }
    analiza()
    {
        if (AF.exists(this.state.user.wniosek))
        {
            var e = AF.analiza(this.state.user.wniosek.content);
            this.setState({analiza:e});
        }
    }
    
    drawWyniki()
    {
        if (AF.exists(this.state.user.wniosek) )
        {

            //console.log("DRAW WYNIKI");
            var e = AF.analiza(this.state.user.wniosek.content);
            this.setState({analiza:e});
        }
    }
    changeState(ev)
    {
        // console.log(ev.target.value);
        var temp = this.state.user;//.wniosek.status
        var st = this.state.StyleSheet;
        st.messagepanel={display:"block"};
        temp.wniosek.status = ev.target.value;
        if (parseInt(ev.target.value)===13){st.datapanel={display:"block"}}else{st.datapanel={display:"none"}};
        if (parseInt(ev.target.value)>23){
            st.endpanel={display:"block"}; 
            st.messagepanel={display:"none"};
        }
        else
        {
            st.endpanel={display:"none"}; 
        }
       
        
        //temp.status = ev.target.value;
        this.setState({user:temp, StyleSheet:st});
        
        //this.ask("changeStatus", JSON.stringify({status:ev.target.value, id:this.state.user.wniosek.ide, text:document.getElementById("message").value}));
    }

    saveState(ev)
    {
        if (ev.target.dataset.action==="cancel")
        {
            var st = this.state.StyleSheet;
            st.messagepanel={display:"none"};
            this.setState({StyleSheet:st});
        }
        if (ev.target.dataset.action==="save")
        {

        
            var flag = true;
            if (document.getElementById("message").value==="")
            {
                document.getElementById("message").classList.add("red"); flag=false;
            }
            else
            {
                document.getElementById("message").classList.remove("red"); flag=true;
            }

            if (parseInt(this.state.user.wniosek.status) === 13)
            {
                if (Date.parse(document.getElementById("dataSpotkania").value)>0)
                {
                    document.getElementById("dataSpotkania").classList.remove("red"); flag=true;
                }
                else
                {
                    document.getElementById("dataSpotkania").classList.add("red");flag=false;
                }
            }
            if (flag===true)     
            {        
                this.ask("changeStatus", JSON.stringify({status:this.state.user.wniosek.status, id:this.state.user.wniosek.ide, text:document.getElementById("message").value, date:document.getElementById("dataSpotkania").value}));
            }   
        } 
        if (ev.target.dataset.action==="ai")
        {
            this.ask("saveEndStatus", JSON.stringify({status:this.state.user.wniosek.status,id:this.state.user.wniosek.ide,wynik:ev.target.dataset.odp}));
        }
    }

    zmianStatus(){
        //console.log(this.state.user);
        this.ask('changeStatus', JSON.stringify({id:this.state.user.id, status:this.state.user.status}));
    }
    loadChanges()
    {
        this.ask('readYourApplications', null);
    }
    unpackComment(e)
    {
        if (AF.IsJsonString(e))
        {
            //console.log("Historia Wniosku", e)
            var mess = JSON.parse(e);
            if (AF.exists(mess.bylo))
            {
                return "Zmiana dokumentów przez uytkownika. Było " + mess.bylo.dokumenty.join(", ") + " jest: " + mess.jest.dokumenty.join(", ") 
            }
            if (AF.exists(mess.dokument))
            {
                if (mess.dokument==="wpis"){return mess.adres;}
            }
            else if (AF.exists(mess.spotkanie))
            {
                if (mess.spotkanie==="proponowane"){return "Propozycja spotkania w dniu " + mess.data + " adres: " + AF.san(mess.adres);}
                if (mess.spotkanie==="zaakceptowane"){return "Klient zaakceptował termin " + mess.data + " adres: " + AF.san(mess.adres);}
            }
        }
        
        else
        {
            return e;
        }
    }
    beginAnalisys(d)
    {
        var wnios;
        if (normalizer.IsJsonString(d.wniosek.content))
        {
            
            var flag = false;
            wnios = JSON.parse(d.wniosek.content);
           var formularz = [];var deepthFormularz=[];
            // Mala analiza, pobiera silnik
            var engine; var deepthengine;
            var eng4 = ['hipInd', 'hipMal'];
            if ( eng4.includes(wnios.formID))
            {
                // ====================== SZTUCZNA INTELIGENCJA KLIENT INDYWIDUALNY ============================
                engine = this.selectEngine(4);
                deepthengine = this.selectEngine(5);

                formularz = normalizer.normalizeHipIndForm(wnios,this.state.engines);
                deepthFormularz = normalizer.normalizeHipIndDepthForm(wnios,this.state.engines);
                flag = true;
                var eng = JSON.parse(engine.dane).data;
                var deepeng = JSON.parse(deepthengine.dane).data;
                //console.log("NORMALIZED FORM",formularz, engine, eng);
            // ===================================================== SZTUCZNA INTELIGENCJA - SMART =====================================
            var net = new brainjs.NeuralNetwork({hiddenLayers: [5]});
           
            net.fromJSON(eng);
            var wynik = net.run(formularz);
            //console.log("WYNIK",wynik);
            
            // ====================================================== SZTUCZNA INTELIGENCJA - DEEPTH ===================================
            net.fromJSON(deepeng);
            var dwynik = net.run(deepthFormularz);
    
            this.setState({smart:wynik, deepth:dwynik});
            }

            // ==================================================== SZTUCZNA INTELIGENCJA KLIENT FIRMOWY =====================
            var eng6 = ['firmaFactoring', 'firmLeasing','firmRatal', 'firmObrot'];
            if ( eng6.includes(wnios.formID))
            {
                var ksiegowosc = parseInt(wnios.ksiegowosc);
                var ksiegowoscEngines = [0,6,7,8,9];
                engine = this.selectEngine(ksiegowoscEngines[ksiegowosc]);
                var eng = JSON.parse(engine.dane).data;
                //console.log("SELECTED ENGINE",eng);
                formularz = normalizer.normalizeFirmForm(wnios,engine);
                var net = new brainjs.NeuralNetwork({hiddenLayers: [5]});
                net.fromJSON(eng);
                var wynik = net.run(formularz);
                //console.log("WYNIK AI",wynik);
                this.setState({smart:wynik, deepth:wynik});
                //this.setState({deepth:dwynik});
                flag = true;
            }


            //  =================================================== RESTART DANYCH DLA BRAKU ANALIZ ==========================
            if (!flag)
            {
                this.setState({smart:{}, deepth:{}});
                return;
            }
        
        }
    }
    selectEngine(id)
    {
        var engines = this.state.engines;
        for (var a = 0; a<engines.length; a++)
        {
            if (parseInt(engines[a].id)===id){return engines[a];}
        }
    }
    oblicz(d, id)
    {
        var engine = this.selectEngine(id);
        var eng = JSON.parse(engine);
        //console.log("Do NORMALIZACJI", d, eng.kozak)
        // var data = normalizer;
    }
    ask(resource, par)
    {
        var self = this;
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
        params.append('section', 'sprzedaz');
        params.append('command', resource);
        params.append('data',par );
        params.append('token', Cookies.get("Experimental"));
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
        //   console.log(response.data.data);
          if (response.data.status==="OK")
          {
            self.setState({loading:false});
              switch (resource){ 
                  case 'readYourApplications': self.setState({dane: response.data.data});break;
                  case 'getOneApplication': 
                    self.setState({user: response.data.data, page:1}); 
                    self.beginAnalisys(response.data.data);
                    break;
                case 'changeStatus':
                    self.ask("updateStory", self.state.user.wniosek.ide);
                    //self.setState({server: response.data.desc});self.server(); 
                    break;
                case 'getFormularz': self.setState({formularz: response.data.data, formID: par});break;
                case 'getSDtatusList': self.setState({statusy: response.data.data});break;
                case 'saveEndStatus':self.ask("updateStory", self.state.user.wniosek.ide);break;
                case 'updateStory':
                    var odp = self.state.user;
                    odp.log = response.data.data;
                    self.setState({user:odp});
                    break;
                case 'getEngines':self.setState({engines: response.data.data});break;
                  default: return;
              }
             
          }
          self.setState({odpowiedz:response.data.data});
         }).catch(function (error) {console.log(error); self.setState({server: "Wystąpił błąd serwera"});self.server();});}
         
         ftp(resource, par)
         {
             var self = this;
             const params = new URLSearchParams(); params.append('asc',security.securityKey());
             params.append('section', 'documents');
             params.append('command', resource);
             params.append('data',par );
             params.append('token', Cookies.get("Experimental"));
             axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
               console.log(response.data.data);
               if (response.data.status==="OK")
               {
                   switch (resource){ 
                       case 'readYourApplications': self.setState({dane: response.data.data});break;
                       
                       default: return;
                   }
                  
               }
               self.setState({odpowiedz:response.data.data});
              }).catch(function (error) {console.log(error); self.setState({server: "Wystąpił błąd serwera"});self.server();});}
              
     
        buttonRouter(ev)
        {
            // console.log(ev.target.dataset);
            if (ev.target.dataset.action==='info')
            {
                this.ask('getOneApplication', ev.target.dataset.id);
                
            }
        }
        MyCell({ value, columnProps: { rest: { somefunc } } }) {
            return (
                <button type="button" className="btn btn-secondary btn-sm" data-id={value} data-action="info" onClick={this.buttonRouter}>Więcej </button>
                   
            )
          }
      
    componentDidMount()
    {
        this.askk();
        this.ask("getSDtatusList", null);
        this.ask('getEngines', null);
    }
    askk()
    {
        if (this.state.loading===true)
        { 
            this.setState({loading:false});
            this.ask('readYourApplications', null);
            
        }
    }
    getLista()
    {
        console.log(this.state);
    }
    parseUser(mail, id)
    {
        if (parseInt(id)===0)
        {
            return "Użytkownik niezalogowany";
        }
        else
        {
            return "Login: " + mail;
        }
    }
    render(){
        if (this.state.dane.length===0)
        {
            return (<div className="container">
                <div className="row">
                    <div className="col-sm-12 listadokumentow">
                        <IoIosAlbums className="bigIcon" />
                        <div className="no doc">Brak nieprzeczytanych wiadomości</div>
                    </div>
                </div>
                </div>)
        }
        else
        {
            if (this.state.page===0)
            {
                var userlist = this.state.dane;
            const columns = [
           
                {
              Header: 'Data',
              accessor: 'data_create',
              filterMethod: (filter, row) =>
                      row[filter.id].startsWith(filter.value) ||
                      row[filter.id].endsWith(filter.value)
            },
            {
              Header: 'Aktualny status',
              accessor: 'nazwa',
              filterMethod: (filter, row) =>
                      row[filter.id].startsWith(filter.value) ||
                      row[filter.id].endsWith(filter.value)
            },
            {
              Header: 'Numer wniosku',
              accessor: 'nw',
             
              filterMethod: (filter, row) =>
                      row[filter.id].startsWith(filter.value) ||
                      row[filter.id].endsWith(filter.value)
            },
            
            {
              Header: '#',
              accessor: 'ide', // String-based value accessors!
              Cell: this.MyCell, getProps: () => ({ })
            },];

        return (<div> 
            <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><span onClick={this.home}>Lista</span></li>
                                    </ol>
                                </nav>
            
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                    <h3 >Lista twoich wniosków</h3>
                        <ReactTable
                            data={userlist}
                            searchable={true}
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                            columns={columns}
                            loading={this.state.loading}
                            onFetchData={this.askk} 
                            previousText={'Poprzednia'}
                            nextText={'Następna'}
                            loadingText={ 'Ładowanie danych...'}
                            noDataText={ 'Nie znaleziono danych'}
                            pageText={'Strona'}
                            ofText={'z'}
                            rowsText={'wierszy'}
                            
                            // Accessibility Labels
                            pageJumpText={'skocz do strony'}
                            rowsSelectorText= {'wierszy na stronę'}
                            defaultPageSize={10}
                        />

                    </div>
                </div>
            </div>
            </div>);}
            else if (this.state.page===1)
            {
                var u = this.state.user
                var content  ={dokumenty:[]}
                if (typeof this.state.user.wniosek !== "undefined") {content = JSON.parse(this.state.user.wniosek.content);}
                
                return (<div>
                    <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><span onClick={this.home}>Lista</span></li>
                                        <li className="breadcrumb-item">Treść</li>
                                    </ol>
                                </nav>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>Wiosek nr HB/{u.wniosek['data_create'].substr(5,2)}{u.wniosek.ide}/{u.wniosek['data_create'].substr(0,4)}</h4>
                            </div>
                        </div>
                   <div className="row">
                       <div className="col-sm-3">
                            <div className="contact header">Data wysłania: {u.wniosek['data_create']} <br></br>
                            Status: {u.wniosek.nazwa}</div>
                       </div>
                       <div className="col-sm-6">
                           <div id="endpanel" style={this.state.StyleSheet.endpanel}>
                                <div>Algorytmy sztucznej inteligencji potrzebują wiedzieć, jaki był ostateczny wynik tego wniosku.</div>
                                <label>Wniosek zakończył się:</label>
                                <div>
                                    <button className="btn btn-info btn-sm" data-action="ai" data-odp="resignate" onClick={this.saveState}>Rezygnacja klienta</button>{"  "}
                                    <button className="btn btn-success btn-sm" data-action="ai" data-odp="yes" onClick={this.saveState}>Sukcesem</button>{"  "}
                                    <button className="btn btn-danger  btn-sm" data-action="ai" data-odp="no"  onClick={this.saveState}>Porażką</button>
                                </div>
                               
                           </div>
                        <div id="messagepanel" style={this.state.StyleSheet.messagepanel}>
                                <label>Treść komunikatu do klienta</label>
                                <textarea className="form-control" id="message" style={{marginBottom:"5px"}}></textarea>
                                <div id="datapanel"  style={this.state.StyleSheet.datapanel}>
                                    <label>Podaj datę i godzinę spotkania</label>
                                    <input type="datetime-local" className="form-control col-sm-6"id="dataSpotkania" /> 
                                </div>
                                <div className="float-right">
                                <button className="btn btn-danger btn-sm " data-action="cancel" onClick={this.saveState}>Anuluj</button>
                                {" "}
                                <button className="btn btn-secondary btn-sm "  data-action="save" onClick={this.saveState}>Zapisz</button>

                                </div>
                               
                        </div>
                       </div>
                       <div className="col-sm-3">
                           <label>Zmień status</label>
                           <select className="form-control" onChange={this.changeState} value={this.state.user.wniosek.status}>
                              {this.state.statusy.map((key, i)=>(
                                  <option key={i} value={key.id}>{key.nazwa}</option>
                              ))}
                           </select>
                           <br></br>
                           <button className="btn btn-secondary btn-sm" onClick={this.analiza} >Analiza</button>
                       </div>
                   </div>
                    <div>
                        <h5>Historia wniosku</h5>
                       
                        <div className="nodoc" style={(u.log.length===0) ? {display:"block"} : {display:"none"}} > Dla tego wniosku nie ma zapsanych zmian</div>
                        <table  className="historiawniosku" style={(u.log.length>0) ? {width:"100%"} : {display:"none"}} >
                            <thead>
                                <tr>
                                    <td>Data</td>
                                    <td>Autor</td>
                                    <td>Treść komunikatu</td>
                                </tr>
                            </thead>
                            <tbody>
                            {u.log.map((key, i)=>(
                            <tr key={i+100}>
                                <td>{key.data} </td>
                                <td>{key.autor}</td>
                                <td>{this.unpackComment(key.tresc)}</td>
                           </tr>
                        ))}
                            </tbody>
                        </table>
                       

                    </div>
                    <div>
                        <h5>Dokumenty</h5>
                        <div className="nodoc" style={(content.dokumenty.length===0) ? {display:"block"} : {display:"none"}} > Dla tego wniosku nie ma załączonych dokumentów</div>
                    {content.dokumenty.map((key, i)=>(
                        <div className="documentss" data-document={key} data-user={this.state.user.wniosek.userid} onClick={this.downloadDocument} key={i}>{key}</div>
                    ))}
                    </div>
                    <div>
                        <div>
                            <h5>Analiza AI (smart)</h5>
                            <AiSmart smart={this.state.smart} />
                        </div>
                        <div>
                            <h5>Analiza AI (deepth)</h5>
                            <AiSmart smart={this.state.deepth} />
                        </div>
                    <div dangerouslySetInnerHTML={{__html: this.state.analiza}} />
                        <h5>Szczegółowe dane wniosku</h5>
                        
                       
                        <Renderwniosek wniosek={JSON.parse(this.state.user.wniosek.content)} />

                    </div>
                    
                    {/* <div><button className="btn btn-secondary btn-sm" onClick={this.zmianStatus}>Zapisz zmiany</button></div> */}
                    </div>
                </div>)
            }
            else{
                console.log(this.state.page);
                return (<div onClick={this.getLista}>Coś poszło nie tak...
                
                
                
                
                
                </div>)
            }
        }
}

}
export default Konsultacje;