import React  from "react";
import Privkantor from './privkantor.js';



class Kantor extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ready:false, faq:[],values:{},powiaty:[],gminy:[],
        };
        this.componentDidUpdate = this.componentDidUpdate.bind(this);


    }
    IsJsonString(str) { try { JSON.parse(str); } catch (e) { return false; } return true;}
    componentDidUpdate()
    {
        //if (this.IsJsonString(this.props.kursy)){console.log(JSON.parse(this.props.kursy))}
    }
    render(){
        var val=[[{effectiveDate:"Brak danych", rates:[]}]];
        var isTable = false;
        if (this.IsJsonString(this.props.kursy)){val = JSON.parse(this.props.kursy);isTable=true;}
        if (isTable)
        {
        return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <h3>Kantor - wymiana walut</h3>
                    <div className="kantor"><b>Tabela kursów walut Narodowego Banku Polskiego</b><br></br>Kurs na dzień: {val[0].effectiveDate}, tabela: {val[0].no}</div>
                    <table className="kantor">
                        <thead>
                            <tr>
                                <td>Waluta</td>
                                <td>Kod waluty</td>
                                <td>Kupno</td>
                                <td>Sprzedaż</td>
                            </tr>
                        </thead>
                        <tbody>
                            {val[0].rates.map((key,i)=>(
                                <tr key={i}>
                                    <td>{key.currency}</td>
                                    <td>{key.code}</td>
                                    <td>{key.bid}</td>
                                    <td>{key.ask}</td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Privkantor clientSelection={this.props.clientSelection} xml={this.props.xml} drawable={["Finanse osobiste", "Inwestycje", "Ubezpieczenia", "Dla Twojej firmy", "Produkty niefinansowe"]} 
        include={["Waluty"]}/>
                </div>
            </div>
        </div>);

}
else
{
    return(<div>Oczekiwanie na dane...</div>)
}
}

}

export default Kantor;