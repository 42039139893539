import React  from "react";
import axios from 'axios';
import security from './../common/security.js';
import Cookies from 'js-cookie';


class RozpatrzoneWnioski extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            wnioski:[], loaded:false,
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.anuluj = this.anuluj.bind(this);
        this.ask = this.ask.bind(this);
    }
    anuluj(ev){this.ask('anulujWniosek', ev.target.dataset.wniosekid);}

    ask(resource, data)
      {
  
            var self = this;
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'aplikacja');
            params.append('command', resource);
            params.append('data', data);
            params.append('token', Cookies.get("Experimental"));
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
              console.log(response.data.data);
            if (response.data.status==="OK")
            {
            //self.setState({loading:false});
                switch (resource){
                case 'getRozpatrzoneWnioski': self.setState({wnioski: response.data.data, loaded:true});return;
                default: return;
                }
                
            }
            else if(response.data.status==="TEST")
            {
            console.log(response.data);
            }
            else if (response.data.status==="err")
            {
                alert(response.data.desc);
            }
            self.setState({odpowiedz:response.data.desc});
            }).catch(function (error) {console.log(error);});
            
        }
    componentDidMount(){
        // if (this.props.level===7){this.ask('getZlozoneWnioski', null);}
    }
    componentDidUpdate(){
        if (this.props.level===8 && !this.state.loaded){this.ask('getRozpatrzoneWnioski', null); this.setState({loaded:true})}
        // else{if (this.state.loaded===true){this.setState({loaded:false});}}   
     }
    
    render(){
    if (this.props.level===8) 
    {
        if (this.state.wnioski.length>0){
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-12"></div>
                    <div className="col-sm-12">
                    <h3>Rozpatrzone wnioski</h3>
                    <hr className="prettyhr"></hr>
            {this.state.wnioski.map((key)=>(
                <div key={key.id} className="listawnioskow row">
                    <div className="col-sm-2">
                    <img src={key.logo} className="setka" alt="logo"/>
                    </div>
                    <div className="col-sm-10">
                    
                    WNIOSEK {key.id} Złożony dnia: <span className="owniosku">{key.data}</span> Kwota: <span className="owniosku">{key.kwota}</span> na <span className="owniosku">{key.rat}</span> rat.<br></br>
                    ADNOTACJA BANKU: <span className="owniosku">{key.bank}</span>
                    <span className="float-right">
                    <span className="badge badge-secondary margin-right">STATUS: {key.status}</span>
                    </span>
                    </div>
                </div>
            ))}
            

                    </div>
                </div>
                
            </div>
        )}
        else{return (<div className="bezwnioskow">Brak złożonych wniosków.</div>)}
    }
    else
    {return (<div></div>)}
}

}


export default RozpatrzoneWnioski;