import React  from "react";
// import { setPriority } from "os";


class Kredyt extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            cks:0, rata:0, rrso:0, wniosek:{kwota:0},
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.rata = this.rata.bind(this);
        this.rrso = this.rrso.bind(this);
        this.cks = this.cks.bind(this);
    }
    rata()
    {
        var rata = parseFloat(this.state.cks/this.props.RA).toFixed(2);
        this.setState({rata: rata});

    }
    rrso()
    {
        var rata = parseFloat(this.state.cks * 100 /this.props.KR - 100).toFixed(2);
        this.setState({rrso: rata});
    }

    cks()
    {
        var calkowitakwotasplaty = parseFloat(this.props.KR);
        var procentowa = parseFloat(this.props.OP);
        var stala = parseFloat(this.props.OS);
        var oprocentowanie = parseFloat(this.props.PR)/100;
        var prowizja = stala + calkowitakwotasplaty * (procentowa/100);
        var odsetki = calkowitakwotasplaty * oprocentowanie * this.props.RA/12;
        calkowitakwotasplaty = calkowitakwotasplaty +prowizja +odsetki;
        this.setState({cks: parseFloat(calkowitakwotasplaty).toFixed(2)});
    }

    

componentDidMount() {
        
        setInterval(() => {
            this.cks();
        setTimeout(() => { this.rata(); this.rrso(); this.setState({ready:true});}, 50);
    
        }, 50);
        
        
}

    render(){
        var un = {zl:"zł", pr:"%"};
        var kolor = "green";
        var zostaje = this.props.HB - this.state.rata;
        if (zostaje<0){kolor="red";}
        if (this.props.UNITS===false){un = {zl:"", pr:""};}
            return (
            <div>  
                KWOTA KREDYTU: {parseFloat(this.props.KR).toLocaleString()} {un.zl}<br></br>
                OPROCENTOWANIE: {parseFloat(this.props.PR).toLocaleString()} {un.pr}, RAT: {this.props.RA}<br></br>
                CKS: {parseFloat(this.state.cks).toLocaleString()} {un.zl}<br></br>
                RATA: {parseFloat(this.state.rata).toLocaleString()} {un.zl}<br></br>
                RRSO: {this.state.rrso} {un.pr}<br></br>
                HB: <span className={kolor}>{this.props.HB}</span><br></br>
                Aktualne HB:{this.props.HX}<br></br>
                RESZTA: {Math.round(zostaje)}
            </div>

            );
        }
       
   

}

export default Kredyt;