import React from 'react';
import axios from 'axios';
import security from './../common/security.js';
import Cookies from 'js-cookie';

import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/pl'

import Edprobank from './edProBank.js';
import Faq from './faq.js';
import Listabankow from './listabankow.js';
import Listaproduktow from './listaproduktow.js';
import {IoIosSave } from "react-icons/io";
import {FaRegFileAlt, FaCogs} from "react-icons/fa";

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
class Ustawieniaadmin extends React.Component{

    constructor(props) {
        super(props);
        this.state = {page:0, content:"",  contentState: {}, text:"", klucz:"", documentList:[], id:0, server:'', opis:""};
       
       this.ccc = this.ccc.bind(this);
       this.componentDidMount = this.componentDidMount.bind(this);
       this.getDocumentList = this.getDocumentList.bind(this);
       this.edit = this.edit.bind(this);
       this.saveItem = this.saveItem.bind(this); 
       this.lista = this.lista.bind(this);
       this.server = this.server.bind(this);
       this.buttonRouter = this.buttonRouter.bind(this);
       this.changeOpis = this.changeOpis.bind(this);
      }
    //   onEditorStateChange: Function = (editorState) => {
    //     this.setState({
    //       editorState,
    //     });
    //   };
    changeOpis(ev)
    {
        this.setState({opis:ev.target.value});
    }
      handleEditorChange = (e) => {
       // console.log('Content was updated:', e.target.getContent());
      }
      server = () => { confirmAlert({  customUI: ({ onClose }) => { return ( <div className='custom-ui'><h3>Komunikat serwera</h3><p>{this.state.server}</p> <button className="btn btn-danger btn-sm" onClick={onClose} >Zamknij</button>  </div> ); } });}

      getDocumentList()
      {
          this.ask("getDocumentList", "");
      }
      edit(ev)
      {
            var t = ev.target.dataset.id;
           // //console.log("TARGET", t);
            this.ask("getDocumentbyId", t);
      }
      lista()
      {
        //$(".ck-toolbar").remove();
        document.getElementsByClassName('ck-toolbar')[0].remove()
        this.setState({page:0});
      }
      saveItem()
      {
          this.ask("saveItem", JSON.stringify({text: this.state.text, id: this.state.id, opis:this.state.opis, klucz:this.state.klucz}))
      }
      ask(resource, data)
      {
  
            var self = this;
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'admin');
            params.append('command', resource);
            params.append('data', data);
            params.append('token', Cookies.get("Experimental"));
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
             // console.log(response.data.data);
              if (response.data.status==="OK")
              {
                //self.setState({loading:false});
                  switch (resource){
                    case 'getDocumentList': self.setState({documentList: response.data.data});return;
                    case 'getDocumentbyId': self.setState({text: response.data.data[0].wartosc, klucz:response.data.data[0].klucz, id: response.data.data[0].id, opis: response.data.data[0].opis}); self.setState({page:1});return;
                    case 'saveItem':self.setState({server: response.data.desc});self.server(); break;
                      default: return;
                  }
                 
              }
              else if(response.data.status==="TEST")
              {
                console.log(response.data);
              }
              else if (response.data.status==="err")
              {
                  alert(response.data.desc);
              }
              self.setState({odpowiedz:response.data.desc});
             }).catch(function (error) {console.log(error);});
        }
      
      componentDidMount(){
        this.getDocumentList();
      }
      buttonRouter(ev)
      {
         // console.log(ev.target.dataset);
          //var action = ev.target.dataset.action;
          this.getDocumentList();
          if (document.getElementsByClassName('ck-toolbar').length>0)
          {document.getElementsByClassName('ck-toolbar')[0].remove();}
          this.setState({page:parseInt(ev.target.dataset.action)});
          
      }
      ccc()
      {
          //console.log(this.state.editorState, convertToRaw(this.state.editorState.getCurrentContent()).blocks);
      }
    render(){
        //const { editorState } = this.state;

        if (this.state.page===1)
        {return (<div className="container">
            <div className="row">
                <div className="col-sm-12">
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><span data-action="0" onClick={this.buttonRouter}>Lista</span></li>
                    <li className="breadcrumb-item">Edytor</li>
                </ol>
            </nav>

                    <h3 onClick={this.ccc}>Edycja <code>{this.state.klucz}</code></h3>
                    <hr className="prettyhr"></hr>
                </div>
            </div>
            <div className="row"> 
                <div className="col-sm-12">
                    <div className="btn-group">
 
                        <button className="btn btn-success" onClick={this.saveItem}><IoIosSave onClick={this.saveItem}/> Zapisz zmiany</button>
                    </div>
                    <br></br>&nbsp;
                    <div>
                        <label>Opis</label>
                        <input className="form-control" value={this.state.opis} onChange={this.changeOpis} />
                        <br></br>
                    </div>
                <CKEditor 
                    locale={
                       { language:"pl"}
                    }
                    onInit={editor => {
                        editor.ui.view.editable.element.parentElement.insertBefore(
                          editor.ui.view.toolbar.element,
                          editor.ui.view.editable.element

                        );
                       // console.log(DecoupledEditor.builtinPlugins.map( plugin => plugin.pluginName ));
                      }}
                    config={{ 
                        alignment: {
                            options: [ 'left', 'right' , 'center', 'justify']
                        },
                        language: 'pl',
                        toolbar: {
                            items: [
                                'heading',
                                '|',
                                'alignment',
                                'bold',
                                'italic',
                                'underline',
                                
                                'Strikethrough',
                                'link',
                                'bulletedList',
                                'numberedList',
                                'blockQuote',
                                '|',
                                'undo',
                                'redo',
                                'save'
                            ]
                        },
                        image: {
                            toolbar: [
                                'imageStyle:full',
                                'imageStyle:side',
                                '|',
                                'imageTextAlternative'
                            ]
                        },
                    }}  
                    editor={ DecoupledEditor } 
                    data={this.state.text}
                    onChange={ ( event, editor ) => {
                        this.setState({text:editor.getData()})
                    } }
                    onBlur={ editor => {
                      //  console.log( 'Blur.', editor );
                    } }
                    onFocus={ editor => {
                       // console.log( 'Focus.', editor );
                    } }
                />
                </div>
            </div>
            
        </div>)}
        else if (this.state.page===0)
        {
            return(
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">        
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><span data-action="0" onClick={this.buttonRouter}>Lista</span></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <h3>Lista dokumentów</h3>
                            <table className="table table-hover table-condensed">
                                <thead>
                                    <tr>
                                        <td>Nazwa zasobu</td>
                                        <td><div className="float-right">#</div></td>
                                    </tr>
                                </thead>
                                <tbody>

                                
                                {this.state.documentList.map((key)=>(
                                    <tr  key={key.id}>
                                        <td><FaRegFileAlt />{key.opis}</td>
                                        <td><button className="btn btn-secondary btn-sm float-right" data-id={key.id} onClick={this.edit}>Edytuj</button></td>
                                    </tr>
                                ))}
                                </tbody>

                            </table>
                        </div>
                        {/* <div className="col-sm-6">
                            <h3>Lista ustawień aplikacji</h3>
                            <table className="table table-hover table-condensed">
                                <thead>
                                    <tr>
                                        <td>Nazwa zasobu</td>
                                        <td>#</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr><td><FaCogs/> Edycja rodzajów produktów bankowych</td><td><button className="btn btn-link btn-sm" data-action="2" onClick={this.buttonRouter}>edytuj</button></td></tr>
                                    <tr><td><FaCogs/> Edycja wpisów FAQ</td><td><button className="btn btn-link btn-sm"  data-action="3" onClick={this.buttonRouter}>edytuj</button></td></tr>
                                    <tr><td><FaCogs/> Edycja listy banków</td><td><button className="btn btn-link btn-sm" data-action="4" onClick={this.buttonRouter}>edytuj</button></td></tr>
                                    <tr><td><FaCogs/> Edycja produktów bankowych</td><td><button className="btn btn-link btn-sm" data-action="5" onClick={this.buttonRouter}>edytuj</button></td></tr>
                                </tbody>

                            </table>
                        </div> */}
                    </div>
                </div>
            )
        }
        else if (this.state.page===2){return (<div className="container">
            <div className="row">
                <div className="col-sm-12">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><span data-action="0" onClick={this.buttonRouter}>Lista</span></li>
                    <li className="breadcrumb-item">Ustawienia rodzajów produktów bankowych</li>
                </ol>
            </nav></div>
            </div><Edprobank /></div>)}
        else if (this.state.page===3){return (
        
            <div className="container">
            <div className="row">
                <div className="col-sm-12">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><span data-action="0" onClick={this.buttonRouter}>Lista</span></li>
                    <li className="breadcrumb-item">Edycja Wpisów FAQ</li>
                </ol>
            </nav></div>
            </div><Faq /></div>)}
        else if (this.state.page===4){return (
            <div className="container">
            <div className="row">
                <div className="col-sm-12">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><span data-action="0" onClick={this.buttonRouter}>Lista</span></li>
                    <li className="breadcrumb-item">Edycja listy Banków</li>
                </ol>
            </nav></div>
            </div><Listabankow /></div>)}
        else if (this.state.page===5){return (
            <div className="container">
            <div className="row">
                <div className="col-sm-12">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><span data-action="0" onClick={this.buttonRouter}>Lista</span></li>
                    <li className="breadcrumb-item">Edycja produktów bankowych</li>
                </ol>
            </nav></div>
            </div><Listaproduktow /></div>)}
        else if (this.state.page===6){return (<div>6<button data-action="0" onClick={this.buttonRouter}>Powrót</button></div>)}
}
}


export default Ustawieniaadmin;