import React  from "react";
import axios from 'axios';
import security from './security.js';
import Cookies from 'js-cookie';
import Termometr from './../common/termometr.js';
import checkIntegrity from './liczhipind.js';
// import Creditofertsnologin from './../common/creditofertsnologin.js';
import strzalka from './../../img/strzalka.png';
import strzalkaL from './../../img/strzalkaR.png';

import ProductListContainer from './productlistcontainer';
// import XML from './../common/xmlparser.js';

class UniversalFirmResponseForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ready:false, checkNieruchomosc:{error:[], info:[], source:"code"},reCalc:true,
            checkZgody:{error:[], info:[], source:"code"},checkFinanse:{error:[],info:[], zdolnosc:0, procent:0, source:"code"},
            poprawnie:false,errorlist:{info:[],zysk:0,zdolnosc:0, procent:0, maxrata:0},policzone:false,
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.changeVal = this.changeVal.bind(this);
        this.ask = this.ask.bind(this);
        this.debug = this.debug.bind(this);
        
    }
    IsJsonString(str) { try { JSON.parse(str); } catch (e) { return false; } return true;}
    debug()
    {
        //console.log("ODPOWIEWDŹ KREDYTOWA",this.props);
    }
    ask(resource, data = null)
    {
          var self = this;
          const params = new URLSearchParams(); params.append('asc',security.securityKey());
          params.append('section', 'user');
          params.append('command', resource);
          params.append('data', data);
          params.append('token', Cookies.get("Experimental"));
          axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            //console.log(response.data.data);
          if (response.data.status==="OK")
          {
          //self.setState({loading:false});
              switch (resource){
              case 'loadGmina':  self.setState({gminy: response.data.data, ready:true});self.analiseUser(response.data.data); return;
      
              default: return;
              }
              
          }
          else if(response.data.status==="TEST")
          {
          console.log(response.data);
          }
          else if (response.data.status==="err")
          {
             //self.setState({server: response.data.desc});self.server();
          }
          self.setState({odpowiedz:response.data.desc});
          }).catch(function (error) {console.log(error);});
          
      }

    
    componentDidMount() {
        this.setState({ready:true});
     
    }
    componentDidUpdate(){
        if (this.props.page===13)
        {
            if (this.state.policzone===false)
            {
                //console.log("UPDATED");
                var ing = checkIntegrity.checkFirma(this.props.form);
                if (ing.info.length>0)
                {

                    this.setState({errorlist:ing, poprawnie:false, policzone:true });
                }
                else
                {
                    this.setState({errorlist:ing, poprawnie:true, policzone:true });
                }
            }
        }
        else
        {
            if (this.state.policzone===true){this.setState({policzone:false});}
        }
        // var nieruchomosc = checkIntegrity.checkNieruchomosc(this.props.form);
        // //reset Analytics
        // if (this.state.checkNieruchomosc.source==="calc" && this.props.page===11){this.setState({checkNieruchomosc:{error:[], info:[], source:"code"}});}
        // if (this.state.checkZgody.source==="calc" && this.props.page===11){this.setState({checkZgody:{error:[], info:[], source:"code"}});}
        // if (this.state.checkFinanse.source==="calc" && this.props.page===11){this.setState({checkFinanse:{error:[],info:[], zdolnosc:0, procent:0, source:"code"}});}
        // if (this.state.checkNieruchomosc.source==="code" && this.props.page===12){this.setState({checkNieruchomosc:nieruchomosc});}
        // if (this.state.checkZgody.source==="code" && this.props.page===12){ var zgody=checkIntegrity.checkZgody(this.props.form);  this.setState({checkZgody:zgody});}
        // if (this.state.checkFinanse.source==="code" && this.props.page===12){var finanse = checkIntegrity.checkFinanse(this.props.form); this.setState({checkFinanse:finanse});}
        
        
        
    }

    changeVal(ev)
    {
        console.log(ev.target.dataset);       
    }

    render(){
       if (this.props.page===13) 
    {
        if (this.state.ready)
        {
            if (this.state.poprawnie===false)
            {
                return(<div>
                    <div className="formError"><h3>Formularz niekompletny lub zawierający błędy</h3><br></br>
                    {this.state.errorlist.info.map((key,i)=>(<div key={i}>{key.text}</div>))}
                    <button className="btn btn-danger" data-action="wroc" onClick={this.props.read}>Powrót do formularza</button>
                    </div>
                </div>)
            }
            else
            {
            return (
            <div>
                <h3>Analiza danych</h3>
                <div className="OdpForm">
                    Formularz wypełniono poprawnie
                    <button className="btn btn-primary" data-action="wroc" onClick={this.props.read}>Powrót do formularza</button>
                </div>
                <img className="nextStepR" src={strzalka} onClick={this.debug}  alt=""/>
                <Termometr onClick={this.debug} procent={this.state.errorlist.procent} kwota={this.state.errorlist.zdolnosc}/>
           
                {this.state.checkFinanse.info.map((key,i)=>(
                    <div className="calcInfo" key={i}>
                        <div className={key.color}>{key.info}</div>
                    </div>
                ))}
                <br></br>
                {/* <button className="btn btn-secondary" data-action="wroc" onClick={this.props.read}>Powrót do formularza</button> */}
                <div>
                    <div className="LnextStep"><img className="nextStepL" src={strzalkaL} alt=""  onClick={this.debug} /></div>
                    <div className="RnextStep"> 
                    <ProductListContainer  xml={this.props.xml} drawable={["Finanse osobiste", "Inwestycje", "Ubezpieczenia", "Dla Twojej firmy", "Produkty niefinansowe"]} 
        include={this.props.showOnly} form={this.props.form}/>
                    </div>
                </div>
               
            </div>
           
        );}
            
                }
        else
        {
            return (<div>Oczekiwanie na dane...</div>)
        }
    }
    else
    {
        return (<span></span>);
    }


}

}

export default UniversalFirmResponseForm;