import React from 'react';
//import images

class WynikMaleAI extends React.Component{
    constructor(props) {
        super(props);
        this.state = {loaded:false,
        };
       this.jakiWynik = this.jakiWynik.bind(this);
    //    this.componentDidMount = this.componentDidMount.bind(this);
       this.componentDidUpdate = this.componentDidUpdate.bind(this);
    }
    exists(e){ if (typeof e !== 'undefined'){return true;}else{return false;}}
    isValidWynik(e){if (this.exists(e.yes)) {return true;}else{return false;}}
    jakiWynik(){ return  (this.props.wynik.yes>this.props.wynik.no)}
    componentDidUpdate()
    {
        if (this.state.loaded===false){this.setState({loaded:true});}
    }
    render(){ 
    //  //console.log("YES>NO",(this.props.wynik.yes>this.props.wynik.no));
     if (this.isValidWynik(this.props.wynik) && this.state.loaded===true){
            if (this.props.wynik.yes>this.props.wynik.no)
            { 
            return (
                <div className="container" id="ocena">
                    <div className="row">
                        <div className="col-sm-12">
                            <br></br>
                            <div className="alert alert-success">
                                <strong>Świetnie!</strong> {" "}
                                Wszystko wskazuje na to, że Bank oceni Twoją zdolność kredytową pozytywnie. Jesteśmy to w stanie stwierdzić z pewnością sięgającą {Math.round(this.props.wynik.yes*1000)/10}%.  Możesz śmiało wybrać którąś z poniższych ofert.<br></br>
                                <small>Pewność algorytmu uzlależniona jest od wielkości próby uczenia maszynowego i może nie odzwierciedlać rzeczywistści. Algorytm sztucznej inteligencji nie uwzględnia innych okoliczności przyznania kredytu hipotecznego, które może brać pod uwagę bank</small>
                            </div>
                        </div>
                        
                    </div>
                </div> 
            );}
            else
            { 
                return (
                    <div className="container" id="ocena">
                        <div className="row">
                            <div className="col-sm-12">
                                <br></br>
                                <div className="alert alert-danger">
                                    <strong>To nie powód do paniki!</strong> 
                                    {" "}Wszystko wskazuje na to, że Bank oceni Twoją zdolność kredytową negatywnie. Jesteśmy to w stanie stwierdzić z pewnością sięgającą {Math.round(this.props.wynik.no*1000)/10}%. 
                                    Sprawdź dokładnie formularz, czy wszystkie pola są wypełnione poprawnie.
                                    <br></br>
                                    Możesz zrobić następujące rzeczy:
                                    <ol>
                                        <li>Zmienić okres kredytowania</li>
                                        <li>Zmienić kwoty zobowiązań w taki sposób, aby wyglądały na spłacone</li>
                                        <li>Zamknąć linie kredytowe</li>
                                        <li>Zmienić kwotę wkładu własnego lub kwotę kredytu</li>
                                    </ol>
                                    Po poprawce naciśnij jeszcze raz przycisk dalej a otrzymasz nowe wyniki.<br></br>
                                    Jeśli te zabiegi nie pomogą możesz skontaktować się z naszym niezalenym brokerem, lub złożyć wniosek do banku, który sprawdzi Twoje dane bardziej dokłanie. Opcja brokera jest możliwa jedynie po założeniu konta i zalogowaniu.  <br></br>
                                    <small>Pewność algorytmu uzlależniona jest od wielkości próby uczenia maszynowego i może nie odzwierciedlać rzeczywistści. Algorytm sztucznej inteligencji nie uwzględnia innych okoliczności przyznania kredytu hipotecznego, które może brać pod uwagę bank</small>
                                </div>
                            </div>
                            
                        </div>
                    </div> 
                );}
        }
        else
        {
            return (<div></div>)
        }
    
        }
}

export default WynikMaleAI;