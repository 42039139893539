import React from "react";
//import { Redirect } from "react-dom";
import axios from 'axios'; 
import security from './../common/security.js';
import Cookies from 'js-cookie';
import ReactTable from 'react-table'
import Userinfo from './userinfo.js';
import Wnioski from './wnioski.js';
import Admin from './admin.js';

class Users extends React.Component{

    constructor(props) {
        super(props);
        this.state = {userlist:[], loading:true, page:0, dane:[], odpowiedz:"", wnioski:[], admin:[]};
       
        this.ask =  this.ask.bind(this);
        this.askk =  this.askk.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.MyCell = this.MyCell.bind(this);
        this.buttonRouter = this.buttonRouter.bind(this);
        this.home = this.home.bind(this);
      }
      home(){
          this.setState({page:0});
      }
      askk(resource, ams=null)
      {
        var self = this;
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
        params.append('section', 'admin');
        params.append('command', resource);
        if (params!==null){params.append('data', JSON.stringify(ams));}
        params.append('token', Cookies.get("Experimental"));
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
         // console.log(response.data.data);
          if (response.data.status==="OK")
          {
            self.setState({loading:false});
              switch (resource){
                  case 'getUserInfo': self.setState({dane: response.data.data});return;
                  case 'getUserWnioski': self.setState({wnioski: response.data.data});return;
                  case 'getUserAdmin': self.setState({admin: response.data.data});return;
                  default: return;
              }
             
          }
          self.setState({odpowiedz:response.data.desc});
         }).catch(function (error) {console.log(error); self.setState({odpowiedz:"ERR"});});}
  
      ask(resource)
      {
          if (this.state.loading===true)
          {
            var self = this;
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'admin');
            params.append('command', resource);
            params.append('token', Cookies.get("Experimental"));
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            //   console.log(response.data.data);
              if (response.data.status==="OK")
              {
                self.setState({loading:false});
                  switch (resource){
                      case 'userlist': self.setState({userlist: response.data.data});return;
                      default: return;
                  }
                 
              }
              self.setState({odpowiedz:response.data.desc});
             }).catch(function (error) {console.log(error); self.setState({odpowiedz:"ERR"});});}
      }
      componentDidMount(){
          //this.ask('userlist');
          //this.setState({loading:true});
        
      }
      buttonRouter(ev)
      {
          console.log(ev.target.dataset);
          var action = ev.target.dataset.action;
          var userId = ev.target.dataset.id;
          if (action==='info'){this.setState({page:1}); this.askk('getUserInfo', userId) }
          if (action==='wnioski'){this.setState({page:2}); this.askk('getUserWnioski', userId) }
          if (action==='admin'){this.setState({page:3}); this.askk('getUserAdmin', userId) }
      }
      MyCell({ value, columnProps: { rest: { somefunc } } }) {
        return (
            <div className="btn-group" role="group" aria-label="Basic example">
                {/* <button type="button" className="btn btn-secondary btn-sm" data-id={value} data-action="info" onClick={this.buttonRouter}>Info</button> */}
                {/* <button type="button" className="btn btn-secondary btn-sm" data-id={value} data-action="wnioski" onClick={this.buttonRouter}>Wnioski</button> */}
                <button type="button" className="btn btn-secondary btn-sm" data-id={value} data-action="admin" onClick={this.buttonRouter}>Ustawienia</button>
            </div>
        )
      }
    renderUstawienia()
    {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-12"><h3>Ustawienia użytkownika.</h3></div>
                </div>
            </div>
        )
    }
    
    render(){
        if (this.state.page===0)
        {
        const {userlist, loading}  = this.state;
        const columns = [
           
              {
            Header: 'Login',
            accessor: 'mail',
            filterMethod: (filter, row) =>
                    row[filter.id].startsWith(filter.value) ||
                    row[filter.id].endsWith(filter.value)
          },
          {
            Header: 'Imię',
            accessor: 'name',
            filterMethod: (filter, row) =>
                    row[filter.id].startsWith(filter.value) ||
                    row[filter.id].endsWith(filter.value)
          },
          {
            Header: 'Nazwisko',
            accessor: 'lastname',
            filterMethod: (filter, row) =>
                    row[filter.id].startsWith(filter.value) ||
                    row[filter.id].endsWith(filter.value)
          },
          {
            Header: 'Ustawienia',
            accessor: 'id', // String-based value accessors!
            Cell: this.MyCell, getProps: () => ({ })
          },];
            return (<div className="container">
                <div className="row">
                    <div className="col-sm-12">
                    <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><span onClick={this.home}>Lista</span></li>
                                    </ol>
                                </nav>
                        <ReactTable
                            data={userlist}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                            columns={columns}
                            loading={loading}
                            onFetchData={this.ask('userlist')} 
                            previousText={'Poprzednia'}
                            nextText={'Następna'}
                            loadingText={ 'Ładowanie danych...'}
                            noDataText={ 'Nie znaleziono danych'}
                            pageText={'Strona'}
                            ofText={'z'}
                            rowsText={'wierszy'}
                            
                            // Accessibility Labels
                            pageJumpText={'skocz do strony'}
                            rowsSelectorText= {'wierszy na stronę'}
                            defaultPageSize={10}
                        />
                    </div>

                </div>
                
            </div>);
            }
            else if (this.state.page===1)
            {
                return (
                    <div className="container">
                        <div className="row">

                            <div className="col-sm-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><span onClick={this.home}>Lista</span></li>
                                        <li className="breadcrumb-item active" aria-current="page">Informacje ogólne</li>
                                    </ol>
                                </nav>
                                
                                <Userinfo user={this.state.dane} />
                                </div>
                        </div>
                    </div>
                )
            }
            else if (this.state.page===2)
            {
                return (
                    <div className="container">
                        <div className="row">

                            <div className="col-sm-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><span onClick={this.home}>Lista</span></li>
                                        <li className="breadcrumb-item active" aria-current="page">Wnioski klienta</li>
                                    </ol>
                                </nav>
                                
                                <Wnioski wnioski={this.state.wnioski} />
                                </div>
                        </div>
                    </div>
                )
            }
            else if (this.state.page===3)
            {
                return (
                    <div className="container">
                        <div className="row">

                            <div className="col-sm-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><span onClick={this.home}>Lista</span></li>
                                        <li className="breadcrumb-item active" aria-current="page">Administracja</li>
                                    </ol>
                                </nav>
                                
                                <Admin admin={this.state.admin} />
                                </div>
                        </div>
                    </div>
                )
            }
}
}


export default Users;