import React  from "react";
import axios from 'axios';
import security from './../common/security.js';



class Recovery extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
           pass1:"", pass2:"", odpowiedz:"Link do zmiany hasła przeszedł pomyślnie procedurę dopuszczającą. Wprowadź dwukrotnie nowe hasło."
        };
        this.readForm = this.readForm.bind(this);
        this.wyslij = this.wyslij.bind(this);
    }
    readForm(e)
    {
        //console.log(e.target.id);
        switch (e.target.id)
        {
            case 'pass1':this.setState({pass1: e.target.value});break;
            case 'pass2':this.setState({pass2: e.target.value});break;
            default:break;
        }
    }
    validatePass()
    {
        var pass1 = this.state.pass1;
        var pass2 = this.state.pass2;
        if (pass1===pass2)
        {
            var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
            if (re.test(pass1))
            {
                this.setState({validatepass:false, odpowiedz:"Hasło musi zawierać duże i małe litery oraz cyfy."});
            }
            else{
                this.setState({validpass:false, odpowiedz:"Hasło musi zawierać duże i małe litery oraz cyfy."});
            }
        }
        else
        {
            this.setState({validpass:false, odpowiedz:"Hasła nie są identyczne"});
        }

    }
    wyslij()
    {
        //przygotowanie danych 
        var url = new URL(window.location);
        var c = url.searchParams.get("token");
        var e = url.searchParams.get("user");
        var self = this;
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
        params.append('section', 'user');
        params.append('command', 'finishRecovery');
        params.append('data', JSON.stringify({token:c, login:e, pass:this.state.pass1}));
        params.append('pass', self.state.pass);
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            console.log(response.data);
            if (response.data.status==="OK")
            {
                
            }
            self.setState({odpowiedz:response.data.desc});
        }).catch(function (error) {console.log(error); self.setState({odpowiedz:"ERR"});});
    }
    render(){
        ////console.log("LG===");
        return (
        <div className="container">
            <div className="row">
                <div className="col-sm-4"></div>
                <div className="col-sm-4">
                    <h4>Odzyskiwanie hasła</h4>
                    <hr className="prettyhr"></hr>
                    {this.state.odpowiedz}
                    <div className="vF">
                        <input type="password" className="form-control form-control-sm" id="pass1" value={this.state.pass1} onChange={this.readForm}/>
                    </div>
                    <div className="vF">
                        <input type="password" className="form-control form-control-sm" id="pass2" value={this.state.pass2} onChange={this.readForm}/>
                    </div>
                    <button type="button" className="btn btn-primary btn-sm" onClick={this.wyslij}>Odzyskaj hasło</button>
                </div>
                <div className="col-sm-4"></div>
            </div>
            
        </div>);
    }
}


export default Recovery;