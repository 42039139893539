function Informacja(str)
{
    if (typeof str =="string")
    {if (str===""){return "Pracownik nie wprowadził komunikatu";}else{return str;}}
    else {return "Pracownik nie wprowadził komunikatu";}
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function IsJsonString(str) { try { JSON.parse(str); } catch (e) { return false; } return true;}

module.exports = {
    numberWithCommas,
    Informacja,
    IsJsonString
}
