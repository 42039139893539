import React  from "react";
import axios from 'axios';
import security from './security.js';
import Cookies from 'js-cookie';
// import Locatofertsnologin from './locatofertsnologin.js';
import ProductListContainer from './productlistcontainer.js';

class Lokaty extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ready:false, faq:[],values:{typ:"Własność", proAsk:0},powiaty:[],gminy:[],kodypocztowe:[],
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.ask = this.ask.bind(this);
        

    }
    IsJsonString(str) { try { JSON.parse(str); } catch (e) { return false; } return true;}
    ask(resource, data = null)
    {
          var self = this;
          const params = new URLSearchParams(); params.append('asc',security.securityKey());
          params.append('section', 'user');
          params.append('command', resource);
          params.append('data', data);
          params.append('token', Cookies.get("Experimental"));
          axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            //console.log(response.data.data);
          if (response.data.status==="OK")
          {
          //self.setState({loading:false});
              switch (resource){
              case 'loadGmina':  self.setState({gminy: response.data.data, ready:true}); return;
      
              default: return;
              //self.analiseUser(response.data.data);
              }
              
          }
          else if(response.data.status==="TEST")
          {
          console.log(response.data);
          }
          else if (response.data.status==="err")
          {
             //self.setState({server: response.data.desc});self.server();
          }
          self.setState({odpowiedz:response.data.desc});
          }).catch(function (error) {console.log(error);});
          
      }

    
    componentDidMount() {
        this.setState({ready:true});

    }

    render(){
 
        if (this.state.ready)
        {
           
            return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h3>Lokaty</h3>
                        <ProductListContainer  clientSelection={this.props.clientSelection} xml={this.props.xml} drawable={["Finanse osobiste", "Inwestycje", "Ubezpieczenia", "Dla Twojej firmy", "Produkty niefinansowe"]} 
        include={["Lokaty"]}/>
                    </div>
                </div>
                
           
            <div className="downMargin"></div>
             </div>       
        );}
            
            
        else
        {
            return (<div>Oczekiwanie na dane...</div>)
        }
    }



}



export default Lokaty;