import React  from "react";

class San extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
           
        };
        
        
        this.componentDidMount = this.componentDidMount.bind(this);
        //this.componentDidUpdate = this.componentDidUpdate.bind(this);

    }
    san(str)
    {
        var odp = str;
        if (typeof odp !=='undefined'){
        var unic = ["u0119","u00f3","u0105","u015b","u0142","u017c","u017a","u0107","u0144","u0118","u00d3","u0104","u015a","u0141","u017b","u0179","u0106","u0143"];
        var lett = ["ę","ó","ą","ś","ł","ż","ź","ć","ń","Ę","Ó","Ą","Ś","Ł","Ż","Ź","Ć","Ń"]
        for (var a = 0; a<unic.length; a++)
        {
            odp = odp.split(unic[a]).join(lett[a]);
        }}
        return odp;
    }
    
    componentDidMount()
    {
       
    }
    
    
    render(){
    
        return (<span>{this.san(this.props.text)}</span>);
}

}
export default San;