import React  from "react";
import axios from 'axios';
import security from './../common/security.js';
import Cookies from 'js-cookie';
class Wnioski extends React.Component{
    constructor(props) {
        super(props);
        this.state = { page:0, data:[]
           
        };
        
        
        this.componentDidMount = this.componentDidMount.bind(this);
        this.ask = this.ask.bind(this);
        this.home = this.home.bind(this);
        this.wn = this.wn.bind(this);
      }
    home(){
        this.setState({page:0});
    }
    san(str)
    {
        var odp = str;
        if (typeof odp !=='undefined'){
        var unic = ["u0119","u00f3","u0105","u015b","u0142","u017c","u017a","u0107","u0144","u0118","u00d3","u0104","u015a","u0141","u017b","u0179","u0106","u0143"];
        var lett = ["ę","ó","ą","ś","ł","ż","ź","ć","ń","Ę","Ó","Ą","Ś","Ł","Ż","Ź","Ć","Ń"]
        for (var a = 0; a<unic.length; a++)
        {
            odp = odp.split(unic[a]).join(lett[a]);
        }}
        return odp;
    }
    
    ask(resource)
    {
        var self = this;
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
        params.append('section', 'admin');
        params.append('command', 'getUserWnioski');
        params.append('data', resource);
        params.append('token', Cookies.get("Experimental"));
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
          console.log(response.data.data);
          if (response.data.status==="OK")
          {
            self.setState({loading:false});
              switch (resource){
                  case 'getUserInfo': self.setState({dane: response.data.data});return;
                  default: return;
              }
             
          }
          self.setState({odpowiedz:response.data.data});
         }).catch(function (error) {console.log(error); self.setState({odpowiedz:"ERR"});});}

    componentDidMount()
    {
       console.log(this.props.wnioski);
    }
    
    wn()
    {
        console.log(this.props.wnioski);
    }
    render(){
        if (this.props.wnioski.length===0)
        {
            return (<div onClick={this.wn}>Wniosków brak</div>)
        }
        else
        {
        return (<div> <h3>Lista wniosków klienta - historia</h3>
            {this.props.wnioski.map((key)=>(
                <div key={key.key} className="adminwniosek">
                    <div className="row">
                        <div className="col-sm-4">
                            <b>Dane wniosku</b><br></br>
                            złożony: {key.data}
                            HB: ?HGW?
                        </div>
                        <div className="col-sm-6">
                            <b>Dane oferty</b><br></br>
                            Nazwa oferty: {key.nazwa}<br></br>
                            Przedział kwot: {key.ofertaod} - {key.ofertado} (rat: {key.minimalnierat} - {key.maksymalnierat}) <br></br>
                            Oprocentowanie: {key.oprocentowanie}  Marża {key.oplatastala} + {key.procentowa}%
                        </div>
                        <div className="col-sm-2">
                            <b>Aktualny status</b><br></br>
                            {key.status}
                        </div>
                    </div>
                     
                </div>
            ))}</div>);
        }
}

}
export default Wnioski;