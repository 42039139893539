import React  from "react";
// import axios from 'axios';
// import $ from "jquery";
// import Documentlist from './../common/documentlist.js';

class Introforminfo extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            step:1, suggestions: [], value: '',pkd:"", nip:"", kodyPKD:[],loaded:false,
        };
        
        this.componentDidMount = this.componentDidMount.bind(this);
        this.dumpProps = this.dumpProps.bind(this);
        // this.componentDidUpdate = this.componentDidUpdate.bind(this);
        //this.steps = this.steps.bind(this);
    }
    self(){return this};
    validatenip(nip) {
        var nipWithoutDashes = nip.replace(/-/g,"");
        var reg = /^[0-9]{10}$/;
        if(reg.test(nipWithoutDashes) === false) {
            return false;}
        else
        {
            var digits = (""+nipWithoutDashes).split("");
            var checksum = (6*parseInt(digits[0]) + 5*parseInt(digits[1]) + 7*parseInt(digits[2]) + 2*parseInt(digits[3]) + 3*parseInt(digits[4]) + 4*parseInt(digits[5]) + 5*parseInt(digits[6]) + 6*parseInt(digits[7]) + 7*parseInt(digits[8]))%11;
             
            return (parseInt(digits[9])===parseInt(checksum));
        }
    }
    dumpProps(){console.log(this.props);}
    
    componentDidMount()
    {
        
        
    }

  
    render(){
    if (this.props.level===0){
        
        return (
            <div>
                <div className="row">
                    <div className="col-sm-12 just">
                        <h3>Złożone wnioski</h3>
                        <hr className="prettyhr"></hr>
                        {/* <Documentlist 
                            document={this.props.wnioski}
                            szczegolyWniosku={this.props.szczegolyWniosku}
                            ask={this.props.ask}
                            updateFinans={this.props.updateFinans}
                            firma={this.props.firma}
                            document={this.props.document}
                            /> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 just">
                        <h3>Produkty, które mogą zainteresować</h3>
                        <hr className="prettyhr"></hr>
                        Brak produktów
                    </div>
                </div>
                <div className="row">
                    
                        <div className="col-sm-12 just">
                            <div className="adnot">
                                    Administratorem Państwa danych osobowych jest homeBank S.A. z siedzibą w Warszawie, plac zamkowy 1. 
                                    W sprawach związanych z pytaniami i operacjami na danych osobowych należy kontaktować się z 
                                    Inspektorem danych osobowych: ido@homebank.pl. Dane o Państwa firmie są zbierane celem zaproponowania
                                    produktów bankowych oraz oceny zdolności kredytowej celem dopasowania produktów kredytowych do Państwa potrzeb
                                    i możliwości. Dane nie będą przekazywane podmiotom trzecim (w tym BiK), ani w kraju, ani za granicami UE,
                                    chyba, że na wyraźne życzenie osoby zarządzającej firmą (np. Wniosek Kredytowy). Podane dane będą przetwarzane 
                                    w celach statystycznych w sposób zapewniający anonimowość ich właściciela celem oszacowania zdolności kredytowej. 
                                    Zgodnie z regulaminem portal homeBank.pl nie bierze odpowiedzialności za ewentualne szkody spowodowane
                                    podaniem danych błędnych, niekompletnych lub nierzeczywistych.
                            </div>
                            
                        </div>
                    
                    </div>
                    
                    <div className="row">
                        <div className="col-sm-12">
                            <hr></hr>
    
                        </div>
                    </div>
                    
                </div>
            
        );}
        else
        {return (<div></div>);}
}

}
export default Introforminfo;