import React from 'react';
//import images
import creditcard from'./../../img/kasa.jpg';
import obrotowy from './../../img/obrotowy.jpg';
import leasing from './../../img/leasing.jpg';
import factoring from './../../img/faktoring.jpg';

import hipoteka from './../../img/hipoteka.jpg';
import malzenski from './../../img/malzenski.jpg';
import indywidualny from './../../img/indywidualny.jpg';

import kantor from './../../img/kantor.jpg';
import lokata from './../../img/lokaty.jpg';
import inne from './../../img/inne.jpg';
import broker from './../../img/broker.jpg';
import archiwum from './../../img/archiwum.jpg';

import rodzina from './../../img/rodzina.jpg';
import budynek from './../../img/budynek.jpg';
import cel from './../../img/cel.jpg';
import samochod from './../../img/samochod.jpg';
class Flipcard extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
        };
       
    }



    render(){ 
       var imagess = creditcard;
       switch (this.props.image)
       {
           case 'ratalny':imagess = creditcard; break;
           case 'obrotowy':imagess = obrotowy;break;
           case 'leasing':imagess = leasing;break;
           case 'factoring':imagess = factoring;break;
           case "hip":imagess = hipoteka;break;
           case "hip_mal":imagess= malzenski;break;
           case 'indywidualny':imagess = indywidualny;break;
           case 'kantor':imagess = kantor;break;
           case 'lokata':imagess = lokata;break;
           case "inne":imagess = inne; break;
           case 'broker':imagess = broker;break;
           case 'archiwum':imagess = archiwum;break;
           case 'rodzina':imagess = rodzina;break;
           case 'budynek':imagess = budynek;break;
           case 'cel':imagess = cel;break;
           case 'samochod':imagess = samochod;break;

           default:imagess = creditcard; break;

       }
        return (
            <div className="flip flip-card">
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                        <img className="flip-card-image"src={imagess} alt="Credit ico" />
                        <div className="flip-card" dangerouslySetInnerHTML={{ __html: this.props.first}}></div>
                    </div>
                    <div className="flip-card-back ">
                        <div className="flip-card-back-container">
                            <div className=" vertical-center">
                                
                                <span className="flip-card" dangerouslySetInnerHTML={{ __html: this.props.second}}></span>

                            </div>
                        </div>
                    
                    </div>
                </div>
            </div> 
        );
    
        }
}

export default Flipcard;