import React from "react";
import axios from 'axios'; 
import security from './../common/security.js';
import Cookies from 'js-cookie';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

class Mailing extends React.Component{

    constructor(props) {
        super(props);
        this.state = {maillist:[],};
       
        this.ask =  this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.buttonRouter = this.buttonRouter.bind(this);
        this.home = this.home.bind(this);
    }
    home(){ this.setState({page:0});  }
    server = () => { confirmAlert({  customUI: ({ onClose }) => { return ( <div className='custom-ui'><h3>Komunikat serwera</h3><p>{this.state.server}</p> <button className="btn btn-danger btn-sm" onClick={onClose} >Zamknij</button>  </div> ); } });}
    submit = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
            return (
                <div className='custom-ui'>
                <h3>Wymagane potwierdzenie</h3>
                <p>Operacja, którą chcesz wykonać jest nieodwracalna i wpływa na strukturę danych. Czy chcesz skasować tą pozycję?</p>
                <button className="btn btn-secondary btn-sm" onClick={onClose}>Anuluj</button>
                &nbsp;
                <button
                    className="btn btn-danger btn-sm"
                    onClick={() => {
                    this.ask("deleteWniosek", this.state.idd);
                    onClose();
                    }}
                >
                    Usuń
                </button>
                </div>
            );
            }
        });}
  
      ask(resource, data=null)
      {
            var self = this;
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'admin');
            params.append('command', resource);
            params.append('data', data);
            params.append('token', Cookies.get("Experimental"));
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
              console.log(response.data.data);
              if (response.data.status==="OK")
              {
                self.setState({loading:false});
                  switch (resource){
                      case 'getMailList': self.setState({maillist: response.data.data});return;
                      default: return;
                  }
                 
              }
              self.setState({odpowiedz:response.data.desc});
             }).catch(function (error) {console.log(error); self.setState({odpowiedz:"ERR"});});
      }
      componentDidMount(){
            this.ask("getMailList", null); 
        
      }
      buttonRouter(ev)
      {
          console.log(ev.target.dataset);
          var action = ev.target.dataset.action;
          var userId = ev.target.dataset.id;
          if (action==='info'){this.setState({page:1}); this.askk('getUserInfo', userId) }
         
      }
      
    render(){
    return (<div className="container">
        <div className="row">
            <div className="col-sm-12">
            <h3>Planowanie kampanii mailingowych</h3>

            <table className="table table-hover">
                <thead>
                    <tr>
                        <td>ID</td>
                        <td>Tytuł</td>
                        <td>Grupa docelowa</td>
                        <td>Data wysyłki</td>
                        <td>Operacje</td>
                    </tr>
                </thead>
                <tbody>

               
                    {this.state.maillist.map((key)=>(
                        <tr key={key.id}>
                            <td>{key.id}</td>
                            <td>{key.tytul}</td>
                            <td>{key.nazwa}</td>
                            <td>{key.sended}</td>
                            <td>
                                <div className="btn-group">
                                    <button className="btn btn-secondary btn-sm" onClick={this.buttonRouter} data-action="EditItem" data-id={key.id}>Edytuj</button>
                                    <button className="btn btn-secondary btn-sm" onClick={this.buttonRouter} data-action="Resend" data-id={key.id}>Wyślij</button>
                                </div>    
                            </td>
                        </tr>
                    ))}
                        </tbody>
                </table>
            </div>
        </div>
        
    </div>)
      
}
}


export default Mailing;