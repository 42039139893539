import React  from "react";
//import Autosuggest from 'react-autosuggest';
import UniwersalFirmForm from './../common/universalFirmForm';


class Introformtre extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            step:1, suggestions: [], value: ''
        };
        
    }

    
    render(){
    if (this.props.level===3){
        return (
            <div>
                <div className="row">
                    <div className="col-sm-12">
                       <UniwersalFirmForm page={11} sendable={{"display":"block"}} settings={{"zluz":true}}  form={this.props.form} formdata={this.props.data} data={this.props.data} changes={this.props.change}formId={this.props.formID}/>
                    </div>
                </div>
            </div>
            
        );}
        else
        {return (<div></div>);}
}

}
export default Introformtre;