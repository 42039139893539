import React  from "react";


class Documentlist extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};


    }
    
    render(){
        var st2per=[0,10,50,100,10,20,25,30,40,45,50,60,70,80,90,95,100];
        var style={width:st2per[this.props.status]+"%", margin:"0px"};
        return (
        <div className="zewnetrzny">
            <div className="wewnetrzny" style={style}>
                {st2per[this.props.status]}%
            </div>
        </div>);
        
}

}

export default Documentlist;