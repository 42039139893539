import React  from "react";
import axios from 'axios';
import security from './common/security.js';
import Cookies from 'js-cookie';

class Ustawienia extends React.Component{
    constructor(props) {
        super(props);
        this.state = { data:[], name:"", lastname:"", mail:"", ohasle:"",
        pass1:"", pass2:"", pass3:""
        };
       this.componentDidMount = this.componentDidMount.bind(this);
       this.handleChange = this.handleChange.bind(this);
       this.saveImie = this.saveImie.bind(this);
       this.savePass = this.savePass.bind(this);
       this.dismissAlert = this.dismissAlert.bind(this);
    }
    saveImie()
    {
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
        var self = this;
        params.append('section', 'user');
        params.append('command', 'saveImie');
        params.append('token', Cookies.get("Experimental"));
        params.append("data", JSON.stringify({name:this.state.name, lastname: this.state.lastname, mail:this.state.mail}));
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            console.log(response.data);
            if (response.data.status==='ok')
            {
                self.setState({ohasle: self.renderWynik('Witaj ' + self.state.name + " " +self.state.lastname +'! Dane personalne zmienione poprawnie.'),pass1:"",pass2:"",pass3:""});
                setTimeout(function(){self.setState({ohasle:""})},2000);
            }
        }).catch(function (error) { console.log(error);});
    }
    savePass()
    {
        var self = this;
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
        params.append('section', 'user');
        params.append('command', 'changePass');
        params.append('token', Cookies.get("Experimental"));
        params.append("data", JSON.stringify({mail:this.state.mail, pass1:this.state.pass1, pass2:this.state.pass2, pass3:this.state.pass3}));
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            console.log(response.data);
            if (response.data.status==='err')
            {
                self.setState({ohasle: self.renderNegativeWynik(response.data.desc)});
                setTimeout(function(){self.setState({ohasle:""})},2000);
                
            }
            else
            {
                self.setState({ohasle: self.renderWynik('Hasło zostało zmienione poprawnie.'),pass1:"",pass2:"",pass3:""});
                setTimeout(function(){self.setState({ohasle:""})},2000);
            
            }
        }).catch(function (error) { console.log(error);});
    }
    componentDidMount(){ 
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
        params.append('section', 'user');
        params.append('command', 'getPersonalData');
        params.append('token', Cookies.get("Experimental"));
        params.append("data", JSON.stringify({   }));
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
        console.log(response.data.data);
        self.setState({name: response.data.data.name,
                        lastname: response.data.data.lastname,
                    mail: response.data.data.mail});
        }).catch(function (error) { console.log(error);});
    }
    handleChange(ev)
    {
        var self = this;
        switch (ev.target.name)
        {
            case 'name': self.setState({name: ev.target.value}); break;
            case 'lastname': self.setState({lastname: ev.target.value});break;
            case 'pass1': self.setState({pass1: ev.target.value});break;
            case 'pass2': self.setState({pass2: ev.target.value});break;
            case 'pass3': self.setState({pass3: ev.target.value});break;
            default:break;
        }
    }
    dismissAlert()
    {
        this.setState({ohasle:""});
    }
    renderNegativeWynik(data)
    {
        return(
            <div className="alert alert-danger" onClick={this.dismissAlert}>
    
    <strong>Ups!</strong> {data}
    </div>
        )
    }
    renderWynik(data)
    {
        return(
            <div className="alert alert-success" onClick={this.dismissAlert}>
    <strong>Sukces!</strong> {data}
    </div>
        )
    }
    render(){
        
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3>Ustawienia użytkownika <code>{this.state.mail}</code></h3>
                            <hr className="prettyhr"></hr>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Imię</label>
                            <input type="text" className="form-control" name="name" value={this.state.name} onChange={this.handleChange}/>
                        </div>
                        <div className="col-sm-3">
                            <label>Nazwisko</label>
                            <input type="text" className="form-control" name="lastname" value={this.state.lastname} onChange={this.handleChange}/>
                        </div>
                        <div className="col-sm-3">
                            
                        </div>
                        <div className="col-sm-3">
                            <div className="nolabel"></div>
                            <button className="btn btn-secondary" onClick={this.saveImie}>Zmień</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <br></br>
                          Hasła muszą mieć minimum 5 znaków, składać się z dużych i małych liter, zawierać cyfrę oraz posiadać co najmniej jeden znak specjalny   
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Stare hasło</label>
                            <input type="password" className="form-control" name="pass1" value={this.state.pass1} onChange={this.handleChange}/>
                        </div>
                        <div className="col-sm-3">
                            <label>Nowe hasło</label>
                            <input type="password" className="form-control" name="pass2" value={this.state.pass2} onChange={this.handleChange}/>
                        </div>
                        <div className="col-sm-3">
                            <label>Powtórz hasło</label>
                            <input type="password" className="form-control"  name="pass3" value={this.state.pass3} onChange={this.handleChange}/>
                        </div>
                        <div className="col-sm-3">
                            <div className="nolabel"></div>
                            <button className="btn btn-secondary" onClick={this.savePass}>Zmień</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-9">
                            <div className="alertmargin">
                                {this.state.ohasle}
                            </div>
                            
                            
                            
                        </div>
                        
                    </div>
                </div>
                
            );
        }
    
}


export default Ustawienia;