import React  from "react";
import axios from 'axios';
import Cookies from 'js-cookie';
import security from './../common/security.js';
import Myalert from './myalert.js';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 


class Edprobank extends React.Component{
    constructor(props) {
        super(props);
        this.state = { products:[], edit:"none", nNazwa:"", nStatus:"", nId:0,
        desc:"",title:"", server:''
            
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.ask = this.ask.bind(this);
        this.chandleVal = this.chandleVal.bind(this);
        this.edytuj = this.edytuj.bind(this);
        this.server = this.server.bind(this);
    }
    
    submit = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h3>Wymagane potwierdzenie</h3>
                  <p>Operacja, którą chcesz wykonać jest nieodwracalna i wpływa na strukturę danych. Czy chcesz skasować tą pozycję?</p>
                  <button className="btn btn-secondary btn-sm" onClick={onClose}>Anuluj</button>
                  &nbsp;
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => {
                      this.ask("deleteExistedGenere", this.state.nId);
                      onClose();
                    }}
                  >
                    Usuń
                  </button>
                </div>
              );
            }
          });}

    server = () => { confirmAlert({  customUI: ({ onClose }) => { return ( <div className='custom-ui'><h3>Komunikat serwera</h3><p>{this.state.server}</p> <button className="btn btn-danger btn-sm" onClick={onClose} >Zamknij</button>  </div> ); } });}
    
    ask(resource, data)
      {
            var self = this;
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'admin');
            params.append('command', resource);
            params.append('data', data);
            params.append('token', Cookies.get("Experimental"));
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
              //console.log(response.data.data);
              if (response.data.status==="OK")
              {
                //self.setState({loading:false});
                  switch (resource){
                    case 'getProductList': self.setState({products: response.data.data});return;
                    case 'saveExistedGenere': self.ask('getProductList', null);self.setState({server: response.data.desc});self.server();break;
                    case 'deleteExistedGenere':self.ask('getProductList', null);self.setState({server: response.data.desc});self.server();break;
                      default: return;
                  }
                 
              }
              else if(response.data.status==="TEST")
              {
                console.log(response.data);
              }
              else if (response.data.status==="err")
              {
                self.setState({server:response.data.desc});
                self.server();
                  
              }
              self.setState({odpowiedz:response.data.desc});
             }).catch(function (error) {console.log(error);self.setState({server: "Wystąpił błąd serwera"});self.server();});
        }
    componentDidMount(){
        this.ask('getProductList', null);
       
    }
    chandleVal(ev)
    {
        var name = ev.target.name;
        ////console.log("PROPS",this.props);
        if (name==="kredyt") {this.setState({kredyt :ev.target.value});}
        if (name==="rat")    {this.setState({rat    :ev.target.value});}
        if (name==="nNazwa") {this.setState({nNazwa :ev.target.value});}
        if (name==="nStatus"){this.setState({nStatus:ev.target.value});}
        if (name==="anuluj") {this.setState({edit   :"none"});}
        if (name==="nowy") {this.setState({nId:"NEW", edit   :"block", nNazwa:"", nStatus:1});}
        if (name==="zapisz") {
            this.ask('saveExistedGenere', JSON.stringify({
                nazwa:this.state.nNazwa,
                id: this.state.nId,
                status: this.state.nStatus
            }));
            this.setState({nId:0, edit   :"none"});}
            if (name==="usun") {
               // console.log(ev.target.dataset);
                this.setState({nId:ev.target.dataset.id, edit   :"none"});
                this.submit();
            }
       
    }
    edytuj(ev)
    {
        var prod = this.state.products;
        for (var a =0; a<prod.length; a++)
        {
            if (parseInt(prod[a].id)===parseInt(ev.target.dataset.id))
            {
               // console.log(prod[a]);
                this.setState({nNazwa: prod[a].nazwa, nStatus:prod[a].aktywny, nId: prod[a].id, edit:"block"});
            }
        }
        
    }
    render(){
    
     
       return (<div className="container">
           <div className="row">
                <div className="col-sm-2"></div>
                <div className="col-sm-4">
                    <table style={{width:"100%"}}>
                        <tbody>
                        {this.state.products.map((key)=>(
                <tr key={key.id}><td>{key.nazwa}</td><td>
                    <div className="btn-group">
                        <button className="btn btn-secondary btn-sm" data-id={key.id} onClick={this.edytuj}>Edytuj</button>
                        <button className="btn btn-danger btn-sm" data-id={key.id} name="usun" onClick={this.chandleVal}>Usuń</button>
                    </div>
                </td></tr>
           ))}
           <tr><td></td><td><button className="btn btn-success btn-sm" name="nowy"  onClick={this.chandleVal}>Nowy</button></td></tr>

                        </tbody>
                    </table>
               </div>
               <div className="col-sm-4">
                   <div style={{display: this.state.edit}}>
                        <div className="form-group">
                            <label>Nazwa:</label>
                            <input type="text" className="form-control" name="nNazwa" value={this.state.nNazwa} onChange={this.chandleVal}/>
                        </div>
                        <div className="form-group">
                            <label>Status:</label>
                            <select className="form-control" id="sel1" name="nStatus" value={this.state.nStatus} onChange={this.chandleVal}>
                                <option value="0">Wyłączony</option>
                                <option value="1">Włączony</option>
                            </select>
                        </div> 
                        <div className="btn-group">
                            <button className="btn btn-secondary btn-sm" name="zapisz"  onClick={this.chandleVal}>Zapisz zmiany</button>
                            <button className="btn btn-secondary btn-sm" name="anuluj"  onClick={this.chandleVal}>Anuluj</button>
                        </div>
                   </div>
               </div>
           </div>
           <Myalert desc={this.state.desc} title={this.state.title}/>
        </div>)
    }
}


export default Edprobank;