import React  from "react";

import Logo from './../img/logo.png';

class Loader extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }

    render(){
        document.title ="HomeBank | Panel Administracyjny";
        
    return (
        <div className="starter">
            <img src={Logo} className="loader-logo" alt="homeBank" />
            <div className="oprawka"><div className="kropka"></div></div>
        </div>
    );


}

}

export default Loader;