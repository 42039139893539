import React  from "react";
import GEO from './../common/geonames.js';
import axios from 'axios';
import security from './security.js';
import Cookies from 'js-cookie';
import KODY from './../common/kodypocztowe.js';
// import { MultiSelect } from '@progress/kendo-react-dropdowns';
import {GoFile} from 'react-icons/go';

import VAL from './validators.js';
import XML from './xmlparser.js';

class UniversalFirmForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ready:true, faq:[],values:{dokumenty:[], formID:"",zdolnosc:0, finanse:[]},powiaty:[],gminy:[],
            kodypocztowe:[],oldpowiaty:"",oldgminy:"",oldwoj:0,documents:[],ksiegowosc:0, wyniki:[[]],
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.rysuj = this.rysuj.bind(this);
        this.changeVal = this.changeVal.bind(this);
        this.debug = this.debug.bind(this);
        this.loadPowiat = this.loadPowiat.bind(this);
        this.loadGmina = this.loadGmina.bind(this);
        this.loadKody = this.loadKody.bind(this);
        this.ask = this.ask.bind(this);
        this.ask2 = this.ask2.bind(this);
        this.addRow = this.addRow.bind(this);
        this.numberOnly = this.numberOnly.bind(this);
        this.iterateTable = this.iterateTable.bind(this);
        this.rebulidArray = this.rebulidArray.bind(this);
        this.wczytajDane = this.wczytajDane.bind(this);
       
    }
    IsJsonString(str) { try { JSON.parse(str); } catch (e) { return false; } return true;}
    wczytajDane()
    {
        var pola = this.state.values.finanse.length;
        var ksiegowosc = this.state.values.ksiegowosc;
        var wierszy = 0.0;
        switch (parseInt(ksiegowosc))
        {
            case 1: wierszy=pola/2;break;
            case 2: wierszy=pola/3;break;
            case 3: wierszy=pola/4;break;
            case 4: wierszy=pola/5;break;
            default:wierszy = 3; //console.log("UNDEFINED CASE");break;
        }
        var xx = []; 
        for (var a =0; a<wierszy;a++)
        {
            xx.push([]);
        }
        this.setState({wyniki:xx});
        // var self = this;
        setTimeout(() => {
            var inputs = document.getElementsByClassName("tables");
            console.log(xx,inputs, pola, wierszy, ksiegowosc);
            for (var x = 0; x<inputs.length; x++)
            {
                if (typeof this.state.values.finanse[x]!=="undefined")
                {inputs[x].value = this.state.values.finanse[x];}
            }
        }, 300);
    }
    rebulidArray(a)
    {
        var ksiegowosc = this.state.values.ksiegowosc;
        
    }
    iterateTable()
    {
        var val = [];
        var values = this.state.values;
        var content = document.getElementsByClassName("tables");
        for (var x = 0; x<content.length; x++)
        {
            val.push(content[x].value);
        }
        values.finanse= val;
        this.setState({values:values});
    }
    numberOnly(ev)
    {
        var n = ev.target.value;
        if (n==="-"){return;}
        var filtr = ev.target.dataset.filtr;
        console.log(n);
        n = parseInt(n); 
        if (isNaN(n)){n="";}
        if (filtr ==="rok" && n.toString().length>4){n=n.toString().substr(0,4);}
        ev.target.value = n;
        this.iterateTable();
    }
    deleteRow(ev)
    {
        console.log(ev.target.parentElement.parentElement.remove());
    }
    addRow()
    {
        var t = this.state.wyniki;
        var values = this.state.values;
        values.finanse.push([2019,0,0,0,0,]);
        t.push([]);
        this.setState({wyniki:t, values:values});
    }
    ask(resource, data = null)
    {
          var self = this;
          const params = new URLSearchParams(); params.append('asc',security.securityKey());
          params.append('section', 'user');
          params.append('command', resource);
          params.append('data', data);
          params.append('token', Cookies.get("Experimental"));
          axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            //console.log(response.data.data);
          if (response.data.status==="OK")
          {
          //self.setState({loading:false});
              switch (resource){
              case 'loadGmina':  self.setState({gminy: response.data.data, ready:true}); return;
      
              default: return;
              //self.analiseUser(response.data.data);
              }
              
          }
          else if(response.data.status==="TEST")
          {
          console.log(response.data);
          }
          else if (response.data.status==="err")
          {
             //self.setState({server: response.data.desc});self.server();
          }
          self.setState({odpowiedz:response.data.desc});
          }).catch(function (error) {console.log(error);});
          
      }
      ask2(resource, data = null)
      {
            var self = this;
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'documents');
            params.append('command', resource);
            params.append('data', data);
            params.append('token', Cookies.get("Experimental"));
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
              //console.log(response.data.data);
            if (response.data.status==="OK")
            {
            //self.setState({loading:false});
                switch (resource){
                case 'getFileList':  
                    // var documents = [];
                    // for (var a = 0; a<response.data.data.length; a++)
                    // {
                    //     documents.push(response.data.data[a].name)
                    // }
                    self.setState({documents: response.data.data, ready:true}); return;
        
                default: return;
                //self.analiseUser(response.data.data);
                }
                
            }
            else if(response.data.status==="TEST")
            {
            console.log(response.data);
            }
            else if (response.data.status==="err")
            {
               //self.setState({server: response.data.desc});self.server();
            }
            self.setState({odpowiedz:response.data.desc});
            }).catch(function (error) {console.log(error);});
            
        }
    
    componentDidMount() {
        this.setState({ready:true});
        this.ask2("getFileList");
        //console.log("MOUNTED");
        
    }
    componentDidUpdate()
    {
        ////console.log("UPDATED");
        var flag = false;
        var form = {};//{settings:{formName:"Brak danych"}, form:[]};
        if (VAL.IsJsonString(this.props.form) && this.props.form !=="" && this.props.form!==null)
        { 
            var inform = JSON.parse(this.props.form);
            if (typeof inform.settings!=='undefined'){form.settings = inform.settings;}
            if (typeof inform.form!=='undefined'){form.form = inform.form; }
            if (typeof form.settings!=='undefined' && typeof form.form!=='undefined'){flag = true;}
            if (this.state.values.formID==="" && typeof inform !== 'undefined')
            {
                if (typeof inform.settings !== 'undefined')
                {
                    if (typeof inform.settings.formId !== 'undefined')
                    {
                        var values = this.state.values;
                        values.formID = inform.settings.formId;
                        this.setState({values:values});
                        // //console.log("FORMULARZ", inform.settings);
                    }
                }
               
                
            }
           
        }
        
        if (flag)
        {
            if (this.state.oldwoj!==this.props.data.wojewodztwo)
            {
                //console.log("WOJ LOAD POWIAT", this.props.data.wojewodztwo);
                this.loadPowiat(this.props.data.wojewodztwo);
                this.setState({oldwoj:this.props.data.wojewodztwo});
            }

            if (this.state.oldpowiaty!==this.props.data.powiat)
            {
                //console.log("WOJ LOAD GMINA", this.props.data.powiat);
                this.loadGmina(this.props.data.powiat);
                this.setState({oldpowiaty:this.props.data.powiat});
            }
        }

    }

    loadPowiat(a)
    {
        if (typeof a !== "undefined")
        {//console.log("powiat", a);
       
        var dane = GEO.geo(a);
        //console.log("GEODATA",a,dane);
        var pow =[];
        pow.push({value:0,text:"Wybierz..." });
        for (var x = 0; x<dane.length; x++)
        {
            var nn = dane[x].powiat;
            pow.push({value:nn,text:nn });
        }
        //console.log("Powiaty",pow);
        this.setState({powiaty:pow});}
        
        
    }
    loadGmina(gmina)
    {
        //console.log("GMINA DLA",gmina);
        this.ask("loadGmina", gmina);

    }
    loadKody(gmi)
    {
        // //console.log("LOADKODY", gmina, this.state.values);
        var s = this.state.values;
        var woj = ["Undef","dolnośląskie", "kujawsko_pomorskie", 'lubelskie','lubuskie', 'łódzkie', 'małopolskie', 'mazowieckie', 'opolskie', 'podkarpackie','podlaskie', 'pomorskie', 'śląskie', 'świętokrzyskie', 'warmińsko_mazurskie', 'wielkopolskie', 'zachodniopomorskie'];
        var wojewodztwo = woj[parseInt(s.wojewodztwo)];
       
        //var pow = s.powiat.split(" ");
        var powiat = s.powiat.replace("Powiat ","");powiat = powiat.replace("m. ","");powiat = powiat.replace(" ","_");powiat=powiat.replace("-","_"); powiat = powiat.replace("st. ", "");
        var gmina = gmi.replace("M. ",""); gmina = gmina.replace("-","_"); gmina = gmina.replace(" ","_"); gmina = gmina.replace("st. ", "");
        var pytaj = gmina+powiat+wojewodztwo;
        var kody = KODY.getKody(pytaj);
        var codes = [];
        codes.push({value:0,text:"Wybierz..."});
        for (var a = 0; a<kody.length;a++)
        {
            codes.push({value:kody[a],text:kody[a]});
        }
        this.setState({kodypocztowe:codes});
        //console.log("LOADKODY", pytaj, kody);
    }
    changeVal(ev)
    {
        console.log(ev.target.dataset);
        var names = ev.target.dataset.id;
        var filtr = ev.target.dataset.filtr;
        var values= this.state.values;
        var wpisz = ev.target.value;
        console.log(names, values, filtr);
        if (names==='ksiegowosc')
        {
            this.setState({ksiegowosc:parseInt(wpisz)});
        }
        if (names==='dokumenty')
        {
            wpisz = ev.target.dataset.name;
            if (!values[names].includes(wpisz))
            {
                values[names].push(wpisz);
                ev.target.classList.add("active");
            }
            else
            {
                var pos = values[names].indexOf(wpisz);
                values[names].splice(pos,1);
                ev.target.classList.remove("active");
                //console.log("REMOVING", wpisz, pos);
                
            }
            //values[names] = [wpisz];
            console.log(values[names]);

        }
        else
        {values[names]= wpisz.toString();}
        var form = JSON.parse(this.props.form);
        values.formID = form.settings.formId;
        console.log(values.formID, form.settings.formId)
        if (typeof filtr !=="undefined" && filtr!=="text" && filtr!=="date")
        { 
            if (filtr==="year"){values[names]=parseInt(values[names]);}
            if (filtr==="float"){values[names]=parseFloat(values[names]);}
            if (isNaN(values[names])){values[names]="0";}
        }
        values.zdolnosc = XML.liczZdolnoscFirmy(values);
        this.setState({values: values});

        if (names==="wojewodztwo"){this.loadPowiat(wpisz);this.setState({gminy:[{text:"Wybierz", value:0}]})} 
        if (names==="powiat"){this.loadGmina(wpisz);}
        if (names==="gmina"){this.loadKody(wpisz);}
        
    }
    validateVal(ev)
    {
        console.log(ev.target.dataset)
        var gen = ev.target.dataset.id.toLowerCase();
        var val = ev.target.value;
        var flag = true;
        switch (gen)
        {
            case 'nip': flag=VAL.validatenip(val); break;
            case 'kod': flag=VAL.validateKod(val); break;
            case 'tel': flag=VAL.validateTel(val); break;
            case 'mail': flag=VAL.validateEmail(val); break;
            case 'zgoda1': flag=VAL.validateZgoda(gen, val); break;
            case 'zgoda2': flag=VAL.validateZgoda(gen, val); break;
            case 'zgoda3': flag=VAL.validateZgoda(gen, val); break;
            default: if (val.length===0){flag=false;}break;
        }
        if (!flag){ev.target.classList.add('error');}else{ev.target.classList.remove('error');}
        if (document.getElementsByClassName('error').length>0)
        {
            document.getElementById("zapisz").disabled = true;
            document.getElementById("zapisz0").disabled = true;
            document.getElementById("zapisz1").disabled = true;
        }
        else
        {   
            if (typeof document.getElementById("zapisz") !== 'undefined')
            {
                document.getElementById("zapisz").disabled = false;
                document.getElementById("zapisz0").disabled = false;
                document.getElementById("zapisz1").disabled = false;
            }
        }
    }
    debug()
    {
        //console.log("STATE",this.state.values, "PROPS", this.props);
    }
    rysuj(d)
    {
        if (typeof d.name !== 'undefined' && typeof this.props.data !== 'undefined' && typeof this.props.formdata !== 'undefined')
        {
            // (typeof this.props.data[d.name] === "undefined" || this.props.data[d.name]===null) ? this.state.values[d.name]: this.props.data[d.name]
            var st = this.state.values;
            if (typeof st[d.name]==="undefined")
            {
                if (typeof this.props.data[d.name]!== 'undefined')
                {
                    st[d.name] = this.props.data[d.name];
                    console.log(d.name+" =  "+this.props.data[d.name])
                }
                else
                {
                    st[d.name]="";
                }
    
                this.setState({values:st});
            }
            if (typeof this.props.data.finanse !== 'undefined' && st.finanse.length===0)
            {
                if (this.props.data.finanse.length>0){st.finanse = this.props.data.finanse;}
            }
        }
        var keys = [];
        if (typeof this.props.formdata!== 'undefined'){keys = Object.keys(this.props.formdata);}
        for (var a = 0; a<keys.length; a++)
        {
            ////console.log("FORMDATA", this.props.formdata[keys[a]]);
            //if ()
        }
        if (d.pole==="label"){
            return (<div className="mcol b12"><b>{VAL.san(d.value)}</b></div>)
        }
        if (d.pole==="finans"){
            if (parseInt(this.state.values.ksiegowosc)===0)
            {return (<div className="col-sm-12"><b>Wyniki finansowe</b> <div className="alert alert-info">Odpowiednia tabela będzie dostępna po wybraniu sposobu prowadzenia księgowości</div></div>)}
       
            else if (parseInt(this.state.values.ksiegowosc)===1)
            {return (<div className="col-sm-12">
                <b>Wyniki finansowe na podstawie Karty Podatkowej</b> 
                <div><small>Wpisz wyniki z trzech ostatnich lat. Jeśli działalność prowadzisz krócej - z zcałego okresu działalności</small></div>
            <div>
                <button onClick={this.addRow} className="btn btn-secondary btn-sm">Dodaj</button> {" "}
                <button onClick={this.wczytajDane} className="btn btn-secondary btn-sm">Wczytaj</button>
                <table className="setka">
                    <thead>
                        <tr>
                            <td>rok</td>
                            <td>Stawka z karty</td>
                            <td>#</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.wyniki.map((key,i)=>(<tr key={i}>
                            <td><input type="text" data-filtr="rok" className="mform tables"  onChange={this.numberOnly} /></td>
                            <td><input type="text" data-filtr="" className="mform tables" onChange={this.numberOnly} /></td>
                            <td><button className="btn btn-secondary btn-sm korekta" onClick={this.deleteRow}>Usuń</button></td>
                        </tr>))}
                    </tbody>
                </table>
                
                
                </div></div>)}


            else if (parseInt(this.state.values.ksiegowosc)===2)
            {return (<div className="col-sm-12"><b>Wyniki finansowe na podstawie Ryczałtu Ewidencjonowanego</b> 
                            <div><small>Wpisz wyniki z trzech ostatnich lat. Jeśli działalność prowadzisz krócej - z zcałego okresu działalności</small></div>

            <div>
                <button onClick={this.addRow} className="btn btn-secondary btn-sm">Dodaj</button>{" "}
                <button onClick={this.wczytajDane} className="btn btn-secondary btn-sm">Wczytaj</button>
                <table className="setka">
                    <thead>
                        <tr>
                            <td>rok</td>
                            <td>Przychód</td>
                            <td>Stawka ryczałtu</td>
                            <td>#</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.wyniki.map((key,i)=>(<tr key={i}>
                            <td><input type="text" className="mform tables" data-filtr="rok" onChange={this.numberOnly} /></td>
                            <td><input type="text" className="mform tables" onChange={this.numberOnly} /></td>
                            <td><select className="mform tables" >
                                <option value="20">20%</option>
                                <option value="17">17%</option>
                                <option value="12.5">12.5%</option>
                                <option value="10">10%</option>
                                <option value="8.5">8.5%</option>
                                <option value="5.5">5.5%</option>
                                <option value="3">3%</option>
                                <option value="2">2%</option>
                                </select></td>

                            <td><button className="btn btn-secondary btn-sm korekta" onClick={this.deleteRow}>Usuń</button></td>
                        </tr>))}
                    </tbody>
                </table>

                
                
                
                </div></div>)}
       
        else if (parseInt(this.state.values.ksiegowosc)===3)
            {return (<div className="col-sm-12"><b>Wyniki finansowe na podstawie KPiR</b> 
                            <div><small>Wpisz wyniki z trzech ostatnich lat. Jeśli działalność prowadzisz krócej - z zcałego okresu działalności</small></div>

            <div>
                <button onClick={this.addRow} className="btn btn-secondary btn-sm ">Dodaj</button>{" "}
                <button onClick={this.wczytajDane} className="btn btn-secondary btn-sm">Wczytaj</button>
                <table className="setka">
                    <thead>
                        <tr>
                            <td>rok</td>
                            <td>Przychód</td>
                            <td>Koszty</td>
                            <td>Amortyzacja</td>
                            <td>#</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.wyniki.map((key,i)=>(<tr key={i}>
                            <td><input type="text" className="mform tables" data-filtr="rok" onChange={this.numberOnly} /></td>
                            <td><input type="text" className="mform tables" onChange={this.numberOnly} /></td>
                            <td><input type="text" className="mform tables" onChange={this.numberOnly} /></td>
                            <td><input type="text" className="mform tables" onChange={this.numberOnly} /></td>
                            <td><button className="btn btn-secondary btn-sm" onClick={this.deleteRow}>Usuń</button></td>
                        </tr>))}
                    </tbody>
                </table>
                </div></div>)}
        else if (parseInt(this.state.values.ksiegowosc)===4)
        {return (<div className="col-sm-12"><b>Wyniki finansowe na podstawie pełnej księgowości</b> 
                        <div><small>Wpisz wyniki z trzech ostatnich lat. Jeśli działalność prowadzisz krócej - z zcałego okresu działalności</small></div>

        <div>
           <button onClick={this.addRow} className="btn btn-secondary btn-sm">Dodaj</button>{" "}
                <button onClick={this.wczytajDane} className="btn btn-secondary btn-sm">Wczytaj</button>
           <table className="setka">
               <thead>
                   <tr>
                       <td>rok</td>
                       <td>Aktywa</td>
                       <td>Pasywa</td>
                       <td>Przychód</td>
                       <td>Zysk/Strata</td>
                       <td>#</td>
                   </tr>
               </thead>
               <tbody>
                   {this.state.wyniki.map((key,i)=>(<tr key={i}>
                       <td><input type="text" className="mform tables" data-filtr="rok" onChange={this.numberOnly} /></td>
                       <td><input type="text" className="mform tables" onChange={this.numberOnly} /></td>
                       <td><input type="text" className="mform tables" onChange={this.numberOnly} /></td>
                       <td><input type="text" className="mform tables" onChange={this.numberOnly} /></td>
                       <td><input type="text" className="mform tables" onChange={this.numberOnly}  data-filtr="signed"/></td>
                       <td><button className="btn btn-secondary btn-sm" onClick={this.deleteRow}>Usuń</button></td>
                   </tr>))}
               </tbody>
           </table>
           </div></div>)}
           else
           {
               return (<div>Dane księgowe {JSON.stringify(this.state.values.ksiegowosc)}</div>)
           }
        }
        if (d.pole==='desc'){
            
            return (<div className="mcol b12"><small>{VAL.san(d.value)}</small></div>)
        }
        if (d.pole==="text")
        {
            // //console.log("NAME", d.name)
            // //console.log("TYPEPOLA",typeof this.props.data, this.props.data);
            //var val = this.state.values;
            return (<div className="">
            <label>{d.title}</label>
            <input data-filtr={d.filtr}
            d={d.id} 
            type={d.type} 
            className="mform" 
            data-id={d.name} 
            value={VAL.san(this.state.values[d.name])} 
            onChange={this.changeVal}
            onBlur={this.validateVal}
            />
            
          </div>)
        }
        
        if (d.pole==="select")
        {
            var opcje = d.option;
            if (d.name==='powiat'){opcje = this.state.powiaty;}
            if (d.name==='gmina'){opcje = this.state.gminy;}
            if (d.name==='kod'){opcje = this.state.kodypocztowe;}

            return (<div className="form-group">
            <label>{d.title}</label>
            <select className="mform"  id={d.id} data-id={d.name} value={this.state.values[d.name]} onBlur={this.validateVal}  onChange={this.changeVal}>
                {opcje.map((key, i)=>(
                    <option key={i} value={key.value}>{key.text}</option>
                ))}
            </select>
            </div> );
        }
        // if (d.pole==="button")
        // {
        //     return (<div>xx</div>)
        // }
        if (d.pole==="row")
        {
            return(<br></br>);
        }
    }
    
    render(){
       if (this.props.page===11) 
    {
        var sendable=this.props.sendable;
        if (typeof sendable === "undefined"){sendable={"display":"none"};}
        var flag = false;
        var form = {};//{settings:{formName:"Brak danych"}, form:[]};
        if (VAL.IsJsonString(this.props.form) && this.props.form !=="" && this.props.form!==null)
        { 
            var inform = JSON.parse(this.props.form);
            if (typeof inform.settings!=='undefined'){form.settings = inform.settings;}
            if (typeof inform.form!=='undefined'){form.form = inform.form; }
            if (typeof form.settings!=='undefined' && typeof form.form!=='undefined'){flag = true;}
           
        }

        else
        {
        // form.settings.formName = "Form data is no JSON format";
        //console.log(this.props.form);
        }
           
        if (this.state.ready && flag)
        {
            
            
            return (
            <div>
                
                <h3>{form.settings.formName}</h3>
                <hr className="prettyhr"></hr>
                <div>
                    
                {form.form.map((key,i)=>(
                <div className={key.col} key={i}>{this.rysuj(key)}</div>
            ))}
            </div>
            
            <div  style={sendable}>
                Dokumenty do załączenia:
                {/* <select multiple className="mform" id="dokumenty" data-id="dokumenty" value={this.state.values.dokumenty}   onChange={this.changeVal}>
                    {this.state.documents.map((key, i)=>(
                        <option key={i} value={key.name}>{key.name}</option>
                    ))}
               </select> */}
                <div className="documentcontainer">
                {this.state.documents.map((key, i)=>(
                        <div key={i} className="documentss" data-name={key.name} data-id="dokumenty" onClick={this.changeVal}><GoFile className="doc" />  {key.name}</div>
                    ))}
                </div>
                 {/* <MultiSelect
                    data={this.state.documents}
                    onOpen={this.onOpen}
                    onClose={this.onClose}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    onFilterChange={this.onFilterChange}
                    filterable={true}
                    className="multiselect"
                    style={{ width: '400px' }}
                /> */}
            </div>
            <div className="btn-group">
            <button 
                id="zapisz"
                disabled={false}
                data-action="saveFirmData" 
                data-dane={JSON.stringify(this.state.values)} 
                onClick={this.props.changes} className="btn btn-secondary">Zapisz na później</button>
                       <button 
                       id="zapisz0"
                data-action="konsultacja" 
                style={sendable}
                data-dane={JSON.stringify(this.state.values)} 
                data-status={1}
                onClick={this.props.changes} className="btn btn-primary">Prośba o konsultację</button>
            <button 
            id="zapisz1"
                data-action="wniosek" 
                style={sendable}
                data-dane={JSON.stringify(this.state.values)} 
                data-status={4}
                onClick={this.props.changes} className="btn btn-primary">Składanie wniosku</button>
                </div>
                <br></br>
                <small style={sendable}>Po wysłaniu w niedługim czasie otrzymasz do trzech propozycji kredytu. Ich otrzymanie nie obliguje Twojej firmy do skorzystania z którejkolwiek z nich.</small>
            <hr className="prettyHr"></hr>
                    {/* <button onClick={this.debug} >DEBUG</button> */}
           
            <div className="downMargin"></div>
             </div>       
        );}
            
            
        else
        {
            return (<div>Oczekiwanie na dane...</div>)
        }
    }
    else
    {
        return (<div>
            
            
            
            </div>);
    }


}

}

export default UniversalFirmForm;