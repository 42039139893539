import React  from "react";
import axios from 'axios';
import security from './../common/security.js';
import Cookies from 'js-cookie';
import Loader from './../loader.js';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import {FaUserTie} from "react-icons/fa";

import Userinfo from './../admin/userinfo.js';
import Generatewniosek from './generatewniosek.js';

class Inbox extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ready:false, server:'',wnioski:[],page:0,dane:[],idd:0,
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.ask = this.ask.bind(this);
        this.handleButton = this.handleButton.bind(this);
    }
server = () => { confirmAlert({  customUI: ({ onClose }) => { return ( <div className='custom-ui'><h3>Komunikat serwera</h3><p>{this.state.server}</p> <button className="btn btn-danger btn-sm" onClick={onClose} >Zamknij</button>  </div> ); } });}
submit = () => {
    confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h3>Wymagane potwierdzenie</h3>
              <p>Operacja, którą chcesz wykonać jest nieodwracalna i wpływa na strukturę danych. Czy chcesz skasować tą pozycję?</p>
              <button className="btn btn-secondary btn-sm" onClick={onClose}>Anuluj</button>
              &nbsp;
              <button
                className="btn btn-danger btn-sm"
                onClick={() => {
                  this.ask("deleteWniosek", this.state.idd);
                  onClose();
                }}
              >
                Usuń
              </button>
            </div>
          );
        }
      });}
componentDidMount() {
    this.ask('getList', this.props.zakres);
    this.setState({ready:true});
}
handleButton(ev)
{
    var a = ev.target.dataset;
    console.log(a);
    if (a.op==="przejmij"){this.ask('opiekuje', a.ide);}
    if (a.op==="getinfo" ){this.ask('getUserInfo', a.id);}
    if (a.op==="home"    ){this.setState({page:0});}
    if (a.op==="wniosek" ){this.ask('getUserWniosek', a.id);}
    if (a.op==="kosz"    ){this.setState({idd:a.id});this.submit();}
}
opis(d,id)
{
    if (d!==null)
    {
        return (<span><FaUserTie className="green"/> {d}</span>);
    }
    else
    {
        return (<button className="btn btn-secondary btn-sm" data-op="przejmij" data-ide={id} onClick={this.handleButton}>Przejmij</button>);
    }
}
operacje(id)
{

    return (<div className="btn-group">
        <button type="button" className="btn btn-secondary btn-sm" data-op="getinfo" data-id={id} onClick={this.handleButton}>Info</button>
        <button type="button" className="btn btn-secondary btn-sm" data-op="wniosek" data-id={id} onClick={this.handleButton}>Wniosek</button>
        <button type="button" className="btn btn-danger btn-sm" data-op="kosz" data-id={id} onClick={this.handleButton}>Usuń</button>

    </div>)
 
}
ask(resource, data = null)
      {
            var self = this;
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'sprzedaz');
            params.append('command', resource);
            params.append('data', data);
            params.append('token', Cookies.get("Experimental"));
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
              console.log(response.data.data);
            if (response.data.status==="OK")
            {
            //self.setState({loading:false});
                switch (resource){
                case 'getList': self.setState({wnioski: response.data.data});break;
                case 'opiekuje':self.ask('getList', self.props.zakres);self.setState({server:response.data.desc});self.server();break;
                case 'getUserInfo': self.setState({dane: response.data.data, page:1});break;
                case 'getUserWniosek': self.setState({dane: response.data.data, page:2});break;
                default: break;
                }
                
            }
            else if(response.data.status==="TEST")
            {
            console.log(response.data);
            }
            else if (response.data.status==="err")
            {
               self.setState({server: response.data.desc});self.server();
            }
            self.setState({odpowiedz:response.data.desc});
            }).catch(function (error) {console.log(error);});
            
        }

    render(){
        document.title ="HomeBank | Seller";
       if (this.state.ready) 
    {
        if (this.state.page===0)
        {
            return (
                <div>
                <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><span data-op="home" onClick={this.handleButton}>Lista</span></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            
                            <table className="table table-hover table-borderless">
                                <thead>
                                    <tr>
                                        <td>Opiekun</td>
                                        <td>Wniosek</td>
                                        <td>Kwota</td>
                                        <td>HB</td>
                                        <td>Status</td>
                                        <td>Operacje</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.wnioski.map((key)=>(
                                            <tr key={key.ide}>
                                                <td> {this.opis(key.opieka, key.ide)}</td>
                                                <td>{key.data}</td>
                                                <td>{key.kwota} @ {key.rat}</td>
                                                <td>{key.punkty}</td>
                                                <td>{key.status}</td>
                                                <td>{this.operacje(key.ide)}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
        else if (this.state.page===1)
        {
            return (<div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><span data-op="home" onClick={this.handleButton}>Lista</span></li>
                                        <li className="breadcrumb-item">Informacja szczegółowa</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                
                <Userinfo user={this.state.dane} /></div>);
        }
        else if (this.state.page===2)
        {
            return (<div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><span data-op="home" onClick={this.handleButton}>Lista</span></li>
                                        <li className="breadcrumb-item">Sporządzenie wniosku</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>


                
                <Generatewniosek wniosek={this.state.dane} zgody={JSON.parse(this.state.dane.zgody)} /></div>);
        }
    }
    else
    {
        return (<div><Loader/></div>);
    }


}

}

export default Inbox;