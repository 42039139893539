import React  from "react";
import axios from 'axios';
import security from './../common/security.js';
// import Accordion from 'react-bootstrap/Accordion';
// import Card from 'react-bootstrap/Card';


class Faqfirma extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            faq:[],
        };
        
       
    }
    
    componentDidMount()
    {
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
            //console.log(this.state.finanse);
            params.append('section', 'aplikacja');
            params.append('command', 'getFaq');
            params.append("data", this.props.level);
            var self = this;
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            console.log(response.data);
            if (response.data.status==="ok")
            {self.setState({faq: response.data.data});}
            }).catch(function (error) {console.log(error);});
        
    }

    render(){
       
        if (this.props.level===10){
    return (<div>
                <h3>Najczęściej zadawane pytania</h3>
                <hr className="prettyhr"></hr>
                {this.state.faq.map((key)=>(
                    <div key={key.id}>
                        <b>{key.pytanie}</b>
                        <div>{key.odpowiedz}</div>
                    </div>
                ))}
            </div>

                );}
                else
                {
                    return (<div></div>)
                }
            }
}
export default Faqfirma;