import React  from "react";
import GEO from './../common/geonames.js';
import axios from 'axios';
import security from './security.js';
import Cookies from 'js-cookie';
import KODY from './../common/kodypocztowe.js';
import VAL from './../common/validators.js';



class UniversalCreditForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ready:false, faq:[],values:{typ:"Własność", proAsk:0},powiaty:[],gminy:[],kodypocztowe:[],
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.rysuj = this.rysuj.bind(this);
        this.changeVal = this.changeVal.bind(this);
        this.debug = this.debug.bind(this);
        this.loadPowiat = this.loadPowiat.bind(this);
        this.loadGmina = this.loadGmina.bind(this);
        this.loadKody = this.loadKody.bind(this);
        this.ask = this.ask.bind(this);
        this.zapiszNaPozniej = this.zapiszNaPozniej.bind(this);
        this.czytajNaPozniej = this.czytajNaPozniej.bind(this);
        this.validateForm = this.validateForm.bind(this);

    }
    validateForm(ev)
    {
        var gen = ev.target.dataset.id.toLowerCase();
        var val = ev.target.value;
        //console.log("VAL", gen, val, ev.target.type, typeof ev.target.type);
        var flag = true;
        switch (gen)
        {
            case 'nip': flag=VAL.validatenip(val); break;
            case 'kod': flag=VAL.validateKod(val); break;
            case 'tel': flag=VAL.validateTel(val); break;
            case 'mail': flag=VAL.validateEmail(val); break;
            case 'zgoda1': flag=VAL.validateZgoda(gen, val); break;
            case 'zgoda2': flag=VAL.validateZgoda(gen, val); break;
            case 'zgoda3': flag=VAL.validateZgoda(gen, val); break;
            default: 
                if (val.length===0){flag=false;}
                if (ev.target.type==="select-one" && parseInt(val)===0){flag=false;}
                if (ev.target.type==="select-multiple" && parseInt(val)===0){flag=false;}
                break;
        }
        if (!flag){ev.target.classList.add('error');}else{ev.target.classList.remove('error');}
    }
    IsJsonString(str) { try { JSON.parse(str); } catch (e) { return false; } return true;}
    ask(resource, data = null)
    {
          var self = this;
          const params = new URLSearchParams(); params.append('asc',security.securityKey());
          params.append('section', 'user');
          params.append('command', resource);
          params.append('data', data);
          params.append('token', Cookies.get("Experimental"));
          axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            //console.log(response.data.data);
          if (response.data.status==="OK")
          {
          //self.setState({loading:false});
              switch (resource){
              case 'loadGmina':  self.setState({gminy: response.data.data, ready:true}); return;
      
              default: return;
              //self.analiseUser(response.data.data);
              }
              
          }
          else if(response.data.status==="TEST")
          {
          console.log(response.data);
          }
          else if (response.data.status==="err")
          {
             //self.setState({server: response.data.desc});self.server();
          }
          self.setState({odpowiedz:response.data.desc});
          }).catch(function (error) {console.log(error);});
          
      }

    
    componentDidMount() {
        this.setState({ready:true});
        //this.setCheckBoxes()
        // if(this.IsJsonString(this.props.form) && this.props.form !=="")
        // {
        //     //console.log("FORM",this.props.form);
        //     var form = JSON.parse(this.props.form);
        //     // var ob ={};
        //         //console.log("GETlocalSTORAGE", form.setting.formId);
        //         var idd = localStorage.getItem(form.settings.formId);
        //         if (idd!==null)
        //         {
        //             this.setState({values: JSON.parse(idd)})
        //         }
        //         else
        //         {
        //             //console.log("NO FORMS ");
        //         }
          
        // }
        // else
        // {
        //     //console.log("FORM IS NOT JSON", this.props.form);
        // }
        var self = this;
        setTimeout(() => {
            var f = "";
            if (self.IsJsonString(self.props.form))
            {
                f=JSON.parse(self.props.form)
                var ff = f.settings.formId;
                if (typeof localStorage[ff]!=="undefined")
                {
                    //console.log("FORM EXIST");
                    if (window.confirm('Ten formularz został rozpoczęty i nie dokończony. Czy chcesz wpisać poprzednią zawartość')) {
                        self.czytajNaPozniej();
                    } 
                }
            }
        }, 1000);

    }

    loadPowiat(a)
    {
        //console.log("powiat", a);
       
        var dane = GEO.geo(a);
        //console.log("GEODATA",a,dane);
        var pow =[];
        pow.push({value:0,text:"Wybierz..." });
        for (var x = 0; x<dane.length; x++)
        {
            var nn = dane[x].powiat;
            pow.push({value:nn,text:nn });
        }
        //console.log("Powiaty",pow);
        this.setState({powiaty:pow});
        
        
    }
    loadGmina(gmina)
    {
        //console.log("GMINA DLA",gmina);
        this.ask("loadGmina", gmina);

    }
    loadKody(gmi)
    {
        // //console.log("LOADKODY", gmina, this.state.values);
        var s = this.state.values;
        var woj = ["Undef","dolnośląskie", "kujawsko_pomorskie", 'lubelskie','lubuskie', 'łódzkie', 'małopolskie', 'mazowieckie', 'opolskie', 'podkarpackie','podlaskie', 'pomorskie', 'śląskie', 'świętokrzyskie', 'warmińsko_mazurskie', 'wielkopolskie', 'zachodniopomorskie'];
        var wojewodztwo = woj[parseInt(s.wojewodztwo)];
       
        //var pow = s.powiat.split(" ");
        var powiat = s.powiat.replace("Powiat ","");powiat = powiat.replace("m. ","");powiat = powiat.replace(" ","_");powiat=powiat.replace("-","_"); powiat = powiat.replace("st. ", "");
        var gmina = gmi.replace("M. ",""); gmina = gmina.replace("-","_"); gmina = gmina.replace(" ","_"); gmina = gmina.replace("st. ", "");
        var pytaj = gmina+powiat+wojewodztwo;
        var kody = KODY.getKody(pytaj);
        var codes = [];
        codes.push({value:0,text:"Wybierz..."});
        for (var a = 0; a<kody.length;a++)
        {
            codes.push({value:kody[a],text:kody[a]});
        }
        this.setState({kodypocztowe:codes});
        //console.log("LOADKODY", pytaj, kody);
    }
    changeVal(ev)
    {
        console.log(ev.target.dataset);
        var names = ev.target.dataset.id;
        var filtr = ev.target.dataset.filtr;
        var values= this.state.values;
        var wpisz = ev.target.value;
        values[names]= wpisz.toString() ;
        var form = JSON.parse(this.props.form);
        values.formID = form.settings.formId;
        console.log(values.formID, form.settings.formId)
        if (typeof filtr !=="undefined" && filtr!=="text" && filtr!=="date")
        { 
            if (filtr==="year"){values[names]=parseInt(values[names]);}
            if (filtr==="float"){values[names]=parseFloat(values[names]);}
            if (isNaN(values[names])){values[names]="0";}
        }
        this.setState({values: values});

        if (names==="wojewodztwo"){this.loadPowiat(wpisz);}
        if (names==="powiat"){this.loadGmina(wpisz);}
        if (names==="gmina"){this.loadKody(wpisz);}
        
    }
    debug()
    {
        console.log(this.state.values);
    }
    rysuj(d)
    {
        if (d.pole==="label"){
            return (<div className="mcol b12"><b>{d.value}</b></div>)
        }
        if (d.pole==='desc'){
            
            return (<div className="mcol b12"><small>{d.value}</small></div>)
        }
        if (d.pole==="text")
        {
            //var val = this.state.values;
            return (<div className="">
            <label>{d.title}</label>
            <input data-filtr={d.filtr} onBlur={this.validateForm} id={d.id} type={d.type} className="mform" data-id={d.name} value={this.state.values[d.name]} onChange={this.changeVal}/>
          </div>)
        }
        
        if (d.pole==="select")
        {
            var opcje = d.option;
            if (d.name==='powiat'){opcje = this.state.powiaty;}
            if (d.name==='gmina'){opcje = this.state.gminy;}
            if (d.name==='kod'){opcje = this.state.kodypocztowe;}

            return (<div className="form-group">
            <label>{d.title}</label>
            <select className="mform" onBlur={this.validateForm} id={d.id} data-id={d.name} value={this.state.values[d.name]}  onChange={this.changeVal}>
                {opcje.map((key, i)=>(
                    <option key={i} value={key.value}>{key.text}</option>
                ))}
            </select>
            </div> );
        }
        if (d.pole==="button")
        {
            return (<button data-action={d.action} data-dane={JSON.stringify(this.state.values)} onClick={this.props.readForm} className={d.style}>{d.text}</button>)
        }
        if (d.pole==="row")
        {
            return(<br></br>);
        }
    }
    zapiszNaPozniej()
    {
        var formId = "undefinedForm";
        if (this.IsJsonString(this.props.form) && this.props.form !=="")
        { var f = JSON.parse(this.props.form); formId = f.settings.formId;//console.log("FormId:", formId)
        }
        console.log(this.state.values);
        localStorage.setItem(formId, JSON.stringify(this.state.values));
    }
    czytajNaPozniej()
    {
        var formId = "undefinedForm";
        if (this.IsJsonString(this.props.form) && this.props.form !=="")
        { var f = JSON.parse(this.props.form); formId = f.settings.formId;//console.log("FormId:", formId)
        }
        
        var form = localStorage.getItem(formId);
        //console.log("FORMID", formId, form);
        var ff = JSON.parse(form);
        this.setState({values:ff});
        if (typeof ff.wojewodztwo !=="undefined"){//console.log("WOJ",ff.wojewodztwo);this.loadPowiat(ff.wojewodztwo);
        }
        if (typeof ff.powiat !=="undefined"){this.loadGmina(ff.powiat);}
        if (typeof ff.gmina !=="undefined"){this.loadKody(ff.gmina);}
      
        this.setState({values:ff});
    }
    render(){
       if (this.props.page===11) 
    {
        if (this.state.ready)
        {
            var form = {settings:{formName:"Brak danych"}, form:[]};
            if (this.IsJsonString(this.props.form) && this.props.form !=="")
           { form = JSON.parse(this.props.form);}
           
            return (
            <div >
                {JSON.stringify(this.props.geoData)}
                <h3>{form.settings.formName}</h3>
                <div>
                    
                    Jeśli nie chcesz wypełniać wszystkich danych możesz zapisać ten formularz i wrócić do niego, gdy będziesz chciał. W tym celu użyj przycisku 
                    <button className="bb default" onClick={this.zapiszNaPozniej}>Zapisz</button>
                {form.form.map((key,i)=>(
                <div className={key.col} key={i}>{this.rysuj(key)}</div>
            ))}
            </div>
            <hr className="prettyHr"></hr>
  
           
            <div className="downMargin"></div>
             </div>       
        );}
            
            
        else
        {
            return (<div>Oczekiwanie na dane...</div>)
        }
    }
    else
    {
        return (<span></span>);
    }


}

}

export default UniversalCreditForm;