import React from "react";
//import { Redirect } from "react-dom";
import axios from 'axios'; 
import Cookies from 'js-cookie';
import security from './../common/security.js';
import Recovery from './recovery.js';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
//import { GoogleLogin } from 'react-google-login';
import { FacebookProvider, LoginButton, Profile } from 'react-facebook';



class LoginForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {login: '', pass:'', odpowiedz:'', redirect:false, unlockInfo:"", recoveryMode:0, token:'', server:""};
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePass = this.handlePass.bind(this);
        this.ask =  this.ask.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.responseFacebook = this.responseFacebook.bind(this);
      }
    

    server = () => { confirmAlert({  customUI: ({ onClose }) => { return ( <div className='custom-ui'><h3>Komunikat serwera</h3><p>{this.state.server}</p> <button className="btn btn-danger btn-sm" onClick={onClose} >Zamknij</button>  </div> ); } });}
    submit = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
            return (
                <div className='custom-ui'>
                <h3>Wymagane potwierdzenie</h3>
                <p>{this.state.server}</p>
                <button className="btn btn-secondary btn-sm" onClick={onClose}>Anuluj</button>
                &nbsp;
                <button
                    className="btn btn-danger btn-sm"
                    onClick={() => {
                    this.aktywacja('notoken',"forgotPassword", this.state.login);
                    onClose();
                    }}
                >
                    Wyślij
                </button>
                </div>
            );
            }
        });}


       
    validateEmail() {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if  (re.test(String(this.state.login).toLowerCase()))
        
        {this.setState({server:"Czy chcesz resetować hasło dla konta "+this.state.login+"? Użycie tej funkcji spowoduje jego zablokowanie do czasu użycia linku aktywacyjnego."}); setTimeout(() => {this.submit()}, 50);}
        else {this.setState({server:"Nieprawidłowy login: " + this.state.login}); setTimeout(() => {this.server();}, 50);;}
    }
      componentDidMount()
      {
        var url = new URL(window.location);
        var c = url.searchParams.get("token");
        var d = url.searchParams.get("action");
        var e = url.searchParams.get("user");
        if (c !== null && d!==null && e!==null)
        {
            this.aktywacja(c, d, e);
        }
        console.log(c,d);
      }

      aktywacja(token, command, mail)
      {
        this.setState({unlockInfo:"Oczekiwanie na aktywację."});
        var self = this;
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'user');
            params.append('command', command); 
            params.append('mail', mail); 
            params.append('token', token);
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
                //console.log(response.data.wartosc);
                console.log(response.data.data);
                if (response.data.status==="OK")
                {
                    self.setState({unlockInfo:response.data.desc});
                    if (typeof response.data.view != "undefined") 
                    {
                        self.setState({recoveryMode:1});
                    }

                }
                else if (response.data.status==="err")
                {
                    self.setState({unlockInfo:response.data.desc});
                }
                else
                {
                    self.setState({unlockInfo:"Wystąpił wewnętrzny błąd serwera."});
                }
                self.setState({odpowiedz:response.data.desc});
               
            }).catch(function (error) {self.setState({validnip:false});console.log(error);}); 
      }
      handleChange(event) {
        this.setState({login: event.target.value});
        //console.log(this.state);
      }
      
      handlePass(event) {
        this.setState({pass: event.target.value});
        //console.log(this.state);
      }
      responseGoogle(ev)
      {
          //console.log("GOOGLE", ev);
      }

    responseFacebook(ev)
    {
        //console.log("FACEBOOK EV",ev);
        window.localStorage.setItem("EXP", JSON.stringify(ev.tokenDetail));
        if (typeof ev.profile !== 'undefined')
        {
            this.ask("",'login', JSON.stringify({login:ev.profile.email, pass:ev.profile.id}))
        }
        
    }
    responseErrorFacebook(ev)
    {
        //console.log("ERROR FB", ev);
    }
    ask(obj, command=null, data= null)
    { 
        var cmd; var dat;
        //console.log("ASK", obj, command, data);
        if (command===null){cmd="login";}else{cmd=command;}
        if (data===null){dat=JSON.stringify({login:this.state.login, pass:this.state.pass});}else{dat=data;}
        var self = this;
        console.log(this.state)
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'user');
            params.append('command', cmd);
            params.append('data', dat);
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
                //console.log(response.data.wartosc);
                console.log(response.data.data);
                if (response.data.status==="OK")
                {
                    Cookies.set('Experimental', response.data.data);
                    window.location = window.location.protocol + "//" + window.location.host;
                }
                else if (response.data.status==="err")
                {
                    self.setState({unlockInfo: response.data.desc});
                }
                else
                {
                    self.setState({unlockInfo: "Wystąpił wewnętrzy bład serwera. Spróbuj ponownie później."});
                }
                
               
            }).catch(function (error) {self.setState({validnip:false});console.log(error);}); 

        
    }
    render(){
        if (this.state.recoveryMode===0)
        {
        //document.title ="homeBank";
    return (<div className="container"><div className="row">
        <div className="col-sm-3"></div>
        <div className="col-sm-6">
            <div className="text-center border border-light p-5">
            
            <p className="h4 mb-4">Panel logowania</p>
            <hr className="prettyhr"></hr>
            {this.state.unlockInfo}
           
            <div className="text-center">
            <FacebookProvider appId="1682933565306936">
                    <LoginButton
                    className="btn btn-primary btn-sm"
                    scope="email"
                    onCompleted={this.responseFacebook}
                    onError={this.responseErrorFacebook}
                    ><span>Zaloguj przez Facebook</span>
                    </LoginButton>
                </FacebookProvider>

                <FacebookProvider appId="1682933565306936">
                <Profile>
                {({ loading, profile }) => (
                   
                   <div>
                   </div>
                )}
        </Profile>
      </FacebookProvider> 

      </div>
      <div className="lub"></div>
            <input type="email" id="defaultLoginFormEmail" className="form-control mb-4" placeholder="E-mail" value={this.state.login} onChange={this.handleChange} onBlur={this.handleChange}></input>
            
            <input type="password" id="defaultLoginFormPassword" className="form-control mb-4" placeholder="hasło" value={this.state.pass} onChange={this.handlePass} onBlur={this.handlePass}></input>
            <div>{this.state.opis}</div>
            <div className="d-flex justify-content-around">
                <div>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="defaultLoginFormRemember"></input>
                        <label className="custom-control-label" htmlFor="defaultLoginFormRemember">Pamiętaj mnie</label>
                    </div>
                </div>
                <div>
                    <button className="btn btn-link" onClick={this.validateEmail}>Zapomniałeś hasła?</button>
                </div>
            </div>
            
            <button className="btn btn-info btn-block my-4" type="submit" onClick={this.ask}>Logowanie</button>
            
            <p>Nie posiadasz konta?&nbsp;
                <a href="/register">Zarejestruj się</a>
            </p>
            
            {/* <p>lub zaloguj za pośrednictwem:</p>
            
            <a type="button" className="light-blue-text mx-2">
                <i className="fab fa-facebook-f"></i>
            </a>
            <a type="button" className="light-blue-text mx-2">
                <i className="fab fa-twitter"></i>
            </a>
            <a type="button" className="light-blue-text mx-2">
                <i className="fab fa-linkedin-in"></i>
            </a>
            <a type="button" className="light-blue-text mx-2">
                <i className="fab fa-github"></i>
            </a> */}
            
        </div>
        <div className="col-sm-3"></div>
        </div></div>
        </div>);
        }
        else{
            return (<div><Recovery /></div>)
        }
        }
    }


export default LoginForm;