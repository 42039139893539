import React  from "react";
// import Autosuggest from 'react-autosuggest';
// import Logo from './../../img/hbbussines.png';
// import axios from 'axios';
// import $ from "jquery";
import UniversalFirmForm from './../common/universalFirmForm.js';

class Introformone extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            step:1, suggestions: [], value: '',pkd:"", nip:"", kodyPKD:[],loaded:false,rodzaj:""
        };
        
        this.componentDidMount = this.componentDidMount.bind(this);
        this.dumpProps = this.dumpProps.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        //this.steps = this.steps.bind(this);
    }
    self(){return this};
    
    dumpProps(){console.log(this.props);}
    
    componentDidMount()
    {
        
    }

    componentDidUpdate()
    {
        if (this.state.rodzaj!== this.props.formID)
        {
            //console.log("TO UPDATE"); 
            this.setState({rodzaj:this.props.formID});  
        }
        //if (!this.state.loaded) {this.setState({suggestions:this.props.kodyPKD, loaded:true});}
    }
    render(){
    if (this.props.level===1){
        
        return (
            
                <div className="row">
                    <div className="col-sm-12">
                     
                        <UniversalFirmForm page={11} sendable={{"display":"block"}} form={this.props.form} formdata={this.props.data} data={this.props.data} changes={this.props.change}formId={this.props.formID}/>
                    </div>
                </div>
                
            
                    
           
            
        );}
        else
        {return (<div></div>);}
}

}
export default Introformone;