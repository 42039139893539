import React from 'react';
import './App.css';
import axios from './../node_modules/axios';
import security from './parts/common/security.js';
import Cookies from 'js-cookie';
import Footer from './parts/footer.js';
import Page from './parts/page.js';
import Firma from './parts/firma.js';
import Loader from './parts/loader.js';
import MainAdmin from './parts/admin/main.js';
import Seller from './parts/seller.js';
import Teacher from './parts/teacher.js';
import Singiel from './parts/singiel.js';

class App extends React.Component {

  constructor() {  
    super();

    this.state = {
        entries: [], step: 1, parts:"JEDEN", cookie:'', uprawnienia:"0", dorenderowania: (<div></div>)
    };
    // this.ask =  this.ask.bind(this);
    // this.ask2 =  this.ask2.bind(this);
} 


    componentDidMount() {
        var self = this;
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
        params.append('section', 'user');
        params.append('command', 'userRights');
        params.append('token', Cookies.get('Experimental'));
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            console.log((response.data.data));
            self.setState({uprawnienia: (response.data.data)});
        }).catch(function (error) {console.log(error);});
    }

render(){

    
    if (this.state.uprawnienia!=="0"){
    switch (parseInt(this.state.uprawnienia))
    {
        case 1: return (<div><Singiel/><Footer/></div>);
        case 2: return (<div><Firma/><Footer/></div>);
        case 5: return (<div><Seller/><Footer/></div>);
        case 6: return (<div><Seller/><Footer/></div>);
        case 7: return (<div><Teacher/><Footer/></div>)
        case 10: return (<div><MainAdmin/><Footer/></div>);
        default:return (<div><Page/></div>);
    }
  }else{return (<Loader/>);}
}
  
}

export default App;
