import React  from "react";
// import axios from 'axios';
// import Cookies from 'js-cookie';
// import parser from 'xml2json';
import XML from './xmlparser.js';
import ProductList from './productList.js';
  
class Inne extends React.Component{ 
    constructor(props) {
        super(props);
        this.state = {
          data:[],
        };
        this.debug = this.debug.bind(this);
        this.xml2json = this.xml2json.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
    }
    componentDidUpdate()
    {
        // 
        if (this.state.data.length===0 && this.props.xml !=="undefined")
        {
            this.xml2json();
           // //console.log("CALLC")
        }

    }
    debug() 
    {
        console.log(this.props.xml)
    }
    xml2json()
    {
        if (XML.IsJsonString(this.props.xml))
       { var xml = this.props.xml;
        var odp = JSON.parse(xml);
    //    var odp = convert.xml2json(xml,{compact: true, spaces: 4});
       //console.log("JSON",odp);
       var  o = XML.getProductList(odp, "Finanse osobiste");
      this.setState({data:o});
      }
    }

    rysuj()
    {
        var d = this.state.data;
        var keys = Object.keys(d);
        return (<span>{JSON.stringify(keys)}</span>);
    }
    render(){
            // this.xml2json();
            if (typeof this.props.xml['@attributes'] !== "undefined")
            {
            var dane = XML.rebulidObject(this.props.xml.kategoria)
            return (
            <div className="container">
               
                <div className="row">
                
                {dane.map((key, i)=>( 
                    <div  className="col-sm-12" key={i}>  
                        <ProductList   produkt={key} clientSelection={this.props.clientSelection} baseurl={this.props.xml['@attributes']['base-url']} drawable={this.props.drawable} include={this.props.include} displayh3={{display:"block"}} displayh5={{display:"block"}}/>
                        
                    </div>
                ))}
                </div>
             </div>       
        )
                }else{
                    return (<div className="minHeight">Oczekuję na dane ....</div>)
                }

}

}

export default Inne;