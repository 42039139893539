function IsJson(str) { try { JSON.parse(str); } catch (e) { return false; } return true;}

function check18(d)
{
    let TODAY = new Date(Date.now());
    let EIGHTEEN_YEARS_BACK = new Date(new Date(TODAY).getDate() + "/" + new Date(TODAY).getMonth() + "/" + (new Date(TODAY).getFullYear() - 18));
    let USER_INPUT = new Date(d);
    // Validate Now
    // //console.log("18", (EIGHTEEN_YEARS_BACK > USER_INPUT))
return(EIGHTEEN_YEARS_BACK > USER_INPUT);
}

function checkIntegrity(data)
{
    var wzor = '{"typ":"tre","rokbudowy":534534,"cena":"12","powierzchnia":435,"wkladwlasny":345,"imienazwisko":"435","rynek":"1","rodzaj":"1","urodzony":"2019-10-01","cywilny":"1","dzieci":"0","telefon":"453","data2":"345","wyksztalcenie":"2","wojewodztwo":"2","powiat":"Powiat chełmiński","gmina":"Chełmno","kod":"534","miejscowosc":"543534rte","ulica":"retert","nrdomu":"tre4","nrmieszkania":"4","stmieszkaniowy":"2","zrodlodochodu":"1","dochodnetto":"tre","raty":"453","limity":"435","wydatki":"435345","regulamin":"1","historia":"1","przetwarzanie":"1","ZgodaMarketing":"1","ZgodaTelefon":"1"}';
    var da = JSON.parse(wzor);
    var dd = true;
    //console.log("TEST INTEGRITY START");
    for (var key in da)
    {
        var flag1 = false;
        if (typeof data[key]!== 'undefined'){flag1=true;}else{dd = false;}
        console.log(key,typeof data[key], flag1);
    }
        //console.log("TEST INTEGRITY END");
    if (data.typ.toLowerCase()==="własność" || data.typs.toLowerCase()==="współwłasność"){}else{return false;}
    if (parseInt(data.rokbudowy)>1000 && parseInt(data.rokbudowy)<2030){}else{return false;}
    if (parseInt(data.powierzchnia)>10 && parseInt(data.powierzchnia)<1000){}else{return false;}
    if (parseInt(data.cena)>500){}else{return false;}
    if (parseInt(data.wkladwlasny)>=parseInt(data.cena)/10){}else{return false;}
    // 10% wartości - rekomendacja S mówi o 20%, chyba, że dodatkowe zabezpieczenie;
    if (data.imienazwisko.length>6){}else{return false;}
    if (check18(data.urodzony)){}else{return false;}
  


    if (dd){return true;}else{return false;}
}

function checkNieruchomosc(data)
{
    ////console.log("VVNV",data);
    var odp ={error:[], info:[], source:"calc"};
    if (!IsJson(data)){return {info:
        [
           {info:"Formularz jest uszkodzony. Nie można odczytać zawartości", color:"red"}
        ]
    };}
    else
    {
        
        var now = new Date();
        var ff = JSON.parse(data);
        if (parseInt(ff.rokbudowy)>now.getFullYear() && parseInt(ff.rynek)===2)
        {
            odp.error.push("Rok budowy jest większy od współczesności a wybrano rynek wtórny");
        }
        if (parseInt(ff.rokbudowy)===now.getFullYear() && parseInt(ff.rynek)===2)
        {
            odp.error.push("Rok budowy jest równy bieżącemu a wybrano rynek wtórny");
        }
        if (ff.typ.toLowerCase()!=="własność"){odp.info.push({info:"Jeśli wybrałeś współwłasność prowadź obliczenia dla kwoty proporcjonalnej do udziału w nieruchomości", color:"yellow"});}
        if ((parseInt(ff.cena)/5)<parseInt(ff.wkladwlasny))
        {
            odp.info.push({info:"Wysokość wkładu własnego jest wystarczająca.",color:"green"});
        }
        else if ((parseInt(ff.cena)/10)<parseInt(ff.wkladwlasny))
        {
            odp.info.push({info:"Niektóre oferty kredytowe mogą wymagać dodatkowego zabezpieczenia.", color:"yellow"});
        }
        else
        {
            odp.info.push({info: "Popraw wysokość wkłady własnego",color:"red"});
        }
        return odp;
    }
}
function checkWnioskodawca(data)
{

}
function checkTeleadresowe(data)
{

}
function checkFinanse(data)
{
    if (!IsJson(data)){return{
        info:[
            {info:"Formularz jest uszkodzony. Nie można odczytać zawartości", color:"red"}
        ]};}
    else 
    {
        
        var odp ={error:[], info:[], source:"calc", zdolnosc:0,procent:0};
        var ff = JSON.parse(data);
        //console.log("DATA URODZENIA",ff.urodzony);
        if (!check18(ff.urodzony)){
            //console.log("URODZONY", ff.urodzony);
            odp = {error:[],info:[{info:"Nie masz ukończonych 18 lat.", color:"red"}], zdolnosc:0,procent:0};
            return odp;
        }
        var przychody = parseInt(ff.dochodnetto);
        var koszty = 0;
        if (ff.cywilny===""){odp.info.push({info:"Nie podano stanu cywilnego.", color:"red"})}
        else{
            switch (parseInt(ff.cywilny))
            {
                case 2: koszty+=1200;break;
                default:koszty +=1000;break;
            }
        }
        if (parseInt(ff.raty)>=0)
        {
            koszty+=parseInt(ff.raty);
        }
        else
        {
            odp.info.push({info:"Jeśli ilość miesięcznych rat wynosi zero należy tą informację podać w formularzu.", color:"red"})
        }
        if (ff.dzieci===""){odp.info.push({info:"Ilość dzieci, które są na utrzymaniu kredytobiorcy jest informacją wymaganą", color:"red"})}
        else 
        {
            koszty += parseInt(ff.dzieci) * 400;
            
        }
        if (ff.limity===""){odp.info.push({info:"Wielkość przyznanych linii kredytowych jest informacją wymaganą", color:"red"})}
        else
        {
            koszty += parseInt(ff.limity) * 0.05;
        }
        //obliczamy kwotę do rat
        var kwota =0;
        var wydatki = parseInt(ff.wydatki);
        if (isNaN(wydatki)){wydatki=0;}
        if (koszty>wydatki){kwota = przychody - koszty;}
        else{kwota = przychody - wydatki;}
       
        var zdolnosc = 0;
        var maxKredyt = (50 * przychody)/2.5; //Uwzględnia kredyt na 30 lat z RRSO = 5%;
        var maxRata = przychody * 0.4;
        if (przychody>5000){maxRata=przychody*0.5;}
        zdolnosc = (200*maxRata/kwota);
        zdolnosc = zdolnosc * (maxKredyt/(ff.cena-ff.wkladwlasny));
        if (zdolnosc>90){zdolnosc=90;}
        if (zdolnosc<0){zdolnosc=0;}
        if (parseInt(ff.historia ===2)){maxKredyt=0; }
        //Znamy kwotę będącą do dyspozycji kredytobiorcy. Sprawdzamy jak się to ma do KNF i wieku. Maksymalny wiek 65 lat;
        var cena = parseInt(ff.cena);
        var wklad = parseInt(ff.wkladwlasny);
        if (cena-wklad>maxKredyt)
        {
            odp.info.push({info:"Twoje zdolność kredytowa jest mniejsza, niż kwota potrzebna do realizacji inwestycji. Możliwe, że powiększając kwotę dochodu o dochód wspólnie z małżonkiem lub zmieniając inne parametry kredytowania uzyskasz odpowiednią zdolność kredytową, aby zakupić tę nieruchomość. Możesz także zmienić nieruchomość lub poszukać innego sposobu finansowania tej inwestycji", color:"danger rekomendacja"})
        }
        odp.info.push({info:"Procent: "+zdolnosc+", zdolnosc: "+maxKredyt+", maxRata: "+maxRata +", kwota: "+kwota, color:"green"})
        odp.procent = zdolnosc;
        odp.zdolnosc = maxKredyt;

        return odp;
}
}
function checkZgody(data)
{
    var odp ={error:[], info:[], source:"calc"};
    if (!IsJson(data)){return {
        info:[
            {info:"Formularz jest uszkodzony. Nie można odczytać zawartości", color:"green"}
        ]};}
    else 
    {
        odp ={error:[], info:[], source:"calc"};
        var ff = JSON.parse(data);
        if (parseInt(ff.regulamin)!==1){odp.info.push({info:"Akceptacja regulaminu jest obowiązkowa", color:"red"});}else{odp.info.push({info:"Regulamin został zaakceptowany", color:"green"});}
        if (parseInt(ff.przetwarzanie)!==1){odp.info.push({info:"Zgoda na przetwarzanie jest konieczna, by móc założyć konto", color:"yellow"});}else{odp.info.push({info:"Wyrażono zgodę na przetwarzanie danych", color:"green"});}
        if (parseInt(ff.ZgodaMarketing)!==1){odp.info.push({info:"Brak zgody na przesyłanie informacji marketingowych nie została zaznaczona", color:"yellow"});}else{odp.info.push({info:"Wyrażono zgodę na przesyłanie informacji marketingowych", color:"green"});}
        if (parseInt(ff.ZgodaTelefon)!==1){odp.info.push({info:"Brak zgody na kontak telefoniczny", color:"yellow"});}
        else
        {
            var processed = ff.telefon.replace(/ /g, '');
            if (processed.length===9)
            {
                odp.info.push({info:"Wyrażono zgodę na kontakt telefoniczny", color:"green"});
            }
            else
            {
                odp.info.push({info:"Wyrażono zgodę na kontakt telefoniczny, ale numer telefonu nie jest poprawny", color:"yellow"});
            }
            
        }
        return odp;
    }
 
}
function isData(data)
{
    var ver = new Date(data);
    if (isNaN(Date.parse(ver))){return false;}else{return true;}
    // var dat = ver.getFullYear() +"-"+lz(ver.getMonth()+1)+"-"+lz(ver.getDate());
    // // console.log(ver, dat, data);
    // if (dat===data && !isNaN(data.getFullYear())){return true;}else{return false;}
}
function checkFirma(data)
{
    var year = new Date();
    var odp={info:[],zysk:0,zdolnosc:10, maxrata:0, procent:10};var ff={};
    
    //console.log("CHECK_FIRMA", data);
    if (IsJson(data)){ff=JSON.parse(data);console.log(ff);}
    var datar="";
    //Zgody (wspólne dla wszystkich użytkowników)
        if (!isData(ff.datar)){odp.info.push({text:"Brak poprawnej daty rozpoczęcia" , color:"red"});}else{datar = new Date(ff.datar)}
        if (parseInt(ff.zgoda1)!==1){odp.info.push({text:"Akceptacja regulaminu jest obowiązkowa", color:"red"});}
        if (parseInt(ff.zgoda2)!==1){odp.info.push({text:"Zgoda na przetwarzanie jest konieczna, by móc założyć konto", color:"yellow"});}
        if (parseInt(ff.zgoda3)!==1){odp.info.push({text:"Brak zgody na przesyłanie informacji marketingowych nie została zaznaczona", color:"yellow"});}
        if (parseInt(ff.zgoda4)!==1){odp.info.push({text:"Brak zgody na kontak telefoniczny", color:"yellow"});}
    //Kwoty
    // //console.log("BRUTTO 2019",ff['brutto' + year.getFullYear()], parseInt(ff['brutto' + year.getFullYear()]));
    if (isData(datar))
    {
        if (datar.getFullYear()<year.getFullYear())
        {
            if (isNaN(parseInt(ff['brutto' + year.getFullYear()]))){odp.info.push({text:"Brak kwoty brutto za rok " + year.getFullYear(), color:"red"});}
            if (isNaN(parseInt(ff['koszty' + year.getFullYear()]))){odp.info.push({text:"Brak kwoty kosztów za rok " + year.getFullYear(), color:"red"});}
            if (isNaN(parseInt(ff['amortyzacja' + year.getFullYear()]))){odp.info.push({text:"Brak kwoty amortyzacji za rok " + year.getFullYear(), color:"red"});}
        }
        if (datar.getFullYear()<(year.getFullYear()-1))
        {
            if (isNaN(parseInt(ff['brutto' + (year.getFullYear()-1)]))){odp.info.push({text:"Brak kwoty brutto za rok " + (year.getFullYear()-1), color:"red"});}
            if (isNaN(parseInt(ff['koszty' + (year.getFullYear()-1)]))){odp.info.push({text:"Brak kwoty kosztów za rok " + (year.getFullYear()-1), color:"red"});}
            if (isNaN(parseInt(ff['amortyzacja' + (year.getFullYear()-1)]))){odp.info.push({text:"Brak kwoty amortyzacji za rok " + (year.getFullYear()-1), color:"red"});}
        }
        if (datar.getFullYear()<(year.getFullYear()-2))
        {
            if (isNaN(parseInt(ff['brutto' + (year.getFullYear()-2)]))){odp.info.push({text:"Brak kwoty brutto za rok " + (year.getFullYear()-2), color:"red"});}
            if (isNaN(parseInt(ff['koszty' + (year.getFullYear()-2)]))){odp.info.push({text:"Brak kwoty kosztów za rok " + (year.getFullYear()-2), color:"red"});}
            if (isNaN(parseInt(ff['amortyzacja' + (year.getFullYear()-2)]))){odp.info.push({text:"Brak kwoty amortyzacji za rok " + (year.getFullYear()-2), color:"red"});}
        }
    }
        if (isNaN(parseInt(ff.kwota))){odp.info.push({text:"Brak kwoty poszukiwanego kredytu " , color:"red"});}
        if (isNaN(parseInt(ff.rat)) && ff.formID!=="firmFactoring"){odp.info.push({text:"Brak ilości rat poszukiwanego kredytu " , color:"red"});}
        if (isNaN(parseInt(ff.rodzaj))){odp.info.push({text:"Brak rodzaju prowadzonej działalności " , color:"red"});}
        if (isNaN(parseInt(ff.wielkosc))){odp.info.push({text:"Brak podanej ilości pracowników" , color:"red"});}
        //console.log("FORMULARZ", ff)
        if (!isData(ff.datar)){odp.info.push({text:"Brak poprawnej daty rozpoczęcia" , color:"red"});}
        if (odp.info.length===0)
        {

            // Symulacja wstępna wnioskowanego kredytu, potencjalna rata
            // rrso = 8%
            var latzrat = parseInt(ff.rat)/12;
            var dosplaty = parseInt(ff.kwota)* 1.08 * latzrat;
            var rata = dosplaty/parseInt(ff.rat)
            odp.maxrata = rata;
            

            //średni miesieczny dochod firmy, liczony jeśli są kwoty
            var mdochodf = 0;
            var month = year.getMonth();
            var miesiecy = 0;
            //rok bierzący:
            mdochodf += parseInt(ff['brutto' + year.getFullYear()]) + parseInt(ff['amortyzacja' + year.getFullYear()]) - parseInt(ff['koszty' + year.getFullYear()]);
            if (datar.getFullYear()<year.getFullYear())
            {
                mdochodf += parseInt(ff['brutto' + (year.getFullYear()-1)]) + parseInt(ff['amortyzacja' + (year.getFullYear()-1)]) - parseInt(ff['koszty' + (year.getFullYear()-1)]);
                miesiecy += 12;
            }
            if (datar.getFullYear()<(year.getFullYear()-1))
            {
                mdochodf += parseInt(ff['brutto' + (year.getFullYear()-2)]) + parseInt(ff['amortyzacja' + (year.getFullYear()-2)]) - parseInt(ff['koszty' + (year.getFullYear()-2)]);
                miesiecy += 12;
            }
            var zyskmc = mdochodf/(miesiecy+month);
            odp.zysk=zyskmc;
            // Jeśli jednoosobowa działalność gospodarcza dodatkowo należy sprawdzić, czy kwota nie
            // jest większa, niż 40% dochodu
            //OBLICZANIE ZDOLNOŚCI KREDYTOWEJ DLA FIRM
            //maksymalna kwota całkowita spłaty //maksymalny okres kredytowania 10 lat
            var maxCAlkowitaKwota = 120 * zyskmc;
            //Dla kredytu, którego RRSO = 8% (10 lat = 80%)
            odp.zdolnosc = Math.round(maxCAlkowitaKwota/1.8);
            odp.procent = odp.zdolnosc * 50/parseInt(ff.kwota);
           
        }
        //console.log("ANALIZA FIRMY",odp);
        
        // odp.info.push({text:"test", color:"red"});
    return odp;
}
module.exports = {
    checkIntegrity,
    checkFinanse,
    checkZgody,
    checkTeleadresowe,
    checkWnioskodawca,
    checkNieruchomosc,
    checkFirma,

}