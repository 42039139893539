import React from "react";
//import { Redirect } from "react-dom";
import axios from 'axios'; 
import security from './../common/security.js';
import Cookies from 'js-cookie';
import San from './san.js';
import Kula from './../firma/kula.js';
import {engpol, slownik, sanFoto, doswiadczenie, wyksztalcenie, stanCywilny} from './../common/interpreting.js';
import Dynax from './../common/manager.js';
//import Minolta from './../common/credit.js';
import brainjs from 'brainjs';
import {IoMdPhonePortrait, IoIosMail} from "react-icons/io";
import {askIndywisualny} from './../common/analizapostow.js';
import {interpretacja, askFirma} from './../common/withai.js';
class Userinfo extends React.Component{
 
    constructor(props) {
        super(props);
        this.state = {loaded:false, user:[], role:99, engine:{},
            wynik:"Oczekiwanie na wyniki analizy sztucznej inteligencji"};
       
        this.ask =  this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.debug = this.debug.bind(this);
        this.runAnalize = this.runAnalize.bind(this);
        this.zapiszZmiany = this.zapiszZmiany.bind(this);
        this.ask = this.ask.bind(this);
      }
     
      ask(resource, ams=null)
      {
        var self = this;
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
        params.append('section', 'sprzedaz');
        params.append('command', resource);
        if (params!==null){params.append('data', JSON.stringify(ams));}
        params.append('token', Cookies.get("Experimental"));
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
          console.log(response.data.data);
          if (response.data.status==="OK")
          {
            self.setState({loading:false});
              switch (resource){
                  case 'getUserInfo': self.setState({dane: response.data.data});return;
                  case 'getEngine':self.setState({engine:response.data.data}); self.runAnalize();break;
                  case 'zapiszWniosek': self.props.alert(response.data.data);break;
                  default: return;
              }
             
          }
          self.setState({odpowiedz:response.data.desc});
         }).catch(function (error) {console.log(error); self.setState({odpowiedz:"ERR"});});}
  
         runAnalize()
         {
            var net = new brainjs.NeuralNetwork({hiddenLayers: [5]});
            net.fromJSON(JSON.parse(this.state.engine.dane));
            //console.log(this.IsJsonString(this.state.engine.dane), this.state.engine.dane)
            if (this.props.user.setup==="1"){
                var us = this.state.user;
                var kredyt = {oprocentowanie:us.oprocentowanie, oplatastala:us.oplatastala, procentowa:us.procentowa, rat:us.rat, kwota:us.kwota};
                var x = askIndywisualny(this.props.gus, this.props.user, kredyt, this.props.kozak.ind);
                console.log(x);
                this.setState({wynik:interpretacja(x)});


            }
            if (this.props.user.setup ==="2")
            {
                us = this.state.user;
                kredyt = {oprocentowanie:us.oprocentowanie, oplatastala:us.oplatastala, procentowa:us.procentowa, rat:us.rat, kwota:us.kwota};
                x = askFirma(this.props.user, this.props.kozak.fir, kredyt);
                console.log(x);
                this.setState({wynik:interpretacja(x)});
            }
         }

         zapiszZmiany()
         {
             var data = {adnot:this.props.user.seller, id:this.props.user.ide, 
                kwota:this.props.user.kwota, rat: this.props.user.rat, umowa:this.props.user.ai}
             this.ask("zapiszWniosek", data);
         }
      componentDidMount(){
          //this.ask('userlist');
          //this.setState({loading:true});
          this.setState({user:this.props.user});
          ////console.log("USER", this.props.user)
        this.ask("getEngine", this.props.user.setup);
      }
      debug()
      {
          console.log(this.props);
      }
      IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
     
    render(){
        
        var role = this.props.role;
        ////console.log("ROLA",role);
        var display = {display:"block"};
        var konsultacje = {display:"none"};
        var wnioski = {display:"none"};
        var procedowane = {display:"none"};
        var douzupelnienia = {display:"none"};
        var rozpatrzone = {display:"none"};
        var zakonczone = {display:"none"};
        var bank = {display:"none"};
        ////console.log("VISIBLE", this.props);
        if (parseInt(this.props.user.vis)===1)
        {
            switch (role)
            {
                case "Prośba o konsultację":konsultacje=display;break;
                case "Wniosek złożony":wnioski=display;break;
                case "Procedowany":procedowane=display;break;
                case "Do uzupełnienia":douzupelnienia=display;break;
                case "Rozpatrzone":rozpatrzone=display;break;
                case "Zakończony":zakonczone=display;break;
                default:break;
            }
            bank = {display:"none"};
        }
        else
        {
            bank = {display:"block"};
            //console.log("BANK======================");
        }
        
        if (this.props.user.setup==="1")
        {
            var zgody ={zgoda1:"false", zgoda2:"false", zgoda3:"false", zgoda4:"false"};
            var dosw = [];
            var finan = {du:"1914-09-10",cpn:"",ndo:"",kmrk:0,plk:5000,iz:4,wk:5,sc:3,ionu:0,nkb:"",pesel:"",hb:0};
            var ad = {nazwa:"",wojewodztwo:"",powiat:"",gmina:"",miejscowosc:"",kod:"",regon:"",ulica:"",lokal:"",forma:"0"};
            if (this.IsJsonString(this.props.user.adres) && this.props.user.adres!==null){ad = JSON.parse(this.props.user.adres);}
            if (this.IsJsonString(this.props.user.finanse) && this.props.user.finanse!==null){finan=JSON.parse(this.props.user.finanse);}
            if (this.IsJsonString(this.props.user.zgody) && this.props.user.zgody!==null){zgody = JSON.parse(this.props.user.zgody)};
            if (this.IsJsonString(this.props.user.doswiadczenie) && this.props.user.doswiadczenie !==null){dosw = JSON.parse(this.props.user.doswiadczenie);}

            ////console.log("ZGODY", zgody);
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="hbozdobnik uzytkownik">
                                <img src={sanFoto(this.props.user.foto)} style={{margin:'5px'}} alt="user" onClick={this.debug}/> 
                                {Dynax.imie(this.props.user.name + " " + this.props.user.lastname)}<br></br>
                                <small>Użytkownik indywidualny</small> 
                                <div>
                                    Zgoda 1: {engpol(zgody.zgoda1)},<br></br>
                                    Zgoda 2: {engpol(zgody.zgoda2)},<br></br>
                                    Zgoda na kontakt mailowy w sprawach dotyczących wniosków: {engpol(zgody.zgoda3)},<br></br>
                                    Zgoda na kontakt telefoniczny w sprawach dotyczących wniosków: {engpol(zgody.zgoda4)},
                                </div>
                                <div>Sposób logowania: {slownik(this.props.user.from)}</div>
                            </div>
                            <div>
                            <div className="hbozdobnik adres">
                                <div className="row">
                                    <div className="col-sm-6">
                                        {ad.wojewodztwo}->{ad.powiat}->{ad.gmina}<br></br>
                                        {ad.ulica} {ad.numer} {ad.lokal}<br></br>
                                        <u>{ad.kod} {ad.miejscowosc}</u><br></br>
                                    </div>
                                    <div className="col-sm-6">
                                        <IoMdPhonePortrait /> {ad.tel}<br></br>
                                        <IoIosMail /> {this.props.user.mail}
                                    </div>
                                </div>
                                
                                
                            </div>
                            <div className="hbozdobnik">
                                <h4>Dane użytkownika</h4>
                                <table className="setka">
                                    <tbody>
                                        <tr><td>Data urodzenia</td><td>{finan.du} ({Dynax.calculateAge(new Date(finan.du))})</td></tr>
                                        <tr><td>Całkowity przychód netto</td><td>{finan.cpn}</td></tr>
                                        <tr><td>Kwota miesięcznej raty kredytu:</td><td>{finan.kmrk}</td></tr>
                                        <tr><td>Przyznane linie kredytowe:</td><td>{finan.plk}</td></tr>
                                        <tr><td>Inne zobowiązania:</td><td>{finan.iz}</td></tr>
                                        <tr><td>Wykształcenie</td><td>{wyksztalcenie(finan.wk)}</td></tr>
                                        <tr><td>Stan cywilny</td><td>{stanCywilny(finan.sc)}</td></tr>
                                        <tr><td>Ilość osób na utrzymaniu </td><td>{finan.ionu}</td></tr>
                                        <tr><td>Numer konta bankowego</td><td>{finan.nkb}</td></tr>
                                        <tr><td>PESEL</td><td>{finan.pesel}</td></tr>
                                        <tr><td>Dowód osobisty</td><td>{finan.ndo}</td></tr>
                                        <tr><td>Ostatnio wyliczona zdolność</td><td><code>{this.props.user.hb}</code></td></tr>
                                    </tbody>
                                </table>
                                </div> 
                                <div className="hbozdobnik zawod">  
                                {doswiadczenie(dosw)}
                                {dosw.map((key,i)=>(
                                    <div key={i}>{key[0]} - {key[1]} w {key[2]} jako {key[3]}</div>
                                ))}
                                </div> 
                            </div>
                            {/* <div>{JSON.stringify(this.props.user)}</div> */}

                        </div>
                        <div className="col-sm-6">
                        <div className="hbozdobnik">  
                               <h4>Wniosek nr: {this.props.user.ide}/{this.props.user.data.substr(0,4)}</h4> 
                               z dnia {this.props.user.data}<br></br>
                               Wnioskowana kwota: <code>{this.props.user.kwota}</code> płatna w <code>{this.props.user.rat}</code> ratach
                        </div>
                                    <div className="hbozdobnik bank">
                                        {Dynax.adnotacja(this.props.user.bank)}
                                    </div>
                                    <div className="aiu">
                                        {this.state.wynik}
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label>Korekta kwoty</label>
                                            <input type="text" className="form-control" data-op="kwota" onChange={this.props.read} onBlur={this.runAnalize} value={this.props.user.kwota} />
                                        </div>
                                        <div className="col-sm-6">
                                            <label>Korekta rat</label>
                                            <input type="text" className="form-control" data-op="rat" onChange={this.props.read} onBlur={this.runAnalize} value={this.props.user.rat} />
                                        </div>
                                    </div>
                                    <label>Adnotacje sprzedawcy</label>
                                    <textarea className="form-control" rows="5" data-op="adnotacje" onChange={this.props.read} value={this.props.user.seller}></textarea>
                                    {this.props.role}
                                <br></br>
                                
                                <div className="text-center" style={konsultacje}>
                                    <div className="btn-group">
                                        <button className="btn btn-secondary btn-sm" onClick={this.zapiszZmiany}>Zapisz</button>
                                        <button data-command="zluzWniosek" data-id={this.props.user.ide} onClick={this.props.preSend} className="btn btn-secondary btn-sm">Złóż wniosek</button>
                                        <button data-command="zakonczWniosek" data-id={this.props.user.ide} onClick={this.props.preSend}className="btn btn-secondary btn-sm">Zakończ</button>

                                    </div>
                                </div>

                                <div className="text-center"  style={wnioski}>
                                    <div className="btn-group">
                                        <button className="btn btn-secondary btn-sm" onClick={this.zapiszZmiany}>Zapisz</button>
                                        <button data-command="procedujWniosek" data-id={this.props.user.ide} onClick={this.props.preSend} className="btn btn-secondary btn-sm">Proceduj</button>
                                        <button data-command="zakonczWniosek" data-id={this.props.user.ide} onClick={this.props.preSend}className="btn btn-secondary btn-sm">Zakończ</button>
                                    </div>
                                </div>

                                <div className="text-center"  style={procedowane}>
                                <div className="btn-group">
                                    <button className="btn btn-secondary btn-sm" onClick={this.zapiszZmiany}>Zapisz</button>
                                    <button data-command="uzupelnijWniosek" data-id={this.props.user.ide} onClick={this.props.preSend} className="btn btn-secondary btn-sm">Do uzupełnienia</button>
                                    <button data-command="rozpatrzWniosek" data-id={this.props.user.ide} onClick={this.props.preSend} className="btn btn-secondary btn-sm">Rozpatrzony</button>
                                    <button data-command="zakonczWniosek" data-id={this.props.user.ide} onClick={this.props.preSend}className="btn btn-secondary btn-sm">Zakończ</button>
                                </div>

                                <div className="text-center"  style={douzupelnienia}>
                                    <div className="btn-group">
                                        <button className="btn btn-secondary btn-sm" onClick={this.zapiszZmiany}>Zapisz</button>
                                        <button data-command="rozpatrzWniosek" data-id={this.props.user.ide} onClick={this.props.preSend} className="btn btn-secondary btn-sm">Rozpatrzony</button>
                                        <button data-command="zakonczWniosek" data-id={this.props.user.ide} onClick={this.props.preSend}className="btn btn-secondary btn-sm">Zakończ</button>
                                    </div>
                                </div>

                                <div className="text-center"  style={rozpatrzone}>
                                    <div>
                                        <label>Numer umowy z bankiem</label>
                                        <input type="text" data-op="Umowa" value={this.props.user.ai} onChange={this.props.read} className="form-control" />
                                    </div>
                                    <div className="btn-group">
                                        <button className="btn btn-secondary btn-sm" onClick={this.zapiszZmiany}>Zapisz</button>
                                        <button data-command="uruchomWniosek" data-id={this.props.user.ide} onClick={this.props.preSend} className="btn btn-success btn-sm">Odnotuj uruchomienie środków</button>
                                        <button data-command="zakonczWniosek" data-id={this.props.user.ide} onClick={this.props.preSend}className="btn btn-danger btn-sm">Zakończ</button>
                                    </div>
                                </div> 

                                <div className="text-center"  style={zakonczone}>
                                    <div>
                                        <label>Numer umowy z bankiem</label>
                                        <input type="text" data-op="Umowa" value={this.props.user.ai} onChange={this.props.read} className="form-control" />
                                    </div>
                                    <div className="btn-group">
                                        <button className="btn btn-secondary btn-sm" onClick={this.zapiszZmiany}>Zapisz</button>
                                        <button data-command="archiwumWniosek" data-id={this.props.user.ide} onClick={this.props.preSend} className="btn btn-success btn-sm">Przenieś do archiwum</button>
                                    </div>
                                </div> 
                                <div className="text-center"  style={bank}>
                                    <div class="alert alert-primary" role="alert">
                                        Ten wniosek procedowany jest przez system bankowy. Nie możesz edytować wniosku, ani zmieniać jego statusu.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else if (this.props.user.setup==="2")
        {
            zgody ={zgoda1:"false", zgoda2:"false", zgoda3:"false", zgoda4:"false"};
            if (this.IsJsonString(this.props.user.zgody) && this.props.user.zgody!==null){zgody = JSON.parse(this.props.user.zgody)};
            var sposoby=["Brak danych", "Jednoosobowa działalność gospodarcza",
            "Spółka Cywilna", "Spółka Jawna", "Spółka Partnerska", "Spółka Komandytowa",
            "Spółka Komandytowa-akcyjna", "Spółka z Ograniczoną Odpowiedzialnością",
            "Spółka Akcyjna"];
            var modelb =["Brak danych", "Startup","Franczyza","Franczyzodawca","Niezależna firma B2B","Niezależna firma B2C"];
            var ksiegowosc = ["Brak danych","Karta podatkowa","Ryczałt ewidencjonowany","Książka przychodów i rozchodów","Pełna księgowość"];
            var adres = {wojewodztwo:"",powiat:"", gmina:"", miejscowosc:"", kod:"", ulica:"",lokal:""};
            //console.log(typeof this.props.user.adres, this.props.user.adres, JSON.parse(this.props.user.adres, adres));
            if (this.props.user.adres!==null){adres = JSON.parse(this.props.user.adres);}
            var osoba = {};
            if (this.props.user.osoba!==null){osoba = JSON.parse(this.props.user.osoba);}
            var finanse = [];
            if (this.props.user.osoba!==null){finanse = JSON.parse(this.props.user.finanse);}
            var model = {};
            if (this.props.user.model!==null){model = JSON.parse(this.props.user.model);}
            return ( 
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="hbozdobnik uzytkownik">
                                    <b><San text={adres.nazwa} /></b><br></br>
                                    Zgoda 1: {engpol(zgody.zgoda1)},<br></br>
                                    Zgoda 2: {engpol(zgody.zgoda2)},<br></br>
                                    Zgoda na kontakt mailowy w sprawach dotyczących wniosków: {engpol(zgody.zgoda3)},<br></br>
                                    Zgoda na kontakt telefoniczny w sprawach dotyczących wniosków: {engpol(zgody.zgoda4)},
                            </div>
                            <div className="hbozdobnik adres">
                                <div>Adres:
                                    Województwo <San text={adres.wojewodztwo} />, Powiat: <San text={adres.powiat} /> gmina <San text={adres.gmina} /><br></br>
                                    <San text={adres.miejscowość} /> {adres.kod}<br></br>
                                    <San text={adres.ulica} /><San text={adres.lokal} /> <br></br>
                                    Status prawny: {sposoby[adres.forma]}
                                    NIP: {this.props.user.nip},<br></br> 
                                    PKD: {this.props.user.pkd} ({this.props.user.opis.trim()})
                                </div>
                            </div>
                            <div className="hbozdobnik">
                                <div>
                                    <b>Osoba kontaktowa:</b><br></br>
                                    <San text={osoba.nazwisko} />: <br></br>
                                    <IoMdPhonePortrait />{osoba.telefon},  
                                    <IoIosMail />{osoba.mail}
                                </div>
                            </div>
                            <div className="hbozdobnik">
                                <div>
                                    <b>Model biznesowy</b><br></br>
                                    Model: {modelb[model.model]}, Księgowość: {ksiegowosc[model.ksiegowosc]}, Staż: {model.staz} lat. <br></br>
                                    Ostatnio wyliczona zdolność: <code>{this.props.user.hb}</code>
                                </div>
                            </div>
                            <div className="hbozdobnik">

                            Tabela 1. Wyniki finansowe podane przez klienta
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <td>Rok</td>
                                        <td>Przychód</td>
                                        <td>Koszty</td>
                                        <td>amortyzacja</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {finanse.map((key, i)=>(
                                        <tr key={i}>
                                            <td>{key.rok}</td>
                                            <td>{key.przychod}</td>
                                            <td>{key.koszty}</td>
                                            <td>{key.amortyzacja}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            </div>
                        </div>
                        <div className="col-sm-6">
                            
                            <div className="hbozdobnik wniosek"> 
                                <h4>Wniosek nr: {this.props.user.ide}/{this.props.user.data.substr(0,4)}</h4> 
                               z dnia {this.props.user.data}<br></br>
                               Wnioskowana kwota: <code>{this.props.user.kwota}</code> płatna w <code>{this.props.user.rat}</code> ratach
                            </div>

                            <div className="aiu">{this.state.wynik}</div>

                            <div className="hbozdobnik bank">
                                        {Dynax.adnotacja(this.props.user.bank)}
                                    </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Korekta kwoty</label>
                                    <input type="text" className="form-control" data-op="kwota" onChange={this.props.read} onBlur={this.runAnalize} value={this.props.user.kwota} />
                                </div>
                                <div className="col-sm-6">
                                    <label>Korekta rat</label>
                                    <input type="text" className="form-control" data-op="rat" onChange={this.props.read} onBlur={this.runAnalize} value={this.props.user.rat} />
                                </div>
                            </div>
                            <label>Adnotacje sprzedawcy</label>
                            <textarea className="form-control" rows="5" data-op="adnotacje" onChange={this.props.read} value={this.props.user.seller}></textarea>
                            {this.props.role}
                            <br></br>
                            
                            <div className="text-center"  style={konsultacje}>
                                <div className="btn-group">
                                    <button className="btn btn-secondary btn-sm" onClick={this.zapiszZmiany}>Zapisz</button>
                                    <button data-command="zluzWniosek" data-id={this.props.user.ide} onClick={this.props.preSend} className="btn btn-secondary btn-sm">Złóż wniosek</button>
                                    <button data-command="zakonczWniosek" data-id={this.props.user.ide} onClick={this.props.preSend}className="btn btn-secondary btn-sm">Zakończ</button>
                                </div>
                            </div>

                            <div className="text-center"  style={wnioski}>
                                <div className="btn-group">
                                    <button className="btn btn-secondary btn-sm" onClick={this.zapiszZmiany}>Zapisz</button>
                                    <button data-command="procedujWniosek" data-id={this.props.user.ide} onClick={this.props.preSend} className="btn btn-secondary btn-sm">Proceduj</button>
                                    <button data-command="zakonczWniosek" data-id={this.props.user.ide} onClick={this.props.preSend}className="btn btn-secondary btn-sm">Zakończ</button>
                                </div>
                            </div>

                            <div className="text-center"  style={procedowane}>
                                <div className="btn-group">
                                    <button className="btn btn-secondary btn-sm" onClick={this.zapiszZmiany}>Zapisz</button>
                                    <button data-command="uzupelnijWniosek" data-id={this.props.user.ide} onClick={this.props.preSend} className="btn btn-secondary btn-sm">Do uzupełnienia</button>
                                    <button data-command="rozpatrzWniosek" data-id={this.props.user.ide} onClick={this.props.preSend} className="btn btn-secondary btn-sm">Rozpatrzony</button>
                                    <button data-command="zakonczWniosek" data-id={this.props.user.ide} onClick={this.props.preSend}className="btn btn-secondary btn-sm">Zakończ</button>
                                </div>
                            </div>
                            <div className="text-center"  style={douzupelnienia}>
                                <div className="btn-group">
                                    <button className="btn btn-secondary btn-sm" onClick={this.zapiszZmiany}>Zapisz</button>
                                    <button data-command="rozpatrzWniosek" data-id={this.props.user.ide} onClick={this.props.preSend} className="btn btn-secondary btn-sm">Rozpatrzony</button>
                                    <button data-command="zakonczWniosek" data-id={this.props.user.ide} onClick={this.props.preSend}className="btn btn-secondary btn-sm">Zakończ</button>
                                </div>
                            </div>
                            <div className="text-center"  style={rozpatrzone}>
                                <div>
                                    <label>Numer umowy z bankiem</label>
                                    <input type="text" data-op="Umowa" value={this.props.user.ai} onChange={this.props.read} className="form-control" />
                                </div>
                                <div className="btn-group">
                                    <button className="btn btn-secondary btn-sm" onClick={this.zapiszZmiany}>Zapisz</button>
                                    <button data-command="uruchomWniosek" data-id={this.props.user.ide} onClick={this.props.preSend} className="btn btn-success btn-sm">Odnotuj uruchomienie środków</button>
                                    <button data-command="zakonczWniosek" data-id={this.props.user.ide} onClick={this.props.preSend}className="btn btn-danger btn-sm">Zakończ</button>
                                </div>
                            </div> 

                            <div className="text-center"  style={zakonczone}>
                                <div>
                                    <label>Numer umowy z bankiem</label>
                                    <input type="text" data-op="Umowa" value={this.props.user.ai} onChange={this.props.read} className="form-control" />
                                </div>
                                <div className="btn-group">
                                    <button className="btn btn-secondary btn-sm" onClick={this.zapiszZmiany}>Zapisz</button>
                                    <button data-command="archiwumWniosek" data-id={this.props.user.ide} onClick={this.props.preSend} className="btn btn-success btn-sm">Przenieś do archiwum</button>
                                </div>
                            </div> 

                            <div className="text-center"  style={bank}>
                                <div class="alert alert-primary" role="alert">
                                    Ten wniosek procedowany jest przez system bankowy. Nie możesz edytować wniosku, ani zmieniać jego statusu.
                                </div>
                            </div>
                        </div>
                    </div>
                    <Kula firma={[this.props.user]} />
                </div>
            )
        }
        else if (this.props.user.setup==="3"){
                return(<div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            Klient instytucjonalny
                        </div>
                    </div>
                </div>)
        }
        else if (this.props.user.setup==="4"){
            return(<div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        Zarezerwowano (Klient Administracji Publicznej)
                    </div>
                </div>
            </div>)
        }
        else if (this.props.user.setup==="5"){
            return(<div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        Zarezerwowano (Pracownik działu sprzedaży)
                    </div>
                </div>
            </div>)
        }
        else if (this.props.user.setup==="6"){
            return(<div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        Zarezerwowano (Kierownik działu sprzedaży)
                    </div>
                </div>
            </div>)
        }
        else if (this.props.user.setup==="7"){
            return(<div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        Zarezerwowano (Nauczyciel Maszyn)
                    </div>
                </div>
            </div>)
        }
        else if (this.props.user.setup==="8" || this.props.user.setup==="9" || this.props.user.setup==="10"){
            return(<div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        Admin
                    </div>
                </div>
            </div>)
        }
        else
        {
     
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            Trwa przekazywanie danych
                        </div>
                    </div>
                </div>
            )
        
        }
            
            }
}


export default Userinfo;