import React  from "react";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer  } from 'recharts';
import brainjs from 'brainjs';

class Kula extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            wyniki:"", zdolnosc:[{rok:2017, wartosc:10}, {rok:2018, wartosc:20}, {rok:2019, wartosc:40}, {rok:2020, wartosc:50}],
            opis:"", pewnosc:"",
        };
        this.consoleDane = this.consoleDane.bind(this);
        this.wyniki = this.wyniki.bind(this);
        this.zdolnosc = this.zdolnosc.bind(this);
        this.brain = this.brain.bind(this);
       
    }
    brain(odp) 
    {
        //PREPARE DATA
        // FIND MAX
        var max = 0;
        for (var a = 0; a<odp.length; a++)
        {
            if (odp[a].wartosc>max){max=odp[a].wartosc;}
        }

        var doliczenia = [odp[0].wartosc/max, odp[1].wartosc/max, odp[2].wartosc/max];
        ////console.log("ZNORMALIZOWANE", doliczenia);
        var net = new brainjs.NeuralNetwork();
        const trainingData = [
            {input:[0, 0, 0], output:[1,0,0,0,0,0,0]},
            {input:[0, 0, 1], output:[0,0,0,0,1,0,0]},
            {input:[0, 1, 1], output:[0,0,0,0,0,1,0]},
            {input:[1, 0, 0], output:[0,1,0,0,0,0,0]},
            {input:[1, 0, 1], output:[0,0,0,0,0,1,0]},
            {input:[1, 1, 0], output:[0,0,1,0,0,0,0]},
            {input:[1, 1, 1], output:[0,0,0,1,0,0,0]},
            
            // {input:[0.5, 0.6, 0.9], output:[0,0,0,0,0,0,1]},
            // {input:[0.4, 0.5, 0.6], output:[0,0,0,0,0,0,1]},
            // {input:[0.3, 0.6, 0.9], output:[0,0,0,0,0,0,1]},
            // {input:[0.8, 0.9, 1], output:[0,0,0,0,0,0,1]},
            // {input:[0.5, 0.6, 0.9], output:[0,0,0,0,0,0,1]},
            // {input:[0.4, 0.5, 0.6], output:[0,0,0,0,0,0,1]},
            // {input:[0.3, 0.6, 0.9], output:[0,0,0,0,0,0,1]},
            // {input:[0.8, 0.9, 1], output:[0,0,0,0,0,0,1]},

            {input:[0.5, 0.5, 0.5], output:[0,0,0,1,0,0,0]},
            {input:[0.6, 0.6, 0.6], output:[0,0,0,1,0,0,0]},
            {input:[0.4, 0.4, 0.4], output:[0,0,0,1,0,0,0]},

            // {input:[0, 0, 0], output:[1,0,0,0,0,0,0]},
            // {input:[0, 0, 0.5], output:[0,0,0,0,1,0,0]},
            // {input:[0, 0.5, 1], output:[0,0,0,0,0,0,1]},
            // {input:[0.5, 0, 0], output:[0,1,0,0,0,0,0]},
            // {input:[0.5, 0, 0.5], output:[0,0,0,0,0,1,0]},
            // {input:[0.5, 1, 0], output:[0,1,0,0,0,0,0]},
            // {input:[0.5, 0.5, 0.5], output:[0,0,0,1,0,0,0]},
            
            // {input:[0, 0, 0], output:[1,0,0,0,0,0,0]},
            // {input:[0, 0, 0.75], output:[0,0,0,0,0,1,0]},
            // {input:[0.25, 0.5, 1], output:[0,0,0,0,0,1,0]},
            // {input:[0.5, 0.25, 0], output:[1,0,0,0,0,0,0]},
            // {input:[0.5, 0, 0.25], output:[0,0,0,0,1,0,0]},
            // {input:[0.25, 0.5, 0], output:[0,1,0,0,0,0,0]},
            // {input:[0.5, 0.5, 0.25], output:[0,0,1,0,0,0,0]},
            ]
            var count = 0;
            net.train(trainingData);
            var zwraca = net.run(doliczenia);
            ////console.log("ZWRACA", zwraca);
            max =0;
            for (a=0; a<zwraca.length; a++)
            {
                if (zwraca[a]>max){max = zwraca[a]; count = a;////console.log("NOWY A MAX", a, max, zwraca[a]);
                }
            }

            var perspektywa = count/3; 
            if (perspektywa <0.7){this.setState({opis:"Sposób zarządzania Twoją firmą wymaga zmian. Jak widzisz wyniki finansowe mają złą tendencję. Jeśli jednak już teraz wprowadzisz konieczne zmiany może być zupełnie inny."});}
            if (perspektywa >0.7 && perspektywa<1.3){this.setState({opis:"Wiele wskazuje na to, że osiągnąłeś stabilność w Twojej firmie. Warto jest to wykorzystać, by popchnąć firmę w kierunku rozwoju. Doświadczenie podpowiada, że często stabilna perspektywa dnia dzisiejszego jest pokusą do tego, by nie obserwować zmian rynkowych i poczynań konkurencji. Warto jest zwracać na to uwagę, by nie popaść w rutynę i nie przegapić ważnego momentu w Twojej branży"});}
            if (perspektywa >1.3){this.setState({opis:"Jeśli utrzymasz takie tempo rozwoju i unikniesz błędów w zarządzaniu a rynek dla Twojej firmy się nie nasyci, Twój wynik na koniec przyszłego roku może być imponujący."});}
            ////console.log("PERSPEKTYWA:", perspektywa, odp[2], odp[2].wartosc * perspektywa, max);
            var pewnosc = parseInt(max*100); 
            this.setState({pewnosc:pewnosc});
            return odp[2].wartosc * perspektywa ;
    }
    consoleDane()
    {
        ////console.log("PKD", this.props.kodyPKD, 'FIRMA', this.props.firma);
        this.zdolnosc(JSON.parse(this.props.firma[0].finanse));
    }
    zdolnosc(dane)
    {
        ////console.log("DANE DO PRACY:",dane);
        var dd = new Date();
        var ddr = dd.getFullYear();
        var pierwszy = {rok:(ddr-2), wartosc:0};
        var drugi = {rok: (ddr-1), wartosc:0};
        var trzeci = {rok:ddr, wartosc:0};
        var czwarty= {rok:(ddr+1), wartosc:0};
        for (var a=0; a<dane.length; a++)
        {
            if (dane[a].rok === pierwszy.rok){pierwszy.wartosc = (dane[a].przychod - dane[a].koszty + dane[a].amortyzacja)/12000};
            if (dane[a].rok === drugi.rok){drugi.wartosc = (dane[a].przychod - dane[a].koszty + dane[a].amortyzacja)/12000};
            if (dane[a].rok === trzeci.rok){trzeci.wartosc = (dane[a].przychod - dane[a].koszty + dane[a].amortyzacja)/(dd.getMonth()+1)/1000};
        }
        // lata po kolei;
        var noweDane = [pierwszy, drugi, trzeci];
        ////console.log("NOWE DANE",noweDane);
        //var czw = this.brain(noweDane);
        
        // for (var a=0; a<dane.length; a++)
        // {
        //     var d = {rok: dane[a].rok, wartosc: ((dane[a].przychod - dane[a].koszty + dane[a].amortyzacja)/1000)};
        //     srednia = srednia + ((dane[a].przychod - dane[a].koszty + dane[a].amortyzacja)/1000);
        //     odp.push(d);
        // }
        // //console.log("DANE:",odp);
        czwarty.wartosc=this.brain(noweDane);;
        noweDane = [pierwszy, drugi, trzeci, czwarty]
        // d = {rok: ddr+1, wartosc: this.brain(odp)};
        // odp.push(d);
        ////console.log("PO ZŁOŻENIU",noweDane)
        this.setState({zdolnosc:noweDane});
    }
    opuszczony(rok)
    {
        var dd = new Date();
        var dds = dd.getFullYear();
        if (dds-rok>2){return "zostały opuszczone";}else {return "zostały uwzględnione";}
    }
 
    wyniki()
    {
        var fin = [];
        if (this.props.firma[0].finanse!==null){fin = JSON.parse(this.props.firma[0].finanse);}
        
        return(
            <div>
                <h5>Dane brane pod uwagę w analizie</h5>
                <table className="poduwage">
                    <tbody>
                {fin.map((key, i)=>(
                    <tr key={i}>
                    <td>Wyniki za rok {key.rok}</td><td> w kwocie: {key.przychod-key.koszty+key.amortyzacja} zł</td><td>{this.opuszczony(key.rok)}</td>
                    </tr>
                ))}
                </tbody>
                </table>
            </div>
        )
    }
    componentDidMount()
    {
        this.setState({wyniki: this.wyniki()});
        if (this.props.firma[0].finanse!==null)
        {this.zdolnosc(JSON.parse(this.props.firma[0].finanse));}
        
    }

    render(){
        if (this.props.level===9){
    return (<div>
                <h3 onClick={this.consoleDane}>Perspektywa rozwoju</h3>
                <hr className="prettyhr"></hr>
                <div className="row">
 
                    <div className="col-sm-6">
                        {this.state.wyniki}
                        WYKRES [tyś/mc][lata]
                        <ResponsiveContainer width='100%' aspect={4.0/3.0}>
                            <LineChart  style={{width:"100%"}}data={this.state.zdolnosc} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                <Line type="monotone" dataKey="wartosc" stroke="#8884d8" />
                                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                <XAxis dataKey="rok" />
                                <YAxis />
                            </LineChart>
                        </ResponsiveContainer>
                        
                    </div>
                    <div className="col-sm-6">
                        <h4>Opis podstawowy</h4>
                        <div className="just">{this.state.opis}</div>
                        <h4>Wartość opisu</h4>
                        <div className="just">Opis został wygenerowany automatycznie za pomocą algorytmu sztucznej inteligencji. Nie uwzględnia wszystkich elementów rynku i branży, zatem jest niekompletny. Dodatkowo nie przewiduje czynników makroekonomicznych zależnych od polityki finansowej i kondycji ogólnej gospodarki.</div>
                    </div>
                </div>
               {this.props.firma[0].hb}
               
            </div>

                );}
                else
                {
                    return(<div></div>)
                }
}

}
export default Kula;