import React  from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axios from 'axios';
import security from './../common/security.js';
import Logo from './../../img/logo.png';
import Users from './users.js';
import DebugCommand from './debugCommand.js';
import Cookies from 'js-cookie';
import Ustawieniaadmin from './ustawieniaadmin.js';
import Readcontactform from './readcontactform.js';
import Applications from './applications.js';
import Media from './media.js';
import Statystyki from './statystyki.js';
import Mailing from './mailing.js';
import Aintelligence from './aintelligence.js';
// import Autoapp from './admin/autoapp.js';
import Autoapp from './autoapp.js';
class MainAdmin extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          default:[],users:[],contacts:[], applications:[], setup:[], noLogout:0, nlstyle:"none", debugCommand:[],
        };
        this.ask = this.ask.bind(this);
        this.users = this.users.bind(this);
        this.contacts = this.contacts.bind(this);
        this.applications = this.applications.bind(this);
        this.setup = this.setup.bind(this);
        this.logout = this.logout.bind(this);
        this.noLogout = this.noLogout.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getStatistic = this.getStatistic.bind(this);
    }
    componentDidMount(){
        var self = this;
        this.ask("debugCommand", null);
        setInterval(function(){
            self.setState({noLogout: self.state.noLogout+1});
            if (self.state.noLogout>570){self.setState({nlstyle:"block"});}else{self.setState({nlstyle:"none"});}
            //console.log(self.state.noLogout);
            if (self.state.noLogout===600){self.logout();}
        }, 1000);
        //this.setState({default: this.ask('default'), users:this.ask('users'), application:this.ask('application'), setup: this.ask('setup')});
    }
    autoapp()
    {
        return (<Autoapp />)
    }
    ask(resource)
    {
        var self = this;
        const params = new URLSearchParams();
        params.append('section', 'admin');
        params.append('command', resource);
        params.append('token', Cookies.get("Experimental"));
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            console.log(response.data);
            if (response.data.status==="OK")
            {
                switch (resource){
                    case 'default': self.setState({default: response.data.data});return;
                    case 'users': self.setState({users: response.data.data});return;
                    case 'contacts': self.setState({contacts: response.data.data});return;
                    case 'applications': self.setState({applications: response.data.data});return;
                    case 'setup': self.setState({setup: response.data.data});return;
                    case 'logout': window.location.replace(window.location.protocol+'//' + window.location.host);return;
                    case 'debugCommand':self.setState({debugCommand:response.data.data});break;
                    default: return;
                }
            }
            self.setState({odpowiedz:response.data.desc});
        }).catch(function (error) {console.log(error); self.setState({odpowiedz:"ERR"});});
    }
    //================================================================================  PARTS =========
    default(){
        return (<Statystyki />)
    }
    users(){
        
        return (<Users userlist={this.state.users}/>);
    }
    getStatistic()
    {
        
        return (<DebugCommand userlist={this.state.debugCommand}/>);
    }
    contacts(){
        return (<div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <Readcontactform />
                        </div>
                    </div>
                </div>)
    }
    mailing()
    {
        return (<Mailing />)
    }
    aintelligence()
    {
        return (<Aintelligence />)
    }
    media()
    {
        return (<Media />)
    }
    applications(){
        return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <Applications />
                </div>
            </div>
        </div>) 
    }
    setup(){
        
        return (<Ustawieniaadmin />)
    }
    
    logout()
    {
        this.ask("logout", Cookies.get('Experimental'));
        Cookies.remove('Experimental');
        window.location.replace(window.location.protocol+'//' + window.location.host);
        return (<div></div>);
    }
    noLogout()
    {
        this.setState({noLogout:0});
    }
    render(){
        document.title ="HomeBank | Panel Administracyjny";
        
    return (
        <div onMouseMove={this.noLogout} onKeyPress={this.noLogout} onKeyDown={this.noLogout}>
            <div className="nologout tlo" style={{display: this.state.nlstyle}}><div className="nologout message"><h3>Alert aktywności</h3>
            z powodu braku aktywności zostaniesz wylogowany za {600-this.state.noLogout}s.
            </div></div>
            <Router>
                <div>
                <nav className="hbnav menuBig">
                    <ul className="hbnav">
                        <li className="hbnav">
                            <Link to="/" className="hbnav">homeBan<span className="red">Q</span></Link>
                        </li>
                        <li className="hbnav">
                            <Link to="/contacts" className="hbnav">Formularze Kontaktowe</Link>
                        </li>
                        <li className="hbnav">
                            <Link to="/setup" className="hbnav">Ustawienia</Link>
                        </li>
                        <li className="hbnav">
                            <Link to="/ai" className="hbnav">AI</Link>
                        </li>
                        <li className="hbnav">
                            <Link to="/logout" className="hbnav">Wyloguj</Link>
                        </li>
                    </ul>
                </nav>
                <nav className="hbnav menuSmall">
                    <ul className="hbnav">
                        <li className="hbnav slim">
                        <span className="nav-link dropdown-toggle hbtoggle clickable"  id="navbardrop" data-toggle="dropdown">
                            h<span className="red">B</span>
                        </span>
                        <div className="dropdown-menu">
                        <Link to="/" className="dropdown-item">homeBan<span className="red">Q</span></Link>
                        <Link to="/forms" className="dropdown-item">Formularze kontaktowe</Link>
                        <a href="/setup" className="dropdown-item">Ustawienia</a>
                        <a href="/ai" className="dropdown-item">Ai</a>
                        <Link to="/logout" className="dropdown-item">Wyloguj</Link>
                      
                    </div>
                        </li>
                    </ul>
                    
                </nav>
                {/* <nav className="navbar navbar-expand-sm  navbar-inverse">
                        <ul className="navbar-nav">
                        <li className="nav-logo"><img className="applogo" src={Logo} alt="logo"/></li>
                         
                            <li className="nav-item">
                                <Link className="nav-link"  to="/">Home</Link>
                            </li>
                           
                            <li className="nav-item">
                                <Link className="nav-link"  to="/users/">Użytkownicy</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link"  to="/contacts/">Formularze kontaktowe</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link"  to="/applications/">Wnioski manualne</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link"  to="/autoapp/">Wnioski automatyczne</Link>
                            </li> */}
                            {/* <li className="nav-item">
                                <Link className="nav-link"  to="/media/">Media</Link>
                            </li> 
                            <li className="nav-item">
                                <Link className="nav-link"  to="/setup/">Ustawienia</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link"  to="/ai/">AI</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link"  to="/logout/">Wyloguj</Link>
                            </li>
                            
                        </ul>
                    </nav> */}
                    
                    <Route exact path="/"  component={this.default} />
                    <Route path="/users/" component={this.users} />
                    <Route path="/contacts/" component={this.contacts} />
                    {/* <Route path="/applications/" component={this.applications} /> */}
                    {/* <Route path="/media/" component={this.media} /> */}
                    <Route path="/setup/" component={this.setup} />
                    <Route path="/ai/" component={this.aintelligence} />
                    <Route path="/logout/" component={this.logout} />
                    <Route path="/debug/" component={this.getStatistic} />
                    {/* <Route path='/autoapp/' component={this.autoapp} /> */}

                    
                </div>
            </Router>
        </div>
    );


}

}

export default MainAdmin;