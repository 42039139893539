import React  from "react";
import axios from 'axios';
import security from './../common/security.js';
import Cookies from 'js-cookie';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fi from 'date-fns/locale/pl';
class Listaproduktow extends React.Component{
    constructor(props) { 
        super(props);
        this.state = { products:[], edit:"none", nNazwa:"", nStatus:"", nId:0,nLogo:'',pliki:[], obrazy:[],
        desc:"",title:"", server:'', nPytanie:'', nOdpowiedz:'', nWidocznosc:0, nEnabled:1, pictures: [] ,
        nnLogo:"", pOd:"", pDo:"", banklist:[],kwOd:0,kwDo:0,ratOd:0,ratDo:0,procent:0.0, rodzaj:0, wyr:0, vis:0,
        prSt:0, prPr:0.0,nazwaproduktu:""
        
            
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.ask = this.ask.bind(this);this.datePicker = this.datePicker.bind(this);this.datePickerDo = this.datePickerDo.bind(this);
        this.chandleVal = this.chandleVal.bind(this); this.changeLogo = this.changeLogo.bind(this);
        this.edytuj = this.edytuj.bind(this);
        this.server = this.server.bind(this);
        this.handleInit = this.handleInit.bind(this);
        this.debug = this.debug.bind(this);
    }
    debug()
    {
        // console.log(this.state);
    }
    handleInit() {
        // console.log('FilePond instance has initialised', this.pond);
    }

    submit = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h3>Wymagane potwierdzenie</h3>
                  <p>Operacja, którą chcesz wykonać jest nieodwracalna i wpływa na strukturę danych. Czy chcesz skasować tą pozycję?</p>
                  <button className="btn btn-secondary btn-sm" onClick={onClose}>Anuluj</button>
                  &nbsp;
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => {
                      this.ask("deleteExistedProduct", this.state.nId);
                      onClose();
                    }}
                  >
                    Usuń
                  </button>
                </div>
              );
            }
          });}

    server = () => { confirmAlert({  customUI: ({ onClose }) => { return ( <div className='custom-ui'><h3>Komunikat serwera</h3><p>{this.state.server}</p> <button className="btn btn-danger btn-sm" onClick={onClose} >Zamknij</button>  </div> ); } });}
    
    ask(resource, data)
      {
            var self = this;
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'admin');
            params.append('command', resource);
            params.append('data', data);
            params.append('token', Cookies.get("Experimental"));
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            //   console.log(response.data.data);
              if (response.data.status==="OK")
              {
                //self.setState({loading:false});
                  switch (resource){
                    case 'getProductsList': self.setState({products: response.data.data});break;
                    case 'getBankList': self.setState({banklist: response.data.data});break;
                    case 'saveOneOfert': self.ask('getProductsList', null);self.setState({server: response.data.desc});self.server();break;
                    case 'deleteExistedProduct':self.ask('getProductsList', null);self.setState({server: response.data.desc});self.server();break;
                    default: break;
                  }
                 
              }
              else if(response.data.status==="TEST")
              {
                console.log(response.data);
              }
              else if (response.data.status==="err")
              {
                self.setState({server:response.data.desc});
                self.server();
                  
              }
              self.setState({odpowiedz:response.data.desc});
             }).catch(function (error) {console.log(error);});
        }
    componentDidMount(){
        this.ask('getProductsList', null);
        this.ask('getBankList', null);
        // this.ask('getFileList', null);
       
    }

    changeLogo(obraz)
    {
        var ob = this.state.banklist;
        for (var a = 0; a<ob.length;a++)
        {
            if (ob[a].id ===obraz)
            {this.setState({nLogo: ob[a].nazwa, nnLogo:ob[a].logo});}
        }

    }
    lz(x){if (x<10){return '0'+x;}else{return x;}}
    convertDate(data) { var odp = data.getFullYear()+"-"+this.lz(data.getMonth()+1)+"-"+this.lz(data.getDate()); return odp; }
    chandleVal(ev)
    {
        var name = ev.target.name; 
        // //console.log("TARGET",ev.target);
        if (name==="nLogo")   {this.setState({nLogo  :ev.target.value});}
        if (name==="nazwap")   {this.setState({nazwaproduktu  :ev.target.value});}
        
        if (name==="kwOd")    { this.setState({kwOd   :parseInt(ev.target.value)});}
        if (name==="kwDo")    { this.setState({kwDo   :parseInt(ev.target.value)});}
        if (name==="ratOd")   { this.setState({nLogo  :parseInt(ev.target.value)});}
        if (name==="ratOd")   { this.setState({ratOd  :parseInt(ev.target.value)});}
        if (name==="ratDo")   { this.setState({ratDo  :parseInt(ev.target.value)});}
        if (name==="procent") { this.setState({procent:parseFloat(ev.target.value).toFixed(2)});}
        if (name==="rodzaj")  { this.setState({rodzaj :parseInt(ev.target.value)});}

        if (name==="prSt")    { this.setState({prSt  :parseFloat(ev.target.value).toFixed(2)});}
        if (name==="prPr")    { this.setState({prPr  :parseFloat(ev.target.value).toFixed(2)});}
        if (name==="wyr")     { this.setState({wyr  :parseInt(ev.target.value)});}
        if (name==="vis")     { this.setState({vis  :parseInt(ev.target.value)});}
        if (name==="anuluj")  { this.setState({edit  : "none"        });}
        if (name==="bank")    { this.setState({nNazwa  :parseInt(ev.target.value)}); this.changeLogo(ev.target.value);}
        if (name==="zapisz")  { 
            // console.log(this.state.pOd.getDate())
    
            this.ask('saveOneOfert', JSON.stringify({
                
                id:this.state.nId,emitent:this.state.nNazwa, od:this.convertDate(this.state.pOd), do: this.convertDate(this.state.pDo), 
                kwOd:this.state.kwOd, kwDo: this.state.kwDo, ratOd: this.state.ratOd, ratDo:this.state.ratDo,
                procent: this.state.procent, prSt: this.state.prSt, prPr: this.state.prPr, rodzaj: this.state.rodzaj,
                wyr: this.state.wyr, vis: this.state.vis,nazwa:this.state.nazwaproduktu
            }))
        }
        if (name==="nowy")  { 
            var cc = new Date();
            this.setState({
                
                nId:"NEW", nNazwa:0, pOd:cc, pDo: cc, nazwaproduktu:"",
                kwOd:0, kwDo:0, ratOd: this.state.ratOd, ratDo:this.state.ratDo,
                procent: this.state.procent, prSt: 0, prPr: 0, rodzaj: 0,
                wyr: 0, vis: 0,edit  : "block"  
            });
        }
        if (name==="usun")   {this.setState({nId  :parseInt(ev.target.dataset.id)});this.submit();}
    }

    datePicker(data) {  this.setState({pOd:data});}
    datePickerDo(data){this.setState({pDo:data});}

    edytuj(ev)
    {
        var prod = this.state.products;
        for (var a =0; a<prod.length; a++)
        {
            if (parseInt(prod[a].ide)===parseInt(ev.target.dataset.id))
            {
                // console.log(prod[a]);
                var llogo = window.location.protocol +"//"+ window.location.hostname +'/sysimg/defaultbank.png';
                if (prod[a].logo!==""){llogo = prod[a].logo;}
                this.setState({nId: prod[a].ide, nNazwa:prod[a].emitent, nnLogo: llogo,
                    pOd:(new Date(prod[a].od)),pDo:(new Date(prod[a].do)),kwOd:prod[a].ofertaod, kwDo:prod[a].ofertado,
                    ratOd:prod[a].minimalnierat, ratDo:prod[a].maksymalnierat, procent:prod[a].oprocentowanie, 
                    wyr:prod[a].wyroznione, vis:prod[a].visible, nazwaproduktu:prod[a].produkt
                    , edit:"block"});
                  //  //console.log("ID",prod[a].ide);
            }
        }
        
    }
    render(){
    
     
       return (<div className="container">
           <div className="row">
               
               <div className="col-sm-6">
                    <table style={{width:"100%"}}>
                        <tbody>
                        {this.state.products.map((key)=>(
               <tr key={key.ide}><td>{key.nazwa} ({key.produkt})</td><td>
                    <div className="btn-group">
                        <button className="btn btn-secondary btn-sm" data-id={key.ide} onClick={this.edytuj}>Edytuj</button>
                        <button className="btn btn-danger btn-sm" data-id={key.ide} name="usun" onClick={this.chandleVal}>Usuń</button>
                    </div>
                </td></tr>
           ))}
           <tr><td></td><td><button className="btn btn-success btn-sm" name="nowy"  onClick={this.chandleVal}>Nowy</button></td></tr>

                        </tbody>
                    </table>
               </div>
               <div className="col-sm-6">
                   <div style={{display: this.state.edit}}>
                       <p onClick={this.debug}>DEBUG FITCHER:</p>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Bank:</label>
                                    <select className="form-control" name="bank" value={this.state.nNazwa} onChange={this.chandleVal}>
                                        {this.state.banklist.map((key)=>(
                                            <option key={key.id} value={key.id}>{key.nazwa}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <img src={this.state.nnLogo} className="setka" alt="logo" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>Nazwa produktu:</label>
                                    <input type="text" className="form-control" name="nazwap" value={this.state.nazwaproduktu}  onChange={this.chandleVal}/>
                                </div>
                            </div>  
                        </div>                      
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Od:</label>
                                    <DatePicker
                                        selected={this.state.pOd}
                                        onChange={this.datePicker}
                                        dateFormatCalendar={"MMM yyyy"}
                                        name="pOd"
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                        locale={fi}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                            <div className="form-group">
                                    <label>Do:</label>
                                    <DatePicker
                                        selected={this.state.pDo}
                                        onChange={this.datePickerDo}
                                        dateFormatCalendar={"MMM yyyy"}
                                        name="pDo"
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                        locale={fi}
                                    />
                                </div>
                            </div>


                            
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Kwota od:</label>
                                    <input type="text" className="form-control" name="kwOd" value={this.state.kwOd}  onChange={this.chandleVal}/>
                                </div>
                            </div>
                            <div className="col-sm-6">
                            <div className="form-group">
                                    <label>Kwota od:</label>
                                    <input type="text" className="form-control" name="kwDo" value={this.state.kwDo}  onChange={this.chandleVal}/>
                                </div>
                            </div>
                        </div>
                       
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Rat od:</label>
                                    <input type="text" className="form-control" name="ratOd" value={this.state.ratOd}  onChange={this.chandleVal}/>
                                </div>
                            </div>
                            <div className="col-sm-6">
                            <div className="form-group">
                                    <label>Rat do:</label>
                                    <input type="text" className="form-control" name="ratDo" value={this.state.ratDo}  onChange={this.chandleVal}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Oprocentowanie:</label>
                                    <input type="text" className="form-control" name="procent" value={this.state.procent}  onChange={this.chandleVal}/>
                                </div>
                            </div>
                            <div className="col-sm-6">
                            <div className="form-group">
                                    <label>Rodzaj rat:</label>
                                    <select className="form-control" name="rodzaj" value={this.state.rodzaj} onChange={this.chandleVal}>
                                        <option value="1">Stała</option>
                                        <option value="2">Malejąca</option> 
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Prowizja stała:</label>
                                    <input type="text" className="form-control" name="prSt" value={this.state.prSt}  onChange={this.chandleVal}/>
                                </div>
                            </div>
                            <div className="col-sm-6">
                            <div className="form-group">
                                    <label>Prowizja procentowa:</label>
                                    <input type="text" className="form-control" name="prPr" value={this.state.prPr}  onChange={this.chandleVal}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                <label>Wyróżniony:</label>
                                    <select className="form-control" name="wyr" value={this.state.wyr} onChange={this.chandleVal}>
                                        <option value="1">Tak</option>
                                        <option value="0">Nie</option> 
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6">
                            <div className="form-group">
                                    <label>Widoczny:</label>
                                    <select className="form-control" name="vis" value={this.state.vis} onChange={this.chandleVal}>
                                        <option value="1">Tak</option>
                                        <option value="0">Nie</option> 
                                    </select>
                                </div>
                            </div>
                        </div>
                        

                        <div className="btn-group">
                            <button className="btn btn-secondary btn-sm" name="zapisz"  onClick={this.chandleVal}>Zapisz zmiany</button>
                            <button className="btn btn-secondary btn-sm" name="anuluj"  onClick={this.chandleVal}>Anuluj</button>
                        </div>
                   </div>
               </div>
           </div>
        </div>)
    }
}


export default Listaproduktow;