import React  from "react";
import axios from 'axios';
import security from './../common/security.js';
import Cookies from 'js-cookie';
import AF from './../common/analizafirm.js';

class Renderwniosek extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ready:false, formID:"", form:null,
        };
       
        this.ask = this.ask.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        
    }
componentDidUpdate()
{
    //console.log("update", this.props.wniosek);
    if (typeof this.props.wniosek !== 'undefined')
    {
        if (this.state.formID !== this.props.wniosek.formID)
        {
            this.ask("getFormbyID", this.props.wniosek.formID)
        }
    }
}
drawWniosek(a)
{
    var u=this.props.wniosek;
    if (a.pole==='label')
    {
        return (
            <div><b>{AF.san(a.value)}</b></div>
        )
    }
    else if (a.pole==="select")
    {
        var text = "###";
        if (a.option.length>1)
        {
            for (var x=0; x<a.option.length; x++)
            {
                if (a.option[x].value===u[a.name]){text = a.option[x].text;}
            }
        }
        else
        {
            text = u[a.name];
        }
        return(<span>
            <div className={a.col}><div className="wniosekTytul">{a.title}</div><div className="wniosekDane" data-action="edit" data-debug="3" data-klucz={a.name} data-option={JSON.stringify(a.option)} onClick={this.editData}>{AF.san(text)}</div></div>
            
        </span>) 
    }
    else if (a.pole==="text")
    {
        return(<span>
            <div className={a.col}><div className="wniosekTytul">{a.title}</div><div className="wniosekDane" data-action="edit" data-debug="4" data-klucz={a.name} data-option={JSON.stringify(a.option)} data-value={AF.san(u[a.name])} onClick={this.editData}>{AF.san(u[a.name])}</div></div>
        </span>) 
    }
    else if (a.pole==="finans")
    {
        var finanse = [];
        
        if (AF.exists(u.ksiegowosc))
        {
            if (AF.exists(u.finanse)){finanse = AF.twoDimmensional(u.finanse, parseFloat(u.ksiegowosc));}
            if (parseFloat(u.ksiegowosc)===1)
            {
                return (<div><b>Deklarowane wyniki finansowe dla firm rozliczających się za pośrednictwem karty podatkowej</b>
                    <table className="setka wynikifinansowe">
                        <thead>
                            <tr>
                                <td>Rok</td>
                                <td>Stawka</td>
                            </tr>
                        </thead>
                        <tbody>
                            {finanse.map((key,i)=>(
                                <tr key={i}>
                                    <td>{key[0]}</td>
                                    <td>{key[1]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>)
            }
            if (parseFloat(u.ksiegowosc)===2)
            {
                return (<div><b>Deklarowane wyniki finansowe dla firm rozliczających się za pośrednictwem Ryczałtu Ewidencjonowanego</b>
                    <table className="setka wynikifinansowe">
                        <thead>
                            <tr>
                                <td>Rok</td>
                                <td>Przychód</td>
                                <td>Procent</td>
                            </tr>
                        </thead>
                        <tbody>
                            {finanse.map((key,i)=>(
                                <tr key={i}>
                                    <td>{key[0]}</td>
                                    <td>{key[1]}</td>
                                    <td>{key[2]}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>)
            }

            if (parseFloat(u.ksiegowosc)===3)
            {
                return (<div><b>Deklarowane wyniki finansowe dla firm rozliczających się za pośrednictwem Ksiąki Przychodód i Rozchodów</b>
                    <table className="setka wynikifinansowe">
                        <thead>
                            <tr>
                                <td>Rok</td>
                                <td>Brutto</td>
                                <td>Koszty</td>
                                <td>Amortyzacja</td>
                            </tr>
                        </thead>
                        <tbody>
                            {finanse.map((key,i)=>(
                                <tr key={i}>
                                    <td>{key[0]}</td>
                                    <td>{key[1]}</td>
                                    <td>{key[2]}</td>
                                    <td>{key[3]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>)
            }

            if (parseFloat(u.ksiegowosc)===4)
            {
                return (<div><b>Deklarowane wyniki finansowe dla firm rozliczających się za pośrednictwem Ksiąki Przychodód i Rozchodów</b>
                    <table className="setka wynikifinansowe">
                        <thead>
                            <tr>
                                <td>Rok</td>
                                <td>Aktywa</td>
                                <td>Pasywa</td>
                                <td>Obrót</td>
                                <td>Zysk/Strata</td>
                            </tr>
                        </thead>
                        <tbody>
                            {finanse.map((key,i)=>(
                                <tr key={i}>
                                    <td>{key[0]}</td>
                                    <td>{key[1]}</td>
                                    <td>{key[2]}</td>
                                    <td>{key[3]}</td>
                                    <td>{key[4]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>)
            }

            
        }
        else
        {
            return (<div><b>Nieznana forma księgowości</b>
        
                </div>)
        }

       
        
    }
    else
    {
        return (<span data-meta={JSON.stringify(a)}></span>)
    }
    
}
ask(resource, data = null)
      {
            var self = this;
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'sprzedaz');
            params.append('command', resource);
            params.append('data', data);
            params.append('token', Cookies.get("Experimental"));
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            //   console.log(response.data.data);
            if (response.data.status==="OK")
            {
                switch (resource){
                    case 'getFormbyID':self.setState({form:JSON.parse(response.data.data), formID: data});break;
                default: break;
                }
                
            }
            
            }).catch(function (error) {console.log(error);});
            
        }

    render()
    {
        var w = this.props.wniosek;
        if (this.state.form===null)
        { return (<div>Oczekiwanie na dane....</div>)}
        
        else if (typeof this.state.form.settings === 'undefined')
           { return (<div>Przetwarzanie danych....</div>)}
        else
        {
        return (
        <div>{this.state.form.settings.formName}
        <hr></hr>
        {this.state.form.form.map((key,i)=>(<span key={i}>
            {this.drawWniosek(key)}
        </span>))}
       
        
        </div>
            )
        }
    }
}

export default Renderwniosek;