import React  from "react";

class AiSmart extends React.Component{
    constructor(props) {
        super(props);
        this.state = { 
        };
    //    this.componentDidMount = this.componentDidMount.bind(this);
    }
    // componentDidMount(){
    //     this.setState({dane:JSON.parse(this.props.dane) });
    //     ////console.log("=============================",JSON.parse(this.props.dane), '==========================' );
    // }
    isData(e) {if(typeof e !=='undefined') {return true;}else{return false;}}
    render(){
       
        if (this.isData(this.props.smart))
        {
        if (this.isData(this.props.smart.yes))
           { return (
            <div className="setka AIresults">  
               {/* {JSON.stringify(this.props.smart)} */}
                <div className="AIwynik AIpositive" style={{width:Math.round(this.props.smart.yes*1000)/10 + "%"}} > {Math.round(this.props.smart.yes*1000)/10}%</div>
                <div className="AIwynik AInegative" style={{width:Math.round(this.props.smart.no*1000)/10 + "%"}} > {Math.round(this.props.smart.no*1000)/10}%</div>
                <div className="AIwynik AIresignate" style={{width:Math.round(this.props.smart.resignate*1000)/10 + "%"}} > {Math.round(this.props.smart.resignate*1000)/10}%</div>
            </div>

            );}
            else
            {
                return (<div >Oczekuję na dane</div>)
            }
        }
        if (this.isData(this.props.smart))
        {
        if (this.isData(this.props.smart.yes))
           { return (
            <div className="setka AIresults">   
               {/* {JSON.stringify(this.props.smart)} */}
                <div className="AIwynik AIpositive" style={{width:Math.round(this.props.smart.yes*1000)/10 + "%"}} > {Math.round(this.props.smart.yes*1000)/10}%</div>
                <div className="AIwynik AInegative" style={{width:Math.round(this.props.smart.no*1000)/10 + "%"}} > {Math.round(this.props.smart.no*1000)/10}%</div>
                <div className="AIwynik AIresignate" style={{width:Math.round(this.props.smart.resignate*1000)/10 + "%"}} > {Math.round(this.props.smart.resignate*1000)/10}%</div>
            </div>

            );}
            else
            {
                return (<div >Oczekuję na dane</div>)
            }
        }
        
            else
            {
                return (<div>Brak analizy</div>)
            }
        }
       
   

}

export default AiSmart;