import React  from "react";
import axios from 'axios';
import security from './../common/security.js';
import Cookies from 'js-cookie';
import Loader from './../loader.js';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

// import ApexCharts, {ReactApexChart} from 'apexcharts'
import Chart from "react-apexcharts";

// eslint-disable-next-line
// import {IoIosSchool, IoIosStar, IoIosStarOutline, IoIosStarHalf, IoIosConstruct, IoIosEasel } from "react-icons/io";
//
//import {FaPowerOff} from "react-icons/fa";
class Statystyki extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ready:false, server:'',pracownicy:[],pracownik:[],page:0,nazwisko:"",errphp:'',errsql:'',errapp:'',
            miesiac:0, rok:0,
            miesiace:['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'],
            id:0, getStatistic:[],
            selection: 'one_year',
          options: {
            annotations: {
            yaxis: [{
              y: 30,
              borderColor: '#999',
              label: {
                show: true,
                text: 'Support',
                style: {
                  color: "#fff",
                  background: '#00E396'
                }
              }
            }],
            xaxis: [{
              x: new Date("2019").getTime(), //123$$
              borderColor: '#999',
              yAxisIndex: 0,
              label: {
                show: true,
                text: 'Rally',
                style: {
                  color: "#fff",
                  background: '#775DD0'
                }
              }
            }]
          },
          dataLabels: {
            enabled: false
          },
          markers: {
            size: 0,
            style: 'hollow',
          },
          xaxis: {
            type: 'datetime',
            min: new Date("2018").getTime(), //1231@@@
            tickAmount: 6,
          },
          tooltip: {
            x: {
              format: 'dd MMM yyyy'
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 100]
            }
          }
          },
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.ask = this.ask.bind(this);
        this.handleButton = this.handleButton.bind(this);
     
    }
server = () => { confirmAlert({  customUI: ({ onClose }) => { return ( <div className='custom-ui'><h3>Komunikat serwera</h3><p>{this.state.server}</p> <button className="btn btn-danger btn-sm" onClick={onClose} >Zamknij</button>  </div> ); } });}
submit = () => {
    confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h3>Wymagane potwierdzenie</h3>
              <p>Operacja, którą chcesz wykonać jest nieodwracalna i wpływa na strukturę danych. Czy chcesz skasować tą pozycję?</p>
              <button className="btn btn-secondary btn-sm" onClick={onClose}>Anuluj</button>
              &nbsp;
              <button
                className="btn btn-danger btn-sm"
                onClick={() => {
                  this.ask("deleteWniosek", this.state.idd);
                  onClose();
                }}
              >
                Usuń
              </button>
            </div>
          );
        }
      });}

      statusPracownika(d)
      {
          var x = new Date(d);
          var y = new Date();
          var dif = (y-x)/1000/60;
          if (dif>3){return (<span className="red">OFFLINE</span>);}else{return  (<span className="green">ONLINE</span>);}

      }
      czaspracy(a,b)
      {
          var x = new Date(a); var y = new Date(b);
          var dif = (y-x)/1000/60;
          if (dif<60) {return "0:"+dif;}
          else
          {
              var godzin = Math.floor(dif/60);
              var minut = dif-(godzin*60);
              return this.landingZero(godzin)+":"+this.landingZero(Math.round(minut));
          }
          

      }
adres()
{
    if (window.location.pathname ==="/")
    {return true;}else{return false;}
}

updateData (timeline) {
    this.setState({
      selection: timeline
    })
    
    switch (timeline) {
      case 'one_month':
        this.setState({
          options: {
            xaxis: {
              min: new Date('28 Jan 2013').getTime(),
              max: new Date('27 Feb 2013').getTime(),
            }
          }
        })
        break;
      case 'six_months':
        this.setState({
          options: {
            xaxis: {
              min: new Date('27 Sep 2012').getTime(),
              max: new Date('27 Feb 2013').getTime(),
            }
          }
        })
        break;
      case 'one_year':
        this.setState({
          options: {
            xaxis: {
              min: new Date('27 Feb 2012').getTime(),
              max: new Date('27 Feb 2013').getTime(),
            }
          }
        })
        break;
      case 'ytd':
        this.setState({
          options: {
            xaxis: {
              min: new Date('01 Jan 2013').getTime(),
              max: new Date('27 Feb 2013').getTime(),
            }
          }
        })
        break;
      case 'all':
        this.setState({
          options: {
            xaxis: {
              min: undefined,
              max: undefined,
            }
          }
        })
        break;
      default:

    }
  }
  
componentDidMount() {
    var options = this.state.options;

    this.ask('getStatistic', null);
    this.ask('getColaboratorList',null);
    var self = this;
    var u = new Date();
    this.options = this.state.options;
    options.xaxis.min= (u-(1000*60*60*24*30));
    this.setState({rok:u.getFullYear(), miesiac:u.getMonth(), options:options});
    setInterval(function(){ if (self.state.page===0 && this.adres){self.ask('getColaboratorList',null);} }, 60000);
    this.setState({ready:true, options:options});
} 
landingZero(d)
{
    if (d<10){return "0" + d;}else{return d;}
}
handleButton(ev)
{
    var a = ev.target.dataset;
    console.log(a);
    var miesiac=0; var rok=0;
    if (a.op==="home"){this.ask('getColaboratorList',null); this.setState({page:0});}
    if (a.op==="wiecej"){ rok = this.state.rok;  miesiac = this.state.miesiac; miesiac++; if (miesiac===12){miesiac=0;rok++;}this.setState({miesiac:miesiac,rok:rok}); 
    this.ask("GetColabolatorMonth",JSON.stringify({data:rok+"-"+this.landingZero(miesiac+1), id:this.state.id}));}
    if (a.op==="mniej"){ rok = this.state.rok;  miesiac = this.state.miesiac; miesiac--; if (miesiac===-1){miesiac=11;rok--;}this.setState({miesiac:miesiac,rok:rok}); 
    this.ask("GetColabolatorMonth",JSON.stringify({data:rok+"-"+this.landingZero(miesiac+1), id:this.state.id}));}
    if (a.op==="miesiac")
    {
        console.log(a);
        var u = new Date();
        this.setState({nazwisko:a.name, id:a.id, rok: u.getFullYear(), miesiac:u.getMonth() });

        this.ask("GetColabolatorMonth",JSON.stringify({data:u.getFullYear()+"-"+this.landingZero(u.getMonth()+1), id:a.id}));
    }
    if (a.op==='errphp'){this.ask("getPhpErrors", null); }
    if (a.op==='errsql'){this.ask("getSqlErrors", null);} 
    if (a.op==='errapp'){this.ask("getAppErrors", null);}
    if (a.op==='clearphp'){this.ask("clearLog", "clearphplog");}
    if (a.op==='clearsql'){this.ask("clearLog", "clearsqllog");}
    if (a.op==='clearapp'){this.ask("clearLog", "clearloglog");}
}
misureWindow(ev)
{
    var x = ev.target.parent;
    //console.log("PARETN",x);
    return 400;
}
ask(resource, data = null)
      {
            var self = this;
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'admin');
            params.append('command', resource);
            params.append('data', data);
            params.append('token', Cookies.get("Experimental"));
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
              console.log(response.data.data);
            if (response.data.status==="OK")
            {
            //self.setState({loading:false});
                switch (resource){
                case 'getColaboratorList': self.setState({pracownicy: response.data.data, engine:response.data.data.dane});break;
                case 'GetColabolatorMonth':self.setState({pracownik: response.data.data, page:1}); break;
                case 'getPhpErrors':self.setState({page:2, errphp:response.data.data}); break;
                case 'getSqlErrors':self.setState({page:3, errsql:response.data.data}); break;
                case 'getAppErrors':self.setState({page:4, errapp:response.data.data}); break;
                case 'getStatistic':
                    var d = response.data.data;
                    var odp =[]; var t;
                    for (var a = 0; a<d.length; a++)
                    {
                        t = new Date(d[a].data);

                        odp.push([t.valueOf(), parseInt(d[a].count)]);
                    }
                    self.setState({getStatistic:[{data:odp}]});
                    
                    break;
                default: break;
                }
                
            }
            else if(response.data.status==="TEST")
            {
            console.log(response.data);
            }
            else if (response.data.status==="err")
            {
               self.setState({server: response.data.desc});self.server();
            }
            self.setState({odpowiedz:response.data.desc});
            }).catch(function (error) {console.log(error);});
            
        }

    render(){
       if (this.state.ready) 
    {
        if (this.state.page===0)

       

          {  return (
                <div>
                <div className="container">
                        <div className="row">
                            <div className="col-sm-12" >
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><span data-op="home" onClick={this.handleButton}>Statystyki</span></li>
                                    </ol>
                                </nav>
                            
                            </div>
                        </div>
                    </div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3>Pracownicy</h3>
                            <hr className="prettyhr"></hr>
                            <table className="setka">
                                <thead>
                                    <tr>
                                        <td>Imię i nazwisko</td>
                                        <td>Rola</td>
                                        <td>Ostatnio widziany</td>
                                        <td>Status</td>
                                        <td>#</td>
                                    </tr>
                                </thead>
                                <tbody>

                                
                            {this.state.pracownicy.map((key)=>(
                                <tr key={key.id}>
                                    <td>{key.sprzedawca}</td>
                                    <td>{key.nazwa}</td>
                                    <td>{key.time}</td>
                                    <td>{this.statusPracownika(key.time)}</td>
                                    <td><button className="btn btn-info btn-sm" data-op="miesiac" data-id={key.id} data-name={key.sprzedawca} onClick={this.handleButton}>Statystyki miesięczne</button></td>
                                </tr>
                            ))}
                            </tbody>
                            </table>
                            <h3>Statystyki kliknięć</h3>
                            <Chart options={this.state.options} series={this.state.getStatistic} type="area" height="350" />

                            <div id="chartContainer">
                                
                            







                            </div>
                            <h3>Logi serwera</h3>
                            <hr className="prettyhr"></hr>
                            <div className="btn-group">
                                <button className="btn btn-secondary btn-sm" data-op="errphp" onClick={this.handleButton}>Błędy PHP</button>
                                <button className="btn btn-secondary btn-sm" data-op="errsql" onClick={this.handleButton}>Błędy SQL</button>
                                <button className="btn btn-secondary btn-sm" data-op="errapp" onClick={this.handleButton}>Logi Aplikacji</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            );
        }
        else if(this.state.page===1)
        {
            return(<div>
                <div className="container">
                        <div className="row">
                            <div className="col-sm-12" >
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><span data-op="home" onClick={this.handleButton}>Statystyki</span></li>
                                        <li className="breadcrumb-item">Statystyki {this.state.nazwisko}</li>
                                    </ol>
                                </nav>
                            
                            </div>
                        </div>
                    </div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3>Pracownicy
                            <span className="float-right">
                                <div className="btn-group">
                                    <button className="btn btn-secondary btn-sm" data-op="mniej" onClick={this.handleButton}>&laquo;</button>
                                    <button className="btn btn-secondary btn-sm" data-op="wiecej" onClick={this.handleButton}>&raquo;</button>
                                </div>
                            </span></h3>
                            <hr className="prettyhr"></hr>
                            {this.state.miesiace[this.state.miesiac]} {this.state.rok}
                            <table className="setka">
                                <tbody>

                                
                            {this.state.pracownik.map((key)=>(
                                <tr key={key.id}>
                                    <td>{key.od}</td>
                                    <td>{key.do}</td>
                                    <td>{this.czaspracy(key.od, key.do)}</td>
                                    
                                </tr>
                            ))}
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
          
            </div>)
        }
        else if(this.state.page===2)
        {
            return(<div>
                <div className="container">
                        <div className="row">
                            <div className="col-sm-12" >
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><span data-op="home" onClick={this.handleButton}>Statystyki</span></li>
                                        <li className="breadcrumb-item">Dziennik błędów PHP</li>
                                    </ol>
                                </nav>
                            
                            </div>
                        </div>
                    </div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3>Log dziennika błędów PHP
                                <span className="float-right">
                                    <button className="btn btn-secondary btn-sm" data-op="clearphp" onClick={this.handleButton}>Wyczyść log</button>
                                </span>
                            </h3>
                            <hr className="prettyhr"></hr>
                          {this.state.errphp}
                        </div>
                    </div>
                </div>
          
            </div>)
        }
        else if(this.state.page===3)
        {
            return(<div>
                <div className="container">
                        <div className="row">
                            <div className="col-sm-12" >
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><span data-op="home" onClick={this.handleButton}>Statystyki</span></li>
                                        <li className="breadcrumb-item">Dziennik błędów SQL</li>
                                    </ol>
                                </nav>
                            
                            </div>
                        </div>
                    </div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3>Log dziennika błędów SQL
                                <span className="float-right">
                                    <button className="btn btn-secondary btn-sm" data-op="clearsql" onClick={this.handleButton}>Wyczyść log</button>
                                </span>
                            </h3>
                            <hr className="prettyhr"></hr>
                          {this.state.errsql}
                        </div>
                    </div>
                </div>
          
            </div>)
        }
        else if(this.state.page===4)
        {
            return(<div>
                <div className="container">
                        <div className="row">
                            <div className="col-sm-12" >
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><span data-op="home" onClick={this.handleButton}>Statystyki</span></li>
                                        <li className="breadcrumb-item">Dziennik Aplikacji</li>
                                    </ol>
                                </nav>
                            
                            </div>
                        </div>
                    </div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3>Log aplikacji
                                <span className="float-right">
                                    <button className="btn btn-secondary btn-sm" data-op="clearapp" onClick={this.handleButton}>Wyczyść log</button>
                                </span>
                            </h3>
                            <hr className="prettyhr"></hr>
                          {this.state.errapp}
                        </div>
                    </div>
                </div>
          
            </div>)
        }
        else
        {
            return(<div>Ups, coś poszło nie tak</div>)
        }
       
        
    }
    else
    {
        return (<div><Loader/></div>);
    }


}

}

export default Statystyki;