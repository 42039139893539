import React  from "react";
import axios from 'axios';
import security from './../common/security.js';
import Cookies from 'js-cookie';

import front from './../common/front.js';
//  import posed from 'react-pose';


class Broker extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            forma:"mail", numer:"", temat:"",tresc:"", odpowiedz:'czekam', zlozony:false,
            form:{zainteresowanie:[],imie:"",kwota_inwestycji:"",kwota_kredytu:"",mail:"",telefon:"",
            wiadomosc:"", zgoda1:"",zgoda2:"",zgoda3:""}
            
        };
        this.readForm = this.readForm.bind(this);
        this.wyslij = this.wyslij.bind(this);
        this.kategorie = this.kategorie.bind(this);
        this.checkForm = this.checkForm.bind(this);
        this.powrot = this.powrot.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }
    componentDidMount()
    {
        var check = window.localStorage.getItem('brokerForm');
        if (front.IsJsonString(check) && check !==null && check !== 'undefined')
        {
            var form = JSON.parse(check);
            console.log(check, form);
            if (typeof form.form !== 'undefined')
            {this.setState({form:form.form});}
        }
        //console.log("CHECK",check);
    }
    readForm(e)
    {
        ////console.log("ReadForm",e.target.dataset.id, e.target.value);
        var name = e.target.dataset.id;
        var val = e.target.value;
        if (typeof e.target.dataset.filtr!=="undefined")
        {
            if (e.target.dataset.filtr==="number"){var patt1 = /[0-9]/g;var sval = val.match(patt1); if ( sval!==null){val = sval.join('');}else {val = "";}}
        }
        var form = this.state.form;
        form[name] = val;
        this.setState({form:form});
    }
    kategorie(ev)
    {
        //console.log(ev.target.dataset);
        var el = ev.target;
        if (el.classList.contains('active'))
        {
            el.classList.remove('active');
        }
        else
        {
            el.classList.add('active');
        }
        var d = this.state.form;
        var ele = document.getElementsByClassName("zakresuslug active");
        var u = [];
        for (var a = 0; a<ele.length; a++)
        {
            u.push(ele[a].dataset.id);
        }
        //console.log(u);
        d['zainteresowanie'] =u;
        this.setState({form:d});
    }
    isMail(email)
    {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    istel(tel)
    {
        return (tel.length===9);
    }
    wyslij()
    {
        var self = this;
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
        params.append('section', 'aplikacja');
        params.append('command', 'broker');
        params.append('data', JSON.stringify(self.state.form));
        params.append('token', Cookies.get('Experimental'));
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            console.log(response.data);
            self.setState({odpowiedz:response.data.data});
        }).catch(function (error) {console.log(error); self.setState({odpowiedz:"ERR"});});
    }
    powrot()
    {
        this.setState({odpowiedz:"czekam"});
    }
    checkForm()
    {
        var f = this.state.form;
        window.localStorage.setItem('brokerForm', JSON.stringify({form:f}))
        var flag = true;
        if (f.zainteresowanie.length===0){flag=false;alert("Nie wybrano produktów");}
        if (f.zgoda1!=="Tak"){flag=false;alert("Brak podstawowej zgody");}
        if (f.zgoda2==="Tak" && !this.isMail(f.mail)){flag=false;alert("Wyrażono zgodę na kontakt mailowy a nie podano poprawnego adresu mail")}
        if (f.zgoda3==="Tak" && !this.istel(f.telefon)){flag=false;alert("Wyrażono zgodę na kontakt mailowy a nie podano poprawnego adresu mail")}
        if (f.zgoda2!=="Tak" && f.zgoda3!=="Tak"){flag=false;alert("Nie wybrano żadnej formy kontaktu");}
        console.log(f);
        if (flag){this.wyslij();}
    }
    render(){
        ////console.log("CF===");
       
            return (<div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="fullHeight" dangerouslySetInnerHTML={{ __html: this.props.text}}></div>
                    </div>
                </div>
            </div>)
        
    }
}


export default Broker;