import React  from "react";

import Cookies from 'js-cookie';
import axios from 'axios';
import security from './../common/security.js';

class Introformsum extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            zdolnosc:0,miesiecy:0, kasa:0, loaded:false,
            step:1, suggestions: [], value: '', renderPKD:"kliknij, aby zobaczyć opis",oldPKD:"",
            rodzaje:["Brak danych", "Jednoosobowa działalność gospodarcza", "Spółka Cywilna","Spółka Jawna",
            "Spółka Partnerska", "Spółka Komandytowa","Spółka Komandytowa-akcyjna","Spółka z Ograniczoną Odpowiedzialnością","Spółka Akcyjna"],
            modele:["Brak danych", "Startup", "Franczyza", "Franczyzodawca", "Niezależna firma B2B", "Niezależna forma B2C"],
            ksiegowanie:["Brak danych", "Karta podatkowa", "Ryczałt Ewidencjonowany", "Książka przychodów i rozchodów", "Pełna księgowość"]
        };
        
        this.validate = this.validate.bind(this);
        this.renderPKD = this.renderPKD.bind(this);
        this.obliczZK = this.obliczZK.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.ask = this.ask.bind(this);
        this.getState = this.getState.bind(this);
        //this.componentDidUpdate = this.componentDidUpdate.bind(this);

    }
    ask(resource, data =null)
    {
        var self = this;
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
        params.append('section', 'firma');
        params.append('command', resource);
        params.append('data', data);
        params.append('token', Cookies.get("Experimental"));
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            console.log(response.data);
            if (response.data.status==="OK")
            {
                switch (resource){
                    case 'default': self.setState({default: response.data.data});return;
                    case 'users': self.setState({users: response.data.data});return;
                    case 'contacts': self.setState({contacts: response.data.data});return;
                    case 'applications': self.setState({applications: response.data.data});return;
                    case 'setup': self.setState({setup: response.data.data});return;
                    case 'logout': window.location.replace(window.location.protocol+'//' + window.location.host);return;
                    default: return;
                }
            }
            self.setState({odpowiedz:response.data.desc});
        }).catch(function (error) {console.log(error); self.setState({odpowiedz:"ERR"});});
    }
    san(str)
    {
        var odp = str;
        var unic = ["u0119","u00f3","u0105","u015b","u0142","u017c","u017a","u0107","u0144","u0118","u00d3","u0104","u015a","u0141","u017b","u0179","u0106","u0143"];
        var lett = ["ę","ó","ą","ś","ł","ż","ź","ć","ń","Ę","Ó","Ą","Ś","Ł","Ż","Ź","Ć","Ń"]
        for (var a = 0; a<unic.length; a++)
        {
            odp = odp.split(unic[a]).join(lett[a]);
        }
        return odp;
    }
    obliczZK()
    {
        //console.log("FINANSE DO LICZENIA", this.props.finanse);
        var czas = new Date();
        var rok = czas.getFullYear();
        var miesiecy = 0;
        var forsa = 0;
   
        for (var a = 0; a< this.props.finanse.length; a++)
        {
            if (this.props.finanse[a].rok===rok || this.props.finanse[a].rok===(rok-1) ||this.props.finanse[a].rok===(rok-2))
            {
                forsa +=  parseInt(this.props.finanse[a].przychod) + parseInt(this.props.finanse[a].amortyzacja) - parseInt(this.props.finanse[a].koszty);
            
                if (this.props.finanse[a].rok === rok) { miesiecy += czas.getMonth();} else {miesiecy+=12;}
                ////console.log("LICZYDŁO",parseInt(this.props.finanse[a].przychod), parseInt(this.props.finanse[a].amortyzacja),  parseInt(this.props.finanse[a].koszty),miesiecy, forsa);
            }
        }

        /// RAW FORSA
        var zd = forsa/miesiecy;
        //if (isNaN(zd)){zd='Brak danych';}
        var odchylkaPKD = this.danePKD();
        switch (odchylkaPKD)
        {
            case '-':zd = zd *0.9;break;
            case '+':zd = zd *1.1;break;
            default : break;
        }
        zd = parseFloat(zd).toFixed(2);
        //if (isNaN(zd) && this.state.zdolnosc!=zd){zd="W trakcie obliczania";}else{this.ask("updateHbPoints", JSON.stringify({hb:zd,id:}))}
        //console.log("SUMA", forsa, miesiecy, odchylkaPKD);
        this.setState({zdolnosc:zd});
    }

    danePKD()
    {
       
        ////console.log("RENDER_PKD");
        // var pkd = this.props.kodyPKD;
        // var szukane = this.props.pkd;
        // //console.log("DANE_PKD",pkd, szukane)
        // ////console.log("zgody", this.props.zgoda1, this.props.zgoda2, this.props.zgoda3,this.props.zgoda4);
        // console.log(szukane, pkd.length);
        // for (var a = 0; a<pkd.length; a++)
        // {
            
        //     if (pkd[a].numer.trim() === szukane.trim()){
        //         ////console.log("Znaleziony", pkd[a])
        //         return pkd[a].trend;}
        // }
        
    }
    validatenip(nip) {
        var nipWithoutDashes = nip.replace(/-/g,"");
        var reg = /^[0-9]{10}$/;
        if(reg.test(nipWithoutDashes) === false) {
            return false;}
        else
        {
            var digits = (""+nipWithoutDashes).split("");
            var checksum = (6*parseInt(digits[0]) + 5*parseInt(digits[1]) + 7*parseInt(digits[2]) + 2*parseInt(digits[3]) + 3*parseInt(digits[4]) + 4*parseInt(digits[5]) + 5*parseInt(digits[6]) + 6*parseInt(digits[7]) + 7*parseInt(digits[8]))%11;
             
            return (parseInt(digits[9])===parseInt(checksum));
        }
    }
    validate(e)
    {
        //console.log(e.target.value);
        if (this.validatenip(e.target.value)){;
    }
    }

    renderPKD()
    {
        ////console.log("RENDER_PKD");
        // var pkd = this.props.kodyPKD;
        // var szukane = this.props.pkd;
        // //console.log("SZUKANE:", szukane);
        // ////console.log("zgody", this.props.zgoda1, this.props.zgoda2, this.props.zgoda3,this.props.zgoda4);
        // //console.log(this.props);
        // for (var a = 0; a<pkd.length; a++)
        // {
        //     if (pkd[a].numer === szukane){
        //         this.setState({renderPKD: pkd[a].opis});
        //         ////console.log("OPIS_PKD",pkd[a].opis);
        //         return pkd[a].opis;}
        // }
        
    }
    componentDidMount()
    {
        //console.log("FINANSE DO LICZENIA", this.props.finanse);
        var self = this;
        setTimeout(function(){ 
        
            self.renderPKD();
            self.obliczZK();
        
        self.setState({loaded:true});
        }, 500);

    }
    
    renderZgoda(zgoda)
    {
        if (zgoda){return "Tak";}else{return "Nie";}
    }
    getState()
    {
        console.log(this.state);
    }
    render(){
    if (this.props.level===6){
        if (this.state.loaded){
        return (
            <div>
                <div className="row">
                    <div className="col-sm-12">
                        <h3>Podsumowanie</h3>
                        <hr className="prettyhr"></hr>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        
                        <h3 onClick={this.getState}>Zdolność kredytowa</h3>
                        <div className="HBpoints" onClick={this.obliczZK}>{this.state.zdolnosc} <small>HBpkt.</small></div>
                    </div>
                    <div className="col-sm-6">
                       
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <h3>Podsumowanie</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <table className="table table-hover">
                            <tbody>
                            <tr><td>Wyrażone zgody</td><td>
                                    1. Zapoznanie się z regulaminem: 
                                    <span className="float-right">{this.renderZgoda(this.props.zgoda1)}</span> <br></br>
                                    2. Przetwarzanie danych osobowych i firmowych:
                                    <span className="float-right">{this.renderZgoda(this.props.zgoda2)}</span><br></br>
                                    3. Zgoda na wiadomości marketingowe dostarczane drogą elektroniczną: 
                                    <span className="float-right">{this.renderZgoda(this.props.zgoda3)}</span> <br></br>
                                    4. Zgoda na kontakt telefoniczny w sprawach związanych ze złożonymi wnioskami: 
                                    <span className="float-right">{this.renderZgoda(this.props.zgoda4)}</span>
                                    
                                    </td></tr>
                                    <tr><td>Osoba kontaktowa</td><td>{this.san(this.props.imienazwisko)}, {this.san(this.props.telefon)}, {this.san(this.props.mail)}</td></tr>
                                <tr><td>Pełna nazwa firmy:</td><td>{this.san(this.props.nazwa)}</td></tr>
                                <tr><td>Adres siedziby</td><td>Województwo:{this.san(this.props.wojewodztwo)}, Powiat: {this.san(this.props.powiat)}, Gmina: {this.san(this.props.gmina)}, adres: {this.san(this.props.kod)} {this.san(this.props.miejscowosc)},  {this.san(this.props.ulica)} {this.san(this.props.lokal)}</td></tr>
                                <tr><td>Numery identyfikacji nadawane przez organy państwowe</td><td>NIP: {this.props.nip}, REGON:{this.props.regon}, KRS:{this.props.krs}</td></tr>
                                <tr><td>Forma prawna</td><td>{this.state.rodzaje[this.props.forma]}</td></tr>
                                <tr><td>Model biznesowy</td><td>{this.state.modele[this.props.model]}</td></tr>
                                <tr><td>PKD</td><td>{this.props.pkd} <span onClick={this.renderPKD}>{this.state.renderPKD}</span></td></tr>
                                <tr><td>Forma rozliczeń z US</td><td>{this.state.ksiegowanie[this.props.ksiegowosc]}</td></tr>
                                <tr><td>Numer konta bankowego</td><td>{this.props.konto}</td></tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
        );}
            else{
                return (<div></div>)
            }
        }
        else
        {return (<div></div>);}
}

}
export default Introformsum;