import React  from "react";
// import axios from 'axios';
// import security from './../common/security.js';
// import Cookies from 'js-cookie';



class Footer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
         
        };
       
    }
   
    render(){
        ////console.log("LG===");
        return (<div>
            <div className="footer">
            <div className="container hbfooter">
                <div className="row">
                    <div className="col-sm-8">
                        <div dangerouslySetInnerHTML={{__html:this.props.texty.stopkaL}}></div>
                    </div>
                    <div className="col-sm-4">
                    <div dangerouslySetInnerHTML={{__html:this.props.texty.stopkaP}}></div>
                    </div>
                </div>
            </div>
        </div></div> );
    }
}


export default Footer;