import React  from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axios from 'axios';
import security from './common/security.js';

import ContactForm from './contactform/contactform.js';
import Logo from './../img/logo.png';
import Cookies from 'js-cookie';

import DrawCredit from './common/drawcredit.js';
// import Intro from './firma/intro.js';
// import Oferty from './firma/oferty.js';
import Flipcard from './common/flipcard.js';
import Loader from './loader';
//import Wnioski from './firma/wnioski.js';
import Ustawienia from './ustawienia.js';
// import Downtown from './../img/downtown.jpg'
//import Coach from './../img/coach.jpg';
import Documents from './firma/documents.js';
// import Broker from './common/broker.js';
import UniversalFirmForm from './common/universalFirmForm';
import Documentlist from './common/documentlist.js';
import Hipoteka from './common/hipoteka.js';
import WynikMaleAI from './common/wynikMaleAI.js';
import normalize from './common/ainormalization.js';
import brainjs from 'brainjs';
import Footer from './login/footer.js';

class Singiel extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            wnioski:[],noLogout:0,nlstyle:"none",xml:{}, strona:11,
            opisy:{firma:"", ratalny:"", obrotowy:"", leasing:"",faktoring:"", kantor:"", inne:"", broker:""},
            documentList:[],firma:{}, CurrentForm:{},CurrentFormID:0,
            formData:{wojewodztwo:0}, fformData:{wojewodztwo:0},
            dokument:[], szczegolyWniosku:[], ai:{},wynik:{}, wynik2:{}, texty:{}
        //   sesje:[], regulamin:"", firma:[], pobrano:false,oferty:[],rodzaje:[], temp:null,kodyPKD:[],
        //   noLogout:0,nlstyle:"none",formularz:{}, currentForm:0,szczegolyWniosku:{},
        //   wnioski:[],documentList:[],
        };
        this.debug = this.debug.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.ask = this.ask.bind(this);
        this.noLogout = this.noLogout.bind(this);
        this.default = this.default.bind(this);
        this.firmowyRatalny = this.firmowyRatalny.bind(this);
        this.leasing = this.leasing.bind(this);
        this.factoring = this.factoring.bind(this);
        this.kantor = this.kantor.bind(this);
        this.lokaty = this.lokaty.bind(this);
        this.inne = this.inne.bind(this);
        this.broker = this.broker.bind(this);

        this.universalBrokerForm =  this.universalBrokerForm.bind(this);

        this.bratalny = this.bratalny.bind(this);
        this.bobrotowy = this.bobrotowy.bind(this);
        this.bleasing = this.bleasing.bind(this);
        this.bfactoring = this.bfactoring.bind(this);
        this.bwnioski = this.bwnioski.bind(this);
        this.ustawienia = this.ustawienia.bind(this);
        this.zapiszWniosek = this.zapiszWniosek.bind(this);
        this.samochodowy = this.samochodowy.bind(this);
        this.karta = this.karta.bind(this);
        this.setWynik = this.setWynik.bind(this);

    }
debug(){console.log(this.state)}
documents(){return (<Documents />);}
isJson(e){try {JSON.parse(e)} catch (m){return false;} return true;}
setWynik(e){this.setState({wynik:e});}

firmowyRatalny()
{
    return (
    <div>
        <Hipoteka ai={this.state.ai} setWynik={this.setWynik}/>
        <div id="oferta" style={{display:"none"}}>
            <WynikMaleAI wynik={this.state.wynik} />
            <DrawCredit xml={this.state.xml} h3={{display:"block"}} h5={{display:"block"}} show={["Finanse osobiste", "Kredyty mieszkaniowe"]} />
        </div>
    </div>
    )
}
leasing()
{
    return (<DrawCredit xml={this.state.xml} h3={{display:"block"}} h5={{display:"block"}} show={["Finanse osobiste", "Kredyty gotówkowe"]} />)

}
factoring()
{
    return (<DrawCredit xml={this.state.xml} h3={{display:"block"}} h5={{display:"block"}} show={["Dla Twojej firmy", "Faktoring"]} />)

}
kantor()
{
    return (<DrawCredit xml={this.state.xml} h3={{display:"block"}} h5={{display:"block"}} show={["Inwestycje", "Waluty"]} />)

}
lokaty()
{
    return (<DrawCredit xml={this.state.xml} h3={{display:"block"}} h5={{display:"block"}} show={["Inwestycje", "Lokaty"]} />)

}
samochodowy()
{
    return (<DrawCredit xml={this.state.xml} h3={{display:"block"}} h5={{display:"block"}} show={["Finanse osobiste", "Kredyty samochodowe"]} />)

}
karta()
{
    return (<DrawCredit xml={this.state.xml} h3={{display:"block"}} h5={{display:"block"}} show={["Finanse osobiste", "Karty kredytowe"]} />)
}
inne()
{
    return (<DrawCredit xml={this.state.xml} 
        h3={{display:"block"}} h5={{display:"block"}} 
        show={["Finanse osobiste", 
        "Karty kredytowe", "Kredyty samochodowe","Kredyty konsolidacyjne", "Kredyty refinansowe","Pożyczki społecznościowe",
        "Pożyczki pozabankowe","Konta dla obcokrajowców","Rachunki inwestycyjne","Rachunki firmowe","Informacja gospodarcza",
        "Inwestycje", "Dla Twojej firmy", ]} />)

}
universalBrokerForm()
{

    var styl = {display:"block"};
    if (this.state.strona===11){styl={display:"none"}}
    return (
    <div className="container">
        <div className="row">
            <div className="col-sm-12">
                <UniversalFirmForm 
                    page={this.state.strona} 
                    sendable={{display:"block"}} 
                    form={JSON.stringify(this.state.CurrentForm)} 
                    data={this.state.firma[0].main} 
                    values={this.state.firma[0].main} 
                    formdata={this.state.firma[0].main}
                    changes={this.zapiszWniosek}
                />
            </div>
            <div style={styl} className="vertical-center">
                <WynikMaleAI wynik={this.state.wynik2} />
                Wniosek zapisano poprawnie. Zobacz listę wniosków
                <Link to='/broker_wnioski/'> Lista</Link>
            </div>
        </div>

    </div>)
}
bratalny()
{
    if (this.state.CurrentFormID!==5){this.ask("firma","getFormula", 5)}
    return this.universalBrokerForm();
}
bobrotowy()
{
    if (this.state.CurrentFormID!==6){this.ask("firma","getFormula", 6)}
    return this.universalBrokerForm();
}

bleasing()
{
    if (this.state.CurrentFormID!==3){this.ask("firma","getFormula", 3)}
    return this.universalBrokerForm();
}
bfactoring()
{
    if (this.state.CurrentFormID!==4){this.ask("firma","getFormula", 4)}
    return this.universalBrokerForm();
}
bwnioski()
{
    return (
       
                    <Documentlist 
                        documentList={this.state.documentList}
                        document={this.state.wnioski}
                        ask={this.ask}
                        szczegolyWniosku={this.state.szczegolyWniosku}
                    />
         )
}
contact(){
    
    return(
        <ContactForm/>  
    )
}


zapiszWniosek(ev){
    console.log(ev.target.dataset);
    // ======================================== SZTUCZNA INTELIGENCJA DEEPTH =============================================

    var alg = normalize.findAiById(this.state.ai, 5);
    if (alg!==false)
    {
        //zachowanie, jesli algorytmy włączone

    
    var formularz = normalize.normalizeHipIndDepthForm(JSON.parse(ev.target.dataset.dane), this.state.ai);
    //console.log("NORMALIZED FORM",formularz);
    // ===================================================== SZTUCZNA INTELIGENCJA =====================================
    var net = new brainjs.NeuralNetwork({hiddenLayers: [5]});
    // var alg = normalize.findAiById(this.props.ai, 4);
    // console.log(alg);
    net.fromJSON(alg.data);
    var wynik = net.run(formularz);
    console.log(wynik);
    this.setState({wynik2:wynik});
    // this.props.setWynik(wynik);
    // ===================================================== WYNIKI OBLICZEŃ WYSŁANE =====================================

    }

    this.setState({strona:10});





    var self=this;
    switch (ev.target.dataset.action)
    {
        case 'saveFirmData':self.ask('firma','saveMainDataAndWniosek', JSON.stringify([ev.target.dataset.dane, 0]));break;
        case 'konsultacja':self.ask('firma','saveMainDataAndWniosek', JSON.stringify([ev.target.dataset.dane, 1]));break;
        case 'wniosek':self.ask('firma','saveMainDataAndWniosek', JSON.stringify([ev.target.dataset.dane, 4]));break;
        default:break;
    }

}
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("Experimental"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            switch(command)
            {
                // case 'getOferty':self.setState({oferty: response.data.data});break;
                // case 'getFormula':self.setState({formularz:(response.data.data), currentForm: data});break;
                case 'getExOferta':if (self.isJson(response.data.data)){self.setState({xml:JSON.parse(response.data.data)})};break;
                case 'getUserWnioski':self.setState({wnioski:response.data.data});break;
                case 'getOpisy':self.setState({opisy:(response.data.data)});break;
                case 'getData':
                    var odp = response.data.data;
                    if (self.isJson(response.data.data[0].main))
                    {
                        odp[0].main = JSON.parse(response.data.data[0].main);
                    }

                    self.setState({firma:odp});break;
                case 'getFormula':if (self.isJson(response.data.data)){self.setState({CurrentForm:JSON.parse(response.data.data), CurrentFormID:data});}break;
                case 'deleteWniosek':self.ask("firma", "getUserWnioski", null);break;
                case 'saveMainDataAndWniosek':self.ask("firma", "getUserWnioski", null);break;
                case 'szczegolyWniosku':self.setState({szczegolyWniosku:response.data.data});break;
                case 'zmianaWniosku':self.setState({szczegolyWniosku:response.data.data});break;
                case 'getFileList':self.setState({documentList:response.data.data});break;
                //case 'saveMainDataAndWniosek': //console.log("SAVED!");self.ask('firma', 'getUserWnioski', null);self.setState({strona:10});break;
                //case 'acceptTermin':self.ask('firma', 'szczegolyWniosku', data);break;
                case 'getAI':self.setState({ai:response.data.data});break;
                default:break;
            }
            // self.setState({pobrano: true});
        }).catch(function (error) {console.log(error);});
        
    }
 
noLogout()
{
    this.setState({noLogout:0});
}
broker(){
    var opis = this.state.opisy;
    if (this.state.strona!==11){this.setState({strona:11});}
    return(  
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <h3>Doradztwo niezalenego brokera</h3>
                    <hr className="prettyhr"></hr>
                </div>
                <div className="col-sm-12" dangerouslySetInnerHTML={{__html: opis.opisBrokera}} /></div>
                <div className="col-sm-12">
                
                    <div className="cen">
                        <Link to="/broker_hipoteczny" className="ainvisi" ><Flipcard image="budynek"  first="Kredyt Hipoteczny indywidualny" second={opis.hipoteczny} /></Link>
                        <Link to="/broker_hipoteczny_malzenski" className="ainvisi" ><Flipcard  image="rodzina" first="Kredyt Hipoteczny dla małżeństw" second={opis.hipoteczny} /></Link>
                        {/* <Link to="/broker_dowolny_cel" className="ainvisi" ><Flipcard  image="cel" first="Kredyt na dowolny cel" second={opis.dowolnycel} /></Link> */}
                        {/* <Link to="/broker_leasing" className="ainvisi" ><Flipcard  image="leasing" first="Leasing" second={opis.leasing} /></Link>
                        <Link to="/broker_factoring" className="ainvisi" ><Flipcard  image="factoring" first="Usługi Faktoringowe" second={opis.faktoring} /></Link> */}
                        </div>
                        <div className="cen">
                        <Link to="/broker_wnioski" className="ainvisi" ><Flipcard image="archiwum"  first="Twoje wnioski" second={opis.wnioski} /></Link>
     
                        </div>
                </div>
                <div className="col-sm-12">
                    <h3>Interesujące oferty</h3>
                    <hr className="prettyhr"></hr>
                    <DrawCredit xml={this.state.xml} h3={{display:"block"}} h5={{display:"block"}} show={["Inwestycje", "Lokaty"]} />
                </div>
            </div>
                   
        )
}
componentDidMount() {
    var self = this;
    //TODO automatyczne wylogowanie
    setInterval(function(){
        self.setState({noLogout: self.state.noLogout+1, pobrano:true});
        if (self.state.noLogout>570){self.setState({nlstyle:"block"});}else{self.setState({nlstyle:"none"});}
        //console.log(self.state.noLogout);
        if (self.state.noLogout===600){self.logout();}
    }, 1000);
    // this.pobierzRodzaje();
    // this.pobierzDaneFirmy();
    this.ask('firma', "getData", null);
    this.ask("firma", 'getUserWnioski', null);
    this.ask("aplikacja", "getExOferta", null);
    this.ask('aplikacja', "getOpisy", null);
    this.ask("documents","getFileList", null);
    this.ask('aplikacja', 'getAI', null);
    
    //this.ask("firma","getFormula", 1);
    // this.pobierzwnioski();
    // this.loadPKD();
}
default()
{
    var opis = this.state.opisy
    return(
        <div>
    
       
        <div className="">
        <div className="container firmowy" > 
                <div className="row">
                <h2>Witaj {this.state.firma[0].nazwisko}</h2>
                <div className="col-sm-12" dangerouslySetInnerHTML={{__html: opis.indywidualny}} />
                </div>
            </div>
            <div className="container firmowy" > 
                <div className="row">
                
                    <div className="col-sm-12 dlaFirm">
                        <h3 className="" >Oferta dla osób indywidualnych</h3>
                        <div dangerouslySetInnerHTML={{__html: opis.indOferta}} />
                        <div className="cen">
                        <Link to="/indywidualny_hipoteczny" className="ainvisi" ><Flipcard image="budynek"  first="Kredyt hipoteczny" second={opis.hipoteczny} /></Link>
                        <Link to="/indywidualny_gotowkowy" className="ainvisi" ><Flipcard  image="rodzina" first="Kredyt gotówkowy" second={opis.gotowkowy} /></Link>
                        <Link to="/indywidualny_samochodowy" className="ainvisi" ><Flipcard  image="samochod" first="Kredyt samochodowy" second={opis.samochodowy} /></Link>
                        <Link to="/indywidualny_karta" className="ainvisi" ><Flipcard  image="karta" first="Karta kredytowa" second={opis.karta} /></Link>
            
                        </div>
                       
                    </div>
                </div>
            </div>
            
            <div className="container inne">
                <div className="row">
                
                    <div className="col-sm-12 dlaFirm">
                        <h3 className="" >Usługi dodatkowe</h3>
                        <span className="just" dangerouslySetInnerHTML={{ __html: this.state.opisy.inne }}></span>
                        <div className="cen">
                            <Link to="/kantor" className="ainvisi" ><Flipcard first="Kantor"  image="kantor" second={opis.kantor} /></Link>
                            <Link to="/lokaty" className="ainvisi" ><Flipcard  image="lokata" first="Lokata" second={opis.lokata} /></Link>
                            <Link to="/inne" className="ainvisi" ><Flipcard first="Inne"  image="inne" second={opis.inne} /></Link>
                            <Link to="/broker" className="ainvisi" ><Flipcard first="Doradztwo niezależnego brokera" image="broker" second={opis.broker} /></Link>
                        </div>
                       
                       
                    </div>
                </div>           
            </div>
        </div>
    </div>
        )
}
logout()
{
    const params = new URLSearchParams(); params.append('asc',security.securityKey());
    params.append('section', 'user');
    params.append('command', 'logout');
    params.append('token', Cookies.get("Experimental"))
    //var self = this;
    axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
        console.log(response.data);
        window.location.reload();
        Cookies.remove("Experimental");
    }).catch(function (error) {console.log(error);});
}

ustawienia()
{
    return (<Ustawienia />)
}
    render(){
        document.title ="HomeBank | Personal";
       if (this.state.pobrano) 
    {
        return (
            <div onMouseMove={this.noLogout} onKeyPress={this.noLogout} onKeyDown={this.noLogout}>
            <div className="nologout tlo" style={{display: this.state.nlstyle}}><div className="nologout message"><h3>Alert aktywności</h3>
            z powodu braku aktywności zostaniesz wylogowany za {600-this.state.noLogout} s.
            </div></div>
                <Router>
                    <div>
                    <nav className="navbar navbar-light navbar-expand-sm hbnav">
                        {/* <button onClick={this.debug} >DEBUG</button> */}
                    <Link to="/"><img className="applogo"   src={Logo} alt="logo"/></Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="collapsibleNavbar">

                            <ul className="navbar-nav">
                            
                            
                                
                                <li className="nav-item">
                                    <Link className="nav-link"  to="/broker/">Broker</Link>
                                </li>
                                <li className="nav-item">
                                     <Link className="nav-link"  to="/broker_wnioski/">Twoje wnioski</Link>
                                </li>
                                <li className="nav-item">
                                     <Link className="nav-link"  to="/documents/">Dokumenty</Link>
                                </li>
                                <li className="nav-item">
                                     <Link className="nav-link"  to="/setup/">Ustawienia</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link"  to="/contact/">Kontakt</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" onClick={this.logout}  to="/">Wyloguj</Link>
                                </li>
                                
                            </ul>
                            </div>
                        </nav>
                        
                        <Route exact path="/"  component={this.default} />
                        <Route path="/broker/" component={this.broker} />
                        <Route path="/documents/" component={this.documents} />
                        <Route path="/setup/" component={this.ustawienia} />
                        <Route path="/contact/" component={this.contact} />
                        {/* // ROUTE NIE BĘDące w Menu */}
                        
                        <Route path="/indywidualny_hipoteczny/" component={this.firmowyRatalny} />
                        <Route path="/indywidualny_gotowkowy/" component={this.leasing} />
                        <Route path="/indywidualny_samochodowy/" component={this.samochodowy} />
                        <Route path="/indywidualny_karta/" component={this.karta} />
                        
                        <Route path="/kantor/" component={this.kantor} />
                        <Route path="/lokaty/" component={this.lokaty} />
                        <Route path="/inne/" component={this.inne} />

                        <Route path="/broker_hipoteczny/" component={this.bratalny} />
                        <Route path="/broker_hipoteczny_malzenski/" component={this.bobrotowy} />
                        {/* <Route path="/broker_leasing/" component={this.bleasing} />
                        <Route path="/broker_factoring/" component={this.bfactoring} /> */}
                        <Route path="/broker_wnioski/" component={this.bwnioski} />

                    </div>
                </Router>
                <Footer  texty={this.state.texty}/>
            </div>
        );
    }
    else
    {
        return (<Loader/>);
    }


}

}

export default Singiel;