import React  from "react";
import axios from 'axios';
import security from './../common/security.js';
import Cookies from 'js-cookie';
import Loader from './../loader.js';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import {FaUserTie} from "react-icons/fa";
import Adres from './adres.js';
//import Wyniki from './wyniki.js';

import Kredyt from './../kredyt.js';

class Generatewniosek extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ready:false, server:'',wnioski:[],page:0,dane:[],idd:0,banki:[], wniosek:[],zgody:"",
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.ask = this.ask.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.recall = this.recall.bind(this);
        this.zgody = this.zgody.bind(this);
    }
server = () => { confirmAlert({  customUI: ({ onClose }) => { return ( <div className='custom-ui'><h3>Komunikat serwera</h3><p>{this.state.server}</p> <button className="btn btn-danger btn-sm" onClick={onClose} >Zamknij</button>  </div> ); } });}
submit = () => {
    confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h3>Wymagane potwierdzenie</h3>
              <p>Operacja, którą chcesz wykonać jest nieodwracalna i wpływa na strukturę danych. Czy chcesz skasować tą pozycję?</p>
              <button className="btn btn-secondary btn-sm" onClick={onClose}>Anuluj</button>
              &nbsp;
              <button
                className="btn btn-danger btn-sm"
                onClick={() => {
                  this.ask("deleteWniosek", this.state.idd);
                  onClose();
                }}
              >
                Usuń
              </button>
            </div>
          );
        }
      });}

      recall(ev)
      {
        console.log(ev.target.dataset);
        var dane = this.state.wniosek;
        if (ev.target.dataset.con==='kwota'){dane.kwota = parseInt(ev.target.value);}
        if (ev.target.dataset.con==='rata') {dane.rat = parseInt(ev.target.value);}
        if (isNaN(dane.rat)){dane.rat = 0;}
        if (isNaN(dane.kwota)){dane.kwota = 0;}
        if (dane.rat>dane.maksymalnierat){dane.rat=dane.maksymalnierat;}
        if (dane.rat<dane.minimalnierat){dane.rat=dane.minimalnierat;}
        if (dane.kwota>dane.ofertado){dane.kwota=dane.ofertado;}
        if (dane.kwota<dane.ofertaod){dane.kwota=dane.ofertaod;}
        this.setState({wniosek: dane});
      }
componentDidMount() {
    //this.ask('getWniosek', JSON.stringify(this.props.wniosek));
    this.setState({wniosek: this.props.wniosek});
    this.zgody();

    this.setState({ready:true});
}
handleButton(ev)
{
    var a = ev.target.dataset;
    console.log(a);
    if (a.op==="przejmij"){this.ask('opiekuje', a.ide);}
}
opis(d,id)
{
    if (d!==null)
    {
        return (<span><FaUserTie className="green"/> {d}</span>);
    }
    else
    {
        return (<button className="btn btn-secondary btn-sm" data-op="przejmij" data-ide={id} onClick={this.handleButton}>Przejmij</button>);
    }
}
operacje(id)
{

    return (<div className="btn-group">
        <button type="button" className="btn btn-secondary btn-sm" data-op="getinfo" data-id={id} onClick={this.handleButton}>Info</button>
        <button type="button" className="btn btn-secondary btn-sm" data-op="wniosek" data-id={id} onClick={this.handleButton}>Wniosek</button>
        <button type="button" className="btn btn-danger btn-sm" data-op="kosz" data-id={id} onClick={this.handleButton}>Usuń</button>

    </div>)
 
}
zgody()
{
    //var zgody = JSON.parse(this.props.zgody);
    setTimeout(() => {
        var zg = this.props.zgody;
        var at = false;
        var str = "";
        if (zg.zgoda1==="true"){str+="<span class='green rectangle'></span>";}else{str+="<span class='red rectangle'></span>";at = true;}
        if (zg.zgoda2==="true"){str+="<span class='green rectangle'></span>";}else{str+="<span class='red rectangle'></span>";at = true;}
        if (zg.zgoda3==="true"){str+="<span class='green rectangle'></span>";}else{str+="<span class='red rectangle'></span>";at = true;}
        if (zg.zgoda4==="true"){str+="<span class='green rectangle'></span>";}else{str+="<span class='red rectangle'></span>";at = true;}
        if (at) {str= "<span class='red small'><b>UWAGA!</b> Klient nie udzielił wszystkich wymaganych zgód</span>"+ str;}
        this.setState({zgody:str})

        //console.log("ZGODY", this.props.zgody);

    }, 50);
    
}
ask(resource, data = null)
      {
            var self = this;
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'sprzedaz');
            params.append('command', resource);
            params.append('data', data);
            params.append('token', Cookies.get("Experimental"));
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
              console.log(response.data.data);
            if (response.data.status==="OK")
            {
            //self.setState({loading:false});
                switch (resource){
                case 'getList': self.setState({wnioski: response.data.data});break;
                case 'opiekuje':self.ask('getList', self.props.zakres);self.setState({server:response.data.desc});self.server();break;
                case 'getUserInfo': self.setState({dane: response.data.data, page:1});break;
                default: break;
                }
                
            }
            else if(response.data.status==="TEST")
            {
            console.log(response.data);
            }
            else if (response.data.status==="err")
            {
               self.setState({server: response.data.desc});self.server();
            }
            self.setState({odpowiedz:response.data.desc});
            }).catch(function (error) {console.log(error);});
            
        }

    render(){
        document.title ="HomeBank | Seller";
        //var zgody = JSON.parse(this.state.wnioski.zgody);
        //console.log("ZGODY!! ",this.state.wnioski.zgody);
       if (this.state.ready) 
    {
        if (this.state.page===0)
        {
            return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h3>Generowanie wniosku
                            <span className="float-right"><span dangerouslySetInnerHTML={{__html: this.state.zgody}} /></span>
                        </h3>
                        <hr className="prettyhr"></hr>
                        DATA ZŁOŻENIA: {this.state.wniosek.data}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-3">
                        {this.state.wniosek.nazwa}
                        <img src={this.state.wniosek.logo} alt="logo" className="setka" />
                    </div>
                    <div className="col-sm-9">
                       <div className="row">
                           <div className="col-sm-6">
                            <Kredyt 
                                KR={this.state.wniosek.kwota}
                                OS={this.state.wniosek.oplatastala}
                                OP={this.state.wniosek.procentowa}
                                PR={this.state.wniosek.oprocentowanie}
                                RA={this.state.wniosek.rat}
                                HB={this.state.wniosek.punkty}
                                HX={this.state.wniosek.hb}
                                UNITS={true}
                                HEADER={true}
                                ADJUST={true}
                            />
                           </div>
                    
                          
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label >Kwota:</label>
                                    <input type="text" value={this.state.wniosek.kwota} className="form-control" data-con="kwota" onChange={this.recall}/>
                                </div>
                                <div className="form-group">
                                    <label >Rat:</label>
                                    <input type="text" value={this.state.wniosek.rat} className="form-control" data-con="rata" onChange={this.recall}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <hr className="prettyhr"></hr>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <Adres id={this.props.wniosek.user_id} adres={this.props.wniosek.adres}/>
                    </div>
                    <div className="col-sm-6">
                       
                    </div>
                </div>
            </div>
            );
        }
       
    }
    else
    {
        return (<div><Loader/></div>);
    }


}

}

export default Generatewniosek;