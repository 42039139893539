import React  from "react";
import Cookies from 'js-cookie';


class Checkcookie extends React.Component{
    constructor(props) {
        super(props);
        this.state = { isCookie:false};
        this.componentDidMount = this.componentDidMount.bind(this);

    }
    componentDidMount()
    {
        var d = new Date();
        Cookies.set("hbCookie", d.toLocaleString());
        if (Cookies.get("hbCookie")===d.toLocaleString())
        {
            this.setState({isCookie:true});
        }
        else
        {
            this.setState({isCookie:false});
        }

    }
    
    render(){
        if (!this.state.isCookie)
        {return (
        <div className="l2">
           Nie masz włączonych Ciasteczek. Szanujemy Twoją prywatność, ale niektóre z funkcji aplikacji mogą być niedostępne.
        </div>);}
        else
        {
            return (<span></span>)
        }
        
}

}

export default Checkcookie;