// Plik odpowiadający za przygotowanie danych firmowych do AI

export function version()
{
    return ("1.0");
}


export function obliczRate(data, kwota, rat)
{
    // console.log(data);
    var calkowitakwotasplaty = parseFloat(kwota);
    var procentowa = parseFloat(data.procentowa);
    var stala = parseFloat(data.oplatastala);
    var oprocentowanie = parseFloat(data.oprocentowanie)/100;
    // console.log(calkowitakwotasplaty, procentowa, stala, oprocentowanie);
    var prowizja = stala + calkowitakwotasplaty * (procentowa/100);
    var odsetki = calkowitakwotasplaty * oprocentowanie * rat/12;
    // console.log(prowizja, odsetki);
    calkowitakwotasplaty = calkowitakwotasplaty +prowizja +odsetki;
    var rata = parseFloat(calkowitakwotasplaty/rat).toFixed(2);
    return rata; 
}

export function nKwota(kw, wk)
{
    var odp = (parseFloat(kw)/parseFloat(wk));
    odp = odp*0.75;
    //console.log("NKWOTA",odp, kw,wk);
    return odp;
}
export function normalizeFirma(data)
{
    var odp=[];
    //console.log("DATA", data);
    var kozak = 0;
    for (var a =0; a<data.length; a++)
    {
        if (parseInt(data[a].punkty)>kozak){kozak = parseInt(data[a].punkty)}
    }
    for (a =0; a<data.length; a++)
    {
        var toOut=[];var toIn=[];
        var rata =0;var zdolnosc=0;
        var finanse = JSON.parse(data[a].model);
        var staz02 = 0;
        var staz25 = 0;
        var staz5 = 0;
        if (parseInt(finanse.staz) <2){staz02=1;}
        else if (parseInt(finanse.staz) >=2 && parseInt(finanse.staz) <5){staz25=1;}
        else {staz5=1;}
        var franczyza1 = 0;
        var franczyza2 = 0;
        var franczyza3 = 0;
        switch (parseInt(finanse.model))
        {
            case 0: franczyza1=1;break;
            case 1: franczyza1=1;break;
            case 2: franczyza1=1;break;
            case 3: franczyza2=1;break;
            case 4: franczyza2=1;break;
            case 5: franczyza3=1;break;
            default:break;
        }
        var ks1=0;
        var ks2=0;
        switch (parseInt(finanse.ksiegowosc))
        {
            case 0: ks1=1;break;
            case 1: ks1=1;break;
            case 2: ks1=1;break;
            case 3: ks2=1;break;
            case 4: ks2=1;break;
            default:break;
        }
        var wielkosc = parseInt(data[a].punkty)/kozak;
        rata = obliczRate({oprocentowanie:parseInt(data[a].oprocentowanie),oplatastala:parseInt(data[a].oplatastala),procentowa:parseInt(data[a].procentowa)},data[a].kwota,data[a].rat);
        zdolnosc = nKwota(rata,data[a].punkty);
        var rat = parseInt(data[a].rat)/420;
        // console.log(rata);
        switch(data[a].sukces)
        {
            case "1":toOut=[1,0,0,0,0,0,0,0,0];break;
            case "2":toOut=[0,1,0,0,0,0,0,0,0];break;
            case "3":toOut=[0,0,1,0,0,0,0,0,0];break;
            case "4":toOut=[0,0,0,1,0,0,0,0,0];break;
            case "5":toOut=[0,0,0,0,1,0,0,0,0];break;
            case "6":toOut=[0,0,0,0,0,1,0,0,0];break;
            case "7":toOut=[0,0,0,0,0,0,1,0,0];break;
            case "8":toOut=[0,0,0,0,0,0,0,1,0];break;
            case "9":toOut=[0,0,0,0,0,0,0,0,1];break;
            default:break;
        }
        switch(data[a].trend)
        {
            case "1":toIn=[1,0,0,0,0, zdolnosc, rat, wielkosc, staz02, staz25, staz5, franczyza1, franczyza2, franczyza3, ks1, ks2];break;
            case "2":toIn=[0,1,0,0,0, zdolnosc, rat, wielkosc, staz02, staz25, staz5, franczyza1, franczyza2, franczyza3, ks1, ks2];break;
            case "3":toIn=[0,0,1,0,0, zdolnosc, rat, wielkosc, staz02, staz25, staz5, franczyza1, franczyza2, franczyza3, ks1, ks2];break;
            case "4":toIn=[0,0,0,1,0, zdolnosc, rat, wielkosc, staz02, staz25, staz5, franczyza1, franczyza2, franczyza3, ks1, ks2];break;
            case "5":toIn=[0,0,0,0,1, zdolnosc, rat, wielkosc, staz02, staz25, staz5, franczyza1, franczyza2, franczyza3, ks1, ks2];break;
           
            default:break;
        }
        var x = {input:toIn, output:toOut };
        odp.push(x);
    }
    console.log(odp);
    return odp;

}

export function askFirma(data, kozak, kredyt)
{
    //console.log("askFirma",data, kozak);
    var finanse = JSON.parse(data.model);
    var rata = obliczRate({oprocentowanie:parseInt(kredyt.oprocentowanie),oplatastala:parseInt(kredyt.oplatastala),procentowa:parseInt(kredyt.procentowa)},kredyt.kwota,kredyt.rat);
    var zdolnosc = nKwota(rata,data.hb);
    var rat = parseInt(kredyt.rat)/420;
    //console.log("RAT",rat);
    var wielkosc =0;
    if (typeof kozak[0]!=='undefined' && typeof kozak[0]!=='string')
    {wielkosc = parseInt(data.hb)/parseInt(kozak[0].punkty);}
    else{wielkosc = parseInt(data.hb)/parseInt(kozak);}
    //console.log("WIEKOŚĆ7", data.hb, kozak, wielkosc, (typeof kozak[0]));
    var staz02 = 0;
    var staz25 = 0;
    var staz5 = 0;
    var franczyza1 = 0;
    var franczyza2 = 0;
    var franczyza3 = 0;
    var ks1 = 0;
    var ks2 = 0;
    var toIn=[];
    if (parseInt(finanse.staz) <2){staz02=1;}
        else if (parseInt(finanse.staz) >=2 && parseInt(finanse.staz) <5){staz25=1;}
        else {staz5=1;}
    switch (parseInt(finanse.ksiegowosc))
        {
            case 0: ks1=1;break;
            case 1: ks1=1;break;
            case 2: ks1=1;break;
            case 3: ks2=1;break;
            case 4: ks2=1;break;
            default:break;
        }
    switch (parseInt(finanse.model))
        {
            case 0: franczyza1=1;break;
            case 1: franczyza1=1;break;
            case 2: franczyza1=1;break;
            case 3: franczyza2=1;break;
            case 4: franczyza2=1;break;
            case 5: franczyza3=1;break;
            default:break;
        }
        //console.log("TREND",data.trend);
    switch(data.trend)
    {
        case "1":toIn=[1,0,0,0,0, zdolnosc, rat, wielkosc, staz02, staz25, staz5, franczyza1, franczyza2, franczyza3, ks1, ks2];break;
        case "2":toIn=[0,1,0,0,0, zdolnosc, rat, wielkosc, staz02, staz25, staz5, franczyza1, franczyza2, franczyza3, ks1, ks2];break;
        case "3":toIn=[0,0,1,0,0, zdolnosc, rat, wielkosc, staz02, staz25, staz5, franczyza1, franczyza2, franczyza3, ks1, ks2];break;
        case "4":toIn=[0,0,0,1,0, zdolnosc, rat, wielkosc, staz02, staz25, staz5, franczyza1, franczyza2, franczyza3, ks1, ks2];break;
        case "5":toIn=[0,0,0,0,1, zdolnosc, rat, wielkosc, staz02, staz25, staz5, franczyza1, franczyza2, franczyza3, ks1, ks2];break;
       
        default:break;
        
    }    
    //console.log("ToIN", toIn);
    return toIn;
}

export function interpretacja(d)
{
    var best = -1;
    var u = -1;
    for (var a=0; a<d.length; a++)
    {
        if (best<d[a]){best = d[a];u=a;}
    }
    var odp = "Brak podpowiedzi co do powodzenia spłaty kredytu w przyszłości.";
    best = parseFloat(best*100).toFixed(2);
    u +=1;
    switch (u)
    {
        case 1:odp="Spodziewany czas spłaty będzie krótszy od zamierzonego z pewnością sięgającą " +best +"%";break;
        case 2:odp="Wygląda na to, że kredyt zostanie spłacony terminowo z pewnością " +best +"%";break;
        case 3:odp="Wygląda na to, że kredyt może być spłacony terminowo z pewnością " +best +"%";break;
        case 4:odp="Wygląda na to, że wystąpią chwilowe trudności z terminową spłatą kredytu z pewnością " +best +"%";break;
        case 5:odp="Wygląda na to, że wystąpią poważne trudności z terminową spłatą kredytu z pewnością " +best +"%";break;
        case 6:odp="Wygląda na to, że wzięcie takiego kredytu może zakończyć się postępowaniem komorniczym z pewnością " +best +"%";break;
        case 7:odp="Wygląda na to, że wzięcie takiego kredytu zakończy się postępowaniem komorniczym z pewnością " +best +"%";break;
        case 8:odp="Wygląda na to, że wzięcie takiego kredytu zakończy się egzekucją komorniczą z pewnością " +best +"%";break;
        case 9:odp="Wygląda na to, że bank nie będzie chciał udzielić takiego kredytu z powodu zbyt wysokiego ryzyka braku spłaty " +best +"%";break;

        default:break;
    }
    return odp;
}

