import React from 'react';
import front from './../common/front.js';

import ProductListContainer from './productlistcontainer.js';
class Creditofertsnologin extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ready:false, oferty:[],
        };
       
    }
 


    render(){
        if (this.props.dane.length>0)
        {
            
        return (
            <div>
                {this.props.dane.map((key)=>(
                    <div className="OfertContainer" key={key.id}>
                        <table className="OfTable" >
                            <tbody>
                                <tr>
                                    <td><img className="OfertaLogo" src={key.logo} alt="logo" /></td>
                                    <td>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Bank udzielający kredytu: {key.bank} <small>Nazwa produktu bankowego: {key.nazwa} (uruchomiony w dniu: {key.od})</small></td>
                                                    
                                                </tr>
                                                <tr>
                                                    <td>
                                                            Oprocentowanie stałe {key.oprocentowanie}% w skali roku, kredyt do {front.numberWithCommas(key.ofertado)}zł,
                                                            maksymalny okres kredytowania: {Math.floor(key.maksymalnierat/12)} lat
                                                    </td>
                                                    <td className="buttonHole"><button className="OfertaButton" src={key.link}>Przedź do banku</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>   
                ))}
            </div>
        );
                }
                else
                {    
                    return( <ProductListContainer  clientSelection={this.props.clientSelection} xml={this.props.xml} drawable={["Finanse osobiste", "Inwestycje", "Ubezpieczenia", "Dla Twojej firmy", "Produkty niefinansowe"]} 
                    include={this.props.showOnly} form={this.props.form}/>)
                }
    
        }
}

export default Creditofertsnologin;