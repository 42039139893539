import React  from "react";
// import img1 from './../../img/';
import {Animated} from "react-animated-css";
// import Slider from 'react-animated-slider';
// import 'react-animated-slider/build/horizontal.css';
import normalize from './../common/ainormalization.js';
import brainjs from 'brainjs';


class Hipoteka extends React.Component{
    constructor(props) {
        super(props);
        this.state = {latSplaty:20, 
        formularz:{
            wartosc:0, urodzony:"",cywilny:0,
            lat:20,
            zobowiazania:0,linia:0,
            dzieci:0,nautrzymaniu:0
        },
    tokedForm:["lat"]};
        this.lata = this.lata.bind(this);
        this.numberOnly = this.numberOnly.bind(this);
        this.toked = this.toked.bind(this);
        this.liczKredyt = this.liczKredyt.bind(this);

    }
    liczKredyt(ev)
    {
        //console.log("LICZ",ev.target.dataset);
        var wej = document.getElementsByClassName("glass");
        for (var z = 0; z<wej.length;z++)
        {
            if (wej[z].value==="") {wej[z].classList.add("red");}else{wej[z].classList.remove("red");}
        }
        for (z = 0; z<wej.length;z++)
        {
            if (wej[z].value==="") {console.log(wej[z]);return;}
        }
        //document.getElementById("ind")
        var formularz = normalize.normalizeHipIndForm(this.state.formularz, this.props.ai);
        //console.log("NORMALIZED FORM",formularz);
        // ===================================================== SZTUCZNA INTELIGENCJA =====================================
        var net = new brainjs.NeuralNetwork({hiddenLayers: [5]});
        var alg = normalize.findAiById(this.props.ai, 4);
        if (alg === false)
        {
            //Zachowanie jeśli algorytm jest wyłączony
            document.getElementById("oferta").style.display="block";
            var link = window.location.toString().split("#")        
            window.location = link[0] +"#oferta"
            return;
        }
        console.log(alg);
        net.fromJSON(alg.data);
        var wynik = net.run(formularz);
        console.log(wynik);
        this.props.setWynik(wynik);
        // ===================================================== WYNIKI OBLICZEŃ WYSŁANE =====================================
        document.getElementById("oferta").style.display="block";
        var link = window.location.toString().split("#")        
        window.location = link[0] +"#ocena"
       
        // document.getElementById("ind").innerHTML = JSON.stringify(wynik);
        // document.getElementById("ind").style.display = "block";
    }
    xyz()
    {
        document.getElementById("oferta").style.display="block";
        var link = window.location.toString().split("#")        
        window.location = link[0] +"#oferta"

    }
    numberOnly(ev)
    {
        var n = ev.target.value;
        n = parseInt(n);
        if (isNaN(n)){n=""; ev.target.classList.add("red");}else{ev.target.classList.remove("red");}
        ev.target.value = n;
        this.lata(ev);
    }
    toked(ev)
    {
        var toked = this.state.tokedForm;
        var id = ev.target.dataset.id;
        if (!toked.includes(id) && ev.target.value!==""){toked.push(id);}
        this.setState({tokedForm:toked});
        if (toked.length===9)
        {
            document.getElementById("dalej").classList.remove("disabled");
        }
        else
        {
            document.getElementById("dalej").classList.add("disabled");
        }
    }
    lata(ev)
    {
        var self = this;
        var form = this.state.formularz;
        //console.log("FORM>",ev.target.dataset);
        form[ev.target.dataset.id] = ev.target.value; 
        switch (ev.target.dataset.id)
        {
            case "lat":form.lat = ev.target.value; self.setState({latSplaty:ev.target.value});break;
            default:break;
        }
        this.setState({formularz:form});
    }
    render(){
        var opisy = {
            wartosc:"Wartość nieruchomości. Jest to jednocześnie kwota potencjalnego kredytu. Suma ta nie uwzględnia dodatkowych kosztów takich jak prowizja, ubezpieczenie kredytu itp.",
            urodzony:"Data urodzenia zostanie uwzględniona wraz z parametrem długości spłaty kredytu.",
            dzieci:"Wpisanie ilości posiadanych dzieci wpływa na koszt prowadzenia gospodarstwa domowego.",
            stan:"Określ swój stan cywilny. Przebywając w wolnym związku kalkulator liczy Ciebie i jedną osobę dorosłą na utrzymaniu. Małzenstwo jest formą preferowaną przez wiele banków",
            lat:"Jak długo chciałbyś spłacać kredyt. Mniejszy okres to większe raty, ale mniejsza całkowita kwota spłaty",
            dochod:"dochód netto ze wszystkich źródeł. Jeśli źródeł jest więcej (np. pensja współmałzonka, świadczenia alimentacyjne, renta dla osób niepełnoletnich) wpisz sumę wszystkich wpływów. Jeśli Twoje dochody są kadego miesiąca rózne wpisz średnią z dłuzszego okresu czasu",
            zobowiazania:"Wpisz sumę wszystkich zobowiązań: dotychczasowe raty kredytów, miesięczny koszt spłaty karty, alimenty itp.",
            linie:"Wpisz sumę wolnych środków w liniach kredytowych, jakie masz dostępne. Jeśli część wykorzystujesz odlicz kwotę zadłuzenia rozumiejąc, ze liczona jest ona w rubryce zobowiązania",
            nautrzymaniu:"Wpisz ilość osób dorosłych będących na utrzymaniu. (dorosłe dzieci które np. są na studiach)",
            wklad:"Wpisz wysokość wkłądu własnego. Nie powinien być on nizszy, niz 20% wartości nieruchomości",
        }

        return (
        <div className="l1">
            <div className="wrapper">
                <div className="section parallax">
                    
                    <div className="paralaxInfo">
                        <div className="row">
                            <div className="col-sm-12">
                                Podaj przyblione wartości. Te dane nigdzie nie są rejestrowane, posłużą do obliczeń w Twojej przeglądarce. Jeśli masz wątpliwość 
                                dotyczącą danego pola najedź myszką na tytuł a wyświetli się obszerniejsze wyjaśnienie.
                                <hr></hr>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                    <label data-toggle="tooltip" title={opisy.wartosc}>Wartość nieruchomości</label>
                                    <input type="text" className="form-control glass" onBlur={this.toked} onChange={this.numberOnly} data-id="wartosc"/>
                            </div>
                            <div className="col-sm-4">
                                    <label data-toggle="tooltip" title={opisy.wklad}>Wkład własny</label>
                                    <input type="text" className="form-control glass" onBlur={this.toked} onChange={this.numberOnly} data-id="wklad"/>
                            </div>
                            <div className="col-sm-4">
                                    <label data-toggle="tooltip" title={opisy.urodzony}>data urodzenia</label>
                                    <input type="date" className="form-control glass" onBlur={this.toked}  data-id="urodzony" onChange={this.lata}/>
                            </div>
                           
                            
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <label data-toggle="tooltip" title={opisy.lat}>Zadeklaruj lata spłaty ({this.state.latSplaty})</label>
                                <input type="range" className="form-control glass" onBlur={this.toked} value={this.state.latSplaty} data-id="lat"   min="1" max="35" onChange={this.lata} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-4">
                                    <label data-toggle="tooltip" title={opisy.dochod}>Dochód netto</label>
                                    <input type="text" className="form-control glass" onBlur={this.toked}  onChange={this.numberOnly} data-id="dochod"/>
                            </div>
                            
                            <div className="col-sm-4">
                                    <label data-toggle="tooltip" title={opisy.zobowiazania}>Suma zobowiązań</label>
                                    <input type="text" className="form-control glass"  onBlur={this.toked} data-id="zobowiazania" onChange={this.numberOnly} data-id="zobowiazania"/>
                            </div>
                            <div className="col-sm-4">
                                    <label data-toggle="tooltip" title={opisy.linie}>Linie kredytowe</label>
                                    <input type="text" className="form-control glass" onBlur={this.toked}  onChange={this.numberOnly} data-id="linia"/>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-sm-4">
                                    <label data-toggle="tooltip" title={opisy.stan} >Stan cywilny</label>
                                    <select className="form-control glass" data-id="stan" onBlur={this.toked}  onChange={this.lata}>
                                        <option value="0">Wybierz...</option>
                                        <option value="3">Wolny związek</option>
                                        <option value="0">Kawaler / Panna</option>
                                        <option value="1">Żonaty / Zamężna</option>
                                        <option value="4">Separacja</option>
                                        <option value="2">Rozwiedziony / Rozwiedziona</option>
                                        <option value="5">Wdowiec / Wdowa</option>
                                    </select>
                            </div>
                            <div className="col-sm-4">
                                    <label data-toggle="tooltip" title={opisy.dzieci}>Dzieci na utrzymaniu</label>
                                    <input type="text" className="form-control glass" onBlur={this.toked}  onChange={this.numberOnly} data-id="dzieci"/>
                            </div>
                            <div className="col-sm-4">
                                    <label data-toggle="tooltip" title={opisy.nautrzymaniu}>Dorośli na utrzymaniu</label>
                                    <input type="text" className="form-control glass" onBlur={this.toked}  onChange={this.numberOnly} data-id="nautrzymaniu"/>
                            </div>
                            <div className="col-sm-8"></div>
                            <div className="col-sm-4">
                                <label className="block">&nbsp;</label>
                                <div className="float-right">
                                    <button type="button" className="btn btn-outline-light" onClick={this.xyz}> Pomiń formularz</button>
                                    &nbsp;&nbsp;
                                    <button type="button" className="btn btn-outline-light disabled" data-form={JSON.stringify(this.state.formularz)} onClick={this.liczKredyt} id="dalej">Dalej</button>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                </div>
                
            </div>
        </div>);
        
}

}

export default Hipoteka;