import React  from "react";
import axios from 'axios';
import security from './../common/security.js';
import Cookies from 'js-cookie';

import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter } from "bs4-modal-react";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
 
class Admin extends React.Component{
    constructor(props) {
        super(props);
        this.state = { page:0, data:[], admin:[], loaded:false,pass:"", imie:"", nazwisko:"", 
        poziom:"", text1:"Włącz do edycji", updated:false, modalIsOpen: false,visible: false,
        styletext1:{display:'none'},styletext2:{display:'block'}, sesje:[], server:""
           
        };
        
        
        this.componentDidMount = this.componentDidMount.bind(this);
        //this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
        this.ask = this.ask.bind(this);
        this.home = this.home.bind(this);
        this.wn = this.wn.bind(this);
        this.readPole = this.readPole.bind(this);
        this.doedycji = this.doedycji.bind(this);
        this.readprop = this.readprop.bind(this);
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.logowania = this.logowania.bind(this);
        this.handleButton = this.handleButton.bind(this);
      }

      onShow = () => this.setState({ visible: true });
    onHide = () => this.setState({ visible: false });
    
    onSave = () => {
        //alert("Saving changes");
        this.onHide();
        this.ask("deleteAccount", {mail: this.state.admin[0].mail});
        setTimeout(function(){ window.location.reload(); }, 100);
        
    };
    home(){
        this.setState({page:0, admin:[]});

    }
    server = () => { confirmAlert({  customUI: ({ onClose }) => { return ( <div className='custom-ui'><h3>Komunikat serwera</h3><p>{this.state.server}</p> <button className="btn btn-danger btn-sm" onClick={onClose} >Zamknij</button>  </div> ); } });}
submit = () => {
    confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h3>Wymagane potwierdzenie</h3>
              <p>Operacja, którą chcesz wykonać jest nieodwracalna i wpływa na strukturę danych. Czy chcesz skasować tą pozycję?</p>
              <button className="btn btn-secondary btn-sm" onClick={onClose}>Anuluj</button>
              &nbsp;
              <button
                className="btn btn-danger btn-sm"
                onClick={() => {
                  this.ask("deleteWniosek", this.state.idd);
                  onClose();
                }}
              >
                Usuń
              </button>
            </div>
          );
        }
      });}

    logowania()
    {
        this.ask('getLoginTable', this.props.admin[0].id);
    }
    check(data)
    {
        if (data.length<1){return (<span></span>)}else
        {
            return (<span>Mail nie został aktywowany lub oczekuje na zmianę hasła przez użytkownika</span>)
        }
    }
    handleButton(ev)
    {
        console.log(ev.target.dataset);
        var a = ev.target.dataset;
        if (a.op==="sendMail"){this.ask("sendMailtouser", a.id);}
    }
    ask(resource, data)
    {
        var self = this;
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
        params.append('section', 'admin');
        params.append('command', resource);
        params.append('data', JSON.stringify(data));
        params.append('token', Cookies.get("Experimental"));
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            console.log(response.data);
            if (response.data.status==="OK")
            {
                self.setState({loading:false});
                switch (resource){
                  case 'getUserInfo': self.setState({dane: response.data.data});return;
                  case 'getLoginTable': self.setState({sesje:response.data.data});return;
                  case 'sendMailtouser':self.setState({server:response.data.desc});self.server();break;
                  default: return;
                }
            }
          self.setState({odpowiedz:response.data.data});
         }).catch(function (error) {
             //console.log("ERROR!",error);
            });
            }

         componentDidMount()
        {
        console.log(this.props.admin);
        this.setState({admin: this.props.admin});
        while (typeof this.props.admin=== 'undefined')
        {
            this.setState({admin: this.props.admin});
            //console.log("repeat", typeof this.state.admin[0]);
        }
        var self = this;
        setTimeout(function(){
            self.setState({admin: self.props.admin}); 
            self.setState({loaded: true});
            // var d = this.state.admin[0];
            // this.setState({imie:d.name, nazwisko:d.lastname, poziom:d.setup});
        }, 500);
        }
        
    wn()
    {
        console.log(this.props.admin, this.state.admin);
        
    }
    readPole(ev)
    {
        console.log(ev.target.dataset.name);
        switch (ev.target.dataset.name)
        {
            case 'name': this.setState({imie: ev.target.value});break;
            case 'lastname': this.setState({nazwisko: ev.target.value});break;
            case 'setup': this.setState({poziom: ev.target.value});console.log(ev.target.dataset.name, ev.target.value);break;
            case 'pass': this.setState({pass: ev.target.value});break;
            default:break;
        }
    }
    readprop(poziom)
    {
        if (poziom === 'poziom')
        {
            var pz =0;
            if (this.state.updated){pz= this.state.poziom;}
            else{pz = this.props.admin[0].setup;}
            if (parseInt(pz)===0 || parseInt(pz)===99){return (<div className="admininfo"><b>Użytkownik zablokowany</b></div>);}
            else
            {
                var rr = ["x", "Użytkownik indywidualny", "Użytkownik formowy", "Użytkownik będący członkiem adminikstracji publicznej",
            'Użytkownik VIP', "Pracownik działu sprzedaży", "Kierownik działu sprzedaży", "Nauczyciel maszyn", "Sub administrator",
            'Administrator', "Super Administrator"  ];
            return (<div className="admininfo"><b>{rr[pz]}</b></div>);
            }
        }
        else if (poziom === 'imie')
        {
            if (this.state.updated){return (<div>{this.state.imie} {this.state.nazwisko}</div>);}
            else
            {
                return (<div>{this.props.admin[0].name} {this.props.admin[0].lastname}</div>)
            }
        }
    }
    doedycji(ev)
    {
        if (this.state.text1==="Włącz do edycji")
        {
            if (!this.state.updated)
            {
                var d = this.state.admin[0];
                this.setState({imie:d.name, nazwisko:d.lastname, poziom:d.setup, text1:"Zapisz", updated:true, styletext1:{display:'block'}, styletext2:{display:'none'}});
            }
            else
            {
                this.setState({text1:"Zapisz", styletext1:{display:'block'}, styletext2:{display:'none'}});
            }
        }
        else
        {
            //wywołanie zapisu
            this.ask('saveUserData', {imie: this.state.imie, nazwisko: this.state.nazwisko, poziom: this.state.poziom, user: this.state.admin[0].mail });
            this.setState({text1:"Włącz do edycji", styletext1:{display:'none'}, styletext2:{display:'block'}});
        }
    }


    //=============================modal functions ================================================
 
 
  openModal() {
    this.setState({modalIsOpen: true});
  }
 
  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#f00';
  }
 
  closeModal() {
    this.setState({modalIsOpen: false});
  }
    //==================================end modal functions =======================================
    render(){
        if (!this.state.loaded)
        {
            return (<div onClick={this.wn}>oczekiwanie na dane...</div>)
        }
        else
        {


            ////console.log("ADMIN", this.state.admin[0]);
            var d = this.state.admin[0];
           // this.setState({imie:d.name, nazwisko:d.lastname, poziom:d.setup});
        return (<div> 
                    <h3 onClick={this.wn}>Administracja kontem użytkownika <code>{d.mail}</code></h3>
                <div className="row">
                    <div className="col-sm-4">
                        <div style={this.state.styletext2}>Imię i nazwisko: {this.readprop("imie")}</div>
                        <div className="form-group" style={this.state.styletext1}>
                            <label>Imię:</label>
                            <input type="text" className="form-control" data-name="name" value={this.state.imie} onChange={this.readPole}/>
                        </div>
                        <div className="form-group" style={this.state.styletext1}>
                            <label>Nazwisko:</label>
                            <input type="text" className="form-control" data-name="lastname" value={this.state.nazwisko}  onChange={this.readPole}/>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div style={this.state.styletext2}>Przyznany poziom uprawnień: {this.readprop("poziom")}</div>
                        <div className="form-group" style={this.state.styletext1}>
                            <label>Poziom uprawnień:</label>
                            <select className="form-control"  data-name="setup" value={this.state.poziom}  onChange={this.readPole}>
                               
                                <option value="99">Zablokowany</option>
                                <option value="1">Użytkownik indywidualny</option>
                                <option value="2">Użytkownik firmowy</option>
                                <option value="3">Użytkownik administracji publicznej</option>
                                <option value="5">Pracownik działu sprzedaży</option>
                                <option value="6">Kierownik działu sprzedaży</option>
                                <option value="7">Nauczyciel maszyn</option>
                                <option value="8">Sub Administrator</option>
                                <option value="9">Administrator</option>
                                <option value="10">Super Administrator</option>
                            </select>
                        </div> 
                        {this.check(d.session)}
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            Resetowanie hasła posiada dwie metody: Wysłanie linku do zmiany hasła lub wpisanie hasła ręcznie.
                            <label>Nowe hasło:</label>
                            <input type="text" className="form-control"  data-name="pass" value={this.state.pass} onChange={this.readPole}/>
                            
                        </div>
                        <div className="btn-group">
                            <button className="btn btn-secondary">Zapisz hasło</button>
                            <button className="btn btn-danger" data-op="sendMail" data-id={d.id} onClick={this.handleButton}>Wyślij link</button>
                        </div>
                    </div>
                    
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="btn-group">
                            <button className="btn btn-secondary" onClick={this.doedycji}>{this.state.text1}</button>
                            <button className="btn btn-danger" onClick={this.onShow}>Usunięcie konta</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                    <h3>Ostatnie dziesięć logowań użytkownika</h3>
                 
                    <hr className="prettyhr"></hr>
                    <button className="btn btn-secondary btn-sm" onClick={this.logowania}>Pobierz z serwera</button>
                    <table className="table table-hover table-sm">
                        <thead>
                            <tr>
                                <td>IP komputera</td>
                                <td>Przeglądarka</td>
                                <td>System</td>
                                <td>Data i godzina logowania</td>
                                <td>Aktywny</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.sesje.map((key)=>(
                                    <tr key={key.id}>
                                        <td>{key.ip}</td>
                                        <td>{key.browser}</td>
                                        <td>{key.client}</td>
                                        <td>{key.aktywnosc}</td>
                                        <td>{key.active}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                </div>

                <Modal visible={this.state.visible} onHide={this.onHide}>
          <ModalHeader>
            <ModalTitle>Potwierdzenie operacji</ModalTitle>
          </ModalHeader>
          <ModalBody>Usunięcie konta jest operacją nieodwracalną. Czy na pewno chcesz usunąć to konto użytkownika wraz ze wszystkimi danymi, które są z nim powiązane?</ModalBody>
          <ModalFooter>
            <button type="button" className="btn btn-secondary" onClick={this.onHide}>
              Anuluj
            </button>
            <button type="button" className="btn btn-danger" onClick={this.onSave}>
              Usuń bezpowrotnie
            </button>
          </ModalFooter>
        </Modal>
                </div>);
        }
}

}
export default Admin;