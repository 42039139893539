import React  from "react";
//import Autosuggest from 'react-autosuggest';
import UniversalFirmForm from './../common/universalFirmForm.js';


class Introformfour extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            step:1, suggestions: [], value: '', zdolnosc:0
        };
        
      
    }
  
    render(){
    if (this.props.level===4){
        return (
            <div>
                <div className="row">
                    <div className="col-sm-12">
                    <UniversalFirmForm page={11} sendable={{"display":"block"}} settings={{"zluz":true}} form={this.props.form} formdata={this.props.data} data={this.props.data} changes={this.props.change}formId={this.props.formID}/>
                    </div>
                </div>
            </div> 
            
        );}
        else
        {return (<div></div>);}
}

}
export default Introformfour;