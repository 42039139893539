import React from 'react';
import Downtown from './../../img/downtown.jpg';
class Singlepage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }
    render(){
        return (
            <div>
                <img src={Downtown} alt="grafica" className="introimage"/>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <span  dangerouslySetInnerHTML={{ __html: this.props.text}}></span>
                    </div>
                </div>
            </div> 
            </div>
        );}
}
export default Singlepage;