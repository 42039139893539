import React  from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axios from 'axios';
import security from './common/security.js';
import Logo from './../img/hbbussines.png';
import Cookies from 'js-cookie';

import Loader from './loader.js';

import Setup from './ustawienia.js';
import Inbox from './seller/inbox.js';
import Konsultacje from './seller/konsultacje.js';
import Dynax from './common/manager.js';
import Readcontactform from './admin/readcontactform.js'
class Seller extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ready:false,st:{}, sek:0,statuswniosku:{data:[],status:""},numerwniosku:"",

            bezopieki:[],
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.logout = this.logout.bind(this);
        this.default = this.default.bind(this);
        this.ask = this.ask.bind(this);
        this.sprawdzStatus = this.sprawdzStatus.bind(this);
        this.chandleInput = this.chandleInput.bind(this);
        this.sprawdz = this.sprawdz.bind(this);
        this.zaopiekuj = this.zaopiekuj.bind(this);
    }
//default(){return (<Inbox zakres="nowe" />);}
sprawdz()
{
    this.ask("echo", null);
}
zaopiekuj(ev)
{
    this.ask("przypiszOpiekuna", ev.target.dataset.id)
}
default(){
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <h3>Wnioski nie posiadające opiekuna</h3>
                    <div className="nodoc" style={(this.state.bezopieki.length === 0) ? {display:"block"} : {display:"none"}}>Nie posiadasz wniosków bez opeiki</div>
                    {this.state.bezopieki.map((key, i)=>(
                        <div key={key.ide}>
                            <button className="btn btn-primary" data-id={key.ide} onClick={this.zaopiekuj} > Przyjmij</button>
                            {" " +key.nazwa} HB/{key['data_create'].substr(5,2)}{key.ide}/{key['data_create'].substr(0,4)}
                             z dnia: {key['data_create']}
                              {/* kwota {Dynax.numberWithSpaces(JSON.parse(key.content).kwota)} */}
                           
                            <hr></hr>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
sprawdzStatus()
{
    this.ask("sprawdzStatus", this.state.numerwniosku);
}
chandleInput(ev)
{
    var value = ev.target.value;
    var op = ev.target.dataset.op;
    if (op==="numerwniosku"){this.setState({numerwniosku:value});}
}
moje()   {return (<Inbox zakres="moje" />);}
konsultacje() {return (<Konsultacje zakres="Prośba o konsultację" title="Prośby o konsultację"/>);}
// applications() {return (<Konsultacje zakres="Wniosek złożony"  title="Złożone wnioski"/>);}
applications() {return (<Konsultacje />) }


proceded(){return (<Konsultacje zakres="Procedowany"  title="Wnioski w trakcie rozpatrywania"/>);}
incomplete(){return (<Konsultacje zakres="Do uzupełnienia"  title="Wnioski do uzupełnienia"/>);}
complete(){return (<Konsultacje zakres="Rozpatrzone"  title="Wnioski rozpatrzone"/>);}
ended(){return (<Konsultacje zakres="Zakończony"  title="Wnioski zakończone"/>);}
Readcontactform(){return (

<div className="container">
    <div className="col-sm-12">
        <Readcontactform />
    </div>
</div>

);}
ustawienia()
{
    return (<Setup />);
}

componentDidMount() {
    this.setState({ready:true});
    var self = this;
    setInterval(function(){ self.ask('respirator') }, 60000);
    // setInterval(function(){ self.ask('statystyka', null) }, 30000);
    setInterval(function(){ self.setState({sek:self.state.sek+1}) }, 1000);
    // this.ask("statystyka", null);
    this.ask("getUnprotected", null);

}
ask(resource, data = null)
      {
            var self = this;
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'sprzedaz');
            params.append('command', resource);
            params.append('data', data);
            params.append('token', Cookies.get("Experimental"));
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
              console.log(response.data.data);
            if (response.data.status==="OK")
            {
            //self.setState({loading:false});
                switch (resource){
                    case 'getUnprotected':self.setState({bezopieki: response.data.data});break;
                // case 'respirator':  console.log(response.data.data);return;
                // case 'statystyka': self.setState({st:response.data.data, sek:0});break;
                // case 'sprawdzStatus':self.setState({statuswniosku:response.data.data});break;
                    case 'przypiszOpiekuna': self.ask("getUnprotected", null);break;
                default: return;
                }
                
            }
            else if(response.data.status==="TEST")
            {
            console.log(response.data);
            }
            else if (response.data.status==="err")
            {
               //self.setState({server: response.data.desc});self.server();
            }
            self.setState({odpowiedz:response.data.desc});
            }).catch(function (error) {console.log(error);});
            
        }

logout()
{
    const params = new URLSearchParams(); params.append('asc',security.securityKey());
    params.append('section', 'seller');
    params.append('command', 'logout');
    params.append('token', Cookies.get("Experimental"))
    //var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
        console.log(response.data);
        //window.location.reload();
        Cookies.remove("Experimental");
        window.location.replace(window.location.protocol+ '//' + window.location.host);
    }).catch(function (error) {console.log(error);});
}
    render(){
        document.title ="HomeBank | Seller";
       if (this.state.ready) 
    {
        var stat = Dynax.groupMessages(this.state.st);
        if (typeof stat==="undefined"){stat = {konsul:0,wnio:0, proced:0, uzup:0, rozp:0,zak:0};}
        
        return (
            <div>
                <Router>
                    <div>
                    <nav className="navbar navbar-expand-sm navbar-light bg-light ">
                    <Link to="/"><img className="applogo"  src={Logo} alt="logo"/></Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="collapsibleNavbar">

                            <ul className="navbar-nav">
                            
                            <   li className="nav-item">
                                    <Link className="nav-link"  to="/">Pulpit</Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link className="nav-link"  to="/consultation">Konsultacje <span className="badge badge-light">{stat.konsul}</span></Link>
                                </li>  */}
                                <li className="nav-item">
                                    <Link className="nav-link"  to="/applications/">Wnioski </Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link className="nav-link"  to="/proceded/">Procedowane <span className="badge badge-light">{stat.proced}</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link"  to="/incomplete/">Do uzupełnienia <span className="badge badge-light">{stat.uzup}</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link"  to="/complete/">Rozpatrzone <span className="badge badge-light">{stat.rozp}</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link"  to="/ended/">Zakończone <span className="badge badge-light">{stat.zak}</span></Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link className="nav-link"  to="/contactform/">Formularze</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link"  to="/setup/">Ustawienia</Link>
                                </li>
                            
                                <li className="nav-item">
                                    <Link className="nav-link" onClick={this.logout}  to="/">Wyloguj</Link>
                                </li>
                                
                            </ul>
                            </div>
                        </nav>


                        <Route exact path="/"  component={this.default} />
                        <Route path="/curatela/" component={this.moje} />
                        <Route path="/applications/" component={this.applications} />
                        <Route path="/setup/" component={this.ustawienia} />
                        <Route path="/contact/" component={this.contact} /> 
                        <Route path="/consultation/" component={this.konsultacje} /> 
                        <Route path="/proceded/" component={this.proceded} />
                        <Route path="/incomplete/" component={this.incomplete} /> 
                        <Route path="/complete/" component={this.complete} /> 
                        <Route path="/ended/" component={this.ended} />
                        <Route path="/contactform/" component={this.Readcontactform} />
                        

                    </div>
                </Router>
            </div>
        );
    }
    else
    {
        return (<Loader/>);
    }


}

}

export default Seller;