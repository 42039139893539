import React  from "react";
import axios, {post} from 'axios';
import security from './../common/security.js';
import Cookies from 'js-cookie';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

// import { FilePond } from 'react-filepond';
// import 'filepond/dist/filepond.min.css';

import niszcz from './../../img/niszczarka.png';
import dload from './../../img/download_arrow.png';



class Documents extends React.Component{
    constructor(props) {
        super(props);
        this.state = { products:[], edit:"none", nNazwa:"", nStatus:"", nId:0, data:[],opis:"",exif:"",
        desc:"",title:"", server:'', nPytanie:'', nOdpowiedz:'', nWidocznosc:0, nEnabled:1, pictures: [] ,
        purl:'', palt:"",vis:'none', pliki:[],
        files: []   , nazwapliku:"Wybierz plik",
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.ask = this.ask.bind(this);
        this.chandleVal = this.chandleVal.bind(this);
        this.podglad = this.podglad.bind(this);
        this.server = this.server.bind(this);
        this.handleInit = this.handleInit.bind(this);
        this.debug = this.debug.bind(this);
        this.odswiez = this.odswiez.bind(this);
        this.zamknij = this.zamknij.bind(this);
        this.deb = this.deb.bind(this);
        this.download = this.download.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.unlinkFile = this.unlinkFile.bind(this);
    }
    debug()
    {
        console.log(this.state);
        this.ask("loadPicture", JSON.stringify(this.state.pictures));
    }
    handleInit() {
        //this.pond.customHeader='token:'+ Cookies.get("Experimental");
        console.log('FilePond instance has initialised', this.pond);
        
    }
    download(ev)
    {
        var name = ev.target.dataset.name;
        var server = window.location.protocol+ "//" + window.location.hostname;
        // //console.log("FOR DOWNLOAD",ev.target.dataset);
        this.ask("download", name);
        setTimeout(() => {
            const response = {
              file: server + "/download/" + name,
            };

            window.open(response.file);
        
          }, 1000);
  
        console.log(ev.target.dataset);
        var name = ev.target.dataset.name;
        this.ask("download", name);
        var self = this;
        setTimeout(() => {
            self.ask("unlink", name );
        }, 20000);
    }
    submit = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h3>Wymagane potwierdzenie</h3>
                  <p>Operacja, którą chcesz wykonać jest nieodwracalna i wpływa na strukturę danych. Czy chcesz skasować tą pozycję?</p>
                  <button className="btn btn-secondary btn-sm" onClick={onClose}>Anuluj</button>
                  &nbsp;
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => {
                      this.ask("deleteFile", this.state.palt);
                      onClose();
                    }}
                  >
                    Usuń
                  </button>
                </div>
              );
            }
          });}

    server = () => { confirmAlert({  customUI: ({ onClose }) => { return ( <div className='custom-ui'><h3>Komunikat serwera</h3><p>{this.state.server}</p> <button className="btn btn-danger btn-sm" onClick={onClose} >Zamknij</button>  </div> ); } });}
    zamknij()
    {
        this.setState({vis:"none"})
    }
    uploadFile(ev)
    {
        this.setState({nazwapliku:"PLIK"});
        var self = this;
        var server = window.location.protocol+ "//" + window.location.hostname;
        console.log(ev.target, ev.target.files[0]);
        const url = server;
    const formData = new FormData(); 
    formData.append('file',ev.target.files[0]);
    formData.append('section',"documents");
    formData.append('command', 'uploadFile');
    formData.append('asc',security.securityKey());
    formData.append('token', Cookies.get("Experimental"));
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
            }
        }
    post(url, formData,config).then(function (response){
        console.log(response);
        self.setState({nazwapliku:"Wybierz plik"});
        self.ask("getFileList", null);
        document.getElementById("file").value = "";
    });
    }
    ask(resource, data)
      {
            var self = this;
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'documents');
            params.append('command', resource);
            params.append('data', data);
            params.append('token', Cookies.get("Experimental"));
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
              console.log(response.data.data);
              if (response.data.status==="OK")
              {
                //self.setState({loading:false});
                  switch (resource){
                    case 'getFileList': self.setState({pliki: response.data.data, opis: response.data.data.length + " obrakzków"});break;
                    case 'deleteFile': self.ask('getFileList', null);break;
                    case 'getExifData':
                        var exift ="";
                        if (response.data.data ==="null"){self.setState({exif: "Brak danych exif<br>"});}
                        else
                        { 
                            Object.getOwnPropertyNames(response.data.data).forEach(function(val, idx, array) {
                            console.log(val + ' -> ' + response.data.data[val]);
                            if (val==="COMPUTED"){exift += "Dimensions:" + response.data.data[val].Width +" x " + response.data.data[val].Height + "<br>";}
                            exift +=val + ": " + response.data.data[val] + "<br>";
                    });
                        self.setState({exif: exift});}
                        
                        break;
                   
                      default: return;
                  }
                 
              }
              else if(response.data.status==="TEST")
              {
                console.log(response.data);
              }
              else if (response.data.status==="err")
              {
                self.setState({server:response.data.desc});
                self.server();
                  
              }
              self.setState({odpowiedz:response.data.desc});
             }).catch(function (error) {console.log(error);});
        }
    componentDidMount(){
        this.ask('getFileList', null);
       
    }
    chandleVal(ev)
    {
        var name = ev.target.name; 
        ////console.log("PROPS",this.props);
        if (name==="nPytanie")   {this.setState({nPytanie    :ev.target.value});}
       
    }
    podglad(ev)
    {
        console.log(ev.target.dataset);
        this.ask('getExifData', ev.target.dataset.alt);
        this.setState({purl: ev.target.dataset.url, palt:ev.target.dataset.alt, vis:"block"});
    }
    deb()
    {
        this.ask('getFileList', null);
    }
    odswiez(err, file)
    {
        this.ask('getFileList', null);

    }
    unlinkFile(ev)
    {
        var name = ev.target.dataset.name;
        this.ask("deleteFile", name);
    }
    render(){
        var server = window.location.protocol+ "//" + window.location.hostname;
     
       return (<div className="container ">
           <div className="row ">
               
               <div className="col-sm-3">
                       <br></br>
                        {/* <FilePond ref={ref => this.pond = ref}
                            files={this.state.files}
                            allowMultiple={false}
                            maxFiles={3} 
                            server={
                                {
                                    url: server,
                                    process: {
                                        headers: {
                                            'token': Cookies.get("Experimental")
                                        },
                                    }
                                }
                            }
                            labelIdle={'Przeciągnij i upuść plik w tym obszarze lub <span className="filepond--label-action"> Przeglądaj </span>'}
                            labelFileProcessingComplete={'Przesyłanie zakończone'}
                            labelFileProcessing={'Przesyłanie...'}
                            labelTapToRetry={'Ponów'}
                            labelTapToCancel={'Anuluj'}
                            onprocessfile={this.odswiez}
                            oninit={() => this.handleInit() }
                            onupdatefiles={fileItems => {
                                 // Set currently active file objects to this.state
                                this.setState({
                                    files: fileItems.map(fileItem => fileItem.file)
                                });
                          }}>
                        </FilePond> */}

                        {/* <input type="file"  id="file" onChange={this.uploadFile} /> */}


                        <div className="input-group">
  <div className="input-group-prepend">
    
  </div>
  <div className="custom-file">
    <input type="file" className="custom-file-input" id="inputGroupFile01"
      id="file"  onChange={this.uploadFile}/>
    <label className="custom-file-label" htmlFor="inputGroupFile01">{this.state.nazwapliku}</label>
  </div>
</div>


                        {/* <div>
                            <button onClick={this.deb}>FTP</button>
                        </div> */}
                        <div style={{display:this.state.vis}} className="media ramka">
                            <img src={this.state.purl} alt={this.state.palt} className="media podglad" />
                            <div className="media podpis">{this.state.palt}</div>
                            <span dangerouslySetInnerHTML={{__html: this.state.exif}} />
                            <div className="btn-group">
                                <button className="btn btn-danger btn-sm" onClick={this.submit}>Usuń</button>
                                <button className="btn btn-secondary btn-sm" onClick={this.zamknij}>Zamknij</button>
                            </div>
                        </div>
               </div> 
               <div className="col-sm-9">
                   <h3>Lista załączonych dokumentów użytkownika</h3>
                   <div className="doccontainer">
                          {this.state.pliki.map((key, i)=>(
                              
                              <span key={i} className="document"   data-name={key.name}>{key.name} <span  className="fdownload" data-name={key.name} onClick={this.download} target="_blank" rel="noopener noreferrer" href={server+"/download/"+key.name}><img data-name={key.name}  src={dload} alt="Pobierz" /></span>  <span data-name={key.name} onClick={this.unlinkFile}><img src={niszcz} data-name={key.name}  alt="Zniszcz" /></span></span>
                          ))}
                    
                   </div>
               </div>
           </div>
        </div>)
    }
}


export default Documents;