function version(){return "1.0";}

function control(d)
{
    var odp="";
    switch (d)
    {
        case 1:odp="Kontrolowany przez aplikację";break;
        case 2:odp="Kontrolowany przez Bank";break;
        case 3:odp="Kontrolowany przez aplikację";break;
        default:break;
    }
    return odp;
}

function adnotacja(a)
{
    if (a===""|| a===null){return "Brak adnotacji wprowadzonej przez Bank."}else{return a}
}

function groupMessages(mes)
{
    var konsul=0,wnio=0, proced=0, uzup=0, rozp=0,zak=0
    for (var a=0;a<mes.length;a++)
    {
        switch (mes[a].status.trim())
        {
            case 'Prośba o konsultację':konsul=mes[a].ile;break;
            case 'Wniosek złożony':wnio=mes[a].ile;break;
            case 'Procedowany':proced=mes[a].ile;break;
            case 'Do uzupełnienia':uzup=mes[a].ile;break;
            case 'Rozpatrzone':rozp=mes[a].ile;break;
            case 'Zakończony':zak=mes[a].ile;break;
            default:break;
        }
        
    }
    var odp = {konsul:konsul,wnio:wnio, proced:proced, uzup:uzup, rozp:rozp,zak:zak};
        return odp; 
}
function fmtMSS(s){return(s-(s%=60))/60+(9<s?':':':0')+s}

function calculateAge(birthday) { // birthday is a date
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}
function imie(str)
{
    if (str.trim()===""){return "Nie wprowadzono";}else{return str;}
}

function numberWithSpaces(x) {
    if (typeof x === 'undefined'){return "???";}
    if (x.toString===""){return "";}
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
} 
function IsJsonString(str) {try {JSON.parse(str);} catch (e) {return false;}return true;}
module.exports = {
    version,
    control,
    adnotacja,
    fmtMSS,
    groupMessages,
    calculateAge,
    IsJsonString,
    imie,
    numberWithSpaces,
}
