import React from 'react';
import axios from 'axios';
import security from './../common/security.js';
import Cookies from 'js-cookie';
import ReactTable from 'react-table';
import XML from './../common/xmlparser.js';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

class Autoapp extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ManualFormList:[],loading:true,page:0, singleForm:{},odpowiedz:'',allsingleForm:{},data:"", odp:{}, from:"", to:"",text:"",
        };
       this.ask = this.ask.bind(this);
       this.loadData = this.loadData.bind(this);
       this.autoloadData = this.autoloadData.bind(this);
       this.MyCell = this.MyCell.bind(this);
       this.buttonRouter = this.buttonRouter.bind(this);
       this.submit = this.submit.bind(this);
    }
    submit = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h3>Czyszczenie nieużytecznych danych</h3>
                  <small>Automatycznie złożone wnioski, które pozostają bez oceny są danymi bezuytecznymi i zaleca się okresowe czyszczenie danych. Należy pamiętać, że operacja jest nieodwracalna.</small>

                  <div>
                      <div className="mcol s2"></div>
                      <div className="mcol s4">Od dnia:<input type="date"  data-action="setOd" onChange={this.buttonRouter} className="form-control" /></div>
                      <div className="mcol s4">Do dnia:<input type="date"    data-action="setDo" onChange={this.buttonRouter} className="form-control" /></div>
                      <div className="mcol s2"></div>
                  </div>
                  
                  <button className="btn btn-secondary btn-sm" onClick={onClose}>Anuluj</button>
                  &nbsp;
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => {
                      this.ask("deleteInutile", JSON.stringify({from:this.state.from, to:this.state.to}));
                      onClose();
                    }}
                  >
                    Usuń
                  </button>
                </div>
              );
            }
          });}


    autoloadData(){if (this.state.loading){this.ask("getManualFormList", null);}}
    loadData(ev){this.ask("getManualFormList", null);}

    ask(resource, data=null)
    {
        
        var self = this; const params = new URLSearchParams(); params.append('asc',security.securityKey()); params.append('section', 'admin'); params.append('command', resource); params.append('data', data); params.append('token', Cookies.get("Experimental"));
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            console.log(response.data);
            if (response.data.status==="OK")
            {
                switch (resource){
                    case 'getManualFormList': self.setState({ManualFormList: response.data.data, loading:false});return;
                    case 'getSingleForm':
                        var form = response.data.data[0].form;
                        form = form.split('}"').join("}").split('"{').join("{");
                        //console.log("odp", response.data.data[0].response);
                        if (XML.IsJsonString(form)){form=JSON.parse(form);}
                        
                        self.setState({singleForm:form, allsingleForm:response.data.data, data:response.data.data[0].data, odp: response.data.data[0].response});
                        break;

                    case 'changeBankStatus':self.setState({ManualFormList: response.data.data, loading:false});break;
                    case 'deleteInutile': self.ask('getManualFormList', null);break;
                    default: return;
                }
            }
            self.setState({odpowiedz:response.data.desc});
        }).catch(function (error) {console.log(error); self.setState({odpowiedz:"ERR"});});
    }
    buttonRouter(ev)
    {
        var action = ev.target.dataset.action; var id = ev.target.dataset.id;
        //console.log("ButtonRouter",action, id, ev.target.value);
        var self = this;
        switch (action)
        {
            case 'info':self.ask("getSingleForm", id);self.setState({page:1});break;
            case 'return':self.setState({page:0});break;
            case 'setOd':self.setState({from: ev.target.value});break;
            case 'setDo':self.setState({to: ev.target.value});break;
            case 'text':self.setState({text: ev.target.value});break;
            case 'odpBank':
                self.ask("changeBankStatus", JSON.stringify({id:self.state.allsingleForm[0].id, status:ev.target.value}));
                self.setState({odp: ev.target.value});
                
                break;
            default:break;
        }
        //console.log("STATE",this.state);

    }
    MyCell({ value, columnProps: { rest: { somefunc } } }) {
        return (
            <div className="btn-group" role="group" aria-label="Basic example">
                <button type="button" className="btn btn-secondary btn-sm" data-id={value} data-action="info" onClick={this.buttonRouter}>Info</button>
            </div>
        )
      }

    FormCell({ value, columnProps: { rest: { somefunc } } }) {

        var val = value.split('}"').join("}").split('"{').join("{");
        var formData = {adr:"",data:{form:{formID:"Wniosek bez formularza"}}};
        var ide="wniosek";
        if (typeof formData.data.form.formID !== undefined){ide = formData.data.form.formID;}
        if (XML.IsJsonString(val)){formData = JSON.parse(val);}
        
        return (
            <div>IP:{formData.adr} - {ide}<br></br><span dangerouslySetInnerHTML={{__html:XML.contactsFromForm(formData)} }></span></div>
        )
    }
    BankResponse({ value, columnProps: { rest: { somefunc } } }) {

        var x = ["Brak danych", "Pozoytywna", "Negatywna", "Akcja pozucona"];
        
        return (
            <span>{x[parseInt(value)]}</span>
        )
    }
    drawResponseForm(key, val)
{
    var odp = val;
    var k = key;
    switch (key)
    {
        case 'wojewodztwo': k = "Województwo"; odp = XML.id2woj(val); break;
        case 'datar': k="Data rozpoczęcia działalności";break;
        case 'formID': k="Nazwa szablonu";break;
        case 'ksiegowosc': k="Rodzaj prowadzonej księgowości"; odp = XML.id2ksiegowosc(val);break;
        case 'wielkosc': k="Wielkość"; odp = XML.id2wielkosc(val);break;
        case 'pkd': k="Kod PKD";break;
        case 'miejscowosc': k="Miejscowość";break;
        case 'nazwa': k="Nazwa firmy";break;
        case 'zgoda1':odp = XML.id2zgoda(val);k="akceptacja regulaminu";break;
        case 'zgoda2':odp = XML.id2zgoda(val);k="zgoda na przetwarzanie danych";break;
        case 'zgoda3':odp = XML.id2zgoda(val);k="zgoda na informację marketingową";break;
        case 'zgoda4':odp = XML.id2zgoda(val);k="Zgoda na kontakt telefoniczny"; break;
        case 'rodzaj':odp = XML.id2dzialalnosc(val);    k="Rodzaj prowadzonej działalności";break; 
        case 'osoba': k="Osoba kontaktowa";break;
        case 'tel': k="numer telefonu";break;
        case 'mail': k="Adres mail";break;
        case 'kod': k="Kod pocztowy";break;

        default:break;
    }
    
    return (<div className="mcol s4"><small>{k}</small><br></br>{XML.san(odp)}</div>)
}
    render(){
        const {ManualFormList, loading}  = this.state;
        const columns = [
            {
          Header: 'ID',
          accessor: 'id',
          filterMethod: (filter, row) =>
                  row[filter.id].startsWith(filter.value) ||
                  row[filter.id].endsWith(filter.value)
        }, 
            {
          Header: 'Data wysłania wniosku',
          accessor: 'data',
          Cell: "GGG",
          filterMethod: (filter, row) =>
                  row[filter.id].startsWith(filter.value) ||
                  row[filter.id].endsWith(filter.value)
        },
        {
          Header: 'Dane z formularza',
          accessor: 'form',
          Cell: this.FormCell, getProps: () =>({ }),
          filterMethod: (filter, row) =>
                    row[filter.id].includes(filter.value)
                //   row[filter.id].startsWith(filter.value) ||
                //   row[filter.id].endsWith(filter.value)
        },
        {
          Header: 'Odpowiedź banku',
          accessor: 'response',
          Cell: this.BankResponse, getProps: () =>({ })
        //   filterMethod: (filter, row) =>
        //           row[filter.id].startsWith(filter.value) ||
        //           row[filter.id].endsWith(filter.value)
        },
        {
          Header: '#',
          accessor: 'id', // String-based value accessors!
          filterable:false,
          Cell: this.MyCell, getProps: () => ({ })
        },];

        if (this.state.page===0)
        {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        
                <h3>Obsługa formularzy automatycznych</h3>
                <button onClick={this.loadData} >Dane</button>
                
                <button data-action="clearInutylity" onClick={this.submit} >Czyść</button>
                <ReactTable
                            data={ManualFormList}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                            columns={columns}
                            loading={loading}
                            onFetchData={this.autoloadData}
                            previousText={'Poprzednia'}
                            nextText={'Następna'}
                            loadingText={ 'Ładowanie danych...'}
                            noDataText={ 'Nie znaleziono danych'}
                            pageText={'Strona'}
                            ofText={'z'}
                            rowsText={'wierszy'}
                            
                            // Accessibility Labels
                            pageJumpText={'skocz do strony'}
                            rowsSelectorText= {'wierszy na stronę'}
                            defaultPageSize={10}
                        />
                {/* {JSON.stringify(this.state.ManualFormList)} */}

                            </div>
                        </div>
                    </div>   
                )
                            }
                            else if (this.state.page===1)
                            {
                                var form = {}; var keys=[];
                                if (typeof this.state.singleForm.data !== 'undefined' && this.state.singleForm.data!==null)
                                {
                                    form = this.state.singleForm.data.form;
                                    if (typeof form !=='undefined' && form !== null)
                                   { keys = Object.keys(form);}
                                }
                                return (
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h3>Szczegóły wniosku automatycznego</h3>
                                                <div>Adres z którego dokonano zgłoszenia: {this.state.singleForm.adr}, data zgłoszenia: {this.state.data}</div>
                                                <div className="mcol s3"><b>Odpowiedź banku:</b><br></br>
                                                <select className='mform ' data-action="odpBank" value={this.state.odp} onChange={this.buttonRouter}>
                                                    <option value="0">Brak danych</option>
                                                    <option value="1">Pozytywna</option>
                                                    <option value="2">Negatywna</option>
                                                    <option value="3">Porzucono bez podjęcia działań</option>
                                                </select>
                                                </div>

                                                <div className="mcol s9"><b>Narzędzia:</b><br></br>
                                                <div className="wniostools">
                                                    <button type="button" className="mbtn bttn-default" data-action="return" onClick={this.buttonRouter}>←</button>
                                                    <button className='mbtn bttn-default' data-id={this.state.allsingleForm.id} data-action="saveForm" onClick={this.buttonRouter}>⦻</button>
                                                </div>
                                                </div>
                                                <div><b>Pola formularza:</b><br></br>
                                                {keys.map((key, i)=>(
                                                    <span key={i}>{this.drawResponseForm(key, form[key])}</span>
                                                ))}
                                                
                                                </div>
                                               
                                                {JSON.stringify(this.state.allsingleForm)}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
        }
}

export default Autoapp;