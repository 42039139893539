import React  from "react";
// import img1 from './../../img/';
import {Animated} from "react-animated-css";
// import Slider from 'react-animated-slider';
// import 'react-animated-slider/build/horizontal.css';


class Landingtop extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};


    }
    
    render(){


        return (
        <div className="l1">
            <div className="wrapper">
                <div className="section parallax">
                    <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={true}>
                        <div className="partext">Nie czekaj na spadającą gwiazdkę. Pomyśl życzenie i pozwól nam o Ciebie zadbać<br></br>
                            <a href="#firma" className="nolink"><span className="partbutton2"><span className="partbutton"> Dla firm</span></span></a>
                            <a href="#osoba" className="nolink"><span className="partbutton2"><span className="partbutton"> Dla osób prywatnych</span></span></a>
                            <a href="#dodatkowe" className="nolink"><span className="partbutton2"><span className="partbutton"> Usługi dodatkowe</span></span></a>

                            <br></br>
                        
                        </div>
                    </Animated>
                  

                </div>
                
            </div>
        </div>);
        
}

}

export default Landingtop;