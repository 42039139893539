import React  from "react";
import axios from 'axios';
import security from './../common/security.js';
import Cookies from 'js-cookie';
import { MdSend } from "react-icons/md";
import VAL from './../common/validators.js';


class ContactForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            forma:"mail", numer:"", temat:"",tresc:"", odpowiedz:'czekam'
            
        };
        this.readForm = this.readForm.bind(this);
        this.wyslij = this.wyslij.bind(this);
    }
    readForm(e)
    {
        //console.log(e.target.id);
        switch (e.target.id)
        {
            case 'forma':this.setState({forma: e.target.value});break;
            case 'numer':this.setState({numer: e.target.value});break;
            case 'temat':this.setState({temat: e.target.value});break;
            case 'tresc':this.setState({tresc: e.target.value});break;
            case 'send':
                
            var flag = true;
                var pola = document.getElementsByClassName("form-control");
                for (var a = 0; a<pola.length; a++)
                {
                    if (pola[a].value===""){pola[a].classList.add("red"); flag=false}
                    else {pola[a].classList.remove("red");}
                }
                var mail = document.getElementById("numer");
            if (document.getElementById('forma').value==="mail")
            {
                
                if (!VAL.validateEmail(mail.value)){mail.classList.add("red");flag=false;}
            }
            else
            {
                if (!VAL.validateTel(mail.value)){mail.classList.add("red");flag=false;}
            }
            if (flag){this.wyslij();}
            default:break;
        }
    }

    wyslij()
    {
        var self = this;
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
        params.append('section', 'aplikacja');
        params.append('command', 'formularz');
        params.append('forma', self.state.forma);
        params.append('numer', self.state.numer);
        params.append('temat', self.state.temat);
        params.append('tresc', self.state.tresc);
        params.append('token', Cookies.get('Experimental'));
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            console.log(response.data);
            self.setState({odpowiedz:response.data.status});
        }).catch(function (error) {console.log(error); self.setState({odpowiedz:"ERR"});});
    }
    render(){
        ////console.log("CF===");
        if (this.state.odpowiedz==='czekam'){
        return (
        <div className="container">
            
            <div className="row">
                <div className="col-sm-12">
                    <h2>Formularz kontaktowy</h2>
                    <hr className="prettyhr"></hr>
                    <small>Wypełnij poniższe pola, napisz pytanie lub omów zagadnienie, na które chcesz uzyskać więcej informacji.
                        Wysyłając formularz zgadzasz się na przetwarzanie danych, wybraną formę kontaktu w zakresie potrzebnym do 
                        udzielenia odpowiedzi na wybraną kwestię. 
                    </small>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <label>Forma kontaktu:</label>
                    <select className="form-control" value={this.state.forma} id="forma" onChange={this.readForm}>
                        <option value="mail">e-mail</option>
                        <option value="tel">Telefon</option>
                    </select>
                    </div> 
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        <label >Numer telefonu lub e-mail:</label>
                        <input type="text" className="form-control" value={this.state.numer} id="numer" onChange={this.readForm}/>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label >Temat:</label>
                        <input type="text" className="form-control" value={this.state.temat} id='temat' onChange={this.readForm}/>
                    </div>
                </div> 
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group">
                        <label>Treść wiadomości:</label>
                        <textarea className="form-control" rows="5" value={this.state.tresc} id="tresc" onChange={this.readForm}></textarea>
                    </div> 
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <button className="btn btn-secondary" id="send" onClick={this.readForm}>Wyślij <MdSend/> </button>
                </div>
            </div>
        </div>
    );}
    else if (this.state.odpowiedz==="ok"){
        return (
            <div className="container">
           
            <div className="row">
                <div className="col-sm-12">
                    <div className="odp">
                        <h3>Formularz przesłano pomyślnie.</h3> Oczekuj kontaktu od jednego z naszych pracowników pod {this.state.numer}<br></br>
                    </div>
                </div>
            </div>
            </div>
        );
    }
    else {
        return (<div className="container">
            
            <div className="row">
                <div className="col-sm-12">
                    <div className="odp">
                        <div className="alert alert-danger">
                            <strong>Błąd HTTP:</strong> Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.
                        </div>
                        
                    </div>
                </div>
            </div>
            </div>);
    }
}
}

export default ContactForm;