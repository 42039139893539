import React  from "react";
import axios from 'axios';
import security from './../common/security.js';
import Cookies from 'js-cookie';

// import IntroMain from './introform_main.js';
import Introformintro from './introform_intro.js';
import Intromaindata from './Intro_maindata.js';

import Introformone from './introform_one.js';
import Introformtwo from './introform_two.js';
import Introformtre from './introform_tre.js';
import Introformfour from './introform_four.js';
import Introformsum from './introform_sum.js';
import DoUzupelnieniaWnioski from './douzupelnieniawnioski.js';

import ZlozoneWnioski from './zlozonewnioski.js';
import RozpatrzoneWnioski from './rozpatrzonewnioski.js';
import ZakonczoneWnioski from './zakonczoneewnioski.js';
import Loader from './../loader.js';

import {GiFactory} from "react-icons/gi";
import Faqfirma from './faqfirma.js';
import Kula from './kula.js';
import San from './../admin/san.js';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import {GoDashboard, GoPerson, GoQuestion, GoArrowBoth, GoOrganization, GoArchive, GoClippy} from 'react-icons/go';
class Intro extends React.Component{
    constructor(props) {
        super(props);
        this.state = {zmiany:false,
            p2:'', p3:'', p4:'', p5:'', introtext:"Wygląda na to, że gościmy Ciebie po raz pierwszy. Aby w pełni móc korzystać z naszej platformy należy wypełnić poniższy formularz. To zajmie kilka chwil. ",
            widok:0,
            step:1, suggestions: [], value: '', loaded:false, renderstep: "", kodyPKD:[], firma:[],
            zgoda1:false,zgoda2:false,zgoda3:false, zgoda4:false,server:"",
            pkd:"", nip:"", nazwa:'', wojewodztwo:'',krs:'',
            powiat:'', gmina:'', miejscowosc:'', kod:'', regon:'', ulica:'', lokal:'', forma:'',
            imienazwisko:'', telefon:'', mail:'', ksiegowosc:0,staz:'',model:0, finanse:[],
            kwota1:"",krota2:"", kwota3:"", kwota4:"",hbpoints:0, konto:'', stan_konta:"",
            formularz:{},currentForm:0,
        };
        this.changeStep = this.changeStep.bind(this);
        this.validate = this.validate.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.stepone = this.stepone.bind(this);
        this.strony = this.strony.bind(this);
        this.savepageone = this.savepageone.bind(this);
        this.obliczZK = this.obliczZK.bind(this);
        this.loadPKD = this.loadPKD.bind(this);
        this.closeForm = this.closeForm.bind(this);
        this.showForm = this.showForm.bind(this);
        this.toggleFront = this.toggleFront.bind(this);
        this.checkIban = this.checkIban.bind(this);
        this.komunikat = this.komunikat.bind(this);
        this.dumpState = this.dumpState.bind(this);
        this.changeFirma = this.changeFirma.bind(this);
        this.getForm = this.getForm.bind(this);
    }
    server = () => { confirmAlert({  customUI: ({ onClose }) => { return ( <div className='custom-ui'><h3>Komunikat serwera</h3><p>{this.state.server}</p> <button className="btn btn-danger btn-sm" onClick={onClose} >Zamknij</button>  </div> ); } });}

    komunikat(ev)
    {
        //console.log("KOMUNIKAT", ev);
        this.setState({server:ev});
        var self = this;
        setTimeout(function(){self.server();}, 50);
    }
    dumpState()
    {
        console.log(this.props);
    }
    obliczZK()
    {
        //console.log("FINANSE DO LICZENIA", this.state.finanse);
        var czas = new Date();
        var rok = czas.getFullYear();
        var miesiecy = 0;
        var forsa = 0;
   
        for (var a = 0; a< this.state.finanse.length; a++)
        {
            if (this.state.finanse[a].rok===rok || this.state.finanse[a].rok===(rok-1) ||this.state.finanse[a].rok===(rok-2))
            {
                forsa +=  parseInt(this.state.finanse[a].przychod) + parseInt(this.state.finanse[a].amortyzacja) - parseInt(this.state.finanse[a].koszty);
            
                if (this.state.finanse[a].rok === rok) { miesiecy += czas.getMonth();} else {miesiecy+=12;}
                ////console.log("LICZYDŁO",parseInt(this.props.finanse[a].przychod), parseInt(this.props.finanse[a].amortyzacja),  parseInt(this.props.finanse[a].koszty),miesiecy, forsa);
            }
        }

        /// RAW FORSA
        var zd = forsa/miesiecy;
        //if (isNaN(zd)){zd='Brak danych';}
        var odchylkaPKD = this.danePKD();
        switch (odchylkaPKD)
        {
            case '-':zd = zd *0.9;break;
            case '+':zd = zd *1.1;break;
            default : break;
        }
        zd = parseFloat(zd).toFixed(2);
        //console.log("SUMA", zd,forsa, miesiecy, odchylkaPKD);
        this.setState({zdolnosc:zd});
        return zd;
    }

    danePKD()
    {
       
        ////console.log("RENDER_PKD");
        var pkd = this.state.kodyPKD;
        var szukane = this.state.pkd;
        //console.log("DANE_PKD",pkd, szukane)
        ////console.log("zgody", this.props.zgoda1, this.props.zgoda2, this.props.zgoda3,this.props.zgoda4);
        console.log(szukane, pkd.length);
        for (var a = 0; a<pkd.length; a++)
        {
            
            if (pkd[a].numer.trim() === szukane.trim()){
                //console.log("Znaleziony", pkd[a])
                return pkd[a].trend;}
        }
        
    }
    savepageone()
    {
        var id = 0;
        var zd=this.obliczZK();
        if (this.state.zmiany)
        {

            this.setState({zmiany:false});
            if (typeof this.state.firma!=='undefined'){if (typeof this.state.firma[0]!=='undefined'){id = this.state.firma[0].id;}}
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            //console.log(this.state.finanse);
            var self = this;
            params.append('section', 'firma');
            params.append('command', 'page1');
            params.append('token', Cookies.get("Experimental"));
            params.append("data", JSON.stringify({firma:id, 
                zgody:{zgoda1:this.state.zgoda1, zgoda2:this.state.zgoda2, zgoda3:this.state.zgoda3, zgoda4:this.state.zgoda4}, 
                adres:{nazwa:this.state.nazwa, wojewodztwo:this.state.wojewodztwo, powiat:this.state.powiat, gmina:this.state.gmina,
                miejscowosc:this.state.miejscowosc, kod:this.state.kod, regon:this.state.regon, ulica:this.state.ulica,
                lokal:this.state.lokal, forma: this.state.forma},
                osoba:{nazwisko:this.state.imienazwisko, telefon:this.state.telefon, mail:this.state.mail},
                finanse:this.state.finanse,
                model:{model:this.state.model, ksiegowosc:this.state.ksiegowosc, staz:this.state.staz},
                konto:this.state.konto,
                pkd: this.state.pkd, nip:this.state.nip, zd:zd}));
            //var self = this;
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            console.log(response.data);
            self.komunikat("Dane zostały zapisane poprawnie.");
            //self.setState({kodyPKD: response.data});
            }).catch(function (error) {console.log(error);});
        }
    }
    loadUserData()
    {

    }
    validatenip(nip) {
        var nipWithoutDashes = nip.replace(/-/g,"");
        var reg = /^[0-9]{10}$/;
        if(reg.test(nipWithoutDashes) === false) {
            return false;}
        else
        {
            var digits = (""+nipWithoutDashes).split("");
            var checksum = (6*parseInt(digits[0]) + 5*parseInt(digits[1]) + 7*parseInt(digits[2]) + 2*parseInt(digits[3]) + 3*parseInt(digits[4]) + 4*parseInt(digits[5]) + 5*parseInt(digits[6]) + 6*parseInt(digits[7]) + 7*parseInt(digits[8]))%11;
             
            return (parseInt(digits[9])===parseInt(checksum));
        }
    }
    validate(e)
    {
        //console.log(e.target.value);
        // if (this.validatenip(e.target.value)){//console.log("NIP OK");}else{//console.log("O CHUJ");}
    }
    komplet(strona)
    {
        // switch (strona)
        // {
        //     case '1':if (this.state.pkd!=='' && this.validatenip(this.state.nip)){return true;}else{return false;}
        // }
    }
    strony(ev)
    {
        if (ev.target.id==="minus")
        {
            if (this.state.step>1)
            {
                this.setState({step: this.state.step-1});
            }
        }
        if (ev.target.id==="plus")
        {
            if (this.state.step<5)
            {
                document.getElementById(this.state.step+1).classList.add("active");
                this.setState({step: this.state.step+1});
                
            }
        }
    }
    changeStep(e){
        //(e.target.classList);
        
        if (e.target.classList[1]==='active')
        {
            this.setState({step:parseInt(e.target.id)});
        }
        if (this.state.step+1 === parseInt(e.target.id))
        {
            this.setState({step: this.state.step+1});
            e.target.classList.add("active");
        }
        this.savepageone();
        
    }
    loadPKD()
    {
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
        params.append('section', 'aplikacja');
        params.append('command', 'getPKD');
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
        console.log(response.data);
        self.setState({kodyPKD: response.data});
    }).catch(function (error) {console.log(error);});
    }

    IsJsonString(str) {try {JSON.parse(str);} catch (e) {return false;}return true;}
    isBoolean(b){if (b==="true"){return true;}else{return false;}}
    readFirma(cf)
    {  

        if (typeof this.props.firma !=='undefined'){
            if (typeof this.props.firma[cf] !=='undefined'){
            ////console.log("PARSE FIRMA");
            this.setState({nip: this.props.firma[cf].nip});
            this.setState({pkd: this.props.firma[cf].pkd, konto:this.props.firma[cf].konto});
            if (this.IsJsonString(this.props.firma[cf].zgody))
            {
                var zgody = JSON.parse(this.props.firma[cf].zgody);
                if (zgody.zgoda1==='false'){
                    this.setState({introtext:"Oświadczenie o zapoznaniu się z regulaminem jest konieczne, aby móc korzystać z serwisu."})
                }
                this.setState({
                    step:2,
                    introtext:"Dbaj o to, aby dane w formularzu były aktualne i odpowiadały rzeczywistości. ",
                    zgoda1: this.isBoolean(zgody.zgoda1),
                    zgoda2: this.isBoolean(zgody.zgoda2),
                    zgoda3: this.isBoolean(zgody.zgoda3),
                    zgoda4: this.isBoolean(zgody.zgoda4)
                });
            }
            if (this.IsJsonString(this.props.firma[cf].adres))
            {
                //console.log(this.props.firma[cf].adres);
                //document.getElementById("2").classList.add("active");
                var adres = JSON.parse(this.props.firma[cf].adres);
                //console.log(adres);
                this.setState({
                    step:3,
                    p2:"active",
                    p3:"active",
                    nazwa: adres.nazwa,
                    wojewodztwo: adres.wojewodztwo,
                    powiat: adres.powiat,
                    gmina:adres.gmina,
                    miejscowosc: adres.miejscowosc,
                    kod:adres.kod,
                    regon:adres.regon,
                    ulica:adres.ulica,
                    lokal:adres.lokal,
                    forma:adres.forma
                });
                
            }
            if (this.IsJsonString(this.props.firma[cf].osoba))
            {
                //console.log(this.props.firma[cf].osoba);
                var osoba = JSON.parse(this.props.firma[cf].osoba);
                
                this.setState({
                    step:4,
                    p3:"active",
                    imienazwisko: osoba.nazwisko,
                    telefon: osoba.telefon,
                    mail:osoba.mail

                });
            }
            if (this.IsJsonString(this.props.firma[cf].model))
            {
                ////console.log("MODEL", JSON.parse(this.props.firma[cf].model));
                var model = JSON.parse(this.props.firma[cf].model);
                this.setState({
                    model: model.model,
                    staz: model.staz,
                    ksiegowosc: model.ksiegowosc
                })
            }
            if (this.IsJsonString(this.props.firma[cf].finanse))
            {
                ////console.log("FINASE",JSON.parse(this.props.firma[cf].finanse));
                this.setState({
                    step:5,p4:"active",p5:"active",
                    finanse:JSON.parse(this.props.firma[cf].finanse),
                });
            }
            this.setState({nip: this.props.firma[cf].nip});
            //console.log(zgody);

        }}
    }
    colorize()
    {
        ////console.log("COLOR");
        if (this.props.step<1){document.getElementById('2').classList.add("active");}
    }
    closeForm()
    {
        this.setState({widok:0});
    }
    showForm()
    {
        this.setState({widok:1});
    }
    componentDidMount(){
        ////console.log("PPKD-PROPS",this.props.kodyPKD);
        this.setState({firma: this.props.firma});
        ////console.log("FirmaPROPS", this.props.firma);
        this.readFirma(0);
        
        //this.loadPKD();
        this.setState({loaded: true});
        //window.setTimeout(this.colorize(),5000);
    }
    checkIban()
    {
        var xiban = this.state.konto;
        var CODE_LENGTHS = {
            AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
            CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
            FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
            HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
            LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
            MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
            RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
        };
        var iban = String(xiban).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
                code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
                digits;
        // check syntax and length
        if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
            return false;
        }
        // rearrange country code and check digits, and convert chars to ints
        digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
            return letter.charCodeAt(0) - 55;
        });
        // final check
        //console.log("IBAN",iban, this.mod97(digits));
        var odp = this.mod97(digits);
        if (!odp){this.setState({stan_konta:"Niepoprawny bądź niekompletny numer konta"});}
        return this.mod97(digits);
    }
    
    mod97(string) {
        var checksum = string.slice(0, 2), fragment;
        for (var offset = 2; offset < string.length; offset += 7) {
            fragment = String(checksum) + string.substring(offset, offset + 7);
            checksum = parseInt(fragment, 10) % 97;
        }
        return checksum;
       
    }
    stepone = event => {
        const {name, value, checked} = event.target;
        //console.log(name, value, checked);
        this.setState({zmiany:true});
        if (name.substring(0, 5)==="zgoda")
        {
            this.setState({
                [name]: checked
              }) 
        }
        else if (name.substring(0, 5)==="akcja")
        {
            var tablica;
            if (name==='akcja-dodaj')
            {
                //console.log(event.target.parentElement.parentElement.children);
                var dane = event.target.parentElement.parentElement.children;
                var row = {rok: parseInt(dane[0].innerHTML),
                    przychod: parseFloat(dane[1].innerHTML).toFixed(2),
                    koszty: parseFloat(dane[2].innerHTML).toFixed(2),
                    amortyzacja: parseFloat(dane[3].innerHTML).toFixed(2),
                }
                tablica = this.state.finanse;
                tablica.push(row)
                this.setState({
                    finanse: tablica
                }); 
            }
            
            if (name==='akcja-usun')
            {
                //console.log(event.target.id);
                tablica = this.state.finanse;
                var newTablica=[];
                for (var a=0; a<tablica.length; a++)
                {
                    if (a!==parseInt(event.target.id)){newTablica.push(tablica[a]);}
                }
                console.log(newTablica);
                this.setState({
                    finanse: newTablica,
                    kwota1:"",krota2:"", kwota3:"", kwota4:""
                }); 
                //tablica.slice()
            }
        }
        else
        {console.log(name, value, checked);
        this.setState({
          [name]: value
        })   } 
      }


    getForm(a)
    {
        if (this.props.currentForm!==a)
        {
            this.props.ask("firma", "getFormula", a);
        }
    }
    toggleFront(ev)
    {
        
        var widok = ev.target.dataset.widok;
        if (typeof (widok)==="undefined"){widok = ev.target.parentElement.dataset.widok;}
        if (typeof (widok)==="undefined"){widok = ev.target.parentElement.parentElement.dataset.widok;}
        if (typeof (widok)==="undefined"){widok = ev.target.parentElement.parentElement.parentElement.dataset.widok;}
        if (typeof (widok)==="undefined"){widok = ev.target.parentElement.parentElement.parentElement.parentElement.dataset.widok;}
        // if (widok==="1"){this.setState({widok:1});}
        // if (widok==="2"){this.setState({widok:2});}
        // if (widok==="3"){this.setState({widok:3});}
        // if (widok==="4"){this.setState({widok:1, step:4});}
        this.setState({widok: parseInt(widok)});
        this.getForm(widok);
    }
    changeFirma(ev)
    {
        var dane = JSON.parse(ev.target.dataset.dane);
        var action = ev.target.dataset.action;
        var status = ev.target.dataset.status;
        //console.log("ACTION", action, status);
        var obj;
        //console.log("ChangeFirma", dane);
        var main = this.state.firma;
        if (typeof main[0].main === "undefined" || main[0].main === null)
        {
            obj = dane;
        }
        else
        {
            obj = Object.assign(main[0].main, dane)
        }
        
        if (main !==obj)
        {
            var aa = this.state.firma;
            aa[0].main = obj;
            this.setState({firma:aa});
            var self = this;
            switch (action)
            {   
                case "universalForm":self.props.ask("firma", "saveMainData", JSON.stringify(dane));break;
                case "universalFormSender":self.props.ask("firma", "saveMainDataAndWniosek", JSON.stringify([dane, status]));break; 
                default:break;
            }
     
        }
        
    }
    render(){
        ////console.log("LG===");
        if (typeof this.state.kodyPKD ==='undefined'){
            return (<Loader/>)
        }
        else
        {
            
            var daneFirmy = this.state.firma;
            if (typeof daneFirmy[0] === "undefined" || daneFirmy[0]===null){daneFirmy={};}
            else
            {
                if (typeof daneFirmy[0] === "undefined" || daneFirmy[0]===null){daneFirmy={};} 
                else
                {
                    if (typeof daneFirmy[0].main === "undefined"  || daneFirmy[0]===null){daneFirmy={};} 
                    else{daneFirmy = daneFirmy[0].main}
                }   
            }      
            if (daneFirmy===null){daneFirmy = {};}
                            // var firma = {adres:""};
                            // if (this.IsJsonString(this.props.firma[0].adres))
                            // {firma = JSON.parse(this.props.firma[0].adres);}
                            var renderMenu = ["side-menu-item", "side-menu-item", "side-menu-item", "side-menu-item", "side-menu-item", "side-menu-item", "side-menu-item", "side-menu-item", "side-menu-item", "side-menu-item", "side-menu-item", "side-menu-item", "side-menu-item", "side-menu-item", "side-menu-item", "side-menu-item", "side-menu-item", "side-menu-item", "side-menu-item", "side-menu-item"];
                            renderMenu[this.state.widok]= "side-menu-item active";
                            return (
                                <div>
                                    
                                <div className="appcontainer">
                                    <div className="row">
                                        {/* ======================= SIDE MENU ======================= */}
                                        <div className="col-sm-3">
                                           <div className="side-menu">
                                                <div className="text-center">
                                                    <GiFactory className="bigIcon" onClick={this.dumpState}/>
                                                   <div><San text={this.props.firma[0].main.nazwa} /></div>
                                                </div>
                                                <div className={renderMenu[0]} data-widok="0" onClick={this.toggleFront}><GoDashboard className="panelicons"/> Pulpit</div>
                                                <div className={renderMenu[10]} data-widok="10" onClick={this.toggleFront}><GoQuestion className="panelicons"/> FAQ</div>
                                                <div className={renderMenu[12]}  data-widok="12" onClick={this.toggleFront}><GoPerson className="panelicons"/> Dane firmy</div>
                                                {/* <div className={renderMenu[1]}  data-widok="1" onClick={this.toggleFront}>Zgody</div>
                                                <div className={renderMenu[2]}  data-widok="2" onClick={this.toggleFront}>Nowy ...</div>
                                                <div className={renderMenu[2]}  data-widok="2" onClick={this.toggleFront}>Dane teleadresowe firmy</div>
                                                <div className={renderMenu[3]}  data-widok="3" onClick={this.toggleFront}>Osoba kontaktowa</div>
                                                <div className={renderMenu[4]}  data-widok="4" onClick={this.toggleFront}>Dane finansowe</div>
                                                <div className={renderMenu[6]}  data-widok="6" onClick={this.toggleFront}>Podsumowanie danych</div>
                                                <div className={renderMenu[9]}  data-widok="9" onClick={this.toggleFront}>Analiza</div> */}
                                                <div className={renderMenu[1]} data-widok="1" onClick={this.toggleFront}><GoClippy className="panelicons"/>Nowy kredyt ratalny</div>
                                                <div className={renderMenu[2]} data-widok="2" onClick={this.toggleFront}><GoClippy className="panelicons"/>Nowy kredyt obrotowy</div>
                                                <div className={renderMenu[3]} data-widok="3" onClick={this.toggleFront}><GoClippy className="panelicons"/>Nowy leasing</div>
                                                <div className={renderMenu[4]} data-widok="4" onClick={this.toggleFront}><GoClippy className="panelicons"/>Usługi factoringowe</div>
                                                <div className={renderMenu[5]} data-widok="5" onClick={this.toggleFront}><GoArrowBoth className="panelicons"/> Kantor</div>
                                                <div className={renderMenu[6]} data-widok="6" onClick={this.toggleFront}><GoArchive className="panelicons" />Lokaty</div>
                                                <div className={renderMenu[7]} data-widok="7" onClick={this.toggleFront}><GoOrganization className="panelicons"/> Doradztwo niezależnego brokera</div>

                                                {/* <div className={renderMenu[8]}  data-widok="8" onClick={this.toggleFront}>Złożone wnioski</div>
                                                <div className={renderMenu[9]}  data-widok="9" onClick={this.toggleFront}>Wnioski do uzupełnienia</div>
                                                <div className={renderMenu[13]}  data-widok="13" onClick={this.toggleFront}>Rozpatrzone wnioski</div> */}
                                                <div className={renderMenu[11]}  data-widok="11" onClick={this.toggleFront}><GoArchive className="panelicons"/>Dokumenty zakończone</div>

                                                
                                               
                                               
                                               
 

                                           </div>
                                        </div>
                                    
                                    {/* ======================= CONTAINER ======================= */}
                                    <div className="col-sm-9">
                                        <br></br>
                                        {/* <IntroMain level={this.state.widok} toggleFront={this.toggleFront}/> */}
                                        <Introformintro 
                                            level={this.state.widok} 
                                            wnioski={this.props.wnioski}
                                            szczegolyWniosku = {this.props.szczegolyWniosku}
                                            ask={this.props.ask}
                                            updateFinans={this.props.updateFinans}
                                            firma={this.props.firma}
                                            document={this.props.document}
                                            />
                                        
                                        <Intromaindata level={this.state.widok} data={daneFirmy} change={this.changeFirma} />
                                        
                                        <Faqfirma level={this.state.widok}/>
                                        <Introformone 
                                            level={this.state.widok}
                                            change={this.changeFirma} 
                                            currentForm={this.props.currentForm} 
                                            form={(this.props.formularz)} 
                                            data ={daneFirmy}
                                            test={"TEST"}
                                            />

                                        <Introformtwo
                                            level={this.state.widok}
                                            change={this.changeFirma} 
                                            currentForm={this.props.currentForm} 
                                            form={(this.props.formularz)} 
                                            data ={daneFirmy}
                                            test={"TEST"}
                                            />
                                        <Introformtre 
                                            level={this.state.widok}
                                            change={this.changeFirma} 
                                            currentForm={this.props.currentForm} 
                                            form={(this.props.formularz)} 
                                            data ={daneFirmy}
                                            test={"TEST"}
                                                />
                                        <Introformfour
                                            level={this.state.widok}
                                            change={this.changeFirma} 
                                            currentForm={this.props.currentForm} 
                                            form={(this.props.formularz)} 
                                            data ={daneFirmy}
                                            test={"TEST"}
                                            />


                                        <Introformsum
                                            level={this.state.widok}
                                            kodyPKD={this.props.kodyPKD}
                                            currentStep={this.state.step} 
                                            pkd={this.state.pkd} 
                                            nip={this.state.nip}
                                            regon={this.state.regon}
                                            krs={this.state.krs}
                                            nazwa={this.state.nazwa}
                                            wojewodztwo={this.state.wojewodztwo}
                                            powiat={this.state.powiat}
                                            gmina={this.state.gmina}
                                            miejscowosc={this.state.miejscowosc}
                                            kod={this.state.kod}
                                            ulica={this.state.ulica}
                                            lokal={this.state.lokal}
                                            forma={this.state.forma}
                                            imienazwisko={this.state.imienazwisko}
                                            telefon={this.state.telefon}
                                            mail={this.state.mail}
                                            model={this.state.model}
                                            staz={this.state.staz}
                                            ksiegowosc={this.state.ksiegowosc}
                                            zgoda1={this.state.zgoda1}
                                            zgoda2={this.state.zgoda2}
                                            zgoda3={this.state.zgoda3}
                                            zgoda4={this.state.zgoda4}
                                            finanse={this.state.finanse}
                                            hbpoints={this.state.hbpoints}
                                            zapisz={this.savepageone}
                                            konto={this.state.konto}
                                        />
                                        <Kula level={this.state.widok} firma={this.props.firma} kodyPKD={this.props.kodyPKD}/>
                                        <ZlozoneWnioski
                                            level={this.state.widok}
                                            komunikat={this.komunikat}
                                        />
                                        <RozpatrzoneWnioski
                                            level={this.state.widok}
                                            komunikat={this.komunikat}
                                        />
                                        <ZakonczoneWnioski 
                                            level={this.state.widok}
                                            komunikat={this.komunikat}
                                        />
                                        <DoUzupelnieniaWnioski
                                            level={this.state.widok}
                                            komunikat={this.komunikat}
                                        />
                                    </div>
                                    </div>
                                    </div>
                                    </div>     
                            )
                        }
                        
                        
        }
    
}


export default Intro;