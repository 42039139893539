import React  from "react";
import { BrowserRouter as Router, Route, Link  } from "react-router-dom";
// import {browserHistory } from 'react-router';
import axios from 'axios';
import Cookies from 'js-cookie';
import security from './common/security.js';

import ContactForm from './contactform/contactform.js';
import Register from './login/register.js';
import Downtown from './../img/downtown.jpg';
import LoginForm from './login/loginform.js';

// import ratalny from './../img/firma_ratalny.xml';
import {FaPhoneSquare} from "react-icons/fa";
// import Debug from './debug/debug.js';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

// import Termometr from './common/termometr.js';
import Flipcard from './common/flipcard.js';
//import front from './common/front.js';

import UniversalCreditForm from './common/universalCreditForm.js';
import UniversalResponseForm from './common/universalResponseForm.js';
import UniversalFirmResponseForm from './common/universalFirmResponseForm.js';
import Kantor from './common/kantor.js';
import Lokaty from './common/lokaty.js';
import Broker from './contactform/broker.js';
import Singlepage from './common/singlepage.js';
import Inne from './common/inne.js';
import XML from './common/xmlparser.js';
import front from './common/front.js';
import Landingtop from './common/landingtop.js';
import Checkcookie from "./common/checkcookie.js";
import {TiDeviceDesktop, TiDeviceTablet, TiDevicePhone} from "react-icons/ti";
// import Hipoteczne from './common/hipoteczne.js';
import Creditofertsnologin from './common/creditofertsnologin.js';
import Hipoteka from './common/hipoteka.js';
import WynikMaleAI from './common/wynikMaleAI.js';

import Footer from './login/footer.js'
// import {Animated} from "react-animated-css";

// import { MdSubdirectoryArrowLeft } from "react-icons/md";

//import Broker from './common/broker.js';



class Page extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          sesje:[], regulamin:"", knf:[{desc:'', title:"", date:"", link:""}], knfid:0,gielda:[],
          noLogout:0, nlstyle:"none",rat:6, kwota:1, tresc:"", mail:"", temat:"", newsletter:"tak",zgoda:"tak",
          style1:"target", style2:"target", style3:"target", style4:"target",cel:"",creditList:[],
          cookieInfo:{display:"block"},
          czas:"Ilość rat:", lat:6,rata:170.83, server:"",introtext:"",formularzkredytowy:"", readForm:{},uniF:{},
          czesc:11,kursWalut:"KURS",onas:"",privacypolicy:"", formName:"", 
          productID:0,produkty:[], loadedProductID:0,xml:[], introtextm:"", introtexts:[
              "17 pozytywnych decyzji krtedytowych dziennie",
              "Setki zadowolonych klientów",
              "Ponad 7 000 transakcji walutowych",
              "Blisko 1300 firm skorzystało z naszych usług",
          ], intropage:0, introstate:0,timer:0,interval:null,

          texty:[],clientSelection:[], algoritms:[],wynik:{}
        };

        this.regulamin = this.regulamin.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.default = this.default.bind(this);
        // this.suwak = this.suwak.bind(this);
        this.contactForm = this.contactForm.bind(this);
        this.wypelnij = this.wypelnij.bind(this);
        // this.rodzaj = this.rodzaj.bind(this);
        this.ask = this.ask.bind(this);
        this.zamknijCookies = this.zamknijCookies.bind(this);
        this.kredytRatalny = this.kredytRatalny.bind(this);
        this.kredytObrotowy = this.kredytObrotowy.bind(this);
        this.Leasing = this.Leasing.bind(this);
        this.Factoring = this.Factoring.bind(this);
        this.hipoteczny_indywidualny = this.hipoteczny_indywidualny.bind(this);
        this.hipoteczny_malzenski = this.hipoteczny_malzenski.bind(this);
        // this.kredyt_indywidualny = this.kredyt_indywidualny.bind(this);
        this.kantor = this.kantor.bind(this);
        this.lokaty = this.lokaty.bind(this);
        this.onas = this.onas.bind(this);
        this.privacypolicy = this.privacypolicy.bind(this);
        this.sprawdzKOD = this.sprawdzKOD.bind(this);
        this.readHipInd = this.readHipInd.bind(this);
        this.loadProducts = this.loadProducts.bind(this);
        this.inne = this.inne.bind(this);
        this.debug = this.debug.bind(this);
        this.letterIng = this.letterIng.bind(this);
        this.setWynik = this.setWynik.bind(this);
        this.broker = this.broker.bind(this);
    }
    server = () => { confirmAlert({  customUI: ({ onClose }) => { return ( <div className='custom-ui'><h3>Komunikat serwera</h3><p>{this.state.server}</p> <button className="btn btn-danger btn-sm" onClick={onClose} >Zamknij</button>  </div> ); } });}
validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
kantor()
{
    if (this.state.kursWalut==="KURS"){this.ask("kursyNBP", null);}
    return (<Kantor  clientSelection={this.state.clientSelection} kursy={this.state.kursWalut}  xml={this.state.xml} drawable={["Finanse osobiste", "Inwestycje", "Ubezpieczenia", "Dla Twojej firmy", "Produkty niefinansowe"]} 
    include={["Karty kredytowe"]}/>)

}
lokaty()
{
    if (this.state.productID!==9){this.setState({productID:9});}
    
    return (<Lokaty xml={this.state.xml} clientSelection={this.state.clientSelection} drawable={["Finanse osobiste", "Inwestycje", "Ubezpieczenia", "Dla Twojej firmy", "Produkty niefinansowe"]} 
    include={["Lokaty"]} />)
}

inne()
{
    //eturn <Inne products={this.state.produkty}/>

    return (<div>
        {/* <span className="pseudobtn" onClick={this.debug}>Sprawdź page.State</span> */}
        <Inne clientSelection={this.state.clientSelection} xml={this.state.xml} 
         drawable={["Finanse osobiste", "Inwestycje", "Ubezpieczenia", "Dla Twojej firmy", "Produkty niefinansowe"]} 
        include={["Karty kredytowe","Konta osobiste", "Kredyty gotówkowe", "Kredyty samochodowe", 
        "Konta młodzieżowe", "Kredyty konsolidacyjne", "Kredyty refinansowe", "Konta oszczędnościowe", 
        "Pożyczki społecznościowe", "Pożyczki pozabankowe", "Informacja kredytowa","Chwilówki", "Konta dla obcokrajowców",
        "Rachunki inwestycyjne", "Ubezpieczenia na życie", "Ubezpieczenia komunikacyjne", 
        "Ubezpieczenia turystyczne","Rachunki firmowe",
        "Informacja gospodarcza","Usługi dla firm",
        "Linia finansowania", ]}/>
        
        </div>)
}

broker()
{
    return (<Broker text={this.state.texty.opisBrokera}/>);
}
ask(resource, data = null, section="user")
      {
            var self = this;
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', section);
            params.append('command', resource);
            params.append('data', data);
            params.append('token', Cookies.get("Experimental"));
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
              //console.log("Asked",resource, response.data.data);
            if (response.data.status==="OK")
            {
            //self.setState({loading:false});
                switch (resource){
                case 'getTexts':self.setState({texty:response.data.data});break;
                case 'contactForm': self.setState({server: response.data.data}); setTimeout(function(){self.server();}, 50);break;
                case 'getCreditList':self.setState({creditList:response.data.data}); break;
                case 'getkorzysci':self.setState({introtext:response.data.data}); break;
                case 'clientSelection':self.setState({clientSelection:response.data.data});break;
                case 'getProductList':self.setState({produkty:response.data.data, loadedProductID:data});console.log(data, self.state.loadedProductID);break;
                case 'getCreditForm':
                    var id = 0;
                    if (front.IsJsonString(response.data.data)){id = JSON.parse(response.data.data);}
                    self.setState({formularzkredytowy:response.data.data, formName:data, productID:id.settings.productID });
                    break;
                case 'kursyNBP':self.setState({kursWalut:response.data.data});break;
                case 'getONas':self.setState({onas:response.data.data});break;
                case 'getPrivacypolicy':self.setState({privacypolicy:response.data.data});break;
                case 'getAlgoritms':self.setState({algoritms: response.data.data});break;
                case 'getExOferta':
                    if (XML.IsJsonString(response.data.data))
                    {
                        self.setState({xml:(JSON.parse(response.data.data))});
                        //console.log("XML", response.data.data)
                    }
                    else{//console.log("JSON ERROR");
                };
                    
                    break;
                default: break;
                }
                
            }
            else if(response.data.status==="TEST")
            {
            console.log(response.data);
            }
            else if (response.data.status==="err")
            {
               self.setState({server: response.data.desc});self.server();
            }
            self.setState({odpowiedz:response.data.desc});
            }).catch(function (error) {console.log(error);});
            
        }

        readHipInd(ev)
        {
            //console.log("daneF",ev.target.dataset);
            var action = ev.target.dataset.action;
            if (action==="dalej"){this.setState({czesc:12, uniF:ev.target.dataset.dane});}
            if (action==="dalejFirmRatalny"){this.setState({czesc:13, uniF:ev.target.dataset.dane});}
            if (action==="wroc"){this.setState({czesc:11});}
            //checkIntegrity.checkIntegrity(JSON.parse(ev.target.dataset.dane));
        }

        loadProducts()
        {
            if (this.state.loadedProductID!==this.state.productID)
            {
                this.ask("getProductList",this.state.productID)
            }
        }

kredytRatalny()
{
    //@@@@
    // //console.log("Firmowy ratalny", this.state.formName);
    // if (this.state.formName!=="firmRatal"){this.ask("getCreditForm", 'firmRatal');}
    // // this.loadProducts();
    // return (
    //     <div className="container">
    //         <div className="row-sm-12"><UniversalCreditForm readForm={this.readHipInd} readData={this.state.readForm} page={this.state.czesc} form={this.state.formularzkredytowy}/>
    //         <div className="row-sm-12"><UniversalFirmResponseForm page={this.state.czesc} form={this.state.uniF} read={this.readHipInd} xml={this.state.xml} showOnly={["Kredyty dla firm"]}/></div>
    //         </div>
    //     </div>
    //     );
    return (<Creditofertsnologin dane={this.state.produkty} xml={this.state.xml} clientSelection={this.state.clientSelection} showOnly={["Kredyt hipoteczny", "Kredyt mieszkaniowy", "Kredyty dla firm"]} />
    )
}
kredytObrotowy()
{
    // //console.log("Firmowy obrotowy", this.state.formName);
    // if (this.state.formName!=="firmObrot"){this.ask("getCreditForm", 'firmObrot');}
    // // this.loadProducts();
    // return (
    //     <div className="container">
    //         <div className="row-sm-12"><UniversalCreditForm readForm={this.readHipInd} readData={this.state.readForm} page={this.state.czesc} form={this.state.formularzkredytowy}/>
    //         <div className="row-sm-12"><UniversalFirmResponseForm page={this.state.czesc} form={this.state.uniF} read={this.readHipInd} xml={this.state.xml} showOnly={["Kredyty dla firm"]}/></div>

    //         </div>
    //     </div>
    //     );
    return (<Creditofertsnologin dane={this.state.produkty} xml={this.state.xml} clientSelection={this.state.clientSelection} showOnly={["Kredyt hipoteczny", "Kredyt mieszkaniowy", "Kredyty dla firm"]} />);

}

Leasing()
{
    // //console.log("Firmowy Leasing", this.state.formName);
    // if (this.state.formName!=="firmLeasing"){this.ask("getCreditForm", 'firmLeasing');}
    // // this.loadProducts();
    // return (
    //     <div className="container">
    //         <div className="row-sm-12"><UniversalCreditForm readForm={this.readHipInd} readData={this.state.readForm} page={this.state.czesc} form={this.state.formularzkredytowy}/>
    //         <div className="row-sm-12"><UniversalFirmResponseForm page={this.state.czesc} form={this.state.uniF} read={this.readHipInd} xml={this.state.xml} showOnly={["Leasing dla firm"]}/></div>
    //         </div>
    //     </div>
    //     );
    return (<Creditofertsnologin dane={this.state.produkty} xml={this.state.xml} clientSelection={this.state.clientSelection} showOnly={["Leasing dla firm"]} />);

}

Factoring()
{
    // //console.log("Firmowy Leasing", this.state.formName);
    // if (this.state.formName!=="firmFactoring"){this.ask("getCreditForm", 'firmFactoring');}
    // return (
    //     <div className="container">
    //         <div className="row-sm-12"><UniversalCreditForm readForm={this.readHipInd} readData={this.state.readForm} page={this.state.czesc} form={this.state.formularzkredytowy}/>
    //         <div className="row-sm-12"><UniversalFirmResponseForm page={this.state.czesc} form={this.state.uniF} read={this.readHipInd} xml={this.state.xml} showOnly={["Faktoring"]}/></div>
    //         </div>
    //     </div>
    //     );
    return (<Creditofertsnologin dane={this.state.produkty} xml={this.state.xml} clientSelection={this.state.clientSelection} showOnly={["Faktoring"]} />);

}
hipoteczny_indywidualny()
{
    if (this.state.formName!=="hipInd"){this.ask("getCreditForm", 'hipInd');}
    // this.loadProducts();
    return (
    <div>
        <Hipoteka  ai={this.state.algoritms} setWynik={this.setWynik}/>
        <WynikMaleAI wynik={this.state.wynik} />
        {/* <Hipoteczne page={12} form={this.state.uniF} read={this.readHipInd} kredyty={this.state.produkty}  xml={this.state.xml} showOnly={["Kredyt hipoteczny", "Kredyt mieszkaniowy", "Kredyty mieszkaniowe"]}/> */}
        <div id="oferta" style={{display:"none"}}>
        <Creditofertsnologin dane={this.state.produkty} xml={this.state.xml} clientSelection={this.state.clientSelection} showOnly={["Kredyt hipoteczny", "Kredyt mieszkaniowy", "Kredyty mieszkaniowe"]} />
        </div>
    </div>
    );
}
hipoteczny_malzenski()
{
    //if (this.state.formName!=="hipMal"){this.ask("getCreditForm", 'hipMal');}
return (
    <div>
        <Hipoteka  ai={this.state.algoritms} setWynik={this.setWynik}/>
        <WynikMaleAI wynik={this.state.wynik} />
        {/* <Hipoteczne page={12} form={this.state.uniF} read={this.readHipInd} kredyty={this.state.produkty}  xml={this.state.xml} showOnly={["Kredyt hipoteczny", "Kredyt mieszkaniowy", "Kredyty mieszkaniowe"]}/> */}
        <div id="oferta" style={{display:"none"}}>
        <Creditofertsnologin dane={this.state.produkty} xml={this.state.xml} clientSelection={this.state.clientSelection} showOnly={["Kredyt hipoteczny", "Kredyt mieszkaniowy", "Kredyty mieszkaniowe"]} />
        </div>
    </div>
)

    // this.loadProducts();
    return (<div className="container">
        <div className="row-sm-12"><UniversalCreditForm readForm={this.readHipInd} readData={this.state.readForm} page={this.state.czesc} form={this.state.formularzkredytowy}/> 
        <div className="row-sm-12"><UniversalResponseForm page={this.state.czesc} form={this.state.uniF} read={this.readHipInd} kredyty={this.state.produkty}  xml={this.state.xml} showOnly={["Kredyt hipoteczny", "Kredyt mieszkaniowy", "Kredyty mieszkaniowe"]}/></div>
        </div>
    </div>)
}

// kredyt_indywidualny() 
// {
//     if (this.state.formName!=="indywidualny"){this.ask("getCreditForm", 'indywidualny');}
//     // this.loadProducts();
//     return (<div className="container">
//         <div className="row-sm-12"><UniversalCreditForm readForm={this.readHipInd} readData={this.state.readForm} page={this.state.czesc} form={this.state.formularzkredytowy}/>
//         <div className="row-sm-12"><UniversalResponseForm page={this.state.czesc} form={this.state.uniF} read={this.readHipInd} kredyty={this.state.produkty}  xml={this.state.xml} showOnly={["Kredyt hipoteczny", "Kredyt mieszkaniowy", "Kredyty mieszkaniowe"]}/></div>
//         </div>
//     </div>)
// }

setWynik(ev)
{
    this.setState({wynik:ev});
    //console.log("WYNIK",ev);
}
login()
{
    return(
    <div>
        <img src={Downtown} alt="grafica" className="introimage"/>
        <LoginForm  />
    </div>
   )
}

contactForm(ev)
{
    if (ev.target.dataset.id === "ta"){this.setState({tresc: ev.target.value })}
    if (ev.target.dataset.id === "te"){this.setState({temat: ev.target.value })}
    if (ev.target.dataset.id === "ma"){this.setState({mail: ev.target.value })}
    if (ev.target.dataset.id === "ne"){this.setState({newsletter: ev.target.value })}
    if (ev.target.dataset.id === "ko"){this.setState({zgoda: ev.target.value })}
    if (ev.target.dataset.id==="send")
    {
        var flag = true;
        var comm = "Popraw następujące błędy: ";
        if (!this.validateEmail(this.state.mail)){flag=false; comm+="Adres mail wydaje się nie być poprawny. ";}
        if (this.state.zgoda==="nie"){flag=false; comm+="Zgoda na kontakt jest wymagana. ";}
        if (this.state.temat.length<10){flag=false; comm+="Zwyt krótki temat.";}
        if (this.state.tresc.length<10){flag=false; comm+="Zwyt krótka treść.";}
        if (!flag){this.setState({server:comm});var self = this; 
        setTimeout(function(){self.server();},50);}
        else
        {
            this.ask("contactForm", JSON.stringify({mail:this.state.mail, temat:this.state.temat, tresc:this.state.tresc, newsletter:this.state.newsletter}));
        }
        
    }
}
wypelnij()
{
    var tresc = "Dzień dobry! Poszukuję kredytu "+this.state.cel+" w kwocie " + this.state.kwota +"000 zł. " + this.state.czas +" " + this.state.lat + ", spłacanego w miesięcznych ratach w wysokości około " +this.state.rata +" zł. Proszę o kontakt drogą mailową";
    this.setState({tresc:tresc, temat:"Niezobowiązujące pytanie dotyczące możliwości uzyskania kredytu"});
}
zamknijCookies()
{
    this.setState({cookieInfo:{display:"none"}});
    window.localStorage.setItem("cookies","noDisplay");
}
sprawdzKOD()
{
    this.ask("sprawdzKOD", JSON.stringify({woj:"Mazowieckie", pow:"Pruszków", gmi:"Piastów"}));
}
default(){
    if (this.state.czesc!==11){this.setState({czesc:11});//console.log("CHANGED PAGE", this.state.czesc);
}
    return(
<div>
    
    <div id="top">
        <Checkcookie />
        <Landingtop />
        <div className="container landing">
            <div className="row">
                <div className="col-sm-12" >
                    <span className="just" dangerouslySetInnerHTML={{ __html: this.state.texty.korzysci }}></span>
                    <div>
                        {/* <button onClick={this.sprawdzKOD}>Sprawdz</button> */}
                        {/* <Termometr procent="50" kwota="300 000 zł"/> */}
                    </div>
                </div>
            </div>  
        </div>



        <div className="wrapper">
                <div className="section parallax">
                    <div className="paralaxContainer">Usługa dostępna na<br></br>
                    <TiDeviceDesktop className="bigIcon white" />
                    <TiDeviceTablet className="bigIcon white"/>
                    <TiDevicePhone className="bigIcon white" /><br></br>
                    <div><br></br>{this.state.introtextm}</div>
                    </div>
                    
                  

                </div>
        </div>

    </div>
    <div className="">
        <div className="container firmowy" > 
            <div className="row">
            
                <div className="col-sm-12 dlaFirm" id="firma">
                    <h3 className="" >Usługi dla firm</h3>
                    <span className="just" dangerouslySetInnerHTML={{ __html: this.state.texty.dlaFirm }}></span>

                    <div className="cen">
                    <Link to="/firmowy_ratalny" className="ainvisi" ><Flipcard image="ratalny" onClick={this.kredytRatalny} first="Kredyt Ratalny" second={this.state.texty.ratalny} /></Link>
                    <Link to="/firmowy_obrotowy" className="ainvisi" ><Flipcard  image="obrotowy" first="Kredyt obrotowy" second={this.state.texty.obrotowy}/></Link>
                    <Link to="/leasing" className="ainvisi" ><Flipcard  image="leasing" first="Leasing" second={this.state.texty.leasing}/></Link>
                    <Link to="/factoring" className="ainvisi" ><Flipcard  image="factoring" first="Usługi Faktoringowe" second={this.state.texty.faktoring}/></Link>
                    </div>
                   
                </div>
            </div>
        </div>

        <div className="l1">
            <div className="wrapper halfsize">
                <div className="section parallax halfsize">
                       <div className="paralaxInfo">Jak to działa
                           <div>
                           <ol>
                            <li>wybierz interesujący Ciebie produkt</li>
                            <li>Wypełnij krótką ankietę o wynikach finansowych Twojej firmy. Dane, które podasz nie zostaną przesłane na nasz serwer, ale użyjemy ich do wstępnej oceny zdolności kredytowej.</li>
                            <li>Jeśli nie chcesz, możesz pominąć ankietę. Wówczas nie ocenimy, jakie są szanse uzyskania pozytywnej oceny banku</li>
                            <li>Otrzymasz listę dostępnych produktów bankowych. Sam zdecydujesz, czy dana oferta jest dla Ciebie atrakcyjna</li>
                            <li>Jeśli zdecydujesz się ja jakiś produkt zostaniesz przekierowany na stronę banku</li>
                       </ol>
                           </div>
                       </div>
                        </div>
                </div>
        </div>

        <div className="container indywidualny">
            <div className="row">
            
                <div className="col-sm-12" id="osoba">
                    <h3 className="" >Usługi dla klientów indywidualnych</h3>
                    <span className="just" dangerouslySetInnerHTML={{ __html: this.state.texty.dlaFirm }}></span>
                    <div className="cen">
                        <Link to="/hipoteczny_dla_malzenstw" className="ainvisi" ><Flipcard  image="hip_mal" first="Kredyt hipoteczny dla małżeństw" second={this.state.texty.indywidualny} /></Link>
                        <Link to="/hipoteczny_indywidualny" className="ainvisi" ><Flipcard  image="hip" first="Kredyt hipoteczny" second={this.state.hipoteczny} /></Link>
                        {/* <Link to="/kredyt_indywidualny" className="ainvisi" ><Flipcard  image="indywidualny" first="Kredyt indywidualny" second="Sprawdź aktualne kursy walut i zobacz ile możesz zaoszczędzić wymieniając waluę z nami<br><button class='btn btn-light'>Więcej</button>"/></Link> */}
                        
                    </div>
                  
                    
                   
                </div>
            </div>
        </div>
        <div className="l1">
            <div className="wrapper halfsize">
                <div className="section parallax halfsize">
                       <div className="paralaxInfo">Jak to działa
                           <div>
                           <ol>
                            <li>Zdecyduj, czy kredyt hipoteczny bierzesz sam, czy ze współmałzonkiem</li>
                            <li>Wypełnij krótką ankietę o twojej sytuacji finansowej. Dane, które podasz nie zostaną przesłane na nasz serwer, ale użyjemy ich do wstępnej oceny zdolności kredytowej.</li>
                            <li>Jeśli nie chcesz, możesz pominąć ankietę. Wówczas nie ocenimy, jakie są szanse uzyskania pozytywnej oceny banku</li>
                            <li>Otrzymasz listę dostępnych kredytów hipotecznych. Sam zdecydujesz, czy dana oferta jest dla Ciebie atrakcyjna</li>
                            <li>Jeśli zdecydujesz się ja jakiś produkt zostaniesz przekierowany na stronę banku</li>
                       </ol>
                           </div>
                       </div>
                        </div>
                </div>
        </div>
        <div className="container inne">
            <div className="row">
            
                <div className="col-sm-12 dlaFirm" id="dodatkowe">
                    <h3 className="" >Usługi dodatkowe</h3>
                    <span className="just" dangerouslySetInnerHTML={{ __html: this.state.texty.inne }}></span>
                    <div className="cen">
                        <Link to="/kantor" className="ainvisi" ><Flipcard first="Kantor"  image="kantor" second={this.state.texty.kantor} /></Link>
                        <Link to="/lokaty" className="ainvisi" ><Flipcard  image="lokata" first="Lokata" second={this.state.texty.lokata}/></Link>
                        <Link to="/inne" className="ainvisi" ><Flipcard first="Inne"  image="inne" second={this.state.texty.inne}/></Link>
                        <Link to="/broker" className="ainvisi" ><Flipcard first="Doradztwo niezależnego brokera" image="broker" second={this.state.texty.broker}/></Link>
                    </div>
                   
                   
                </div>
            </div>           
        </div>
    </div>

    <div className="bgred">
        <div id="contactform" className='container bgred'>
            <div className="row">
                <div className="col-sm-12">
                    <br></br>
                    <h2>Formularz kontaktowy</h2>
                    <br></br>
                    <div className="just">
                    Wysłanie formularza nie jest wiążące dla Ciebie i możesz to zrobić bez żadnych konsekwencji. 
                    Nasi specjaliści skontaktują się z Tobą najszybciej, jak to możliwe. 
                    Aby móc otrzymać odpowiedź na zadane pytanie musisz wyrazić zgodę na kontakt drogą elektroniczną. 
                    Wysłanie formularza jest równoznaczne z akceptacją tego punktu regulaminu. 
                    Uzupełnij formularz i naciśnij przycisk wyślij.
                    <br></br><br></br><br></br><br></br>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4">
                    <div className="form-group">
                        <label >Adres mail:</label>
                        <input type="text" className="form-control" data-id="ma" onChange={this.contactForm} value={this.state.mail} placeholder="adres mail" id="usr"/>
                    </div>
                </div>
                <div className="col-sm-8">
                    <div className="form-group">
                        <label >Temat:</label>
                        <input type="text" className="form-control" data-id="te" onChange={this.contactForm} value={this.state.temat}/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-8">
                    <div className="form-group">
                        <label >Treść wiadomości:</label>
                        <textarea className="form-control" rows="5" data-id="ta" value={this.state.tresc} onChange={this.contactForm}></textarea>
                    </div> 
                </div>
                <div className="col-sm-4">
                    <div className="form-group">
                        <label>Zgoda na przetwarzanie danych:</label>
                        <select className="form-control" data-id="ko" onChange={this.contactForm} value={this.state.zgoda}>
                                <option value="tak">Wyrażam zgodę na kontakt email</option>
                                <option value="nie">Nie wyrażam zgody na kontakt email</option>       
                        </select>
                        <label>Dodatkowo:</label>
                        <select className="form-control" data-id="ne" onChange={this.contactForm} value={this.state.newsletter}>
                                <option value="tak">Chcę otrzymywać newsletter</option>
                                <option value="nie">Nie chcę otrzymywać newsletter</option>       
                        </select>
                    </div> 
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <button className="btn btn-dark" data-id="send" onClick={this.contactForm}>Wyślij</button>
                    <br></br><br></br><br></br><br></br>
                </div>
            </div>
            

            
        </div>
    </div>
  
</div>

    )
}

home(){return(<div>HOME</div>);}
debug()
{
console.log(this.state);    
}
register(){return(<div><img src={Downtown} alt="grafica" className="introimage"/><Register /></div>);}
regulamin(){
    if (this.state.regulamin === "")
    {
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
        params.append('section', 'aplikacja');
        params.append('command', 'getRegulamin');
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            //console.log(response.data.wartosc);
            self.setState({regulamin: response.data.wartosc});
        }).catch(function (error) {console.log(error);});
    }

    return(<Singlepage text={this.state.regulamin} />);
}
onas()
{
    return(<Singlepage text={this.state.texty.Onas} />);

}
privacypolicy()
{ 
    return(<Singlepage text={this.state.texty.Politykaprywatnosci} />);
}
contact(){
    
    return(
        <div>
            <img src={Downtown} alt="grafica" className="introimage"/>
            <ContactForm/>
        </div>
        
    )
}

letterIng()
{
    // @@@
    // odczytaj stronę
    var pg = this.state.intropage;
    var st = this.state.introstate;
    var tx = this.state.introtexts;
    var tt = this.state.introtextm;
    var tm = this.state.timer;

    st++;
    tt = tx[pg].substr(0, st) +"|";
    //RESET LENGTH
    if (st >tx[pg].length){
        tm++
        if (tm===10){pg++;st=0;tt="";tm=0;}
       
        //next page
       if (pg === tx.length) {pg=0;}
    }
    this.setState({intropage:pg, introtextm:tt, introstate:st, timer:tm});
}

componentDidMount() {
    
    var x = window.localStorage.getItem('cookies');
    if (x==="noDisplay"){this.setState({cookieInfo:{display:"none"}});}
    this.ask("getTexts", null);
    this.ask("getkorzysci", null);
    this.ask('getExOferta',null,"aplikacja");
    this.ask('clientSelection', null);
    this.ask('getAlgoritms', null);
    
    if (this.state.interval===null && window.location.pathname==="/")
    {

            var u = setInterval(() => {
                this.letterIng();
            }, 100);
            this.setState({interval:u});
    }
    else
    {
        var tx = this.state.introtexts;
        var sel= this.getRandomInt(0, tx.length);
        var cc = this.state.interval;
        cc = clearInterval();
        this.setState({interval:cc, introtextm:tx[sel]});
    }
}
getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
}
    render(){
        document.title ="HomeBanQ";
        var contact = "/contact/";

    return (
        <div>
            <Router>
                <div>
                <div className="infolinia"><FaPhoneSquare className="red"/>800 100 100</div>
                <nav className="hbnav menuBig">
                    <ul className="hbnav">
                        <li className="hbnav">
                            <Link to="/" className="hbnav">homeBan<span className="red">Q</span></Link>
                        </li>
                        <li className="hbnav">
                            <Link to="/about" className="hbnav">O nas</Link>
                        </li>
                        <li className="hbnav">
                            <Link to="/regulamin" className="hbnav">Regulamin</Link>
                        </li>
                        <li className="hbnav">
                            <Link to="/privacypolicy" className="hbnav">Polityka prywatności</Link>
                        </li>
                        <li className="hbnav">
                            <Link to="/login" className="hbnav">Logowanie</Link>
                        </li>
                        <li className="hbnav">
                            <Link to="/register" className="hbnav">Rejestracja</Link>
                        </li>
                        <li className="hbnav">
                        <Link to={contact} className="hbnav">Kontakt</Link>
                        </li>
                    </ul>
                </nav>
                <nav className="hbnav menuSmall">
                    <ul className="hbnav">
                        <li className="hbnav slim">
                        <span className="nav-link dropdown-toggle hbtoggle clickable"  id="navbardrop" data-toggle="dropdown">
                            h<span className="red">B</span>
                        </span>
                        <div className="dropdown-menu">
                        <Link to="/" className="dropdown-item">home<span className="red">Bank</span></Link>
                        <Link to="/about" className="dropdown-item">O Znas</Link>
                        <a href="/regulamin" className="dropdown-item">Regulamin</a>
                        <a href="/privacypolicy" className="dropdown-item">Polityka prywatności</a>
                        <Link to="/login" className="dropdown-item">Logowanie</Link>
                        <Link to="/register" className="dropdown-item">Rejestracja</Link>
                        <a href={contact} className="dropdown-item ">Kontakt</a>
                    </div>
                        </li>
                    </ul>
                    
                </nav>
             
                    
                    <Route exact path="/"  component={this.default} />
                    <Route path="/register/" component={this.register} />
                    <Route path="/regulamin/" component={this.regulamin} />
                    <Route path="/contact/" component={this.contact} />
                    <Route path="/login/" component={this.login} />
                    <Route path="/debug/" component={this.debug} />
                    <Route path="/about/" component={this.onas} />
                    <Route path="/privacypolicy/" component={this.privacypolicy} />
                    {/* route niewidoczne w menu */}
                    <Route path="/firmowy_ratalny/" component={this.kredytRatalny} />
                    <Route path="/firmowy_obrotowy/" component={this.kredytObrotowy} />
                    <Route path="/leasing/" component={this.Leasing} />
                    <Route path="/factoring/" component={this.Factoring} />

                    <Route path="/hipoteczny_indywidualny/" component={this.hipoteczny_indywidualny} />
                    <Route path='/hipoteczny_dla_malzenstw/' component={this.hipoteczny_malzenski} />
                    {/* <Route path="/kredyt_indywidualny/" component={this.kredyt_indywidualny} /> */}
                    <Route path="/kantor/" component={this.kantor} />
                    <Route path="/lokaty/" component={this.lokaty} />
                    <Route path="/inne/" component={this.inne} />

                    <Route path="/broker/" component={this.broker} />

                    
                </div>
            </Router>
            <Footer texty={this.state.texty} />
        </div>
    );


}

}

export default Page;