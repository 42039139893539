import React  from "react";
//import Autosuggest from 'react-autosuggest';
//import Logo from './../../img/hbbussines.png';

import UnivwesalFirmForm from './../common/universalFirmForm';

class Introformtwo extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            step:1, suggestions: [], value: '',pkd:"", nip:""
        };
        
    }
    


    render(){
    
        if (this.props.level===2){
            return (
                <div>
                    <div className="row">
                        <div className="col-sm-12">
                            <UnivwesalFirmForm page={11} sendable={{"display":"block"}} settings={{"zluz":true}} form={this.props.form} formdata={this.props.data} data={this.props.data} changes={this.props.change}formId={this.props.formID}/>
                        </div>
                    </div>
                        
                </div>
                
            );}
            else
            {return (<div></div>);}
}

}
export default Introformtwo;