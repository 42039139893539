import React  from "react";
import XML from './xmlparser.js';
import {FiEdit} from "react-icons/fi";
import axios from 'axios';
import security from './security.js';
import Cookies from 'js-cookie';

class ProductList extends React.Component{ 
    constructor(props) {
        super(props);
        this.state = {
            allproduct:["Karty kredytowe","Konta osobiste","Kredyty mieszkaniowe", "Kredyty gotówkowe", "Kredyty samochodowe", 
            "Konta młodzieżowe", "Kredyty konsolidacyjne", "Kredyty refinansowe", "Konta oszczędnościowe", 
            "Pożyczki społecznościowe", "Pożyczki pozabankowe", "Informacja kredytowa","Chwilówki", "Konta dla obcokrajowców",
            "Lokaty", "Rachunki inwestycyjne", "Waluty", "Ubezpieczenia na życie", "Ubezpieczenia komunikacyjne", 
            "Ubezpieczenia turystyczne",

            "Leasing dla firm", "Rachunki firmowe","Kredyty dla firm","Informacja gospodarcza","Faktoring","Usługi dla firm",
            "Linia finansowania", 
        ]
        };
        this.ask = this.ask.bind(this);
        this.popitup = this.popitup.bind(this);
    }
    renderStyle(ev)
    {
        // //console.log("RenderStyle",ev.target.dataset);
        return {display:"block"};
    }
    ask(resource, data = null)
    {
          var self = this;
          const params = new URLSearchParams(); params.append('asc',security.securityKey());
          params.append('section', 'user');
          params.append('command', resource);
          params.append('data', data);
          params.append('token', Cookies.get("Experimental"));
          axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            console.log(response.data.data);
          if (response.data.status==="OK")
          {
          //self.setState({loading:false});
              switch (resource){
            //   case 'loadGmina':  self.setState({gminy: response.data.data, ready:true});self.analiseUser(response.data.data); return;
      
              default: return;
              }
              
          }
          else if(response.data.status==="TEST")
          {
          console.log(response.data);
          }
          else if (response.data.status==="err")
          {
             //self.setState({server: response.data.desc});self.server();
          }
          self.setState({odpowiedz:response.data.desc});
          }).catch(function (error) {console.log(error);});
          
      }

    popitup(ev) {

        //console.log("CLOCKED",ev.target.dataset);
        this.ask("selectedProduct", ev.target.dataset.internalid);
        // this.ask('saveItem', JSON.stringify({form:this.props.form, product:ev.target.dataset.product}))
        var url = ev.target.dataset.url;
        var windowName = ev.target.dataset.name;
        var newwindow=window.open(url,windowName,'height=200,width=150');
        if (window.focus) {newwindow.focus()}
        return false;
      }
    render(){
        
        if (typeof this.props.produkt !== "undefined" && typeof this.props.clientSelection !== "undefined")
        {
            var list = XML.rebulidObject(this.props.produkt.podkategoria);
            
        // console.log(list);
            var style ={display:"none"};
            if (this.props.drawable.includes(this.props.produkt["@attributes"]["nazwa"])){style={display:"block"}}

            return (
               
            <div style={style}>
      
                 {/* {JSON.stringify(this.props.drawable)} */}
                  <h3  className="displayh3" style={this.props.displayh3}>{this.props.produkt["@attributes"]["nazwa"]}</h3>
                  
                    {this.props.produkt.podkategoria.map((key, i)=>(
                        <div key={i} style={this.props.include.includes(key["@attributes"]['nazwa']) ? {display:"block"} : {display:"none"}}><hr></hr>
                        <h5 className="displayh5" style={this.props.displayh5}>{key["@attributes"]['nazwa']}</h5>
                        {
                            XML.rebulidObject(key.produkt).map((subkey, j)=>(
                                <div key={i+"-"+j}>
                                    <table  className="ofertaTable">
                                        <tbody>
                                            <tr style={(subkey["@attributes"]['nazwa'].includes("Pożycz")) ? {display:"none"} : {}}>
                                                <td>
                                                    <img className="creditListLogo" src={subkey.dostawca['@attributes']['logo-male']} alt={subkey.dostawca['@attributes']['nazwa']} />
                                                    <div className="clientSelection">
                                                        {XML.exists(this.props.clientSelection[subkey['@attributes']['id'].toString()]) ? "Produkt wybierany " + this.props.clientSelection[subkey['@attributes']['id'].toString()] +" razy" : "Produkt nowy"}
                                                    </div>
                                                    
                                                    {/* {this.props.clientSelection[]} */}
                                                    {/* { XML.exists(this.props.clientSelection[subkey.dostawca['@attributes']['id']]) ? "Produkt wybrany " + this.props.clientSelection[subkey.dostawca['@attributes']['id']] +" razy" : "Produkt nowy"} */}
                                                </td>
                                                <td> 
                                                    <b>{subkey["@attributes"]['nazwa']}</b> <br></br>
                                                    od {subkey.dostawca['@attributes']['nazwa']}
                                                    <div className="creditOpis">
                                                        
                                                        <div dangerouslySetInnerHTML={{__html: subkey.opis}} />
                                                        {/* {(typeof subkey.opis!=='undefined') ? subkey.opis : ""} */}
                                                    </div>
                                                    
                                                
                                                </td>
                                                <td>

                                                    <span className="wniosek2" 
                                                        data-url={this.props.baseurl+subkey.linki['@attributes']['wniosek']} 
                                                        data-internalid={subkey["@attributes"]['id']} 
                                                        data-name={"newName"} 
                                                        onClick={this.popitup}
                                                        data-product={this.props.baseurl+subkey.linki['@attributes']['wniosek']}
                                                    >Złóż wniosek <FiEdit /></span></td>
                                            </tr>
                                        </tbody>
                                    </table>


                                </div>
                            ))
                        }
                        
                            
                        
                        </div>
                    ))}
  
        
             </div>       
        )

                  }else{
                      return(<div className="minHeight">Oczekuję na dane</div>)
                  }


} 

}

    export default ProductList;