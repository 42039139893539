import React from "react";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FaRegBell } from "react-icons/fa";
import { TiArrowBackOutline }from "react-icons/ti";
import { FiFile } from "react-icons/fi";
import AF from './../common/analizafirm.js';
import Thh from './thh.js';
// import Datetime from 'react-datetime';
//import pl from 'moment/locale/pl';
// import moment from 'moment';
// import moment from 'moment/locale/pl'
import { isNull } from "util";
// import {DatetimePicker} from 'rc-datetime-picker';
// import DateTimePicker from 'react-datetime-picker';
// import $ from 'jquery';
class Documentlist extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ready:false, faq:[],values:{},powiaty:[],gminy:[],toDelete:0,page:0,
            form:{settings:{formId:"BD"},form:[]},dde:{}, zalaczniki:[],formId:0,date:"",
            textStyle:{display:"none"},sms:"",
            archiwizuj:{display:"none"},
        };
        this.debug = this.debug.bind(this);
        this.detailview = this.detailview.bind(this);
        this.submit = this.submit.bind(this);
        this.editCash = this.editCash.bind(this);
        this.changeFinans = this.changeFinans.bind(this);
        this.editDocuments = this.editDocuments.bind(this);
        this.editTermin = this.editTermin.bind(this);
        this.onChange = this.onChange.bind(this);
        this.renderDocumentClass = this.renderDocumentClass.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.acceptTermin = this.acceptTermin.bind(this);
        this.routeEditCash = this.routeEditCash.bind(this);
        this.editInd = this.editInd.bind(this);
        this.saveInd = this.saveInd.bind(this);
    }
    debug()
    {
        //console.log("DEBUG", this.props, this.state);
    }
    forceNumber(ev)
    {
        // console.log(ev.target.value);
        if (isNaN(parseInt(ev.target.value)))
        {
            ev.target.value = "";
        }
        else
        {
            ev.target.value = parseInt(ev.target.value);
        }

       
    }
    saveInd()
    {
        var form = document.getElementsByClassName('updateInd');
        for (var a = 0; a<form.length; a++)
        {
            // console.log(form[a].value, form[a].dataset);
        }
    }
    editInd= (ev) => {
        confirmAlert({
            customUI: ({ onClose }) => {
              
                
              return (
                <div className='custom-ui w80'>
                  <h3>Edycja danych finansowych</h3>
                  <div className="row">
                      <div className="col-sm-6">
                          <label>Miesięczny dochód netto</label>
                          <input className="form-control updateInd" onChange={this.forceNumber} data-name="mdn"/>
                          <label>Kwota przyznanych limitów</label>
                          <input className="form-control updateInd" onChange={this.forceNumber} data-name="kpl"/>
                      </div>
                      <div className="col-sm-6">
                        <label>Miesięczna kwota rat</label>
                          <input className="form-control updateInd" onChange={this.forceNumber} data-name="mkr"/>
                          <label>Kwota miesięcznych wydatków</label>
                          <input className="form-control updateInd" onChange={this.forceNumber} data-name="kmw"/>
                      </div>
                  </div>
                
                  <hr></hr>
                  <button className="btn btn-secondary btn-sm" onClick={onClose}>Anuluj</button>
                  &nbsp;
                  
                  <button
                    className="btn btn-danger btn-sm" data-action="sendSMS"
                    onClick={(ev) => {    
                           
                           this.saveInd(ev);
                           onClose();
                    }}
                  >
                    Zapisz
                  </button>
                </div>
              );
            }
          });}
    routeEditCash()
    {
        var firmowe = ["firmRatal", 'firmObrot', 'firmLeasing', 'firmFactoring', 'firmaMain'];
        var ind = ['hipMal', 'hipInd', 'indywidualny'];
        var formid = this.props.szczegolyWniosku.formID;
        if (firmowe.includes(formid)){this.editCash();}
        if (ind.includes(formid)){this.editInd();}
    }
    componentDidMount()
    {
        this.setState({date:new Date()});
        this.props.ask("documents", "getFileList", null);

    }
    server = () => { confirmAlert({  customUI: ({ onClose }) => { return ( <div className='custom-ui'><h3>Komunikat serwera</h3><p>{this.state.server}</p> <button className="btn btn-danger btn-sm" onClick={onClose} >Zamknij</button>  </div> ); } });}
submit = () => {
    confirmAlert({
        customUI: ({ onClose }) => {
            
          return (
            <div className='custom-ui'>
              <h3>Wymagane potwierdzenie</h3>
              <p>Operacja, którą chcesz wykonać jest nieodwracalna i wpływa na strukturę danych. Czy chcesz skasować tą pozycję?</p>
              <button className="btn btn-secondary btn-sm" onClick={onClose}>Anuluj</button>
              &nbsp;
              <button
                className="btn btn-danger btn-sm"
                onClick={() => {
                  this.props.ask("firma","deleteWniosek", this.state.toDelete);
                  onClose();
                }}
              >
                Usuń
              </button>
            </div>
          );
        }
      });}

      editDocuments = (ev) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                // //console.log("editDocuments",ev.target.dataset);
                
              return (
                <div className='custom-ui'>
                  <h3>Edycja dokumentów załączonych do wniosku</h3>
                  <div>
                      <div><b>Lista Twoich załączników</b></div>
                      <small>Wybierz nowy zestaw załączników do wniosku. Aby zarządzać dokumentami wybierz zakładkę dokumenty</small>
                    {this.props.documentList.map((key, i)=>(
                        <div data-docname={key} data-data={ev.target.dataset.data} data-doclist={ev.target.dataset.doc} className="documentss" onClick={this.renderDocumentClass} key={"DOC"+i}>
                            {key.name}
                        </div>
                    ))}

                  </div>
                  <button className="btn btn-secondary btn-sm" onClick={onClose}>Anuluj</button>
                  &nbsp;
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => {
                        var doc=[];
                        var docl = document.getElementsByClassName("documentss active");
                        for (var a = 0; a<docl.length; a++)
                        {
                            doc.push(docl[a].innerHTML);
                        }
                        // //console.log("DOCLIST", doc);
                        var dokumenty = {dokumenty:doc};
                        
                        this.props.ask("firma","zmianaZalacznikow", JSON.stringify({id:this.state.formId, data: dokumenty}));                      onClose();
                    }}
                  >
                    Zapisz
                  </button>
                </div>
              );
            }
          });}

        //   onChange = date => {this.setState({ date });}
        onChange(ev)
        {
            // console.log(ev);
            this.setState({date:new Date(ev)});
        }
          editTermin = (ev) => {
            confirmAlert({
                customUI: ({ onClose }) => {
                   
                    
                  return (
                    <div className='custom-ui'>
                      <h3>Propozycja wnioskodawcy</h3>
                      <p>Opisz sytuację i wyślij. Z pewnością znajdziemy sensowne rozwiązanie.</p>
                     <textarea className="form-control" data-action="editSMS" id="sms"></textarea>
                      <hr></hr>
                      <button className="btn btn-secondary btn-sm" onClick={onClose}>Anuluj</button>
                      &nbsp;
                      <button
                        className="btn btn-danger btn-sm" data-action="sendSMS"
                        onClick={(ev) => {    
                               
                               this.acceptTermin(ev);
                               onClose();
                        }}
                      >
                        Zapisz
                      </button>
                    </div>
                  );
                }
              });}

    acceptTermin(ev)
    {
        // console.log(ev.target);
        var self = this;
        switch (ev.target.dataset.action)
        {
            case 'accept':self.setState({page:0}); self.props.ask("firma", "acceptTermin", JSON.stringify({action:"acceptTermin",wpis:ev.target.dataset.id, wniosek:ev.target.dataset.wniosek, newstatus:ev.target.dataset.newstatus, opis:""}));break;
            case 'change':self.editTermin(); self.setState({sms:ev.target.dataset});break;
            case 'editSMS':self.setState({sms:ev.target.value});break;
            case 'sendSMS':
                var x = self.state.sms;
                var toSend = {wpis:x.id, wniosek:x.wniosek, newstatus:x.newstatus, opis:document.getElementById("sms").value, action:"changeTermin"};
                self.setState({page:0});self.props.ask('firma', "acceptTermin", JSON.stringify(toSend));
            break;
            case 'archive':
                // console.log(ev.target.dataset);   
                self.setState({page:0});self.props.ask("firma", "makeArchive", ev.target.dataset.wniosek);
            break;
            case 'uzupelnienie':
                    self.setState({page:0});self.props.ask("firma", "acceptTermin", JSON.stringify({action:"uzupelnienie",wpis:ev.target.dataset.id, wniosek:ev.target.dataset.wniosek, newstatus:ev.target.dataset.newstatus, opis:""}));break;
            default:break;
        }
    }
    renderDocumentClass(e)
    {
        //var doc = JSON.parse(e.target.dataset.doc);
        var formId = e.target.dataset.data;
        // var docName = e.target.dataset.docname;
        // var doclist = this.state.zalaczniki;
        e.target.classList.toggle("active");
        // if (!doclist.includes(docName))
        // {

        //     e.target.classList.add("active");
        // }
        // else
        // {
        //     e.target.classList.remove("active");
        // }
        this.setState({formId:formId, page:0});
        // //console.log("RDCLASS", e.target.dataset);
    }

    toogleDocClass(ev)
    {
            // if (!values[names].includes(wpisz))
            // {
            //     values[names].push(wpisz);
            //     ev.target.classList.add("active");
            // }
            // else
            // {
            //     var pos = values[names].indexOf(wpisz);
            //     values[names].splice(pos,1);
            //     ev.target.classList.remove("active");
            //     //console.log("REMOVING", wpisz, pos);
                
            // }
        
    }
    editCash = (aa) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            var oggi = new Date();
            var cy = oggi.getFullYear();
            // console.log(aa.target);
            
                //Tworzenie obiektu o odpowiedniej str.
                var dde = this.state.dde;
                dde.id = aa.target.dataset.data;
                dde.kwota = dde.kwota ;
                dde.rat = dde.rat;
                dde["brutto"+cy]= dde["brutto"+cy];
                dde["brutto"+(cy-1)]= dde["brutto"+(cy-1)];
                dde["brutto"+(cy-2)]= dde["brutto"+(cy-2)];
    
                dde["koszty"+cy]= dde["koszty"+cy];
                dde["koszty"+(cy-1)]= dde["koszty"+(cy-1)];
                dde["koszty"+(cy-2)]= dde["koszty"+(cy-2)];
    
                dde["amortyzacja"+cy]= dde["amortyzacja"+cy];
                dde["amortyzacja"+(cy-1)]= dde["amortyzacja"+(cy-1)];
                dde["amortyzacja"+(cy-2)]= dde["amortyzacja"+(cy-2)];
    
                this.setState({dde:dde});
            
            
            
            return (
            <div className='custom-ui'>
                <h3>Edycja danych finansowych</h3>
                
                <table id="zmianaFinansow">
                    <thead>
                        <tr>
                            <td>Rok podatkowy</td>
                            <td>Dochód netto</td>
                            <td>Koszty</td>
                            <td>Amortyzacja</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{cy}</td>
                            <td><input type="text" value={this.state.dde["brutto"+cy]} data-name={"brutto"+cy} onChange={this.changeFinans} /></td>
                            <td><input type="text" value={this.state.dde["koszty"+cy]} data-name={"koszty"+cy} onChange={this.changeFinans} /></td>
                            <td><input type="text" value={this.state.dde["amortyzacja"+cy]} data-name={"amortyzacja"+cy} onChange={this.changeFinans} /></td>
                        </tr>

                        <tr>
                            <td>{cy-1}</td>
                            <td><input type="text" value={this.state.dde["brutto"+(cy-1)]} data-name={"brutto"+(cy-1)} onChange={this.changeFinans} /></td>
                            <td><input type="text" value={this.state.dde["koszty"+(cy-1)]} data-name={"koszty"+(cy-1)} onChange={this.changeFinans} /></td>
                            <td><input type="text" value={this.state.dde["amortyzacja"+(cy-1)]} data-name={"amortyzacja"+(cy-1)} onChange={this.changeFinans} /></td>
                        </tr>

                        <tr>
                            <td>{cy-2}</td>
                            <td><input type="text" value={this.state.dde["brutto"+(cy-2)]} data-name={"brutto"+(cy-2)} onChange={this.changeFinans} /></td>
                            <td><input type="text" value={this.state.dde["koszty"+(cy-2)]} data-name={"koszty"+(cy-2)} onChange={this.changeFinans} /></td>
                            <td><input type="text" value={this.state.dde["amortyzacja"+(cy-2)]} data-name={"amortyzacja"+(cy-2)} onChange={this.changeFinans} /></td>
                        </tr>
                        <tr>
                            <td>Wnioskowana kwota</td>
                            <td><input type="text" value={this.state.dde.kwota} data-name="kwota" onChange={this.changeFinans} /></td>
                            <td>Ilość rat</td>
                            <td><input type="text" value={this.state.dde.rat} data-name="rat" onChange={this.changeFinans} /></td>
                        </tr>

                    </tbody>
                </table>

                <button className="btn btn-secondary btn-sm" onClick={onClose}>Anuluj</button>
                &nbsp;
                <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                    this.setState({page:0});
                    var self = this;
                    
                    this.props.ask("firma","zmianaWniosku", JSON.stringify({id:this.state.dde.id,data: this.state.dde}));
                    setTimeout(() => {
                        self.setState({dde:{}, zalaczniki:[]});
                    }, 100);
                    onClose();
                }}
                >
                Zapisz
                </button>
            </div>
            );
        }
        });}

        changeFinans(ev)
        {
            var dde = this.state.dde;
            dde[ev.target.dataset.name]= parseInt(ev.target.value);
            // //console.log("TYPE",typeof parseInt(ev.target.value), parseInt(ev.target.value));
            if (isNaN(parseInt(ev.target.value))){dde[ev.target.dataset.name]=0;}
            ev.target.value = dde[ev.target.dataset.name];
            this.setState({dde:dde});
        }
    IsJsonString(str) { try { JSON.parse(str); } catch (e) { return false; } return true;}
      
    renderStyle(a)
    {
        var enabled = [3,6,9,13,14];
        if (enabled.includes(parseInt(a)))
        {
            return {disaplay:"block"};
        }
        else
        {
            return {display:"none"};
        }
    }
    policzZdolnosc(wn){return (<div>{AF.zdolnosc(wn)}</div>)}

    detailview(ev)
    {
        // console.log(ev.target.dataset, this.props.ask);
        var self = this;
        switch (ev.target.dataset.action)
        {
            case "delete":
                self.setState({toDelete:ev.target.dataset.id});
                self.submit();
                break;
            case "szczeg":
                self.props.ask("firma", "szczegolyWniosku", ev.target.dataset.id);
                setTimeout(() => {
                    self.setState({page:1});
                }, 400);
                 
                break;
            case "powrot":
                    self.setState({page:0});
                break;
            default:break;
        }

    }
    rysujSory(e)
    {
        //Rozpakowanie danych
        var a = "";
        var txt = "Zapis nie powiódł się lub nie było danych do zapisu";
        if (this.IsJsonString(e)){a = JSON.parse(e);}
        else
        {
            txt = "IS NO JSON ->"+(typeof e);
        }
        
       //Identyfikacja
       if (typeof a.bylo !== 'undefined')
       { 
           if (typeof a.bylo.rat !== "undefined")
            {
                txt = AF.bylojestTabela(e);
            
            }
            else if (typeof a.bylo.dokumenty !== "undefined")
            {
                txt = AF.dokumenty(a);
            }
        }
        else if (typeof a.dokument !== "undefined")
        {
            if (a.dokument==="wpis")
            {
                txt = AF.san(a.adres);
            }
            if (a.dokument==="konsultacja")
            {
                txt = "<b>Wynik konsultacji:</b><br>"+AF.san(a.adres);
                //if (this.state.archiwizuj!=={display:"block"}){this.setState({archiwizuj:{display:"block"}});}
            }
            if (a.dokument==="uzupelnienie")
            {
                txt = "<span class='red'>Wymagane uzupełnienie</span><br>"+AF.san(a.adres);
            }
        }
        else if (typeof a.spotkanie !== "undefined")
        {
            txt = AF.spotkanie(a);
        }
        else
        {
            txt = JSON.stringify(a);
        }
        return (<span dangerouslySetInnerHTML={{__html: txt}}>
           
           
        </span>)
    }
    isMetting(u)
    {
        if (u===null || typeof u === 'undefined' || isNull(u))
        {return false;}
        else{
        var e = JSON.parse(u);
        // console.log(u,e);
        if (typeof e.spotkanie !== 'undefined')
        {
            if (e.spotkanie === 'proponowane')
            {return true;}else{return false;}
        }else{return false;}}
    }
    editData(ev)
    {
        //var editable = ['kwota', 'rat', 'osoba', 'tel', 'mail']
        // console.log(ev.target.dataset);
    }
 
    rysuj(a,u)
    {
        if (a.pole==='label')
        {
            return (
                <div><b>{AF.san(a.value)}</b></div>
            )
        }
        else if (a.pole==="select")
        {
            var text = "###";
            if (a.option.length>1)
            {
                for (var x=0; x<a.option.length; x++)
                {
                    if (a.option[x].value===u[a.name]){text = a.option[x].text;}
                }
            }
            else
            {
                text = u[a.name];
            }
            return(<span>
                <div className={a.col}><div className="wniosekTytul">{a.title}</div><div className="wniosekDane" data-action="edit" data-debug="3" data-klucz={a.name} data-option={JSON.stringify(a.option)} onClick={this.editData}>{AF.san(text)}</div></div>
                
            </span>) 
        }
        else if (a.pole==="text")
        {
            return(<span>
                <div className={a.col}><div className="wniosekTytul">{a.title}</div><div className="wniosekDane" data-action="edit" data-debug="4" data-klucz={a.name} data-option={JSON.stringify(a.option)} data-value={AF.san(u[a.name])} onClick={this.editData}>{AF.san(u[a.name])}</div></div>
            </span>) 
        }
        else if (a.pole==="finans")
        {
            var dzielnik =[1,2,3,4,5];
            var rys = [];
            var wierszy = u.finanse.length/dzielnik[parseInt(u.ksiegowosc)];
            
            for (var a = 0; a<wierszy; a++)
            {
                if (parseInt(u.ksiegowosc)===5){rys.push([u.finanse[a*5+0],u.finanse[a*5+1],u.finanse[a*5+2],u.finanse[a*5+3],u.finanse[a*5+4]]);}
                if (parseInt(u.ksiegowosc)===1){rys.push([u.finanse[a*2+0],u.finanse[a*2+1]]);}
                if (parseInt(u.ksiegowosc)===2){rys.push([u.finanse[a*3+0],u.finanse[a*3+1],u.finanse[a*3+2]]);}
                if (parseInt(u.ksiegowosc)===3){rys.push([u.finanse[a*4+0],u.finanse[a*4+1],u.finanse[a*4+2],u.finanse[a*4+3]]);}

            }
            
            switch (parseInt(u.ksiegowosc))
            {
                case 1:  return (<div className="row">
                    <div className="col-sm-12">
                        <b>Wyniki finansowe</b> 
                        <table className="setka wynikifinansowe">
                            <thead>
                                <tr>
                                    <td>Rok</td>
                                    <td>Stawka</td>
                                    
                                </tr>
                            </thead>
                            <tbody>
                            {rys.map((key,i)=>(
                                <tr key={"AK1"+i}>
                                    <td>{key[0]}</td>
                                    <td>{key[1]}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div></div>); 
                    break;
                    case 2:  return (<div className="row">
                    <div className="col-sm-12">
                        <b>Wyniki finansowe</b> 
                        <table className="setka wynikifinansowe">
                            <thead>
                                <tr>
                                    <td>Rok</td>
                                    <td>Przychód</td>
                                    <td>Stawka ryczałtu</td>
                                    
                                </tr>
                            </thead>
                            <tbody>
                            {rys.map((key,i)=>(
                                <tr key={"AK1"+i}>
                                    <td>{key[0]}</td>
                                    <td>{key[1]}</td>
                                    <td>{key[2]}%</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div></div>); 
                    break;
                    case 3:  return (<div className="row">
                    <div className="col-sm-12">
                        <b>Wyniki finansowe</b> 
                        <table className="setka wynikifinansowe">
                            <thead>
                                <tr>
                                    <td>Rok</td>
                                    <td>Przychód</td>
                                    <td>Koszty</td>
                                    <td>Amortyzacja</td>
                                    
                                </tr>
                            </thead>
                            <tbody>
                            {rys.map((key,i)=>(
                                <tr key={"AK1"+i}>
                                    <td>{key[0]}</td>
                                    <td>{key[1]}</td>
                                    <td>{key[2]}</td>
                                    <td>{key[3]}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div></div>); 
                    break;
                case 4:  return (<div className="row">
                    <div className="col-sm-12">
                        <b>Wyniki finansowe</b> 
                        <table className="setka wynikifinansowe">
                            <thead>
                                <tr>
                                    <td>Rok</td>
                                    <td>Aktywa</td>
                                    <td>Pasywa</td>
                                    <td>Obrót</td>
                                    <td>Zysk / Strata</td>
                                </tr>
                            </thead>
                            <tbody>
                            {rys.map((key,i)=>(
                                <tr key={"AK5"+i}>
                                    <td>{key[0]}</td>
                                    <td>{key[1]}</td>
                                    <td>{key[2]}</td>
                                    <td>{key[3]}</td>
                                    <td>{key[4]}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div></div>); 
                    break;
                default: return (<div>Nie ma parsera</div>);break;
            }
            return (<div className="col-sm-12"><b>Wyniki finansowe</b> {JSON.stringify(u.finanse)}</div>)
        }
        else
        {
            return (<span data-meta={JSON.stringify(a)}></span>)
        }
        
            
    }
    render(){
        if (this.props.document.length>0)
        {
            if (this.state.page===0)
            {
        return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    
                    
                    </div>
                    {this.props.document.map((key, i)=>(
                        
                        <div data-id={key.id} className="wniosekk clickable" key={i}> 
                            <div className="ringing">Dokument DB/{key.data_create.substr(5,2)}{key.id}/
                            {key.data_create.substr(0,4)}<FaRegBell style={this.renderStyle(key.status)} className="bell"/></div>
                            <FiFile className="bigicon"/> {key.nazwa} <br></br>złożony {key.data_create}
                            <Thh status={key.status}/>
                            <div className="pseudobtn" data-id={key.id} data-action="szczeg" onClick={this.detailview}> Szczegóły</div>
                            <div style={(parseInt(key.status)===1 || parseInt(key.status)===4) ? {display:"inline-block"} : {display:"none"}} className="pseudobtn btn-d" data-id={key.id} data-action="delete" onClick={this.detailview}> Usuń</div>
                        </div>
                    ))}
                </div>   
            </div>);
            }
            else if (this.state.page===1)
            {
                if (typeof this.props.szczegolyWniosku.wniosek.id ==="undefined" )
                {

                    return (<div>Oczekiwanie na dane....
                        {JSON.stringify(this.props.szczegolyWniosku)}
                        <hr></hr>
                            <button className="btn btn-secondary" data-action="powrot" onClick={this.detailview}>powrót</button>
                            
                    </div>)
                }
                else
                {
                    var wn = {};
                    var form={settings:{formName:"BD"},form:[]};
                    if (this.IsJsonString(this.props.szczegolyWniosku.wniosek.content)){wn=JSON.parse(this.props.szczegolyWniosku.wniosek.content);}
                    if (this.IsJsonString(this.props.szczegolyWniosku.formularz)){form=JSON.parse(this.props.szczegolyWniosku.formularz);}
                    var edytujacy = ["Brak danych", "Wnioskodawca", "Opiekun", "Wybrany Bank"];
                    //  SKRUTY VAR 
                    var status = parseInt(this.props.szczegolyWniosku.wniosek.status);
                    // var uprawnienia = parseInt(this.props.szczegolyWniosku.wniosek.uprawnienia);
                    
                    //  TABLICE Z OPCJAMI PER STATUS
                    var kwoty=[1,4,6];
                    var zalaczniki=[1,4,6,9];
                    // var termin=[13,14];

                    // STYLE WATUNKOWE
                    var ukryj = {display:"none"}; var pokaz = {display:"inline-block"}
                    var kwotyStyl=ukryj; if (kwoty.includes(status)){kwotyStyl=pokaz;}
                    var zalacznikiStyl=ukryj; if (zalaczniki.includes(status)){zalacznikiStyl=pokaz;}
                    // var terminStyl=ukryj; if (termin.includes(status)){terminStyl=pokaz;}

                    //  WYŚWIETLANIE HISTORII WNIOSKU
                    var historia = {display:"none"};
                    if (this.props.szczegolyWniosku.historia.length>0){historia={display:"inline-table"};}
                    // 
                    
                    // WYŚWIETLANIE AKCEPTACJI TERMINU
                    // var akceptacja_terminu = [13,14];
                    // var terminy = {display:"none"};
                    // if (akceptacja_terminu.includes(status)){terminy={display:"block"};}

                    // MOŻE WNIOSKODAWCA
                    // var wnioskodawca = {display:"none"};
                    // var mozeWnioskodawca = [1,4,6,7,9,13,14];
                    // if (mozeWnioskodawca.includes(status)){wnioskodawca={display:"block"};}
                    // var dokumenty = {display:"block"};
                    
                return (
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                            
                            <h3 onClick={this.debug}>Wniosek HB/{this.props.szczegolyWniosku.wniosek.data_create.substr(5,2)}{this.props.szczegolyWniosku.wniosek.number}/{this.props.szczegolyWniosku.wniosek.data_create.substr(0,4)}</h3>
                            <b>{form.settings.formName}</b>
                            {/* <div>Obliczona zdolność kredytowa: </div> */}
                            
                            <i className="float-right"> aktualny status wniosku: {this.props.szczegolyWniosku.wniosek.nazwa}<br></br>
                            Przyznany opiekun: {(this.props.szczegolyWniosku.wniosek.opieka===null) ? "Nie przydzielono" : this.props.szczegolyWniosku.wniosek.opieka} <br></br>
                            {/* Prawo do edycji dokumentu posiada: {edytujacy[this.props.szczegolyWniosku.wniosek.uprawnienia]}*/}
                            </i> <br></br> 
                            <span className="pseudobtn" data-action="powrot" onClick={this.detailview}><TiArrowBackOutline/> Powrót do listy</span>
                            {/* <span className="pseudobtn" 
                                style={kwotyStyl} 
                                data-data={this.props.szczegolyWniosku.wniosek.id}
                                onClick={this.routeEditCash}>
                                    <FaRegBell/> Edytuj dane finansowe
                            </span> */}
                            <span className="pseudobtn" 
                                style={zalacznikiStyl} 
                                data-data={this.props.szczegolyWniosku.wniosek.id} 
                                data-doc={JSON.stringify(wn.dokumenty)} 
                                onClick={this.editDocuments}>
                                    <FaRegBell/> Edytuj listę załączników
                            </span>
                            
                           
                            
                            <br></br> <br></br>
                            <hr></hr>

                           
                               
                            

                            <b>Historia edycji dokumentu</b>
                            <div  key="AOX">
                                <div className="historiadokumentu" style={(this.props.szczegolyWniosku.historia.length===0) ? {display:"none"} :{display:"block"}}>
                                <table className="historiawniosku" style={historia}>
                                    <thead>
                                        <tr>
                                            <td>Data wpisu</td>
                                            <td>Autor wpisu</td>
                                            <td>Wprowadzone zmiany</td>
                                        </tr>
                                    </thead>
                                    <tbody>

   
                                {this.props.szczegolyWniosku.historia.map((key,i)=>(
                                    <tr key={"AOX"+i}   style={(this.isMetting(key.tresc)) ? {backgroundColor:"#eac4ff"}  : {backgroundColor:"white"} }>
                                        <td>{key.data}</td>
                                       <td>{key.wpis}</td>
                                       <td>{AF.san(this.rysujSory(key.tresc))} 
                                       <div style={(this.isMetting(key.tresc)) ? {display:"block"}  : {display:"none"} }>
                                           
                                            <span className="pseudobtn" data-action="accept" data-newstatus="15" data-wniosek={this.props.szczegolyWniosku.wniosek.id} data-id={key.id} onClick={this.acceptTermin}>Akceptuj</span> 
                                            <span className="pseudobtn" data-action="change" data-newstatus="14" data-wniosek={this.props.szczegolyWniosku.wniosek.id} data-id={key.id} onClick={this.acceptTermin}>Zaproponuj zmianę</span> 
                                            
                                        </div>
                                        
                                        <div style={(JSON.parse(key.tresc).dokument==='konsultacja')? {display:"block"} : {display:"none"}}>
                                            <span className="pseudobtn" data-action="archive" data-newstatus="25" data-wniosek={this.props.szczegolyWniosku.wniosek.id} data-id={key.id} onClick={this.acceptTermin}>Archiwizuj</span> 
                                        </div>
                                        <div style={(JSON.parse(key.tresc).dokument==='aktualizacja')? {display:"block"} : {display:"none"}}>
                                            <span className="pseudobtn" data-action="archive" data-newstatus="25" data-wniosek={this.props.szczegolyWniosku.wniosek.id} data-id={key.id} onClick={this.acceptTermin}>Oznacz aktualizację</span> 
                                        </div>
                                        
                                        <div style={(JSON.parse(key.tresc).dokument==='uzupelnienie')? {display:"block"} : {display:"none"}}>
                                            <span className="pseudobtn" data-action="uzupelnienie" data-newstatus="7" data-wniosek={this.props.szczegolyWniosku.wniosek.id} data-id={key.id} onClick={this.acceptTermin}>Oznacz aktualizację danych</span> 
                                        </div>
                                        
                                        
                                        
                                        
                                        </td>
                                        
                                    </tr>
                                ))}
                                </tbody>
                                </table>

                                </div>
                                <div className="nodoc" style={(this.props.szczegolyWniosku.historia.length>0) ? {display:"none"} :{display:"block"}}>Nie wprowadzono zmian</div>  

                                <b>Załączone dokumenty</b>
                            <div>
                              <div className="nodoc" style={(wn.dokumenty.length>0) ? {display:"none"} :{display:"block"}}>Brak dokumentów</div>  

                            {wn.dokumenty.map((key, i)=>(

                                    <div key={i}>{key}</div>
                                ))}
                            </div>
                                <div className="trescwniosku"> 
                                    <h2>Treść wniosku</h2>
                                
                                {form.form.map((key,i)=>(
                                    <span key={i}>{this.rysuj(key, wn)}</span>
                                ))}
                                </div>
                            </div>
                            {/* <button className="btn btn-secondary" data-action="powrot" onClick={this.detailview}>powrót</button> */}
                            </div> 
                        </div>
                    </div>)
                }
            }
            else
            {
                return(<div>Coś poszło nie tak</div>)
            }
        }
        
        else
        {
            return(
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                    {/* <div dangerouslySetInnerHTML={{__html: this.props.opis.opisWnioski}} /> */}
                        <div className="listadokumentow">
                            <h3>Brak złożonych dokumentów</h3>

                            <small>W tym miejscu pojawią się złożone przez Ciebie wnioski, jeśli będą procedowane przez osobistego Brokera</small>
                        </div>
                    </div>
                </div>

            </div>
            )
        }
}

}

export default Documentlist;