import React  from "react";
import axios from 'axios';
import security from './../common/security.js';
//import Cookies from 'js-cookie';
import { FacebookProvider, LoginButton, Profile } from 'react-facebook';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import {FaCheckCircle } from "react-icons/fa";

class Register extends React.Component{
    constructor(props) {
        super(props);
        this.state = { server:"",requiestSended:true,
            odpowiedz:'czekam', login:"",pass:"", pass2:"", nip:"", czyfirma:0,validmail:false, validnip:false,validpass:false,opis:"",
        };
        this.wyslij = this.wyslij.bind(this);
        this.readForm = this.readForm.bind(this);
        this.rejestr1 = this.rejestr1.bind(this);
        this.rejestr2 = this.rejestr2.bind(this);
        this.readRadio = this.readRadio.bind(this);
        this.chandleNIP = this.chandleNIP.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validatePass = this.validatePass.bind(this);
        this.loginWithFacebook = this.loginWithFacebook.bind(this);
        this.ask = this.ask.bind(this);
    }
    server = () => { confirmAlert({  customUI: ({ onClose }) => { return ( <div className='custom-ui'><h3>Komunikat serwera</h3><p>{this.state.server}</p> <button className="btn btn-danger btn-sm" onClick={onClose} >Zamknij</button>  </div> ); } });}

    readRadio(ev)
    {
        console.log(ev.target, this.state);
        this.setState({czyfirma:parseInt(ev.target.dataset.role)});
    }
    loginWithFacebook(loaded, profile)
    {
        if (typeof profile !=='undefined' && this.state.requiestSended===true)
        {
                //console.log("USERPROFILE", profile, loaded,this.state.requiestSended);
                    setTimeout(() => {
                        this.ask("addUserfromFacebook", JSON.stringify(profile));
                    }, 50);
                
                if (this.state.requiestSended===true){this.setState({requiestSended:false});}
        }
    }
    readForm(e)
    {
        //console.log(e.target.id);
        switch (e.target.id)
        {
            case 'login':this.setState({login: e.target.value});break;
            case 'pass':this.setState({pass: e.target.value});break;
            case 'pass2':this.setState({pass2: e.target.value});break;
            case 'nip':this.setState({nip: e.target.value});break;
            default:break;
        }
    }
    validatePass()
    {
        var pass1 = this.state.pass;
        var pass2 = this.state.pass2;
        if (pass1===pass2)
        {
            var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
            if (re.test(pass1))
            {
                this.setState({validpass:true, opis:""});
            }
            else
            {
                this.setState({validpass:false, opis:"Hasło musi zawierać duże i małe litery oraz cyfy."});
            }
        }
        else
        {
            this.setState({validpass:false, opis:"Hasła nie są identyczne"});
        }

    }
    ask(command, data)
    {
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'user');
            params.append('command', command);
            params.append('data',data);
            var self = this;
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
                //console.log(response.data.wartosc);
                if (response.data.status==="OK")
                {
                    switch (command){
                        case 'addUserfromFacebook': self.setState({server: response.data.desc}); self.server();return;
                        default: return;
                    }
                    
                }
                if (response.data.status==="err")
                {
                    var desc = response.data.desc
                    self.setState({server: desc}); self.server();
                    
                }
            }).catch(function (error) {console.log(error);self.setState({validmail:false});return false;}); 
    }
    validateEmail(ev)
    {
        var mail = ev.target.value;
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(String(mail).toLowerCase()))
        {
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'validator');
            params.append('command', 'mail');
            params.append('mail', ev.target.value);
            var self = this;
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
                //console.log(response.data.wartosc);
                console.log(response.data.data);
                if (response.data.data)
                {
                    self.setState({validmail:true});
                }
                else
                {
                    self.setState({validmail:false});
                }
            }).catch(function (error) {console.log(error);self.setState({validmail:false});return false;}); 
        }
        else
        {
            this.setState({validmail:false});
            return false;
        }
    }
    validatenip(nip) {
        var nipWithoutDashes = nip.replace(/-/g,"");
        var reg = /^[0-9]{10}$/;
        if(reg.test(nipWithoutDashes) === false) {
            
            return false;}
        else
        {
            var digits = (""+nipWithoutDashes).split("");
            var checksum = (6*parseInt(digits[0]) + 5*parseInt(digits[1]) + 7*parseInt(digits[2]) + 2*parseInt(digits[3]) + 3*parseInt(digits[4]) + 4*parseInt(digits[5]) + 5*parseInt(digits[6]) + 6*parseInt(digits[7]) + 7*parseInt(digits[8]))%11;
             
            return (parseInt(digits[9])===checksum);
        }
    }
    chandleNIP(ev)
    {
        var nip = ev.target.value;
        console.log(nip);
        if (!this.validatenip(nip)){this.setState({validnip:false});alert ("Niepoprawny numer NIP.");}
        else
        {
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'validator');
            params.append('command', 'nip');
            params.append('nip', nip);
            var self = this;
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
                //console.log(response.data.wartosc);
                console.log(response.data.data);
                if (response.data.data)
                {
                    self.setState({validnip:true});
                }
                else
                {
                    self.setState({validnip:false});
                    alert(response.data.desc);
                }
               
            }).catch(function (error) {self.setState({validnip:false});console.log(error);}); 
        }
    }
    rejestr1()
    {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-5">
                    <div className="register">
                <h4>Rejestracja osoby prywatnej <span className="float-right"><button data-role="1" className="btn btn-secondary btn-sm" onClick={this.readRadio}>Firma</button></span></h4>
                <hr className="prettyhr"></hr>
                <div className=" text-center">
                <FacebookProvider appId="803872199975201">
                    <LoginButton
                    className="btn btn-primary btn-sm"
                    scope="email"
                    onCompleted={this.responseFacebook}
                    onError={this.responseFacebook}
                    >
                    <span>Zarejestruj się przez Facebook</span>
                    </LoginButton>
                </FacebookProvider>

                <FacebookProvider appId="803872199975201">
                <Profile>
                {({ loading, profile }) => (
                   
                   <div>{ this.loginWithFacebook(loading, profile)}{ //console.log("FACEBOOK",loading, profile) 
                }</div>
                )}
        </Profile>
      </FacebookProvider> 
      </div>
       <div className="lub"></div>
                <div className="vF">
                    <label>Adres poczty elektronicznej</label>
                    <input type="text" className="form-control form-control-sm" id="login" value={this.state.login} onChange={this.readForm} onBlur={this.validateEmail}/>
                </div>
                <div className="vF">
                    <label>Hasło</label>
                    <input type="password" className="form-control form-control-sm" id="pass" value={this.state.pass} onChange={this.readForm} onBlur={this.validatePass}/>
                </div>
                <div className="vF">
                    <label>Powtórnie hasło</label>
                    <input type="password" className="form-control form-control-sm" id="pass2" value={this.state.pass2} onChange={this.readForm} onBlur={this.validatePass}/>
                </div>
                <div className="vF center">
                    <div className="col text-center">
                    <button type="button" className="btn btn-primary btn-sm" onClick={this.wyslij}>Dalej</button>&nbsp;
                    <div>{this.state.odpowiedz}</div>
                    <br></br>
                    
                    </div>
                </div>
            </div>

                    </div>
                    <div className="col-sm-3"></div>
                </div>

            </div>
            );
    }

    rejestr2()
    {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">

                    
            <div className="register">
                <h4>Rejestracja firmy <span className="float-right"><button data-role="0" className="btn btn-secondary btn-sm" onClick={this.readRadio}>Prywatna</button></span></h4>
                <hr className="prettyhr"></hr>
                
                <div className="vF">
                    <label>Adres poczty elektronicznej</label>
                    <input type="text" className="form-control form-control-sm" id="login" value={this.state.login} onChange={this.readForm} onBlur={this.validateEmail}/>
                </div>
                <div className="vF">
                <label>Hasło</label>
                    <input type="password" className="form-control form-control-sm" id="pass" value={this.state.pass} onChange={this.readForm} onBlur={this.validatePass}/>
                </div>
                <div className="vF">
                <label>Powtórnie</label>
                    <input type="password" className="form-control form-control-sm" id="pass2" value={this.state.pass2} onChange={this.readForm} onBlur={this.validatePass}/>
                </div>
                <div className="vF">
                    <label>NIP</label>
                    <input type="text" className="form-control form-control-sm" id="nip" value={this.state.nip} onChange={this.readForm} onBlur={this.chandleNIP}/>
                </div>
                <div className="vF center">
                    <div className="col text-center">
                    <button type="button" className="btn btn-primary btn-sm" onClick={this.wyslij}>Dalej</button>&nbsp;
                    <div>{this.state.odpowiedz}</div>
                    <br></br>
                    </div>
                </div>
            </div>
                
            </div>
                    <div className="col-sm-4"></div>
                </div>

            </div>);
    }
    rejestr3()
    {
        return(<div className="container">
            <div className="row">
                <div className="col-sm-3"></div>
                <div className="col-sm-6">
                    <h3><FaCheckCircle className="green"/> Konto dodano poprawnie</h3>
                    <hr className="prettyhr"></hr>
                    Aby aktywować swoje konto wykonaj instrukcje zawarte w wiadomości mail.
                </div>
                <div className="col-sm-3"></div>
            </div>
            
        </div>)
    }
    wyslij()
    { 
        var poszlo=false;
        //console.log("VALIDATOR",this.state.validmail,this.state.validpass, this.state.czyfirma, this.state.validnip);
        if (this.state.validmail && this.state.validpass && this.state.czyfirma===0){poszlo=true;}
        if (this.state.validmail && this.state.validpass && this.state.czyfirma===1 && this.state.validnip){poszlo=true;}

        if (poszlo)
        {
            var self = this;
            const params = new URLSearchParams(); params.append('asc',security.securityKey());
            params.append('section', 'user');
            params.append('command', 'register');
            params.append('login', JSON.stringify(self.state));
            axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
                console.log(response.data);
                if (response.data.status==="OK")
                {
                    self.setState({czyfirma:3});
                }
                self.setState({odpowiedz:response.data.desc});
            }).catch(function (error) {console.log(error); self.setState({odpowiedz:"ERR"});});
        }
        else
        {
            alert("Dane nie są kompletne");
        }
    }
    render(){
        ////console.log("LG===");
        if (this.state.czyfirma===0)
        {
            return(this.rejestr1())
        }
        if (this.state.czyfirma===1)
        {
            return(this.rejestr2())
        }
        if (this.state.czyfirma===3)
        {
            return(this.rejestr3())
        } 
    }
}


export default Register;