function exists(e) {if (typeof e !== 'undefined') {return true;}else{return false;}}
function liczZdolnoscFirmy(data)
{
    var dzis = new Date();
    var rok = dzis.getFullYear();
    var brutto = 0;
    if (exist(data['brutto'+rok])){brutto += parseInt(data['brutto'+rok]);}
    if (exist(data['brutto'+(rok-1)])){brutto += parseInt(data['brutto'+(rok-1)]);}
    if (exist(data['brutto'+(rok-2)])){brutto += parseInt(data['brutto'+(rok-2)]);}

    var koszty = 0;
    if (exist(data['koszty'+rok])){koszty += parseInt(data['koszty'+rok]);}
    if (exist(data['koszty'+(rok-1)])){koszty += parseInt(data['koszty'+(rok-1)]);}
    if (exist(data['koszty'+(rok-2)])){koszty += parseInt(data['koszty'+(rok-2)]);}

    var amortyzacja = 0;
    if (exist(data['amortyzacja'+rok])){amortyzacja += parseInt(data['amortyzacja'+rok]);}
    if (exist(data['amortyzacja'+(rok-1)])){amortyzacja += parseInt(data['amortyzacja'+(rok-1)]);}
    if (exist(data['amortyzacja'+(rok-2)])){amortyzacja += parseInt(data['amortyzacja'+(rok-2)]);}

    var rozpoczecie = dzis;
    if (exist(data.datar))
    {
        rozpoczecie = new Date(data.datar);
    }

    var zdolnosc = 0;
    var miesiecy = (dzis - rozpoczecie)/(1000*60*60*24*30); 
    if (miesiecy>=36) {zdolnosc = ((brutto + amortyzacja) - koszty)/36}
    else
    if (miesiecy>=6){zdolnosc = ((brutto + amortyzacja) - koszty)/miesiecy}
    else {zdolnosc = 0}
    return Math.round(zdolnosc);

}


function exist(el)
{
    if (typeof el !== 'undefined'){return true;}else{return false;}
}
function id2wielkosc(id)
{
    var odp = ["Brak danych","Samozatrudnienie","2-9 pracowników",
    "10 - 15 pracowników","16 - 50 pracowników","< 50 pracowników"];
    return odp[parseInt(id)];
}
function id2ksiegowosc(id)
{
    var odp = ["Brak danych","Karta podatkowa","Ryczałt ewidencjonowany","Książka przychodów i rozchodów",
    "Pełna księgowość"];
    return odp[parseInt(id)];
}

function id2dzialalnosc(id)
{
    var odp = ["Brak danych","Jednoosobowa działalność gospodarcza","Spółka Cywilna","Spółka Jawna","Spółka partnerska",
    "Spółka Komandytowa","Spółka komandytowo - Aukcyjna","Spółka z ograniczoną odpowiedzialnością",
    "Spółka Akcyjna"];
    return odp[parseInt(id)];
}
function id2zgoda(id)
{
    var odp = "Nie";
    if (parseInt(id)===1){odp="Tak";} return odp;
}

function id2woj(id)
{
    var woj = ["Undef", "dolnośląskie", "kujawsko-pomorskie", 'lubelskie','lubuskie', 'łódzkie', 'małopolskie', 'mazowieckie', 'opolskie', 'podkarpackie', 'pomorskie', 'podlaskie','śląskie', 'świętokrzyskie', 'warmińsko-mazurskie', 'wielkopolskie', 'zachodniopomorskie'];
    return woj[id];
}

function san(str)
{
    ////console.log("TO_SAN",str);
    var odp = str.toString();
    if (typeof odp !=='undefined' && odp !=="" && odp !==null){
    var unic = ["u0119","u00f3","u0105","u015b","u0142","u017c","u017a","u0107","u0144","u0118","u00d3","u0104","u015a","u0141","u017b","u0179","u0106","u0143"];
    var lett = ["ę","ó","ą","ś","ł","ż","ź","ć","ń","Ę","Ó","Ą","Ś","Ł","Ż","Ź","Ć","Ń"]
    for (var a = 0; a<unic.length; a++)
    {
        odp = odp.split(unic[a]).join(lett[a]);
    }}
    return odp;
}
function mexist(d){return (typeof d !== 'undefined')}
function contactsFromForm(d)
{
    var odp = "";
    ////console.log("CFF", d.data.form, d.data.product);
    if (mexist(d.data))
    {
        if (mexist(d.data.form))
        {
            if (mexist(d.data.form.nazwa)){odp +="Firma " + san(d.data.form.nazwa)+" ";}
            if (mexist(d.data.form.osoba)){odp +="osoba " + san(d.data.form.osoba)+" ";}
            if (mexist(d.data.form.tel))  {odp +="tel: " + san(d.data.form.tel)+" ";}
            if (mexist(d.data.form.mail)) {odp +="mail " + san(d.data.form.mail)+" ";}
        
        }
        if (mexist(d.data.product))
        {
            odp += "<a href='"+d.data.product+"' target='_blank' className='pseudobtn' >Produkt</a>"
        }
    }
   
    return odp;
}

function rebulidObject(o)
{
    var odp = [];
    if (Array.isArray(o))
   {
    for (var a = 0; a<o.length; a++)
    {
        odp.push(o[a]);
    }}
    else
    {
        odp.push(o);
    }
    return odp;
}

function getProductList(a, product)
{
    if (typeof a.kategoria !== "undefined")
    {
        for (var x = 0; x<a.kategoria.length; x++)
        {
            //console.log("x=", x, typeof a.kategoria[x]["@attributes"]);
            if (typeof a.kategoria[x]["@attributes"] !== "undefined")
            {
               
                if (typeof a.kategoria[x]["@attributes"]['nazwa'] !== "undefined")
                {//console.log("PARSING",a.kategoria[x]["@attributes"]['nazwa'], a.kategoria[x]);
            }
                if (a.kategoria[x]["@attributes"]['nazwa']===product && typeof a.kategoria[x].podkategoria !== "undefined")
                {
                    return (a.kategoria[x].podkategoria)
                }
            }
        }
    }
    return [];
}
function IsJsonString(str) { try { JSON.parse(str); } catch (e) { return false; } return true;}

module.exports = {
    IsJsonString,
    getProductList,
    rebulidObject,
    contactsFromForm,
    san,
    id2woj,
    id2zgoda,
    id2dzialalnosc,
    id2ksiegowosc,
    id2wielkosc,
    liczZdolnoscFirmy,
    exists,
}
