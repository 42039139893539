import React  from "react";
import axios from 'axios';
import security from  './../common/security.js';
import Cookies from 'js-cookie';

import ReactTable from 'react-table';
// import Symulacja from './../firma/symulacja.js';
import redux from './../common/manager.js';
class Applications extends React.Component{
    constructor(props) {
        super(props);
        this.state = { page:0, data:[], dane:[],loading:true, user:{adres:"",ai:"",bank:"", created:"",data:"",do:"",doswiadczenie:"",emitent:"",finanse:"",foto:"",from:"", konto:"",kwota:"",lastlogin:"",lastname:""}, opis:"", status:"", log:[]
           
        };
        
        
        this.componentDidMount = this.componentDidMount.bind(this);
        this.ask = this.ask.bind(this);
        this.askk = this.askk.bind(this);
        this.home = this.home.bind(this);
        this.getLista = this.getLista.bind(this);
        this.MyCell = this.MyCell.bind(this);
        this.MyCel2 = this.MyCell2.bind(this);
        this.buttonRouter = this.buttonRouter.bind(this);
        this.zmianStatus = this.zmianStatus.bind(this);
        this.changeState = this.changeState.bind(this);
        this.loadChanges = this.loadChanges.bind(this); 
        this.debug = this.debug.bind(this);
      }
    home(){
        this.loadChanges();
        this.setState({page:0});
    }
    debug()
    {
        console.log(this.state);
    }
    san(str)
    {
        var odp = str;
        if (typeof odp !=='undefined'){
        var unic = ["u0119","u00f3","u0105","u015b","u0142","u017c","u017a","u0107","u0144","u0118","u00d3","u0104","u015a","u0141","u017b","u0179","u0106","u0143"];
        var lett = ["ę","ó","ą","ś","ł","ż","ź","ć","ń","Ę","Ó","Ą","Ś","Ł","Ż","Ź","Ć","Ń"]
        for (var a = 0; a<unic.length; a++)
        {
            odp = odp.split(unic[a]).join(lett[a]);
        }}
        return odp;
    }
    changeState(ev)
    {
        //console.log(ev.target.value);
        var temp = this.state.user;
        temp.status = ev.target.value;
        this.setState({status:temp});
    }
    zmianStatus(){
        //console.log(this.state.user);
        this.ask('changeStatus', JSON.stringify({id:this.state.user.ide, status:this.state.user.status}));
    }
    loadChanges()
    {
        //this.ask('Readcontactform', null);
    }
    ask(resource, par)
    {
        var self = this;
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
        params.append('section', 'manager');
        params.append('command', resource);
        params.append('data',par );
        params.append('token', Cookies.get("Experimental"));
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
          console.log(response.data.data);
          if (response.data.status==="OK")
          {
            self.setState({loading:false});
              switch (resource){
                  case 'readApplications': self.setState({dane: response.data.data});break;
                  case 'getOnceApplication': self.setState({user: response.data.data});break;
                  case 'getLogApplication':self.setState({log:response.data.data});break;
                  default: break;
              }
             
          }
          
         }).catch(function (error) {console.log(error); self.setState({odpowiedz:"ERR"});});}
         

        buttonRouter(ev)
        {
            console.log(ev.target.dataset);
            if (ev.target.dataset.action==='info')
            {
                this.ask('getOnceApplication', ev.target.dataset.id);
                this.ask('getLogApplication', ev.target.dataset.id);
                this.setState({page:1});
            }
        }
        MyCell({ value, columnProps: { rest: { somefunc } } }) {
            return (
                <button type="button" className="btn btn-secondary btn-sm" data-id={value} data-action="info" onClick={this.buttonRouter}>Więcej</button>
                   
            )
          }
        MyCell2({ value, columnProps: { rest: { somefunc } } }) {
            
        return (
            <div>ABC</div>
                
        )
        }
    componentDidMount()
    {
        this.askk();
    }
    askk()
    {
        if (this.state.loading===true)
        { 
            console.log(this.state.loading);
            this.setState({loading:false});
            this.ask('readApplications', null);
            
        }
    }
    getLista()
    {
        console.log(this.state);
    }

    nws(x) {
        var xx = parseInt(x);
        return xx.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    parseUser(mail, id)
    {
        if (parseInt(id)===0)
        {
            return "Użytkownik niezalogowany";
        }
        else
        {
            return "Login: " + mail;
        }
    }
    render(){
        if (this.state.dane.length===0)
        {
            return (<div onClick={this.getLista}>Brak złożonych wniosków</div>)
        }
        else
        {
            if (this.state.page===0)
            {var userlist = this.state.dane;
            const columns = [
                {
                    Header: 'Nr wniosku',
                    accessor: 'id',
                    Cell: row =>{
                        ////console.log("ROW",row);
                        return(
                            <span>{row.row.id}/{row.row.data.substr(0,4)}</span>
                        )
                    }
                  },
                {
              Header: 'Data złożenia',
              accessor: 'data',
              filterMethod: (filter, row) =>
                      row[filter.id].startsWith(filter.value) ||
                      row[filter.id].endsWith(filter.value)
            },
            {
                Header: 'Opiekun',
                accessor: 'op',
                filterMethod: (filter, row) =>
                        row[filter.id].startsWith(filter.value) ||
                        row[filter.id].endsWith(filter.value)
              },
            {
              Header: 'Kwota',
              accessor: 'kwota',
              filterMethod: (filter, row) =>
                      row[filter.id].startsWith(filter.value) ||
                      row[filter.id].endsWith(filter.value)
            },
            {
                Header: 'Rat',
                accessor: 'rat',
                filterMethod: (filter, row) =>
                        row[filter.id].startsWith(filter.value) ||
                        row[filter.id].endsWith(filter.value)
              },
            {
              Header: 'HBP',
              accessor: 'punkty',
              filterMethod: (filter, row) =>
                      row[filter.id].startsWith(filter.value) ||
                      row[filter.id].endsWith(filter.value)
            },
            {
                Header: 'Status',
                accessor: 'status',
                filterMethod: (filter, row) =>
                        row[filter.id].startsWith(filter.value) ||
                        row[filter.id].endsWith(filter.value)
              },
            {
              Header: '#',
              accessor: 'id', // String-based value accessors!
              Cell: this.MyCell, getProps: () => ({ })
            },];

        return (<div> 
            <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><span onClick={this.home}>Lista</span></li>
                                    </ol>
                                </nav>
            <h3>Lista wniosków</h3>
                        <ReactTable
                            data={userlist}
                            
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                            columns={columns}
                            loading={this.state.loading}
                            onFetchData={this.askk} 
                            previousText={'Poprzednia'}
                            nextText={'Następna'}
                            loadingText={ 'Ładowanie danych...'}
                            noDataText={ 'Nie znaleziono danych'}
                            pageText={'Strona'}
                            ofText={'z'}
                            rowsText={'wierszy'}
                            
                            // Accessibility Labels
                            pageJumpText={'skocz do strony'}
                            rowsSelectorText= {'wierszy na stronę'}
                            defaultPageSize={10}
                        />
            </div>);}
            else if (this.state.page===1)
            {
                var u = this.state.user;
                
                var jaki = "Użytkownik indywidualny";
                switch (parseInt(u.rodzaj))
                {
                    case 1: jaki="Użytkownik Indywidualny";break;
                    case 2: jaki="Użytkownik Firmowy";break;
                    default:jaki="Użytkownik niezidentyfikowany";break;

                }
                var styleMedia = {tak:{display:"block"},nie:{display:"none"}};
                if (parseInt(u.vim)!==1){styleMedia = {nie:{display:"block"},tak:{display:"none"}};}
                var sukces = "";
                switch (parseInt(u.sukces))
                {
                    case 1: sukces="Spłacony przed terminem";break
                    case 2: sukces="Spłacony terminowo";break
                    case 3: sukces="W trakcie regularnej spłaty";break
                    case 4: sukces="Spłacony z opóźnieniem";break
                    case 5: sukces="W trakcie spłaty z upomnieniami";break
                    case 6: sukces="Niespłacany - do egzekucji";break
                    case 7: sukces="Niespłacany - w trakcie egzekucji";break
                    case 8: sukces="Po egzekucji";break
                    case 9: sukces="Odmowa udzielenia kredytu";break
                    default: sukces="Brak danych";break;
                }
               
                return (<div>
                    <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><span onClick={this.home}>Lista</span></li>
                                        <li className="breadcrumb-item">Treść</li>
                                    </ol>
                                </nav>

                   <div className="row">
                       <div className="col-sm-9">
                            <div className="contact header">Data złożenia: {u.data} <br></br>
                            Account Manager: {u.op} od: {u.zaopiekowany}  WIDOCZNOŚĆ: {u.visible}</div>
                       </div>
                       <div className="col-sm-3">
                           {/* <label>Status</label>
                           <select className="form-control" onChange={this.changeState} value={this.state.user.status}>
                               <option value="NEW">Nowy</option>
                               <option value="WORK">W trakcie</option>
                               <option value="END">Zakończony</option>
                           </select> */}
                       </div>
                   </div>
                   <div className="row">
                       <div className="col-sm-12">
                           <hr className="prettyhr"></hr>
                           <h3>Szczegóły oferty  banku</h3>
                       </div>
                   </div>
                   <div className="row">
                       <div className="col-sm-12">
                           <div className="row">
                                <div className="col-sm-3">
                                    <img className="setka" src={u.logo} alt="logo"/>
                                </div>
                                <div className="col-sm-9">
                                    
                                    <h4>{u.nazwa} <small>{u.produkt}</small></h4>
                                    Czas trwania oferty: {u.od} do {u.do}, <br></br>
                                    Przedział kwot:  {this.nws(u.ofertaod)} do {this.nws(u.ofertado)} w ratach od {u.minimalnierat} do {u.maksymalnierat}<br></br>
                                    Oprocentowanie: {u.oprocentowanie}%, Prowizja: {this.nws(u.oplatastala)} zł + {u.procentowa}%

                                </div>
                           </div>
                           <div className="row">
                           <div className="col-sm-12">
                                    <h3 onClick={this.debug}>Dane Klienta</h3>
                                    {jaki}
                                    <h3 onClick={this.debug}>Dane wniosku nr {u.id}/{u.data.substr(0,4)}</h3>
                                    Kwota znajdująca się na wniosku: {u.kwota} zł, zadeklarowana ilość rat:{u.rat} rat <br></br> <br></br>

                               </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="alert alert-success" style={styleMedia.tak}>
                                        Ten wniosek może być edytowany przez pracowników homeBank
                                    </div>
                                    <div className="alert alert-danger" style={styleMedia.nie}>
                                        Ten wniosek nie może być edytowany przez pracowników homeBank, ponieważ jest pod kontrolą banku {u.nazwa}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                               <div className="col-sm-6">
                                   <b>Adnotacje pracownika banku {u.nazwa}</b><br></br>
                                   {redux.adnotacja(u.bank)}<br></br>
                                   <b>Adnotacje pracownika homeBank</b><br></br>
                                   {redux.adnotacja(u.seller)}<br></br>
                                   <b>Status wniosku</b><br></br>
                                   {u.status}
                               </div>
                               <div className="col-sm-6">
                                   
                                   <b>Status wniosku</b><br></br>
                                   {u.status}<br></br>
                                   <b>Status spłaty</b><br></br>
                                   <div>{sukces}</div>
                                   <b>Numer umowy pomiędzy Bankiem a Klientem</b><br></br>
                                   <div>{u.ai}</div>
                               </div>
                           </div>
                           <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        
                                    </div> 
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        
                                    </div>
                                </div>
                           </div>
                            
                       </div>
                       
                       <div className="col-sm-4">
                           {/* Symulacja
                            <Symulacja 
                                krmin={u.ofertaod}
                                krmax={u.ofertado}
                                rmin={u.minimalnierat}
                                rmax={u.maksymalnierat}
                                kredyt={u.kwota}
                                rat={u.rat}
                                stala={u.oplatastala}
                                procentowa={u.procentowa}
                                oprocentowanie={u.oprocentowanie}
                            /> */}
                       </div>
                    </div>
                    
                    
                    <div className="btn-group">
                        <button className="btn btn-secondary btn-sm" onClick={this.zmianStatus}>Zapisz notatkę</button>
                    </div>
                </div>)
            }
            else{
                console.log(this.state.page);
                return (<div onClick={this.getLista}>Coś poszło nie tak...</div>)
            }
        }
}

}
export default Applications;