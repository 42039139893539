import React  from "react";
import axios from 'axios';
import security from './../common/security.js';
import Cookies from 'js-cookie';

import San from './../admin/san.js';
// import { setPriority } from "os";


class Adres extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            adres:[], dane:"", display:"none", mail:"",
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.checkAdres = this.checkAdres.bind(this);
        this.ask=this.ask.bind(this);
        this.askAdress = this.askAdress.bind(this);
    }
    askAdress()
    {
        this.ask('askAdress', this.props.id);
    }
    ask(resource, data = null)
    {
          var self = this;
          const params = new URLSearchParams(); params.append('asc',security.securityKey());
          params.append('section', 'sprzedaz');
          params.append('command', resource);
          params.append('data', data);
          params.append('token', Cookies.get("Experimental"));
          axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            console.log(response.data.data);
          if (response.data.status==="OK")
          {
          //self.setState({loading:false});
              switch (resource){
              case 'mailWithAdres': self.setState({mail: response.data.data});break;
              default: break;
              }
              
          }
          else if(response.data.status==="TEST")
          {
          console.log(response.data);
          }
          else if (response.data.status==="err")
          {
             console.log(response.data);
          }
          self.setState({odpowiedz:response.data.desc});
          }).catch(function (error) {console.log(error);});
          
      }
checkAdres()
{
    var flag = false;
    if (this.state.adres.wojewodztwo === ""){flag=true; console.log(typeof this.state.adres.wojewodztwo);}
    if (this.state.adres.powiat === ""){flag=true; console.log(typeof this.state.adres.powiat);}
    if (this.state.adres.gmina === ""){flag=true; console.log(typeof this.state.adres.gmina);}
    if (this.state.adres.miejscowosc === ""){flag=true;console.log(typeof this.state.adres.miejscowosc);}
    if (this.state.adres.ulica === ""){flag=true; console.log(typeof this.state.adres.ulica);}
    if (this.state.adres.kod === ""){flag=true;console.log(typeof this.state.adres.kod);}
    if (this.state.adres.lokal === ""){flag=true;console.log(typeof this.state.adres.lokal);}
    if (flag===true)
    {
        this.setState({dane:"Uwaga, nie wszystkie dane klienta są uzupełnione", display:"block"});
    }
    else
    {
        this.setState({dane:"", display:"none"});
    }
}   

componentDidMount() {
  
   this.setState({adres: JSON.parse(this.props.adres)});
   setTimeout(() => { this.checkAdres(); }, 150);
}

    render(){
       
            return (
            <div>  
               <table>
                   <tbody>
                       <tr>
                           <td>Nazwa</td><td><b><San text={this.state.adres.nazwa} /></b></td>
                        </tr>
                        <tr>
                           <td>Adres</td><td>Województwo: <San text={this.state.adres.wojewodztwo}  />, Powiat: <San text={this.state.adres.powiat} /> Gmina: <San text={this.state.adres.gmina} />
                            <br></br>
                            <San text={this.state.adres.ulica} /> <San text={this.state.adres.lokal} />  <br></br>
                            <San text={this.state.adres.kod} /> <San text={this.state.adres.miejscowosc} />
                           </td>

                           <td></td><td></td>
                       </tr>
                   </tbody>
               </table>
               {this.state.dane}
               <div style={{display: this.state.display}}>
                   <button className="btn btn-danger btn-sm" onClick={this.askAdress}>Poproś o uzupełnienie</button> {this.state.mail}
               </div>
            </div>

            );
        }
       
   

}

export default Adres;