import React from 'react';
import XML from './xmlparser.js';
import {FiEdit} from "react-icons/fi";
import axios from 'axios';
import security from './../common/security.js';
import Cookies from 'js-cookie';

class DrawCredit extends React.Component{
    constructor(props) {
        super(props);
        this.state = { klienci:[],
        };
       this.ask = this.ask.bind(this);
       this.sendSelected = this.sendSelected.bind(this);
       this.componentDidMount = this.componentDidMount.bind(this);
    }
    componentDidMount()
    {
        this.ask("user", "clientSelection", null)
    }
    popitup(ev) {

        //this.ask('saveItem', JSON.stringify({form:this.props.form, product:ev.target.dataset.product}))
        var url = ev.target.dataset.url;
        var windowName = ev.target.dataset.name;
        var newwindow=window.open(url,windowName,'height=200,width=150');
        if (window.focus) {newwindow.focus()}
        return false;
      }

      ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("Experimental"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            switch(command)
            {
                case 'clientSelection':self.setState({klienci:response.data.data});break;
                
                default:break;
            }
            // self.setState({pobrano: true});
        }).catch(function (error) {console.log(error);});
        
    }
    sendSelected(ev)
    {
        this.ask("user","selectedProduct", ev.target.dataset.id);
        this.popitup(ev)
    }
    render(){ 
     
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        {this.props.xml.kategoria.map((key, i)=>(
                            <div key={i} style={(this.props.show.includes(key['@attributes'].nazwa)) ? {display:"block"} : {display:"none"}}>
                                <h3 style={this.props.h3}>{key['@attributes'].nazwa}</h3>
                                {key.podkategoria.map((subKey, j)=>(
                                    <div key={i+"_"+j} style={(this.props.show.includes(subKey['@attributes'].nazwa)) ? {display:"block"} : {display:"none"}}>
                                        <h5 style={this.props.h5}>{subKey["@attributes"].nazwa}</h5>
                                        {XML.rebulidObject(subKey.produkt).map((uK,k)=>(
                                            <div key={i+"_"+j+"_"+k}>
                                                <table className="ofertaTable">
                                                    <tbody>
                                                        <tr style={(uK["@attributes"].nazwa.includes("Pożycz") ? {display:"none"}:{color:"black"})}>
                                                            <td><img src={uK.dostawca['@attributes']['logo-male']} alt={uK.dostawca['@attributes']['nazwa']} />
                                                            <div>
                                                            {isNaN(parseInt(this.state.klienci[uK['@attributes']['id']])) ? "Produkt nowy":"Produkt wybrany " + this.state.klienci[uK['@attributes']['id']] + " razy" }
                                                            </div>
                                                            </td>
                                                            <td>
                                                                <b>{uK["@attributes"].nazwa} </b>od {uK.dostawca['@attributes']['nazwa']}
                                                                <div dangerouslySetInnerHTML={{__html: uK.opis}} />
                                                            </td>
                                                            <td>
                                                            <span className="wniosek2" data-id={uK['@attributes']['id']} data-url={this.props.xml['@attributes']['base-url'] + uK.linki['@attributes']['wniosek']} data-name={"newName"}
                                                    data-product={uK.linki['@attributes']['wniosek']} onClick={this.sendSelected}
                                                    >Złóż wniosek <FiEdit /></span>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                
                                            </div>
                                        ))}
                                       
                                    
                                        <hr></hr>
                                    </div>
                                ))}
                            </div> 
                        ))}
                    </div>
                </div>
                
            </div>
        );
    
        }
}

export default DrawCredit;