import React  from "react";
import ProductList from "./productList";
import XML from './../common/xmlparser.js';




class ProductListContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ready:false, faq:[],values:{},powiaty:[],gminy:[],
        };
    }
    render(){
        
        if (typeof this.props.xml['@attributes'] !== "undefined")
        {
        var dane = XML.rebulidObject(this.props.xml.kategoria)
        return (
        <div className="container">
           
            <div className="row">
            
            {dane.map((key, i)=>(
                <div  className="col-sm-12" key={i}>  
                    <ProductList 
                    produkt={key} 
                    clientSelection={this.props.clientSelection}
                    baseurl={this.props.xml['@attributes']['base-url']} 
                    drawable={this.props.drawable} 
                    include={this.props.include} 
                    displayh3={{display:"none"}} 
                    displayh5={{display:"none"}}
                    form={this.props.form}
                    />
                    
                </div>
            ))}
            </div>
         </div>       
    )
            }else{
                return (<div className="minHeight">Oczekuję na dane .... </div>)
            }

}

    }




export default ProductListContainer;