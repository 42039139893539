import React  from "react";

class Myalert extends React.Component{
    constructor(props) {
        super(props);
        this.state = { 
            visible:"none", message:""
        };
        this.componentWillMount = this.componentWillMount.bind(this);
       
    }
  
    componentWillMount()
    {
        this.setState({visible:"block"});
        var self = this;

        setTimeout(function(){ self.setState({visible:'none'})}, 1000);
    }
    
    render(){
        
       return (<div className="alertDB" style={{display:this.state.visible}}>
           <h3>{this.props.title}</h3>
           <p>{this.props.desc}</p>
       </div>)
    }
}


export default Myalert;