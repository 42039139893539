import React  from "react";


class Footer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }
    render(){return (<div><div className="footermargin"></div><div className="footer">&#9400; by Grecki 2019</div>
</div>);}
}

export default Footer;