// Plik odpowiadający za przygotowanie danych klientów indywidualnych do AI

var words =[];
var powiaty=[];
var data =[
    {message:"Winter can be cool!!!",created_time:"2017-07-06T21:35:37+0000",id:"10214489414773994_10209923510189233"},
    {message:"Jestem za!",created_time:"2016-07-20T22:13:26+0000",id:"10214489414773994_10207001470900077"},
    {message:"Od lewej: Lewandan Pazdanu, Milikian Trafianu, turlikian murawianu, bramkan fabianu: gramy :)",created_time:"2016-06-30T18:54:19+0000",id:"10214489414773994_10206860136006793"},
    {message:"Fantastycznie :)",created_time:"2016-06-05T22:12:01+0000",id:"10214489414773994_10206696794963369"},
    {created_time:"2016-06-05T21:59:11+0000",id:"10214489414773994_10206696743162074"},
    {created_time:"2016-05-31T22:14:55+0000",id:"10214489414773994_10206661735286899"},
    {message:"Taka Gdynia 😘",created_time:"2016-05-30T23:06:56+0000",id:"10214489414773994_10206656154587385"},
    {message:"Ten jeden, jedyny :)",created_time:"2016-05-03T22:09:06+0000",id:"10214489414773994_10206482758292586"},
    {message:"Pendolino relacji Warszawa - Poznań  po Meczu na Narodowym :)",created_time:"2016-05-03T21:29:43+0000",id:"10214489414773994_10206482603888726"},
    {message:"Do restauracji prowadzonych przez te dzieciaki jak dorosną pójdę z przyjemnością :)",created_time:"2016-04-25T21:39:54+0000",id:"10214489414773994_10206428381013188"},
    {message:"Chcę poznać grupę tych ekspertów i dołączyć do eksperymentu :P",created_time:"2016-02-04T13:26:18+0000",id:"10214489414773994_10205822085576181"},{message:"Ojoj :)",created_time:"2016-01-02T22:49:35+0000",id:"10214489414773994_10205633439620150"},
    {message:"Oto mój przegląd 2015 roku. Zobacz swój na stronie facebook.com/yearinreview2015",created_time:"2015-12-20T22:09:02+0000",id:"10214489414773994_10205552504316818"},
    {message:"Zapraszam do polubienia :)",created_time:"2015-11-27T22:05:56+0000",id:"10214489414773994_10205422334382651"},
    {message:"Nie macie pojęcia, jak prawdziwa i piękna jest historia, którą ma do opowiedzenia ten młody człowiek :)\n\n",created_time:"2015-11-15T23:44:49+0000",id:"10214489414773994_10205367711657117"},
    {message:"Czy jest jakieś dobre zaklęcie na piątek 13? A no jest :)  Wysłuchaj do końca a będzie kompletnie inaczej :) ",created_time:"2015-11-13T17:15:54+0000",id:"10214489414773994_10205357430840103"},
    {message:"Widziałem to 20 lat temu.Polska edukacja nie dostrzeże do jutra :)",created_time:"2015-11-09T00:03:53+0000",id:"10214489414773994_10205336729402580"},
    {message:"Nic dodać, nic ująć :)",created_time:"2015-11-08T16:00:21+0000",id:"10214489414773994_10205334883756440"},
    {created_time:"2015-11-07T22:13:21+0000",id:"10214489414773994_10205330050955623"},
    {message:"Od dziś jestem fanem prezentów wielkogabarytowych :)",created_time:"2015-10-18T09:47:07+0000",id:"10214489414773994_10205232202309468"},
    {message:"\bPowiedzenie \"zrobić sceny na ulicy\" zmienia znaczenie :)",created_time:"2015-10-08T19:06:11+0000",id:"10214489414773994_10205186489246670"},
    {message:"Mądrej głowie dość dwie słowie...",created_time:"2015-09-07T22:27:23+0000",id:"10214489414773994_10205026815814934"},
    {message:"Nie gadaj. Antymateria?!",created_time:"2015-07-04T17:29:10+0000",id:"10214489414773994_10204647300967300"},
    {message:"Gdzie jest przycisk \"Lubię to\"?\n",created_time:"2015-06-24T22:25:46+0000",id:"10214489414773994_10204564204409938"},
    {message:"Naleśniki mają tę niesamowitą magię w sobie :)",created_time:"2015-06-22T22:50:48+0000",id:"10214489414773994_10204545571184119"}];
    
var mostFrequentWords =["się","i","w","nie","na","z","do","to","że","a","o","jak","ale","po","co","tak",
    "za","od","go","już","jego","jej","czy","przez","tylko","tego","sobie","jeszcze","może","ze","kiedy","pan",
    "ich","dla","by","gdy","teraz","ja","ten","który","nawet","bardzo","przed","tu","jednak","pod","coś","tam",
    "wszystko","przy","więc","nic","bo","nim","żeby","miał","on","być","potem","też","jeśli","bez","nad","gdzie",
    "lecz","siebie","nigdy","ani","właśnie","sam","u","dobrze","niż","jakby","aby","ty","oczy","zawsze","raz","były",
    "no","albo","gdyby","aż","wtedy","przecież","ona","drzwi","jako","chyba","nagle","wszyscy","jeden","czym","kto",
    "sposób","czas","kilka","dlaczego","razem","także","mój","nikt","choć","wiele","dwa","ktoś","lub","trzeba",
    "niech","ku","twarz","którego","we","znowu","człowiek","jakiś","tutaj","szybko","tyle","głos","między","wreszcie",
    "również","życie","oczywiście","znów","swoje","dlatego","zbyt","ciebie","zupełnie","taki","czego","iż","dopiero",
    "powiedzieć","obok","prawie","poza","zaś","wciąż","jeżeli","moje","prawda","trzy","dzień","miejsce","mimo",
    "ponieważ","zaraz","długo","coraz","podczas","natychmiast","zanim","cóż","każdy","zrobić","ojciec","my","dość",
    "oraz","jaki","stanie","wcale","wśród","mieć","zresztą","dziś","ile","chociaż","gdyż","kiedyś","swój","jedynie",
    "pewno","nieco","niemal","gdzieś","jedno","wokół","powoli","wrażenie","matka","świat","kobieta","skąd","myśl",
    "stary","dużo","drogi","nadal","drugi","bowiem","przynajmniej","pewnie","często","razy","mężczyzna","dokładnie",
    "niczego","mówić","dzięki","pewien","widać","cicho","właściwie","rzecz","wolno","oto","ciało","czasem","wiedzieć",
    "stąd","wkrótce","dół","pół","noc","całkiem","wówczas","dom","wzrok","mocno","trudno","dziewczyna","dziecko",
    "skoro","wobec","śmierć","rzeczywiście","koniec","tuż","daleko","pięć","serce","spokojnie","czegoś","wielki",
    "dłoń","część","najpierw","mało","temat","włosy","wraz","usta","widok","równie","ponad","robić","cztery","ciągle",
    "inny","znaleźć","dobry","wyraźnie","ponownie","pytanie","dzisiaj","kogoś","zwykle","słowo","jakoś","nasz",
    "szczęście","prawo","zapewne","światło","bóg","tymczasem","przykład","przede","nikogo","dzieje","lekko","żaden",
    "zobaczyć","ramię","twój","wy","czymś","znacznie","powietrze","rano","doktor","sprawa","młody","dziesięć",
    "następnie","och","dwadzieścia","jutro","zatem","list","jednocześnie","ach","zamiast","iść","dawno","czemu",
    "natomiast","wiadomo","możliwe","słońce","kapitan","wiatr","głośno","ostatni","podobnie","dać","ego","całkowicie",
    "zostać","prosto","niewiele","krew","broń","zwłaszcza","spod","większość","miłość","koło","pomiędzy","mąż","wprost",
    "dopóki","wieczorem","doskonale","niby","łatwo","blisko","dotąd","kogo","droga","głęboko","żona","przedtem",
    "niebo","panna","źle","ów","obaj","niestety","według","zewnątrz","prawdopodobnie","bądź","miasto","choćby",
    "król","ostrożnie","czyli","spojrzenie","około","zaledwie","spokój","moment","wczoraj","gwałtownie","pokój",
    "chłopiec","wzdłuż","przeciwko","bok","pomocy","cisza","nowy","stać","ależ","ból","okno","ciężko","syn","myśleć",
    "czasami","wszędzie","odpowiedź","obraz","sto","krok","rok","statek","żyć","ledwie","postać","owszem","dokąd",
    "szczególnie","sześć","znak","brat","ruch","los","jakże","brak","wieczór","uśmiech","sen","pomysł","długi","chwila",
    "woda","zza","fakt","wyraz","ogień","uważnie","chłopak","wyłącznie","cokolwiek","zrozumieć","akurat","samochód",
    "wiadomość","naprzód","numer","wrócić","nazwisko","ni","wprawdzie","krótko","zgodnie","czoło","wysoko","widzieć",
    "wina","pomoc","kiedykolwiek","oboje","rozdział","strach","spotkanie","dosyć","szeroko","dwaj","oko","zapach",
    "wziąć","zabić","tłum","toteż","czekać","stan","brew","trzydzieści","jednakże","oddech","późno","przeciw","głowa",
    "oprócz","dźwięk","cień","zarówno","czyż","chętnie","mama","szukać","dostać","niezbyt","dowiedzieć","mnóstwo",
    "obecnie","niezwykle","uczucie","południe","poważnie","podobno","stamtąd","morze","dziwnie","wyjść","kawałek",
    "naturalnie","telefon","wewnątrz","problem","język","czarny","stół","wręcz","gotów","słychać","ksiądz","poznać",
    "wypadek","profesor","spać","ostatnio","siostra","póki","starannie","szczerze","zamiar","ziemia","tamten","pójść",
    "plan","zabrać","wysoki","pies","szkoda","las","trzeci","widocznie","rzadko","sporo","pełen","przyznać","byle",
    "ono","umysł","historia","rozkaz","tydzień","patrzeć","reszta","nos","przyjaciel","cel","sprawdzić","poprzez",
    "podróż","miecz","działo","strasznie","generał","osobiście","przykro","zarazem","pomimo","kamień","lewo","deszcz",
    "potrzeba","znać","moc","zły","wejść","pamięć","nareszcie","przekonać","udział","kolejny","świetnie","ostatecznie",
    "tom","zdanie","wojna","rozmawiać","istotnie","otóż","ręka","niedawno","plecy","duży","rana","córka","ślad",
    "pięćdziesiąt","ostro","dookoła","nigdzie","siedem","nóż","jechać","ciotka","nowo","mary","nieraz","spotkać",
    "czyżby","wpół","sytuacja","wyobrazić","delikatnie","zazwyczaj","powstrzymać","pozwolić","dotychczas","krzyk",
    "zadanie","kształt","wierzyć","pośród","facet","zachód","uwierzyć","koń","lekarz","pułkownik","porozmawiać",
    "poczucie","niegdyś","dokoła","północ","osiem","powrót","ton","znaczenie","rodzina","klucz","ukryć","żal","oficer",
    "zatrzymać","liczyć","przejść","zimno","głąb","łagodnie","tysiąc","zmienić","praca","bob","jedyny","obecność",
    "ciemność","przyszłość","słyszeć","odpowiedzieć","uczynić","dziewczynka","piękny","podobny","głównie","prócz",
    "stale","siła","niekiedy","absolutnie","radość","brzeg","centrum","zdobyć","świadomość","zachować","względem",
    "państwo","niedługo","zegarek","punkt","śnieg","niedaleko","prawdziwy","równocześnie","gra","pewny","rozmowa",
    "pole","wóz","trzymać","ledwo","aha","własny","stanowczo","cześć","pokazać","czterdzieści","wschód","pisać",
    "specjalnie","obcy","obiad","dziwny","księżyc","zająć","pracować","przestrzeń","milczenie","głupi","czytać",
    "dane","dotrzeć","ucha","niewątpliwie","możliwość","słuchać","spojrzeć","płaszcz","ubranie","kraj","piętnaście",
    "prędko","doprawdy","śmiech","jasno","odejść","błąd","niebezpieczeństwo","ciepło","wcześnie","pewność","pistolet",
    "gniew","korytarz","związek","blask","wracać","wyjaśnić","pora","silny","nazajutrz","utrzymać","pokład","walczyć",
    "następny","dziadek","pas","przeciwnie","żołnierz","szef","kochanie","pięknie","chodzić","osoba","środek","dwoje",
    "atak","wuj","system","naprzeciw","zapytać","uczuć","drzewo","grupa","hrabia","pośrodku","zacząć","rodzaj","palec",
    "duch","wbrew","odgłos","zapomnieć","major","przypomnieć","łeb","teren","wystarczająco","przyjąć","miło",
    "codziennie","początkowo","zamek","przyjemność","nerwowo","spośród","usłyszeć","nieźle","gardło","jeść","pomyśleć",
    "zostawić","wyjątkowo","początek","miesiąc","rząd","hej","łódź","otworzyć","niepokój","cholera","szczęśliwy",
    "spodnie","umrzeć","niepewnie","wiek","naraz","złapał","czerwony","kupić","pociąg","kontakt","porucznik","gorąco",
    "tył","niechętnie","powód","oddać","łóżko","dowód","nieruchomo","opuścić","ażeby","niespodziewanie","wesoło",
    "godzina","odkąd","któryś","okazać","krzesło","któż","zdecydowanie","odległość","mieszkanie","oba","policja","cios",
    "odnaleźć","spokojny","starzec","przyjść","muzyka","nieustannie","ogromny","potrzebny","słusznie","działać",
    "sens","okropnie","fala","ciemno","wolność","dusza","ktokolwiek","wspomnienie","błyskawicznie","dzieło","pozostać",
    "bynajmniej","wysokość","dym","kapelusz","cichy","walka","charakter","brać","czuć","uprzejmie","stopniowo",
    "śniadanie","pierś","schody","gość","oddział","ponadto","omal","lada","chory","miły","ponuro","głęboki","owo",
    "wszakże","dyrektor","wino","dowódca","serdecznie","nisko","komuś","kilkanaście","lud","wspaniale","frank","babcia",
    "opowieść","zachowanie","wedle","robota","nazwać","pusty","grać","częściowo","niebawem","bezpośrednio","stanowisko",
    "lęk","udać","imperium","sygnał","wygląd","krótki","wolny","pat","przypadek","ciężki","spróbować","nauczyć",
    "rzucić","rozumieć","wejście","trzej","pospiesznie","ostry","strażnik","mistrz","prosić","drobne","spodziewać",
    "towarzystwo","opowiedzieć","rad","podnieść","okres","swobodnie","dokonać","mózg","jedzenie","chleb","zwłoki",
    "sierżant","dach","uciekać","wszelki","koniecznie","radio","biedny","para","siedzieć","proces","dostatecznie",
    "znad","nieszczęście","kot","potężny","dosłownie","młodzieniec","ciężar","okrzyk","pragnienie","zdrowie",
    "niezależnie","istota","skóra","zauważyć","dwanaście","wódz","służyć","przeważnie","gospodarz","złapać",
    "uważać","mowa","mur","nawzajem","gest","ogromnie","mianowicie","zwrócić","ucho","wykorzystać","brzuch","mrok",
    "prowadzić","gruby","sędzia","zimny","pozbyć","się","przedmiot","przeżyć","sprzed","okręt","dziewięć","kochać",
    "dojść","poradzić","obejrzeć","święty","otwarcie","papier","bronić","dwór","piwo","kula","słabo","skończyć",
    "wszak","tato","wytłumaczyć","święta","silnik","samolot","uwolnić","ptak","ba","niecierpliwie","autor",
    "zniszczyć","mgła","sztuka","poeta","nastrój","wygodnie","budynek","niezmiernie","dno","ruszyć","skutek","wpływ",
    "szum","wasz","winien","świeżo","napisać","tytuł","adres","pamiętać"];
    //// SUORCE : https://pl.wiktionary.org/wiki/Indeks:Polski_-_Najpopularniejsze_s%C5%82owa_1-1000_wersja_Jerzego_Kazojcia
    
var youngDisctionary =['asap', 'bae', 'borżul', 'okejkę', 'okejka', 'ok', 'dżolo', 'epik', 'friendzone', 'lol', 
    'bff', 'yolo', 'btn', 'btb', 'swag', 'rofl','insta','fb', 'noob','lajk','lajka','lajkujcie','merry','christmas', 'happy',
    'new', 'year','muza','radio','bronks','dziara','dziary','Janusz','Janusze','cziter','czitera','cziterze',
    'urwał', 'jedwabiście','500', 'plus', '500+', 'aids', 'akadia', 'ameryka', 'anglia',  'animalsi', 
    'armagedon', 'atlantyk', 'autonomia', 'Palestyńska', 'kysz', 'abisynka', 'abisyńczyk', 'absurdalny', 'abwera', 
    'acerola', 'acetylen', 'achać', 'achanie', 'achilles', 'achy i ochy', 'adapter', 'adidas', 'adios',
    'adwokatować', 'adwokatowanie', 'aerofoto', 'aerozol', 'afera', 'afera rozporkowa', 'aferzysta', 'afgan', 
    'afganiec', 'afgański', 'agawa', 'agitka', 'agrafeczka', 'agregat', 'agrest', 'agronomówka', 'akacja', 'akant', 
    'akcja', 'akryl', 'aktoreczka', 'aktorzyć', 'aktorzyna', 'aktualny', 'akuratnie', 'albo', 'alfa',  'alfons', 
    'alfonsiak', 'algebra', 'algida', 'alimenciarz', 'alka', 'alkoholizm', 'alkus', 'alt', 'alternatywa', 'alufolia',
    'alwar', 'alwarowy', 'alzheimer', 'ałun', 'amant', 'amarok', 'amba', 'ambasadorowa', 'ameba', 'ameo', 'amerykaniec',
    'amerykańszczyzna', 'amfa', 'amnestia', 'amok', 'amplituda', 'ampułeczka', 'amunicja', 'analfabeta', 'analfabetka',
    'ananas', 'anarchia', 'anatomia', 'anemia', 'anemiczny', 'anemik', 'anestezjolog', 'angielka', 'angielski',
    'anglik', 'angol', 'ani dudu', 'anioł', 'anioł stróż', 'anszlus', 'antałek', 'antena', 'antybelkowy', 
    'antyfeminizm', 'antyk', 'antylopa', 'antyplatformiany', 'aparat', 'aparatka', 'apiać', 'apka', 'arabszczyzna', 
    'arafatka', 'arbuz', 'archeolog', 'archeolożka', 'areszt', 'arteon', 'artretyzm', 'as', 'aseksualny', 'astrofizyczka', 
    'astronomka', 'atakować', 'atarynka', 'atomówka', 'atramentówka', 'audica', 'auto z kratką', 'autoburdel',
    'automat', 'autostradówka', 'autoszyba', 'Balice', 'Baran', 'Bardotka', 'Bliźniak', 'Bliźnięta', 'BOR-owiec', 
    'Bośniak', 'Botnik', 'Bozia', 'Boże', 'Ciało', 'Byk', 'ba', 'baba', 'baba-chłop', 'baba-grzmot', 'baba-jaga', 
    'babcia', 'babcinka', 'babeczka', 'babiarz', 'babieć', 'babienie', 'babka', 'babochłop', 'babok', 'babrać', 
    'babunia', 'bach', 'badylarz', 'badziewie', 'bagnet', 'bagno', 'bajcowanie', 'bajeczka', 'bajer', 'bajerować',
    'bajerowanie', 'bajka', 'bajtlować', 'bajura', 'bajzel', 'bajzlowanie', 'bak', 'bakać', 'bakszysz', 'bakutil', 
    'bal samców', 'balanga', 'balangowanie', 'balerina', 'balerinka', 'balet', 'baletnica', 'baletowanie', 'balijka', 
    'balonówa', 'balowanie', 'bałwan', 'bambotle', 'bambus', 'ban', 'banan', 'bananowiec', 'banderowski', 'bandyctwo', 
    'bania', 'baniak', 'banialuki', 'banian', 'banieczka', 'bank krwi', 'bank spermy', 'banować', 'bańka', 'mleczny', 
    'barachło', 'baran', 'baranek', 'barbarzyńca', 'bardak', 'barłóg', 'bary', 'baryłeczka', 'baryłka', 'bas', 'bateria', 
    'bauer', 'bawół', 'bazgrać', 'bazia', 'bazooka', 'bąbelki', 'bąbelkować', 'bączek', 'bądź mądry i pisz wiersze', 
    'bąk', 'bąkanie', 'bebechy', 'beblać', 'bebzon', 'bebzun', 'beczeć', 'behapowski', 'bek', 'beka', 'beknąć', 'beksa', 
    'beksa-lala', 'beleczka', 'belfer', 'belka', 'bełkot', 'bełt', 'bełtać', 'berbeć', 'beret z antenką', 'bertonka', 
    'bet', 'betka', 'beton', 'bety', 'bez', 
    'prądu', 'sensu', 'życia', 'bezbłędnie', 'bezbłędny', 'bezbożnica', 'bezbożnik', 'bezhołowie', 
    'beznadzieja', 'beznadziejnie', 'bezpieczniak', 'bezpieka', 'bezpłciowy', 'bezrękawnik', 'bezsens', 'bęben', 
    'bębnić', 'będąc szczerym', 'bi', 'bialusieńki', 'bialuśki', 'bialuteńki', 'biała gorączka', 'białe tango', 
    'białko', 'biało-czerwoni', 'biały murzyn', 'biały walc', 'bibka', 'biblioteczka', 'bidul', 'biedaczyna', 
    'biedniusieńki', 'biedniuteńki', 'biedota', 'biedra', 'biedroneczka', 'biedronka', 'biedróneczka', 'biegać', 
    'bielusieńki', 'bieluśki', 'bieluteńki', 'bigos', 'bikej', 'bikejka', 'bilard', 'bimbać', 'bimbać se', 
    'bimbrownia', 'bimbrownictwo', 'bimbrowniczy', 'biola', 'biolożka', 'bistor', 'bitka', 'biurokracja', 
    'biurokratycznieć', 'biurwa', 'biuściasty', 'blacha', 'blachara', 'blachować', 'bladziuteńki', 'blagier', 
    'blagowanie', 'blask', 'blaszka', 'bledziuteńki', 'bliziuteńki', 'blok', 'blokhauz', 'blołdżob', 'blondyneczka', 
    'blondynka', 'bluzeczka', 'bluzg', 'błędne koło', 'błękitny przycisk', 'błogosławiony', 'błotnica', 'bo', 'boa', 
    'bobrowanie', 'bocian', 'bociek', 'bocznica', 'bolszewicki', 'bolszewik', 'bom', 'bombeczka', 'bomboniera', 
    'borowiec', 'borowik', 'borówka', 'boski', 'boss', 'botaniczka', 'brach', 'brachol', 'braciszek', 'brać', 'łapę', 
    'bramka', 'bramkarz', 'brechanie', 'brechta', 'bredzić', 'breja', 'brokuł', 'brom', 'broszeczka', 'browar', 
    'browiec', 'brudas', 'brudasek', 'brudny', 'brudy', 'brudzio', 'brunatna koszula', 'bryczka', 'bryk', 'bryka', 
    'brykanie', 'bryle', 'bryndza', 'brzdąc', 'brzeziniak', 'brzuch', 'brzuchaty', 'listopadowa', 'brzydota', 
    'brzytwa', 'bubel', 'buchać', 'buchnąć', 'buda', 'budowa', 'budować', 'budowlaniec', 'budowlanka', 'budżetowy', 
    'bujać', 'bukować', 'bulgotać', 'bulić', 'bulwa', 'bulwersacja', 'buła', 'bułeczka', 'bułka', 'bułka z masłem', 
    'bumaga', 'bumblować', 'bumelka', 'bumelować', 'bumlowanie', 'bura', 'burak', 'burek', 'burza mózgów', 
    'butelczyna', 'buzi', 'buzia', 'buziak', 'buzieczka', 'byczek', 'byczy', 'być czystym', 'być na bańce', 
    'wylocie', 'sosie', 'hełmem', 'bydlak', 'bydlę', 'byk', 'były', 'bystrzak', 'bzdet', 'bzik', 'bzikowanie', 
    'bzykać', 'bzykanie', 'bzyknięcie', 'bździągwa', 'bździuch', 'Centralny', 'Chjena', 'Creutzfeldt', 'cackać', 
    'cacko', 'cacy', 'calusieńki', 'caluśki', 'caluteńki', 'całka', 'całus', 'całusek', 'całuśny', 'canon', 
    'capiący', 'carving', 'ceber', 'cebula', 'cebularz', 'cefałka', 'ceglak', 'cegła', 'celibat', 'centrala', 
    'centuś', 'cep', 'cepeen', 'cepelia', 'ceramika', 'ceregiela', 'ceremonie', 'certolić się', 'certować się', 
    'chabanina', 'chaber', 'chabet', 'chabeta', 'chachoł', 'chajtać się', 'chajtnąć się', 'chałowaty', 'chałupa', 
    'chamidło', 'chamówa', 'chamsa', 'chap', 'chapsnąć', 'charyzmatycznie', 'chata', 'chcąc nie chcąc', 'chciejstwo', 
    'chemia', 'chemiczka', 'chemik', 'cherub', 'cherubin', 'chińszczyzna', 'chirurgia', 'chirurgiczny', 'chlać', 
    'chlapacz', 'chlastać', 'chlor', 'chlusnąć', 'chłeptać', 'chłop', 'chłop jak dąb', 'chłopak', 'chłopczyca', 
    'chłopeczka', 'chłopiec', 'chłopina', 'chmiel', 'chmureczka', 'chodliwy', 'chodzenie', 'chodzić', 'rzęsach', 
    'choineczka', 'choinka', 'cholera',  'cholernica', 'cholernik', 'cholerny', 'cholerować', 'cholerstwo', 
    'choleryczny', 'cholewa', 'chomik', 'chomikować', 'chomikowanie', 'choroba', 'chorobowe', 'chorować', 'chory', 
    'chrzan', 'chrząszcz', 'chrzcić', 'chrzczenie', 'chrzest', 'chrzestna', 'chrzestny', 'chuch', 'chuchać', 
    'chuderlak', 'chudeusz', 'chudoba', 'chudzielec', 'chudzieńki', 'chudzinka', 'chudziusieńki', 'chudziuteńki', 
    'chwalipięta', 'chwileczka', 'chytreńki', 'chytrus', 'chytry', 'ci', 'ciacho', 'ciamajda', 'ciapa', 'ciasteczko', 
    'ciąć', 'ciągnąć', 'ciągnąć kota', 'ciągutka', 'cicheńki', 'cichusieńki', 'cichuśki', 'cichuteńki', 'cieć', 
    'ciekawska', 'ciekawski', 'cielę', 'cielęcina', 'cielęcy', 'ciemięga', 'ciemniak', 'ciemniusieńki', 
    'ciemniuteńki', 'ciemnogród', 'ciemnota', 'ciemny', 'cieniusieńki', 'cieniuśki', 'cieniuteńki', 'cienki', 
    'cienkopis', 'ciepać', 'cieplusieńki', 'ciepluśki', 'ciepluteńki', 'ciepły', 'ciepły lód', 'ciepnąć', 'ciesielka', 
    'cieszyć się jak Ruski z roweru', 'cięty', 'ciężarowiec', 'cinkciarz', 'ciota', 'cioteczka', 'ciotka', 'cipka', 
    'ciuch', 'ciuchland', 'ciucholand', 'ciułactwo', 'ciułanie', 'ciupa', 'ciupać', 'ciupciać', 'ciurkać', 'ciuszek', 
    'ciut', 'cizia', 'cmentarzysko', 'cmok', 'co łaska', 'co nieco', 'coby', 'cofać', 'corvetta', 'cosik',  'crack', 
    'cudak', 'cudować', 'cug', 'cuksy', 'cwaniak', 'cwaniakowanie', 'cwaniara', 'cyber', 'cycek',  'cycować', 
    'cyfra', 'cyfrówka', 'cygan', 'cyganeczka', 'cygański', 'cyjanek', 'cyjankali', 'cykać', 'cyklamat', 
    'cyklaminian', 'cykor', 'cymbał', 'cymbałki', 'cyna', 'cyngiel', 'cynia', 'cynk', 'cynobrówka', 'cypek', 
    'cyrk', 'cytologia', 'cytrynka', 'czad', 'czareczka', 'czarna sotnia', 'czarne koszule', 'czarniusieńki', 
    'czarniuteńki', 'czarnogłówka', 'czarować', 'czarownica', 'czaszka', 'czcionka', 'czego', 'czepialstwo', 
    'czerwone', 'czerwony', 'czesać', 'czeszka', 'cześć', 'często gęsto', 'często-gęsto', 'częstować', 'czółenko', 
    'czubek', 'czwartak', 'czwóreczka', 'czysta', 'czysty', 'czyściusieńki', 'czyściuteńki', 'czytacz', 'deski', 
    'ćpać', 'ćpanie', 'ćpun', 'ćpunka', 'ćwiarteczka', 'ćwiartka', 'ćwierkać', 'ćwierkanie', 'ćwok','dns', 'ds', 'dvd', 
    'dachowiec', 'dać', 'drapaka', 'dyla', 'nogę', 'plamę', 'siana', 'dalekopis', 'dalekowzroczność', 'dance', 'darcie', 
    'darmozjad', 'darmówka', 'datura', 'dawno i nieprawda', 'debeściarski', 'debil', 'decybel', 'defilada', 
    'degustacja', 'dekatyzy', 'delicja', 'delta', 'demolka', 'denat', 'denko', 'dentystka', 'dentystyka', 'deprecha', 
    'deptacz', 'deptać', 'desa', 'deseczka', 'deska', 'desynch', 'detaliczny', 'dezel', 'dezelować', 'diabeł', 
    'diabeł wie', 'diabełek', 'diabli', 'diabła', 'dialektyk', 'diament', 'diesel', 'dieta', 'dinozaur', 'dłoń', 
    'dłubać', 'długaśny', 'długi', 'długi weekend', 'dmuchawiec', 'dmuchnąć', 'chrzanu', 'dupy', 'kitu', 'rzici', 
    'zobaczyska', 'dobra', 'dobra, dobra', 'dobranoc', 'dochodzić', 'dochrapać się', 'docierać', 'dojechać', 'dojny', 
    'dojść', 'dokładnie', 'dokrętka', 'doktor', 'dokuczliwiec', 'dokument', 'dola', 'dolec', 'dołek', 'dołować', 
    'wariatów', 'domek', 'domokrążca', 'donosić', 'dopalacz', 'doping', 'dopraszanie', 'doproszenie', 'dosmaczyć', 
    'dostawać',  'dostawczak', 'doważać', 'dowcipas', 'dowód', 'dożywocie', 'dół', 'drabinka', 'draczny', 'dragon', 
    'draka', 'draństwo', 'drapichrust', 'drąg', 'drągal', 'drednot', 'drelich', 'dres', 'drewienko', 'drewniak', 
    'drewutnia', 'drinas', 'drobić', 'drobne', 'drobniusieńki', 'drobniuśki', 'drobniuteńki', 'drobny', 'drogowiec', 
    'drogówka', 'drożdżowiec', 'druciak', 'drugie', 'drugoroczniak', 'drukarz', 'drukować', 'drut', 'dryndnąć', 
    'drzeć', 'drzeć łacha', 'duchota', 'dudek', 'dudy', 'dupa', 'dupeczka', 'duperele', 'dureń', 'dusigrosz', 
    'dwója', 'dwójarz', 'dwójeczka', 'dwójka', 'dwójkowicz', 'dwubiegunówka', 'dwudziestka', 'dwukilowy', 'dygać', 
    'dylu-dylu', 'dym', 'dymać', 'dymek', 'dymić', 'dyndać', 'dynks', 'dyplomatka', 'dyplomatycznie', 'dyrdymała',
    'dyrygować', 'dyscyplinarka', 'dziabać', 'dziabka', 'dziad', 'dziad kalwaryjski', 'dziadek', 'dziadkowie', 
    'dziadostwo', 'dziadować', 'dziadowanie', 'dziadyga', 'działeczka', 'działka', 'działo', 'dziara', 'dzieciak', 
    'dzieciaty', 'dziecinada', 'dzieciorób', 'dziecko', 'dziekanica', 'dziekanka', 'dziennikarzyna', 'dzierganie', 
    'dzierżawa', 'dziewczyna', 'dziewczyneczka', 'dziewczynka', 'dzieweczka', 'dziewictwo', 'dziewiętnastka', 
    'dziewoja', 'dzięcielina', 'dzięki', 'dziękówa', 'dzik', 'dziki lokator', 'dziobać', 'dziób', 'dziumdzia', 
    'dziunia', 'dziupla', 'dziura', 'dziura w moście', 'dziureczka', 'dziurnik', 'dziw', 'dzwon', 'dzwonić', 
    'dzwonienie', 'dzwonki', 'dzwony', 'dżaga', 'dżip', 'Emi', 'Emila', 'Emilcia', 'Emili', 'Emilunia', 'Emilusia', 
    'Emiluś', 'Emiluśka', 'Emiraty', 'Ewka', 'e-mailować', 'echo', 'ecstasy', 'egoizm', 'egzekutywa', 'einstein', 
    'ej', 'ejds', 'ekolog', 'ekologiczny', 'eks', 'elektrograt', 'elektroluks', 'elektronika', 'elektrośmieć', 
    'elektryczność', 'elektryczny', 'elektryzować', 'elka', 'elzetes', 'emka', 'empetrójka', 'emulsja', 'endecja', 
    'endek', 'endokrynologia', 'enerdowiec', 'energetyk', 'entropia', 'erosoman', 'es', 'esbecja', 'esbeczka', 
    'esbek', 'esesman', 'eska', 'estragon', 'eter', 'etymolożka', 'eurocent', 'europeistyka', 'exploitowanie', 
    'Fiat', 'f-ma', 'fabryka', 'fabrykowanie', 'fabuła', 'facecik', 'facet', 'fach', 'fachowiec od siedmiu boleści', 
    'fachura', 'facio', 'facjata', 'fagas', 'fajans', 'fajczyć', 'fajera', 'fajka', 'fajnie', 'fajowo', 'fajrant', 
    'faks', 'falbaneczka', 'fałdeczka', 'fan', 'fanga', 'fanka', 'fantastycznie', 'fantastyczny', 'fanzolić', 
    'farciara', 'farciarz', 'farcić się', 'farelka', 'farfocel', 'farmazon', 'farsa', 'fart', 'faseczka', 'fasolka', 
    'faszerować', 'fatalny', 'faza', 'febra', 'fejs', 'feler', 'ferwor', 'fetyszyzm', 'fi', 'fiacik', 'fifka', 
    'fifty-fifty', 'figa', 'figura', 'figurówka', 'fiks', 'fiksum', 'fikuśny', 'filharmonia', 'filiżaneczka', 
    'filiżanka', 'fill', 'filmidło', 'filmowiec', 'filmówka', 'filologia', 'finka', 'fioł', 'fiołek', 'firaneczka', 
    'firma', 'fiskus', 'fiutek', 'fiza', 'fizjologiczny', 'fizol', 'fizyczka', 'fizyk', 'flacha', 'flak', 
    'flaszeczka', 'flaszka', 'fleks', 'fliza', 'foch', 'fochać się', 'fochnąć się', 'fok', 'foka', 'foliak', 
    'foliarz', 'foliówka', 'fontanna', 'foremeczka', 'formalnie', 'formatować', 'forsa', 'forteczka', 
    'forumowicz', 'forumowy', 'foszasty', 'fotka', 'foto', 'fotoszopować', 'fotoszopowanie', 'fotowoltaika', 
    'frajda', 'frajer', 'frajerka', 'franca', 'francuska choroba', 'francuski piesek', 'francuz', 'fraszka', 
    'fruwać', 'fryc', 'frycowe', 'frymuśny', 'fryzjer', 'fufajka', 'fuj', 'fujara', 'fujareczka', 'fujarka', 
    'fuks', 'ful', 'full', 'fuma', 'fundnąć', 'fundować', 'fundusz', 'funkcja', 'funta kłaków niewarte', 'fura', 
    'furgonetka', 'furteczka', 'futbolówka', 'futro', 'futrować', 'Gabon', 'Gombrowiczowa', 'Gorzów', 'gach', 
    'gacie', 'gaciowy', 'gad', 'gadać', 'gadanie', 'gadanina', 'gadka', 'gadulstwo', 'gadzina', 'gagatek', 'gaj', 
    'galareta', 'galerianka', 'galopem', 'gała', 'gałązeczka', 'gałeczka', 'gałgan', 'gałganek', 'gamoń', 'gapa', 
    'gapić się', 'garbato', 'garbaty', 'garbus', 'garnczek', 'garnitur', 'garsteczka', 'gasić', 'gatunkowy', 
    'gaworzyć', 'gazeta', 'gazik', 'gąbeczka', 'gąseczka', 'gderliwość', 'gdzieś', 'gegra', 'geniusz', 'geografka', 
    'geriatria', 'germanistyka', 'gest', 'geszeft', 'gęba', 'gęsia skórka', 'gęsina', 'gęś', 'gie', 'gierkówka', 
    'gil', 'gilać', 'gilosz', 'gimbus', 'gimnastyk', 'ginekologia', 'ginekolożka', 'gips', 'gira', 'git', 'gites', 
    'giwera', 'glaca', 'glanc', 'glazura', 'glazurowanie', 'ględzić', 'glina', 'gliniarz', 'glista', 'globus', 'glut', 
    'gładzik', 'gładziusieńki', 'gładziuteńki', 'głaz', 'głąb', 'głodny', 'głodomór', 'głosowanie', 'główeczka', 
    'główka', 'głupawka', 'głupek', 'głupieć', 'głupkowatość', 'głuptak', 'gnat', 'gniada', 'gniady', 'gniazdko', 
    'gnić', 'gniot', 'gnoić', 'gnój', 'godzineczka', 'godzinka', 'goguś', 'goista', 'goistka', 'goj', 'golić', 
    'golusieńki', 'goluśki', 'goluteńki', 'gołębiarz', 'gołodupiec', 'gołymi rękami', 'googlować', 'gorąc', 
    'gorąco', 'gorączka złota', 'goryl', 'gorzała', 'gorzałeczka', 'gospodarka', 'gospodarz', 'gość', 'gotować', 
    'gotowy', 'gotów', 'gotóweczka', 'góra', 'góral', 'góreczka', 'graba', 'grabina', 'graciarnia', 'grać', 
    'grad', 'grajdoł', 'granat', 'granatek', 'granatowy', 'granda', 'grandzić', 'grat', 'gratis', 'grawer', 
    'gregorianka', 'greka', 'grochowa', 'grochówa', 'grochóweczka', 'grono', 'groszek', 'grubas', 'grubaska', 
    'grumbek', 'gruszeczka', 'gruszka', 'gruszki na wierzbie', 'gryczany', 'grynszpanik', 'grypsera', 'grzać', 
    'grzaneczka', 'grzbiet', 'grzebać', 'grzechotka', 'grzeczność', 'grzmot', 'grzmotnąć', 'grzyb', 'grzybowa', 
    'grzyweczka', 'guglać', 'guglować', 'gula', 'guma', 'gumeczka', 'gumka', 'guzdrała', 'guzik', 'guzik prawda', 
    'gwiazdeczka', 'gwiazdka', 'gwiazdorzyć', 'gwizdnąć', 'Hamza', 'Hel', 'HGW', 'habilitacja', 'haftka', 'haftować', 
    'haj', 'hajcować', 'haker', 'hala', 'haleczka', 'hammond', 'handryczyć się', 'harcereczka', 'hardcore', 'harley', 
    'harmonia', 'harmonijka', 'harować jak wół', 'hawira', 'heheszka', 'hejt', 'hejterka', 'helmut', 'herbatka', 
    'herod-baba', 'herszt', 'hery', 'heurystyka', 'hę', 'hiacynt', 'hipis', 'histeryczka', 'histeryk', 'historyczka', 
    'histra', 'hiszpanka', 'hitlerowiec', 'hitlerówka', 'hokej', 'holender', 'holenderka', 'holować', 'hop', 
    'hortensja', 'huba', 'hurma', 'huzar', 'hybryda', 'hycel', 'hydraulika', 'hyś', 'hyź', 'Iwonicz', 'ibiszek', 
    'ichni', 'idiota', 'idiotka', 'idiotoodporny', 'idylla', 'igiełeczka', 'ikona', 'ikra', 'imbirówka', 'imię', 
    'impreza', 'imprezowanie', 'in vitro', 'indyk', 'instalka', 'instalować', 'integral', 'interes', 'interna', 
    'internauta', 'intuicja', 'iperyt', 'irys', 'iskiereczka', 'izba', 'izbeczka','Jackowo', 'Jaga', 'Jagiellonka', 
    'Japoniec', 'Jastrzębie', 'Jaś błądzący', 'Jezu','Chryste', 'jabcok', 'jabłko', 'jabłkowity', 'jabłoneczka', 
    'jabłuszko', 'jabol', 'jadalny', 'jagoda', 'jagodówka', 'jagodzianka', 'jagodzianka na kościach', 'jagódeczka', 
    'jaja', 'jajarz', 'jajcarnia', 'jajco', 'jajówa', 'jakby', 'jałóweczka', 'jamnik', 'jamowanie', 'japoniec', 
    'japonista', 'japonistyka', 'japonka', 'jarać', 'jarka', 'jarzębiaczek', 'jarzębina', 'jarzynowa', 'jaskółeczka', 
     'jastrząb', 'jaszczureczka', 
    'jaśniuteńki', 'jąkała', 'jebać', 'jebadełko', 'jebadło', 'jebaka', 'jebielonka', 'jechać', 'jeden', 'jedenastka', 
    'jednakowiusieńki', 'jednakowiuśki', 'jednakowiuteńki', 'jedniusieńki', 'jedniuteńki', 'jednorazówka', 
    'jednostka elektryczna', 'jedynka', 'jedynkowicz', 'jeleń', 'jesioneczka', 'jesionka', 'jeziorko', 'jeż',
    'jeżyna', 'jodyna', 'jogurt', 'jolka', 'juan', 'jubel', 'jubilat', 'jubilerka', 'junkers','Kanary', 'Karmel', 
    'Karolina', 'Katyń', 'Kleparz', 'KLM', 'Kongresówka', 'Korea', 'Koziorożec', 'KRK', 'Krynica', 'Krzyżak', 'Kudowa',
    'kabareciarz', 'kabel', 'kablowanie', 'kablówka', 'kac', 'kacap', 'kacapski', 'kacet', 'kacyk', 'kaczka', 
    'kaczkowaty', 'kaczy dół', 'kadet', 'kadłub', 'kadłubek', 'kadzić', 'kafejka', 'kajzereczka', 'kaleczyć', 
    'kaleka', 'kaloria', 'kaloryfer', 'kalosz', 'kałach', 'kałasznikow', 'kałboj', 'kałdun', 'kamasz', 'kamerka', 
    'kamerować', 'kamyczek', 'kanaliza', 'kanał', 'kanapowiec', 'kanar', 'kanarek', 'kancelaria', 'kanciapa', 'kanka', 
    'kanonada', 'kant', 'kaowiec', 'kapa', 'kapcan', 'kapeczka', 'kapeć', 'kapela', 'kapitalik', 'kaplica', 'kapok', 
    'kapota', 'kapucha', 'kapusta', 'kapusta morska', 'kapuś', 'kapuśniak', 'kaput', 'karafeczka', 'karafijoł', 
    'karafioł', 'karakan', 'karakuł', 'karakuły', 'karbitówa', 'karcianka', 'karczek', 'karczmareczka', 'karczycho', 
    'kardan', 'karela', 'kark', 'karkówka', 'karnawałowicz', 'karnąć się', 'karniak', 'karteczka', 'kartka', 
    'kartkówka', 'kartoflisko', 'kartografka', 'kasa', 'kasiarz', 'kasiasty', 'kaska', 'kaszana', 'kaszka z mlekiem', 
    'kaszkiet', 'kasztan', 'kasztanowaty', 'katabas', 'katana', 'katapulta', 'katar', 'katiusza', 'kawa po turecku', 
    'kawalerować', 'kawał', 'kawałek', 'kawiara', 'kawiarz', 'kawka', 'kawusia', 'kazanie', 'kaznodzieja', 
    'kaznodziejsko', 'kaznodziejstwo', 'każdziuśki', 'każdziuteńki', 'kąt', 'ki czort', 'ki diabeł', 'kibel', 
    'kiblować', 'kibol', 'kibolski', 'kichać', 'kicia', 'kiciunia', 'kiciuś', 'kić', 'kiecka', 'kielich', 
    'kieliszeczek', 'kielonek', 'kiełbaska', 'kiep', 'kiepski', 'kiepsko', 'kierat', 'kieszoneczka', 'kij', 
    'kijkowanie', 'kijowo', 'kilo', 'kilogram', 'kilometrówka', 'kima', 'kimać', 'kimono', 'kinderbal', 'kinol', 
    'kinomaniak', 'kinówka', 'kira', 'kircha', 'kiszeczka', 'kiszka', 'kitajec', 'kitajka', 'kitajski', 'kiwać', 
    'klajster', 'klaka', 'klakier', 'klameczka', 'klamereczka', 'klamot', 'klamoty', 'klapa', 'klapeczka', 'klasówka', 
    'klata', 'klateczka', 'klatka', 'klatkować', 'klawiszowiec', 'klawy', 'klecha', 'kleić durnia', 'klejnot', 
    'klekotać', 'kleks', 'klepać trzy po trzy', 'klerykat', 'klękajcie narody', 'klient', 'klikać', 'klima', 'klin', 
    'klituś-bajduś', 'kloc', 'klocek', 'klop', 'klops', 'kluseczka', 'kluska', 'kłamczuch', 'kłaść', 'kłódeczka', 
    'kłódka', 'kłusować', 'knajacki', 'knajak', 'knajpa', 'knajpeczka', 'knajpka', 'knot', 'knypek', 'kobiałeczka', 
    'kobieciarz', 'kobiecina', 'kobiecinka', 'kobita', 'kobitka', 'kobra', 'kobyła', 'kochaneczka', 'kochaneńki', 
    'kochanieńki', 'kociara', 'kociarz', 'kocie łby', 'kociokwik', 'kocioł', 'kocmołuch', 'koczkodan', 'kodziarz', 
    'kodzić', 'kogel-mogel', 'kogut', 'kojfnąć', 'koka', 'kokoszka', 'koks', 'koksiak', 'koktajl Mołotowa', 'kola', 
    'kolarka', 'kolebeczka', 'kolejka', 'kolejówka', 'koleś', 'koleżaneczka', 'koleżka', 'kolędowanie', 'kolorowy', 
    'kolt', 'kolubryna', 'kołatka', 'kołek', 'koło', 'kołować', 'kołtun', 'kołyseczka', 'kombajn', 'kominiarka', 
    'kominówka', 'komoda', 'komóra', 'komóreczka', 'komórka', 'kompakt', 'kompan', 'kompozycja', 'kompresja', 
    'komputerowiec', 'komsomoł', 'komu w drogę, temu czas', 'komuna', 'komunalka', 'komuszy', 'kondotier', 
    'koneweczka', 'konfederatka', 'koniara', 'koniec świata', 'konik', 'konik polny', 'koniuszek', 'konkretny', 
    'konkwista', 'konowalski', 'konserwa', 'kontener', 'kontent', 'kontrola', 'konturóweczka', 'konturówka', 
    'konwejer', 'koń', 'końcówka', 'kop', 'kopać', 'kopać się z koniem', 'kopanie', 'kopanina', 'kopcić', 'kopciuch', 
    'koper', 'koperta', 'kopertówka', 'kopiec', 'kopiejeczka', 'kopniak w górę', 'korek', 'korelacja', 'korki', 
    'korkować', 'korona z głowy nie spadnie', 'koroneczka', 'koronka', 'koronować', 'korowarka', 'korozja', 
    'korpo', 'koryto', 'korzonki', 'korzonki nerwowe', 'kosa', 'kosić', 'kosteczka', 'kostropaty', 'kostucha', 
    'kosz', 'koszałki-opałki', 'koszuleczka', 'kościec', 'kościołowy', 'kościotrup', 'kościół', 'kość', 'kot', 
    'koteczka', 'kotlet', 'kotwica', 'koza', 'kozacki', 'kozacko', 'kozak', 'kozok', 'koźlak', 'koźlina', 'kra', 
    'krainka', 'krajan', 'krajanka', 'krajówka', 'krakus', 'krakuska', 'kram', 'krasnal', 'krasula', 'krateczka', 
    'kratka', 'krawat', 'krawężnik', 'kreda', 'krejzolka', 'kremik', 'kremlowski', 'kremówka', 'kreseczka', 'kreska', 
    'kret', 'kretyński', 'krewa', 'kręcić', 'kręcić lody', 'krętorogi', 'krogulec', 'kroić', 'krokodyl', 'kromeczka', 
    'kropeczka', 'kroplówka', 'krosteczka', 'krowa', 'króbeczka', 'króciusieńki', 'króciuśki', 'króciuteńki', 'król', 
    'król śledziowy', 'króliki', 'kruchusieńki', 'kruchuteńki', 'kruszyneczka', 'krwisty', 'kryminał', 'kryształ', 
    'kryty', 'krzaczek', 'krzaczyć', 'krzak', 'krzewistość', 'krzyżak', 'krzyżówka', 'krzyżyk', 'ksero', 'książeczka', 
    'książka', 'księżycówka', 'kto wie', 'ktoś', 'który', 'kubik', 'kuchareczka', 'kucharzyć', 'kuchcić', 'kuchenka', 
    'kuchta', 'kuciapa', 'kufajka', 'kufel', 'kufer', 'kukiełeczka', 'kukułeczka', 'kukuruźnik', 'kukuryku', 'kuleczka', 
    'kulfon', 'kumać się', 'kumaty', 'kumpel', 'kumpela', 'kumpelka', 'kumpelski', 'kumplostwo', 'kumplować się', 
    'kumplowski', 'kundel', 'kupa', 'kupeczka', 'kuper', 'kupić kota w worku', 'kupny', 'kupować', 'kur zapiał', 
    'kurde', 'kurka', 'kurkuma', 'kurna chata', 'kursować', 'kurteczka', 'kurwik', 'kuśka', 'kuternoga', 'kutwa', 
    'kuzyneczka', 'kwadrat', 'kwas', 'kwasówka', 'kwatereczka', 'kwiat', 'kwiatek', 'L4', 'Lew', 'Luksemburg', 'lab', 
    'laba', 'laboratorium', 'laborka', 'lacz', 'lać', 'lać jak z cebra', 'lafirynda', 'laga', 'lala', 'laleczka', 
    'lampeczka', 'landara', 'lapek', 'lapis lazuli', 'laptop', 'larwa', 'laseczka', 'laser', 'laserować', 'laserówka', 
    'laska', 'świetlne', 'latać', 'latający spodek', 'latareczka', 'lateks', 'latte', 'laufer', 'lawina', 
    'lazania', 'lecieć', 'leciusieńki', 'leciuśki', 'leciuteńki', 'legia cudzoziemska', 'legion', 'legionista', 
    'legun', 'lekarka', 'lekkusieńki', 'lekkuśki', 'leksykolożka', 'lekusieńki', 'lekuśki', 'lelek', 'leluja', 
    'leninówka', 'leniuch', 'leniwiec', 'lenonki', 'lepki', 'leśny dziadek', 'lewar', 'lewy', 'leżeć', 
    'leżeć jak betka', 'leżenie', 'licho wie', 'lichy', 'liczykrupa', 'likwidować', 'lilia', 'lilia wodna', 
    'liliput', 'limo', 'linia', 'lipa', 'lipny', 'liryczny', 'lis', 'lisi', 'listeweczka', 'liszka', 'liść', 
    'literatka', 'litr', 'lizać', 'lizak', 'lizus', 'lizuska', 'lodóweczka', 'lodówka', 'logicznie', 'logiczny', 
    'lojalka', 'lokal', 'lokator', 'loteria', 'lotna', 'lotus', 'lód', 'lu', 'lub', 'lucyper', 'lufa', 'luj', 'lulu', 
    'lump', 'lumpeks', 'lupo', 'lura', 'lustro', 'lutować', 'luxtorpeda', 'luz', 'luzytanistyka','łabądek', 'łacha', 
    'łachać', 'łachman', 'łachudra', 'łacina', 'ładniuśki', 'ładny', 'łajba', 'łajdak', 'łakomczuch', 'łamaniec', 
    'łamistrajk', 'łapa', 'łapać', 'łapeczka', 'łaps', 'łapu-capu', 'łaska', 'łaski bez', 'łateczka', 'łatwiuśki', 
    'łatwiuteńki', 'łatwizna', 'ławeczka', 'łazieneczka', 'łazik', 'łączyć', 'łąteczka', 'łochynia', 'łoić', 
    'łojóweczka', 'łomot', 'łomotać', 'łopatologia', 'łopatologicznie', 'łopatologiczny', 'łopuch', 'łoś', 
    'łódeczka', 'łóżeczko', 'łucznik', 'ług', 'łupina', 'łuseczka', 'łykać', 'łysawy', 'łysinka', 'łyskacz', 
    'łyso', 'łysy', 'łzy świętego Wawrzyńca', 'Madziar', 'Marych', 'Meksyk', 'Mikronezja', 'Mila', 'Milcia', 
    'Milka', 'Monciak', 'Moskwa', 'MP3', 'Murzynka', 'macant', 'machać', 'macher', 'machlojka', 'maciejka', 
    'maciek', 'maciupki', 'magik', 'magister', 'magisterka', 'magnet', 'magnolia', 'mahoń', 'mailować', 
    'majdan', 'majka', 'majówka', 'majster', 'majściera', 'majtać', 'majtanie', 'mak', 'makaroniarz', 'makóweczka', 
    'makówka', 'makro', 'makulatura', 'maleństwo', 'malina', 'malinka', 'malowidło', 'maltańczyk', 'maluch', 
    'maluśki', 'maluteńki', 'mała', 'małe piwo', 'małolat', 'małolata', 'małpa', 'małpeczka', 'małpka', 
    'małpowanie', 'mały', 'mama', 'mamałyga', 'mammografia', 'mamona', 'mamuci', 'mamusienieczka', 'mamut', 
    'manatki', 'mandacik', 'maniak', 'manipulant', 'mańkuctwo', 'mańkut', 'mapa drogowa', 'marchewka', 'marihuana', 
    'marniuteńki', 'marshall', 'maruda', 'maruder', 'maruderstwo', 'marycha', 'marynareczka', 'maryśka', 
    'masakryczny', 'masami', 'masarnia', 'masło', 'masło maślane', 'masturbator', 'mata', 'mateczka', 
    'matematyczka', 'materialista', 'matki boskiej pieniężnej', 'matma', 'matoł', 'matros', 'mazak', 'mazepa', 
    'mecenas', 'mechanik', 'mecyja', 'medalikarz', 'medyk', 'mega', 'mega-', 'mejl', 'mejlować', 'meksyk', 'melanż', 
    'meldunek', 'meleks', 'melepeta', 'melina', 'meliniara', 'menda', 'menel', 'mentol', 'merc', 'mercedes', 'mes', 
    'mesa', 'mesel', 'messenger', 'meta', 'metafizyczny', 'metal', 'metalizacja', 'metaloplastyka', 'metalowy', 
    'metoda kija i marchewki', 'metr', 'mezzosopran', 'mężateczka', 'mężuś', 'mianowaniec', 'miareczka', 'miasto', 
    'michałek', 'mieczyk', 'mieć',  'miedziak', 'miejscowy', 'miejski', 'mielić', 'mielonka', 'mielony', 
    'mieszaniec', 'mięciusieńki', 'mięciuteńki', 'miękki', 'miękkim chujem zrobione', 'mięsko', 'mięsny', 
    'mięso', 'miętówka', 'miętus', 'migać', 'migawka', 'mikol', 'mikro', 'mikrofalówka', 'mikrus', 'mikry', 
    'miks', 'mileńki', 'milusieńki', 'miluśki', 'miłość własna', 'mina', 'mineczka', 'miniówka', 'minoryta', 
    'minuteczka', 'miód', 'misiu', 'miss', 'miś', 'mit', 'mizdrzyć się', 'mizernieńki', 'mizerniuteńki', 'mlecz', 
    'mleczaj', 'mleczak', 'mleczarka', 'mleczarz', 'mleczniak', 'mleko', 'mlycziczko', 'mlyczok', 'młody', 
    'młodzieniaszek', 'młodzik', 'młodziuchny', 'młodziusieńki', 'młodziuśki', 'młodziuteńki', 'młodziutki', 
    'młotek', 'młyn', 'młynareczka', 'młynarka', 'mniszka', 'moc', 'mocny jak byk', 'mocny w gębie', 'moczyć', 
    'modowy', 'mogiła', 'moher', 'mokruteńki', 'mokry sen', 'mokrzusieńki', 'mokrzuteńki', 'monarcha', 'monopol', 
    'morda', 'mordeczka', 'mordokleja', 'mordoklejka', 'mordownia', 'morfologia', 'morowy', 'mors', 'morus', 'motor', 
    'motór', 'motylek', 'możliwie', 'możliwy', 'mróweczka', 'mrówka', 'mróz', 'mruczeć', 'mrugać', 'mruk', 
    'mufeczka', 'mugol', 'mulić', 'muł', 'mundurowy', 'mundurówka', 'murowany', 'murzynek', 'murzyńskość', 'mus', 
    'muskla', 'muskuł', 'muszelka', 'muszla', 'musztardówka', 'muza', 'muzyczka', 'muzyk', 'muzyka', 'muzykant', 
    'mużyk', 'mydło', 'myk', 'mysz', 'myszeczka', 'myszka', 'myśl', 'Natka', 'Niemiec', 'Nobel', 'NPC', 
    'nabierać', 'nabrać', 'nabytek', 'nachalny', 'naczalstwo', 'nadawać', 'nadąć', 'nadęty', 'nadlatywać', 
    'nadliczbówka', 'nadymać', 'nadziany', 'nafta', 'naganiacz', 'nagotować', 'nagrywać', 'nagusieńki', 
    'naguteńki', 'nahajcować', 'naiwniaczka', 'naiwniak', 'najarany', 'najazd', 'nakręteczka', 'nakrętka', 
    'nakryweczka', 'nakupować', 'nalać', 'naleśnik', 'naleweczka', 'nalot', 'namawiać', 'namiocik', 'namolność', 
    'namolny', 'napalony', 'napić się', 'napiec', 'napięcie', 'napoleon', 'napój gazowany', 'naraić', 'narcyz', 
    'narkomaństwo', 'narkus', 'narozwieszać', 'narty', 'narzeczona', 'narzeczony', 'nastrój', 'naście', 'naumieć', 
    'nawalać', 'nawalić', 'nawigacja', 'nawijać', 'nawijać makaron na uszy', 'nawinąć', 'nawtranżalać', 
    'nawtranżalanie', 'nawtykać', 'naznosić', 'neandertalczyk', 'negatyw', 'nenufar', 'neonatologia', 'neonek', 
    'neonówka', 'nereczka', 'nerka', 'nerwus', 'nędza', 'pies', 'wydra', 'niebieski', 'niebrzydki', 'szlag', 
    'niechluj', 'niedopity', 'niedoróbka', 'niedźwiedziowaty', 'niefajny', 'niefart', 'niegramotny', 'nieletni', 
    'niemiec', 'niemilec', 'niemowlak', 'nienormalność', 'niepokalane poczęcie', 'nierdzewka', 'nieskończoność', 
    'nieszpułka', 'nieślubna', 'nieślubny', 'nieumalowany', 'niewyraźnie', 'nigdy w świecie', 'nikon', 'niteczka',
    'nitro', 'niuchać', 'niunia', 'niziusieńki', 'niziuśki', 'niziuteńki', 'no', 'no to co', 'nocka', 'nocnik', 
    'nocny', 'nocny marek', 'noga', 'nogaweczka', 'nora', 'nosek', 'nowiusieńki', 'nowiuśki', 'nowiuteńki', 
    'nowobogactwo', 'nożyk', 'nówka', 'nóżka', 'numerek', 'nurkować', 'nygus', 'nylon', 'nyny', 'nyska', 'Odra', 
    'OK', 'Olo', 'Oświęcim', 'oazowy', 'obabuchać', 'obalać', 'obarzanek', 'obciach', 'obciachać się', 'obciągnąć', 
    'obcinać', 'obcyndalać się', 'ober', 'oberwanie chmury', 'obiecanka', 'obieraczka', 'obierać', 'objętość', 
    'oblech', 'obóreczka', 'obrabiać', 'obracać', 'obraz nędzy i rozpaczy', 'obrażalski', 'obrok', 
     'obrosnąć w tłuszcz', 'obrotówka', 'obrót', 'obrzydliwiec', 'obstrukcja', 'obszczymur', 'obudzić',
      'obwarzanek', 'obwieś', 'obżartuch', 'ocet', 'ochlapać', 'ochroniarz', 'ochrzcić', 'ocipieć', 'oczko', 
      'oczyszczalnia', 'odbębnianie', 'odbicie', 'odbijać', 'odkuć', 'odmałpować', 'odmawiać', 'odmóżdżać', 
      'odpalać', 'odpieprz', 'odpływać', 'odrobineczka', 'odstawiać', 'odstawka', 'odwalić kitę', 'odznaczać', 
      'odzywka', 'oferta', 'ofiara', 'oficerki', 'oficjałka', 'ogarnąć', 'ogień', 'ogier', 'ogniówka', 'ognisko', 
      'ogon', 'ogonek', 'ogólniak', 'ogórek', 'ogórkowa', 'ogrodnictwo', 'ogrodniczki', 'ogrodzenie', 'ohap', 
      'ojczulek', 'okantować', 'okay', 'okazja', 'okazyjność', 'okej', 'okienko', 'okrąglak', 'okrąglusieńki', 
      'okrągluśki', 'okrągluteńki', 'okrągły', 'okres', 'okręt', 'okropnie', 'okropny', 'okularki', 'okularnica', 
      'okularnik', 'okupować', 'olbrzym na glinianych nogach', 'olewka', 'olimpijczyk', 'olimpijka', 'olsztyniak', 
      'opalać', 'opalić', 'open', 'opieka', 'opieprzać', 'opinacz', 'opona', 'oprych', 'orać', 'oral', 'orangutan', 
      'orbital', 'orchidea', 'ordynariusz', 'organy', 'orgia', 'orgietka', 'orientalistyka', 'origami', 'orkisz', 
      'ortalion', 'ortograf', 'oryginał', 'orzech', 'orzechowy', 'orzeszek', 'orżnąć', 'osełeczka', 'osiemnastka', 
      'osioł', 'oskarżony', 'ostro', 'ostrzałka', 'oszczep', 'oszołom', 'oszustwo', 'oszwabiać', 'owieczka', 'owsik', 
      'ozór', 'ónaczyć', 'Palestyna', 'Pankracy', 'Panna', 'Pepik', 'PET', 'Pierwszy Maja', 'PIT', 'PKP', 

      'Polaczek', 'Polanica', 'Połczyn', 'Południe', 'Południowiec', 'Północ', 'Pribałtika', 'Pyrzowice','pa', 
      'pacan', 'pacierz', 'pacnąć', 'pacyna', 'paczka', 'paczkomat', 'paćkać', 'pad', 'padać', 'padalec', 'pająk', 
      'pajda', 'pajęczarstwo', 'pajęczarz', 'pajęczyna', 'paka', 'paker', 'pakować', 'palant', 'palcóweczka', 
      'palcówka', 'paleta', 'palić', 'palnąć', 'palnąć sobie w łeb', 'palnik', 'paluszek', 'pała', 'pałka', 
      'pałowanie', 'pamięciówka', 'pancerniak', 'pancur', 'pancura', 'panda', 'panieneczka', 'panna', 'panować', 
      'pantera', 'pantofel', 'pańcia', 'pańszczyzna', 'papa', 'papeć', 'papier', 'paplać', 'paplanina', 'papu', 
      'papuć', 'papugowanie', 'papużka długoogonowa', 'para', 'paragraf', 'paranoiczka', 'paranoik', 'paranoja', 
      'parapetówka', 'pareczka', 'parkinson', 'parówa', 'paróweczka', 'parówka', 'parskać', 'parszywy', 'partacz', 
      'partia', 'pasat', 'pasibrzuch', 'pasieczka', 'pasmajtki', 'passat', 'pastereczka', 'pasterstwo', 'pastuch', 
      'pasy', 'paszoł', 'paszoł won', 'pasztecik', 'pasztet', 'patafian', 'patałach', 'pateczka', 'patelnia', 
      'patrzeć', 'patyk', 'paw', 'pazerność', 'pazur', 'pączkować', 'pchać', 'pecet', 'pechowiec', 'pecyna', 
      'pedalski', 'pedalstwo', 'pedałować', 'pederastka', 'pedet', 'pedryl', 'peelen', 'peerka', 'pekaes', 'pelikan', 
      'pełen', 'pełniusieńki', 'pełniuśki', 'pełniuteńki', 'pensjonareczka', 'pepesza', 'perfekt', 'perka', 
      'perkusjada', 'persymona', 'perwers', 'pesteczka', 'pet', 'pewnie', 'peżot', 'pędrak', 'pędzlować', 'pękać', 
      'pętak', 'photoshopować', 'pi razy drzwi', 'pi razy oko', 'pianka', 'piąsteczka', 'piąta kolumna', 'piątak', 
      'piąteczek', 'piąteczka', 'pic', 'pic na wodę, fotomontaż', 'pichcić', 'picie', 'piec', 'piec źle ciągnie', 
      'piecuszek', 'piegus', 'pieguska', 'pieluszeczka', 'pieluszka', 'pienić', 'pieprznąć', 'pieprznięty', 
      'pieprzony', 'pieprzyć', 'pierdnięcie', 'pierdolnik', 'pierdoły', 'pierdu-pierdu', 'pierdy', 'pierdyliard', 
      'pierdzielić', 'piernik', 'pierożek', 'piersiasty', 'piersióweczka', 'piersiówka', 'pierwiosnek', 'pierwiosnka',
       'pierwszak', 'pierwszy', 'pierzyneczka', 'piesek', 'pieski', 'pięciominutówka', 'pięć', 'pięćdziesiątka', 
       'pięćset plus', 'piętnastka', 'piętrowiec', 'piętrus', 'pigularz', 'pigułeczka', 'pigułka', 'pijaczka', 
       'pijak', 'pijanica', 'pijaniusieńki', 'pijaniuteńki', 'pijarowski', 'pijaweczka', 'pikas', 'pikuś', 
       'pilot', 'pilotka', 'piła', 'piłeczka', 'piłka', 'piona', 'piorunem', 'pioseneczka', 'pipidówka', 'pipsztyk', 
       'piptoły', 'piramida', 'piramidy', 'pirat', 'pisior', 'pisiorek', 'pistolet', 'pisuar', 'piszczałeczka', 
       'piszczałka', 'piterek', 'pitolenie', 'pitolić', 'pitrasić', 'piwonia', 'pizgać', 'piżamka', 'plac', 
       'plajta', 'plama', 'plameczka', 'planszajba', 'plask', 'plastik', 'platforma', 'platfus', 'plazma', 
       'plażowicz', 'pleban', 'plecioneczka', 'plecy', 'plery', 'pleść', 'plujka', 'pluskwa', 'płacić', 
       'płyciusieńki', 'płyciuteńki', 'płyn', 'pobić', 'pobiec', 'pocałunek z języczkiem', 'pochlastać', 
       'pociągać', 'pociecha', 'pociotek', 'pociotka', 'pocukrzyć', 'pod dobrą datą', 'podawać', 'podchmielony', 
       'podeszwa', 'podkładka', 'podkóweczka', 'podle', 'podlec', 'podlizywać się', 'podlotek', 'podłożyć świnię', 
       'podły', 'podnieta', 'podobniusieńki', 'podpierać powieki zapałkami', 'podpucha', 'podrasować', 'podrywać', 
       'podskakiwać', 'podstawówka', 'poduszka', 'podwiązać', 'podwiązywać', 'poemat', 'pofarcić się', 'pogłaśniać', 
       'pogłośnić', 'pogoda', 'pogoda w kratkę', 'pogodynek', 'pogodynka', 'pogonić kota', 'pogrom', 'pojazd', 'pojeb', 
       'pojechać', 'pojechać po bandzie', 'pokazać pięty', 'pokojóweczka', 'pokój', 'pokroić', 'pokropek', 'pokrzywka', 
       'pokurcz', 'polać', 'polaj', 'polak', 'polanie', 'polar', 'polecieć', 'polecony', 'poleczka', 'polewaczka', 
       'polewać', 'polewanie', 'polibuda', 'policja', 'policja drogowa', 'policjant w spódnicy', 'policzyć', 'polio', 
       'politruk', 'polonista', 'polonistka', 'polonistyka', 'polskawy', 'polski', 'połówka', 'południowy', 'połysk', 
       'pomagier', 'pomajtać', 'pomajtanie', 'pomalutku', 'pomidorowa', 'pomidorówka', 'pomieszanie z poplątaniem', 
       'pomieszkać', 'pomiotło', 'pomnik', 'pomoc domowa', 'pomostówka', 'pompa', 'pompować', 'pomyślunek', 
       'ponieść', 'ponurak', 'popelina', 'popędzić kota', 'popić', 'popieprzyć', 'popijać', 'popijawa', 
       'popiskiwać', 'popojutrze', 'poprawczak', 'poprawka', 'popychadło', 'popylać', 'porno', 'pornos', 'porodówka', 
       'poroniony', 'port', 'portki', 'poruta', 'poryw', 'pospólstwo', 'postawić', 'postrzeleniec', 'posuwadło', 
       'posuwanko', 'posuwka', 'poszeweczka', 'potakiwacz', 'potańcówka', 'powaga', 'powalać', 'powierzchnia', 
       'powinowactwo', 'powtórka', 'poziomeczka', 'poznaniak', 'pozycja', 'pożerać', 'pożyczka', 'pójść', 
       'półdupek', 'półkownik', 'półośka', 'pracować w zawodzie', 'pracuś', 'prać', 'praleczka', 'pranie', 
       'prawdziwek', 'prawko', 'prawoślaz', 'prąd', 'prądożerca', 'prikaz', 'pro-lifer', 'probówka', 'procentowy', 
       'procesja', 'prochowiec', 'prochy', 'profan', 'profesor', 'profesorka', 'program', 'prokuratorka', 
       'propaganda', 'prorok', 'prosię ziemne', 'prostytucja', 'prostytutka', 'prościusieńki', 'prościuśki', 
       'prościuteńki', 'protekcjonizm', 'prowizorka', 'próba', 'próbnik', 'próżniusieńki', 'próżniuteńki', 
       'pruć', 'pryk', 'prymula', 'pryncypał', 'pryskać', 'prysnąć', 'pryszcz', 'prywaciarz', 'przebicie', 
       'przebieraniec', 'przebieranka', 'przebiśnieg', 'przeciętniactwo', 'przeciętniaczka', 'przeciętniak', 
       'przeciętny', 'przecinek', 'przeczytać od deski do deski', 'przedmiot codziennego użytku', 'przedpotopowy', 
       'przedstawienie', 'przedszkolanka', 'przeegzaminować', 'przeegzaminowanie', 'przeegzaminowywanie', 
       'przegenialny', 'przegiąć', 'przegląd tygodnia', 'przegrywać', 'przegryźć', 'przejeżdżać', 'przejściówka', 
       'przekręt', 'przekupka', 'przelewać', 'przemądrzały', 'przemowa', 'przepić', 'przepierka', 'przepijać', 
       'przepióreczka', 'przepisywać', 'przepitka', 'przepowiadać', 'przepowiedzieć', 'przepustka', 'przepuszczać', 
       'przepuścić', 'przerabiać', 'przerżnąć', 'przesadyzm', 'przesiadać się', 'przewijak', 'przewód', 'przewracać', 
       'przybierać', 'przybijać', 'przybytek', 'przychówek', 'przyczepiać', 'przygłup', 'przygłupek', 'przygodówka', 
       'przyjaciółeczka', 'przyjaciółka', 'przyjąć z dobrodziejstwem inwentarza', 'przyjemniaczek', 'przykładać', 
       'przylecieć', 'przylepeczka', 'przyłoić', 'przymulać', 'przymykać', 'przypałowy', 'przyprawiać rogi', 
       'przysiąść', 'przystojniak', 'przyszywany', 'przytulaniec', 'przyzwoitka', 'psiak', 'psiara', 'psiarz', 
       'psikus', 'psina', 'psor', 'psorka', 'psota', 'pstryczek', 'psu oczy sprzedać', 'psuj', 'psychiatryk', 
       'psychiczny', 'psychol', 'pszczoła', 'pszczółeczka', 'pszczółka', 'pszonka', 'ptak', 'ptasi móżdżek', 
       'ptaszek', 'ptaszkować', 'ptaszkowanie', 'publika', 'pucel', 'puch', 'puchnąć', 'pudło', 'pudłowanie', 
       'pufa', 'pukać', 'pulać się', 'pulchniuteńki', 'pulmonologia', 'pultać się', 'punciak', 'punkt', 'punktowiec', 
       'punto', 'pupa', 'pupeczka', 'purpurat', 'pustułeczka', 'puszczać', 'puszczalski', 'puszka', 
       'puściusieńki', 'puściuśki', 'puściuteńki', 'pych', 'pychówka', 'pykać', 'pyrkać', 'pysk', 'pyskaty', 'pyskować', 
       'pyskówka', 'pyszczenie', 'pyza', 'Rak', 'raw', 'Rio', 'Romkowie', 'Ryba', 'Ryby', 'Ryśkowie', 'raban', 
       'rachunki', 'raczkować', 'rad', 'radar', 'radary', 'radio', 'radocha', 'radomiak', 'radzik', 'rajza', 'rajzer', 
       'rajzować', 'rameczka', 'ramię', 'ramka', 'raptem', 'rasowiec', 'raszpla', 'raz na ruski rok', 'razowiec', 
       'rąbać', 'rdest żyworodny', 'reaktywacja', 'rechotać', 'recytować', 'reebok', 'regałek', 'reglan', 
       'rejestracja', 'reklamówka', 'religianctwo', 'remiza', 'renault', 'rendez-vous', 'rentgen', 'repeta', 
       'resort', 'retsina', 'reumatologia', 'reżyserka', 'ręczna', 'ręczny', 'ręka', 'rękoczyn', 'robak', 'robal', 
       'robić', 'robo', 'robociarz', 'robol', 'robótka', 'rock', 'rodzić', 'rodzina', 'rodzynek', 'rogacz', 
       'rogoża', 'rogówka', 'rolibuda', 'roller', 'rollerka', 'rolować', 'romanista', 'romanistka', 'romans', 
       'romantyczny', 'ropa', 'ropniak', 'ropuch', 'rosołek', 'rosówka', 'rowek', 'rowerek', 'rowerować', 'rozbiórka', 
       'rozchwierutać', 'rozciągać', 'rozebrać', 'rozgałęzienie', 'rozjaśnić', 'rozkładać', 'rozkminka', 
       'rozmiarówka', 'rozmieniać się na drobne', 'rozpiska', 'rozpusta', 'rozpuszczać', 'rozpuścić', 'rozrabiacz', 
       'rozrabiać', 'rozróba', 'rozwałka', 'rozwolnienie', 'róg', 'równiusieńki', 'równiuśki', 'równiuteńki', 
       'rózeczka', 'róża', 'różowiuteńki', 'różowy', 'ruchacz', 'ruchawka', 'ruchers', 'ruda', 'rudzielec', 'rukola', 
       'rureczka', 'rurki', 'ruski', 'ruski miesiąc', 'ruszać', 'rybeczka', 'rybeńka', 'rybie oko', 'rychtować', 
       'ryć', 'rydzyk', 'ryk', 'ryneczek', 'ryneczka', 'rypać', 'rytuał', 'ryzyk-fizyk', 'ryżobrody', 'ryży', 
       'rząd', 'rządzić', 'rzemiosło', 'rzep', 'rzeźba', 'rzeźbić', 'rzeźnia', 'rzeźnik', 'rzęch', 'rzępolenie', 
       'rzodkieweczka', 'rzucać', 'rzucać mięsem', 'rzucić w diabły', 'rzutem na taśmę', 'rzutka', 'rzutki', 
       'rzygać', 'rzymianka', 'rżeć', 'rżnąć', 'Samoa', 'Skorpion', 'Solidarność', 'Sopot', 'Sorbona', 'Sowiet', 
       'Stany', 'Strzelec', 'Syberia', 'Sykstyna', 'Szwab', 'sadło', 'sadzaweczka', 'sagan', 'saks', 'saksofon', 
       'saksy', 'salceson', 'salicyl', 'salmiak', 'salonowiec', 'sam', 'sama', 'samiusieńki', 'samiuśki', 
       'samiutenieńki', 'samiuteńki', 'samobój', 'samochodówka', 'samochód z kratką', 'samogon', 'samograj', 
       'samorządowiec', 'samosiejka', 'samotny jak kołek w płocie', 'sandał', 'sanitarka', 'sarenka', 'sarna', 
       'satelita', 'saunować', 'sąd', 'sąg', 'sąsiadeczka', 'scalak', 'schaboszczak', 'schabowy', 'schiz', 
       'schizofreniczny', 'schmeisser', 'schodzić', 'scyzoryk', 'se', 'seks', 'seksafera', 'seksbomba', 'seksi', 
       'seksić się', 'seksować', 'sekunda', 'sekundeczka', 'selery', 'selfiak', 'sensat', 'sensowny', 'serce', 
       'serducho', 'serek', 'setka', 'setuchna', 'sexy', 'sędzina', 'sępić', 'sia', 'siadać', 'siara', 'siata', 
       'siatka', 'siąść', 'sidło', 'sieciówka', 'sieć', 'siedemnastka', 'siedzieć', 'siedzieć jak mumia', 
       'siedzieć jak na niemieckim kazaniu', 'siedzieć jak na tureckim kazaniu', 'siemens', 'sierota', 'sikacz', 
       'sikać', 'sikać ze śmiechu', 'sikalnia', 'siki', 'sikoreczka', 'siku', 'silny jak byk', 'siłka', 'siniak', 
       'sinologia', 'sio', 'siorbać', 'siostrzyczka', 'sipa', 'sitwa', 'siusiak', 'skalar', 'skarbówka', 
       'skarpetka', 'skarżypięta', 'skarżypyta', 'skąpy', 'sklepowy', 'sklerotyczka', 'sklerotyk', 'składać', 
       'skłotowanie', 'sknerstwo', 'sknocić', 'skoczek', 'skoczyć', 'skok w bok', 'skołowany', 'skośnooki', 
       'skóra', 'skórka pomarańczowa', 'skórowarka', 'skracać', 'skrajnia', 'skrewić', 'skręt', 'skrobać', 
       'skrobanka', 'skromniusieńki', 'skromniuśki', 'skromniuteńki', 'skrzat', 'skrzyżować', 'skubaniec', 
       'skuć', 'skumplować', 'skunks', 'skurczybyk', 'skurczygnat', 'skurkowany', 'słabieńki', 
       'słabiusieńki', 'słabiuśki', 'słabiuteńki', 'słabizna', 'sładnie', 'sława', 'sławojka','słodzieńki', 
       'słodziusieńki', 'słodziuśki', 'słodziuteńki', 'słomianka', 'słomka', 'słonecznik', 'słonina', 'słony', 
       'słoń', 'słowniczek', 'słuchać jak świnia grzmotu', 'słuchy', 'smak', 'smarować', 'smarowidło', 'smart', 
       'smażyć', 'smerf', 'smok', 'smolić', 'smrodek', 'smrodzić', 'smród', 'smyk', 'smykałka', 'snap', 'snapować', 
       'snurek', 'snurkowanie', 'snycerka', 'sobaka', 'sobie', 'socjal', 'sodówka', 'sokoli wzrok', 'solar', 
       'solarka', 'solniczka', 'solo', 'sopran', 'sort', 'sos', 'sowa', 'sójka', 'sóweczka', 'spadać', 'spałowanie', 
       'spaślak', 'spec', 'spieprzać', 'spieprzyć', 'spirala', 'spiralny', 'splajtować', 'spłukany', 'spływać', 
       'spływaj', 'spoczynek', 'spodek', 'spojrzeć', 'spoko', 'spokojniuteńki', 'spolegliwość', 'spolegliwy', 
       'sponiewierać', 'sponsor', 'sponsorka', 'sponsorówka', 'spółdzielnia', 'spółka', 'spóźnialstwo', 
       'sprawiedliwość', 'sprint', 'spróchnialec', 'spryciarz', 'spryciula', 'sprzątać', 'sprzej', 'sprzęgło', 
       'spsieć', 'sputnik', 'spychologia', 'spychotechnika', 'spylać', 'spylić', 'sraczka', 'srać', 'srajtaśma', 
       'sroczka', 'stać', 'stado', 'stajeneczka', 'stale', 'stalka', 'stanąć', 'stanik', 'stare miasto', 'stareńki', 
       'staruszeczka', 'staruszek', 'staruszkowie', 'stary', 'statystyczny', 'statystyka', 'stawiać', 'stegować', 
       'sterczeć', 'stety', 'stężenie procentowe', 'stłuc', 'stłuczka', 'stokówka', 'stokroteczka', 'stokrotka', 
       'stołek', 'stonka', 'stop', 'stopka', 'stópka', 'stówa', 'straszliwy', 'strasznie', 'straszny', 'straszyć', 
       'strategia', 'strawić', 'streszczać', 'strit', 'stroik', 'strugać', 'strzał', 'strzałeczka', 'strzałka', 
       'strzelać', 'strzemiączko', 'strzępić sobie język', 'stukać', 'stuptut', 'stylówa', 
       'suchar', 'suchusieńki', 'suchuteńki', 'suchy', 'sufragan', 'sukieneczka', 'sukienka', 'sukinkot', 'super', 
       'superksiężyc', 'surrealistyczny', 'suszyć', 'suwak', 'swoje', 'swój', 'syf', 'syfek', 'sykomora', 'sylwek', 
       'symulka', 'sypać', 'syrenka', 'szabrownictwo', 'szabrownik', 'szacher-macher', 'szacherka', 'szachować', 
       'szachraj', 'szachrajski', 'szachrajstwo', 'szachy', 'szafareczka', 'szafeczka', 'szafirek', 'szajba', 
       'szajbnięty', 'szajbus', 'szajbuska', 'szajs', 'szajspapier', 'szaleć', 'szaleniec', 'szaleństwo', 'szama', 
       'szamać', 'szambiarka', 'szambo', 'szambonurek', 'szambowóz', 'szantrapa', 'szarak', 'szarańcza', 'szareńki', 
       'szarpać', 'szarpanina', 'szarpidrut', 'szarytka', 'szastać', 'szatan', 'szczaw', 'szczawik', 'szczawiowa', 
       'szczawiówka', 'szczekać', 'szczeniacki', 'szczeniak', 'szczerze mówiąc', 'szczęka', 'szczęściara', 
       'szczęściarz', 'szczupak', 'szczuplusieńki', 'szczupluteńki', 'szczypać', 'szczypawka', 'szefowa', 
       'szeleczka', 'szelka', 'szeptanka', 'szesnastka', 'sześciopak', 'szew', 'szewc', 'szewska pasja', 'szkapieć', 
       'szklanka', 'szklankowy', 'szkło', 'szkoda', 'szkrab', 'szlaban', 'szlachcianeczka', 'szlachtować', 
       'szlajać się', 'szlauch', 'szlug', 'szmaciarz', 'szmacić', 'szmajser', 'szmal', 'szmata', 'szmateczka', 
       'szmateks', 'szmatławiec', 'szmatogłowy', 'szmelc', 'szmugiel', 'szmuglerka', 'sznaps', 'szneka', 'sznur', 
       'sznyt', 'szoferka', 'szopa', 'szopeczka', 'szoping', 'szopka', 'szosa', 'szosówka', 'szóstka', 'szpaner', 
       'szpanerka', 'szpanerski', 'szpanować', 'szparagowa', 'szpareczka', 'szpas', 'szperacz', 'szpicbródka', 
       'szpiegować', 'szpileczka', 'szpilki', 'szpion', 'szprechać', 'szprycer', 'szprycha', 'szpula', 'szpuleczka', 
       'szrot', 'sztajmes', 'sztama', 'sztamajza', 'sztuczka', 'sztuka', 'sztukas', 'szturchaniec', 'sztymować', 
       'sztywny', 'szuflada', 'szufladeczka', 'szufladkować', 'szujowaty', 'szuler', 'szumowina', 'szurać', 
       'szurnąć', 'szurnięty', 'szuwar', 'szwabski', 'szwagiereczka', 'szwankować', 'szwargot', 'szwargotanie', 
       'szwedka', 'szwedy', 'szwindel', 'szwung', 'szybciuteńki', 'szybeczka', 'szycha', 'szyfr', 'szyjka', 
       'szyneczka', 'szynkareczka', 'szynobus', 'szyszeczka', 'szyszka', 'ściągać', 'ściągnąć', 'ściema', 
       'ściemniacz', 'ściemniać', 'ścieżeczka', 'ślamazara', 'śląskie', 'śledzik', 'śledź', 'ślep',
       'ślepak', 'ślepota', 'ślepotka', 'ślepy', 'ślepy pasażer', 'śliczniuteńki', 'ślicznotka', 'ślimak', 'śliski', 
       'śliwa', 'śliweczka', 'śliwka', 'ślubna', 'ślubny', 'śmichy', 'śmieciarz', 'śmieciówka', 'śmieć', 
       'śmierdziel', 'śmierdziuch', 'śmieszny', 'śmietana', 'śmietnik', 'śmiga', 'śmigać', 'śnieg', 'śnieżek', 
       'śnięty', 'śpiąca królewna', 'śpiączka', 'śpiewać', 'śpiewać Tadeusza', 'śpiewanie', 'śpik', 'śpioch', 
       'średnia', 'śrubeczka', 'światek', 'światła', 'światło', 'świderek', 'świecznik', 'świergotać', 'świetliczanka', 
       'świetlik', 'świeżusieńki', 'świeżuteńki', 'święta', 'święty', 'świneczka', 'świnia', 'świniobicie', 
       'świnka', 'świntuch', 'świński', 'świństwo', 'świr', 'TBG', 'Tepsa', 'TIR', 'taca', 'tacierzyński', 
       'tacierzyństwo', 'tadam', 'tajniak', 'taka owaka', 'taksa', 'taksiarz', 'taksóweczka', 'takusieńki', 
       'takuśki', 'talerz', 'tałatajstwo', 'tancbuda', 'tankować', 'tankowiec', 'tapeciara', 'tapir', 'tarapaty', 
       'tareczka', 'taryfa', 'taryfiarz', 'tarzan', 'tasiemeczka', 'taszczyć', 'tata', 'tata z mamą', 'tatar', 
       'tatareczka', 'tatarski', 'tato', 'taxi', 'tą', 'techniawa', 'technolożka', 'telefon', 'telepajęczarka', 
       'telepajęczarstwo', 'telepajęczarz', 'teleskop', 'telewizornia', 'temperatura', 'tenor', 'tentegować', 
       'teologia', 'teoria', 'tere-fere kuku', 'tere-fere kuku, strzela baba z łuku', 'teren', 'terenówka', 
       'tetra', 'tępaczka', 'tępak', 'tępy', 'tipsiara', 'tirowiec', 'tirówka', 'tiurma', 'tłuc', 'tłuczek', 
       'tłuk', 'tłumaczyć z polskiego na nasze', 'tłumek', 'tłuszcz', 'tłuścieńki', 'tłuściusieńki', 'tłuściuteńki', 
       'toczyć', 'toi toi', 'toi-toi', 'toner', 'top', 'topielica', 'topinambur', 'torebeczka', 'toto', 'towar', 
       'towar macany należy do macanta', 'trach', 'tradycyjnie', 'trafić', 'trafo', 'trajkotka', 'transformator', 
       'tranzystor', 'traperka', 'trawa', 'traweczka', 'trawić', 'trawka', 'trąba', 'trąba jerychońska', 'trąbeczka', 
       'trącać', 'trącić', 'treserka', 'trociny', 'trójeczka', 'trójkąt', 'truchło', 'truć', 'truć głowę', 'trumna', 
       'trunkowy', 'trup', 'truposz', 'truposzczak', 'truskaweczka', 'truteń', 'trutka', 'trwała', 'tryper', 'trza', 
       'trzaseczka', 'trzaskać', 'trząść', 'trząść portkami', 'trzepać', 'trzepak', 'trzeźwiuteńki', 'trzęsionka', 
       'trzydziestka', 'trzymać', 'tuczyć', 'tulipan', 'tuman', 'tumiwisizm', 'turbo', 'turkaweczka', 'tutejszy', 
       'tutka', 'twardziel', 'twarz', 'tyci', 'tyciusieńki', 'tyciuśki', 'tyciuteńki', 'tygrys', 'tykać', 'tyłek', 
       'typ', 'typek', 'tyrać', 'tysiąc', 'tysiąc pięćset sto dziewięćset', 'tysiąclatka', 'tysiączek', 'tytłać', 
       'u-boot', 'ubecja', 'ubeczka', 'ubek', 'ubijać', 'ucho', 'uchynięcie', 'uciąć komara', 
       'uciecha', 'uczciwy', 'uczulenie', 'ukatrupianie', 'ukatrupić', 'ukatrupienie', 'układ', 'ulica', 'ulicznica',
       'ulicznik', 'ulizać', 'ultrafioletowy', 'ululany', 'ułanka', 'ułaskawienie', 'umarlak',  'umieć', 
       'umorusać', 'umowa śmieciowa', 'umrzyk', 'umywaleczka', 'uniwerek', 'uniwerkowy', 'upalony', 'uparciuch', 
       'upierdliwiec', 'upierdliwy', 'upominać', 'ups', 'upupić', 'urlopowicz', 'urodzaj', 'urologia', 
       'urząd', 'urządzać', 'urządzić', 'urzędas', 'user', 'ustawiać', 'ustawka', 'ustrojstwo', 'uszanka', 
       'utykać', 'utytłany', 'uwodzić', 'Waga', 'WAWA', 'Wesołych Świąt', 'Wisła', 'Wodnik', 'WRO', 'Wrocek', 
       'Wronki', 'Wuj Sam', 'wacek', 'wacik', 'wajcha', 'walenie', 'waleriana', 'walet', 'walić', 
       'walizeczka', 'walnięty', 'waluta', 'wałek', 'wałówka', 'wania', 'wańka', 'wara', 'warcaby', 'warczeć', 
       'wariactwo', 'wariat', 'wariatkowo', 'wariować', 'wariowanie', 'warszawiak', 'warzywo', 'wasabi', 'watować', 
       'ważyć', 'wąs', 'wąsisko', 'wąskotorówka', 'wątrobianeczka', 'wątrobianka', 'wąziusieńki', 'wąziuteńki', 
       'wcale', 'wcinać', 'wciry', 'wcisnąć gaz do dechy', 'wczorajszy', 'wdzianko', 'webło', 'wege', 'wejście', 
       'wejść na głowę', 'weksle', 'weselnik', 'wesoluteńki', 'wet', 'weterynaria', 'węch', 'węgierka', 'węglarka', 
       'węgle', 'węszyć', 'wiać', 'wiara', 'wiatr', 'wiatrak', 'wiatrówka', 'wiatry', 'wiązanka', 'wiązeczka', 
       'wicemisska', 'wiceprezeska', 'widzimisię', 'wieczne pretensje', 'wieczność', 'wieczny', 'wieczorek', 
       'wieczorówka', 'wieczorynka', 'wieczór', 'wiedźma', 'wielkolud', 'wielozawodowiec', 'wieprz', 'wierchuszka', 
       'wiercić', 'wiercipięta', 'wiertareczka', 'wierzyciel', 'wieszać psy', 'wieszak', 'wieśniaczka', 'wieśniak', 
       'wietrzysko', 'wiewiór', 'wiewióreczka', 'wiewiórka', 'więźniarka', 'wihajster', 'wikiholik', 'wilczur', 
       'wilczyca', 'wilk', 'windziarz', 'winiacz', 'winkować', 'winkowanie', 'wino', 'winogrono', 'wioseczka', 
       'wiosło', 'wirować', 'wisieć', 'wisienka', 'wiślak', 'wiśnióweczka', 'wiśniówka', 'witeczka', 'wklepywać', 
       'wkładka', 'wkręt', 'włam', 'włączać', 'włoszczyzna', 'włóczykij', 'wojak',  'wojo', 'wojsko', 'wolej', 
       'wolniusieńki', 'wolniuśki', 'wolniuteńki', 'wolnomyśliciel', 'worek', 'wory', 'wow', 'wóda', 'wódeczka', 
       'wódzia', 'wóz', 'wózek', 'wpadać', 'wpadka', 'wrak', 'wrażliwiec', 'wredny', 'wrocławiak', 'wróbel', 
       'wrzód', 'wrzucać', 'wsiadać', 'wsio', 'wsio rawno', 'wsiok', 'wskazóweczka', 'wskoczyć', 'wspinaczka', 
       'wstawiać', 'wstążeczka', 'wstrętny', 'wsuwać', 'wsypać', 'wszarz', 'wszyściusieńki', 'wszyściuśki', 
       'wszyściuteńki', 'wściekły', 'wte i wewte', 'wtopa', 'wtranżalanie', 'wtranżolenie', 'wtranżolić', 'wtyczka', 
       'wucet', 'wuef', 'wujaszek', 'wujcio', 'wujek Czesiek', 'wukadka', 'wuzetka', 'wybebeszyć', 'wybić', 'wychodzić', 
       'wyciąg', 'wyciągać', 'wycinać', 'wycinanka', 'wyciskać', 
       'wycyganić', 'wyć', 'wydarzyć', 'wydziobywać', 'wyfiokować', 'wygadać', 'wygadywać', 'wygłupiać', 
       'wyjec', 'wyjeżdżać', 'wyjść z siebie', 'wykańczać', 'wykapany', 'wykaszać', 'wykitować', 'wykiwać', 
       'wykładzik', 'wykończony', 'wykopyrtnąć', 'wykopywać', 'wykorkować', 'wykosztować', 'wykrzaczyć', 'wykucie', 
       'wykuć', 'wylaszczyć się', 'wylew', 'wylotówka', 'wyłazić ze skóry', 'wyłączać', 'wyłącznik', 'wymawianie', 
       
       'wymiatać', 'wymoczek', 'wyngloszpyr', 'wyniuchać', 'wynocha', 'wynosić', 'wypad', 'wypalać', 'wypałowanie', 
       'wypas', 'wypaśny', 'wypieprzyć', 'wypierdek', 'wypinać', 'wypisz, wymaluj', 'wypływać', 'wypoczynek', 
       'wypominki', 'wyprawa', 'wyprawiać', 'wypuszczać', 'wyrabiać', 'wyro', 'wyrobnik', 'wyrostek', 'wyrwać', 
       'wyrwiząb', 'wyrychtować', 'wyrżnąć', 'wysiadać', 'wysiudać', 'wystawiać', 'wystawić', 
       'wysyp', 'wysypywać', 'wysztafirować', 'wyścigowiec', 'wyścigówka', 'wyśpiewywać', 'wytentegować', 
       'wytrąbić', 'wytrzeźwiałka', 'wywalić', 'wywiad', 'wywiadówka', 'wywinąć orła', 'wywłoka', 'wyżulić', 
       'wziąć', 'wziąć na luz', 'wziątka', 'wzrok','yamaha', 'Zachód', 'Zagłębie', 'Zakopiec',  'ZREMB',
       'zabaweczka', 'zabieganie', 'zabierać', 'zabobon', 'zabójczy', 'zabór', 'zabujać', 'zachorować', 
       'zaciągać', 'zaciągnąć do łóżka', 'zaczepiać', 'zaczynać', 'zad', 'zadek', 'zadupie', 'zadyma', 
       'zadymiarz', 'zafajdać', 'zafajdanie', 'zagadać', 'zaglądać', 'zagórze', 'zagrycha', 'zagwozdka', 
       'zaiwaniać', 'zaiwanić', 'zajawka', 'zając', 'zajączek', 'zajebisty', 'zajebiście', 'zajefajny', 
       'zajęty', 'zajob', 'zajść', 'zajść za skórę', 'zajumać', 'zajzajer', 'zakałapućkać', 'zakazany', 
       'zakichany', 'zakładeczka', 'zakopianka', 'zakupoholizm', 'zakurzyć', 'zakuwać',  'zalany', 'zalewać', 
       'zaliczać', 'załamka', 'załatwiać', 'załatwić','założenie', 'zamordować', 'zamordyzm', 'zanosić', 'zapalać', 
       'zapalić', 'zapałeczka', 'zapas', 'zapaseczka', 'zapieprz', 'zapieprzać', 'zapineczka', 'zaprząc', 'zapukać', 
       'zapuszczać', 'zapuszczać żurawia', 'zapuszkowanie', 'zapychacz', 'zarabiać', 'zaraza', 'zarobas', 'zarobiony', 
       'zarozumialstwo', 'zarząd', 'zasadniczo', 'zaskakiwać', 'zaskłotowanie', 'zaskórniak', 'zasrany', 'zastrzyk', 
       'zasuwać', 'zaszwargotać', 'zaszwargotanie', 'zatrzęsienie', 'zatwardzenie', 'zawał serca', 'zawczasu', 'zawias', 
       'zawilec', 'zawodówka', 'zawracać głowę', 'zazdrostka', 'zazdrośnica', 'zażywać', 'zbańczyć', 'zbawca', 'zbijać', 
       'zbiornik', 'zblazowany', 'zboczeniec', 'zboczony', 'zbok', 'zborsuczyć się', 'zbój', 'zbrodnia', 'zdechnąć', 
       'zdjąć', 'zdołać', 'zdołowany', 'zdrowaśka', 'zdrowiusieńki', 'zdrowiuśki', 'zdrowiuteńki', 'zdrowo', 'ze', 
       'zebra', 'zegarynka', 'zenitówka', 'zerówka', 'zerwać', 'zeszmacić', 'zetrzeć', 'zewłok', 'zezować', 
       'zgaduj-zgadula', 'zgaga', 'zgnoić', 'zgraja', 'zgrywus', 'zielarz', 'zielenina', 'zielone', 'zielony', 
       'zielsko', 'zimnica', 'zimniusieńki', 'zimniuteńki', 'zimno', 'zimowanie', 'zimówka', 'zioło', 'ziółko', 
       'zipać', 'zjarany', 'zjechać', 'zlać', 'zlewać', 'zład', 'złapać zająca', 'złocisz', 'złodziejka', 
       'złomiarz', 'złota rózga', 'złote myśli', 'złotko', 'złotówka', 'złotówkodajny', 'zmechacony', 'zmiatać', 
       'zmielić', 'zmiennik', 'zmora', 'zmykać', 'znielubić', 'zoolog', 'zoom', 'zresetować', 'zrobić', 'zrzucać', 
       'zuchmistrz', 'zuchmistrzyni',  'zupina', 'zupka', 'zwałka', 'zwariowany', 'zwąchać', 'zwierzak', 
       'manatki', 'zwinąć', 'zwisać', 'zwlekać', 'zwrotka', 'zwrócić', 'zwyczajnie', 'zwyrol', 'zygotarianin', 
       'zygotarianizm', 'zygotarianka', 'ździebko', 'źdźbło', 'Żywiec', 'żaba', 'żabi skok', 'żabka', 'żabojad', 
       'żadniusieńki', 'żagiel', 'żaglowiec', 'żałoba', 'żarłok', 'żaróweczka', 'żelazo', 'żigolak', 'żłobek', 
       'żłób', 'żołądkować się', 'żołdak', 'żoneczka', 'żonkoś', 'żonobijka', 'żonusia', 'żółciusieńki', 'żółtak', 
       'żółtodziób', 'żółty', 'żółwiątko', 'żółwik', 'żreć', 'żubrówka', 'żulić', 'żużel', 'żwirówka', 'życiowy', 
       'życiówka', 'żyd', 'żydożerstwo', 'żydzić', 'żyła', 'żyłeczka', 'żyłka', 'żyrafowaty', 'żytniówka', 
        ];

var travelWords =['plaża', 'woda', 'morze', 'plażing', 'aquapark', 'atrakcja', 'lotnisko', 'samolot', 'samolotu',
    'prom', 'bagaż', 'bagaże', 'taxi', 'uber', 'góry', 'zwiedzanie', 'usa', 'paryż', 'rzym', 'grecja', 'kanary', 
    'wyspa', 'wyspy', 'lot', 'muzeum', 'śródziemne', 'atlantyk', 'świata', 'strefa', 'czasowa', 'restauracja', 
    'zemsta', 'faraona', 'opóźniony', 'pociąg', 'pociągiem', 'urlop', 'lato', 'samolotem', 'przesiadka', 'przesiadki',
    'basen','bikini','boja','fajka','fala','ferie','foremka','gorąco','grabki','kąpać','kapelusz',
    'kąpielówki','koszulka','krem' , 'opalania', 'leżak', 'lody', 'maska', 'materac', 'molo','motorówka','muszla',
    'nocleg','nurkować', 'przeciwsłoneczne', 'opalać','opalenizna',
    'parasol', 'parasolka', 'paszport', 'piasek', 'pływać','ponton','ratownik','ręcznik', 'słoneczny','słońce',
    'surfing','topić','turysta','upał','wakacje','wiatr','wiosło','woda','wybrzeże','wypoczynek','żagiel',
    'stok', 'stoku', 'pass', 'snowboard', 'alpy', 'alpejskie', 'narty', 'buty', 'narciarskie', 'szkółka', 'wypożyczalnia',
    'kombinezon', 'wyjazd',
];

function getUser(){
    // Code here
}

function getUsers(){
    // Code here
}
function wyrownaj(str, s)
{
    return str.split(s).join(",");
}


function extractWords(str)
{
    // var wrd = str.split(" ");
    var ss = str.split(" ").join(',');
    ss = wyrownaj(ss,";");
    ss = wyrownaj(ss,"!");
    ss = wyrownaj(ss,"-");
    ss = wyrownaj(ss,"?");
    ss = wyrownaj(ss,"(");
    ss = wyrownaj(ss,")");
    ss = wyrownaj(ss,"[");
    ss = wyrownaj(ss,"]");
    ss = wyrownaj(ss,"$");
    ss = wyrownaj(ss,"#");
    ss = wyrownaj(ss,"@");
    ss = wyrownaj(ss,".");
    ss = wyrownaj(ss,":p");
    ss = wyrownaj(ss,":");
    ss = wyrownaj(ss,"\n");
    
    ss = wyrownaj(ss,'"');
    ss = wyrownaj(ss,'\u0008');
    var sl = ss.split(',');

    // //console.log("WRD", sl);
    for (var a = 0; a<sl.length; a++)
    {
        
        if (!words.includes(sl[a].toLowerCase()))
        {
            // //console.log("PUSH",sl[a]);
            if (sl[a]!=="")
            {
                words.push(sl[a].toLowerCase());
            }
        }
    }
    return words;
}
function extractData()
{
    var odp = [];
    // //console.log("extractData");
    for (var a=0; a<data.length; a++)
    {
        odp.push(new Date(data[a].created_time));
        if (typeof data[a].message==="string"){extractWords(data[a].message);}
        // console.log(typeof data[a].message);
    }
    // //console.log("WORDS",words);
    var analiza1 = analizaslownikowa(mostFrequentWords, words);//popular
    var analiza2 = analizaslownikowa(youngDisctionary, words);//young
    var analiza3 = analizaslownikowa(travelWords, words);
    // console.log({Popularne:analiza1,Czymlody:analiza2,Podroz:analiza3});
    return {Popularne:analiza1,Czymlody:analiza2,Podroz:analiza3};
}
function IsJsonString(str) { try { JSON.parse(str); } catch (e) { return false; } return true;}
function analizaslownikowa(ax,bx)
{
    var counter = 0;
    for (var a = 0; a<bx.length; a++)
    {
        if (ax.includes(bx[a]))
        {
            counter++;// //console.log("found:", bx[a]);
        }
    }
    // //console.log("PERCENT: " +(counter/ bx.length));
    return (counter/ bx.length);
}

function calculateWiek(data)
{
    var ur = new Date(data);
    var ageDifMs = Date.now() - ur.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return (Math.abs(ageDate.getUTCFullYear() - 1970))/140;
}

function listaPowiatow(gus)
{
    for (var a = 0; a<gus.length; a++)
    {
        powiaty.push(gus[a].nazwa.toLowerCase());
    }
    // //console.log("POWIATY",gus.length, powiaty);
}
function szukajDanych(gus, nazwa)
{
    // //console.log("szukane", nazwa);
    for (var a = 0; a<gus.length; a++)
    {
        if (gus[a].nazwa.toLowerCase() === nazwa.toLowerCase())
        {
            return gus[a];
        }
    }
}
function prepareData(gus, users)
{
    // console.log(users);
    //znajdz najwiekszego kozaka
    var kozak = 0;
    var ToAi = [];
    listaPowiatow(gus);
    for(var a = 0; a<users.length; a++)
    {
        if (parseInt(users[a].punkty)>kozak){kozak = parseInt(users[a].punkty);}
    }
    // składamy dane dla sztucznej inteligencji
    for(a = 0; a<users.length; a++)
    {
       
        //zmienne:
        var finanse = JSON.parse(users[a].finanse);
        var adres = JSON.parse(users[a].adres);
        // //console.log("FINANSE", finanse);
        var isMostFreq = 0;
        var isYoungDict = 0;
        var isTravelWords =0;
        var wiek = calculateWiek(finanse.du);
        var zarobki = kozak/parseInt(users[a].hb);
        var isSingiel = 0;
        var isMarried = 0;
        var isDivorced = 0;
        var notEmpl = 100;
        var isV = 0;
        var countRat = 0;
        var toOut =[];
        var daneGUS;
        var procent;
        var maxQ;

        // pobieranie danych o bezrobociu @powiat, gdy nie ma powiatu @wojewodztwo, gdy nie ma województwa = @kraj z GUS
        // //console.log("SPR",adres.powiat, powiaty.includes(adres.powiat.trim()),adres.wojewodztwo, powiaty.includes(adres.wojewodztwo));
        if (powiaty.includes(adres.powiat.toLowerCase())) {daneGUS = szukajDanych(gus, adres.powiat);}
        else if (powiaty.includes(adres.wojewodztwo.toLowerCase())){daneGUS = szukajDanych(gus, adres.wojewodztwo);}
        else
        {daneGUS = szukajDanych(gus, "POLSKA");}

        //implementacja bezrobocia do wykształcenia klenta
        switch(finanse.wk)
        {
            case 1:notEmpl= daneGUS.gimnaz; break;
            case 2:notEmpl= daneGUS.zasadnicze; break;
            case 3:notEmpl = daneGUS.srednieog;break;
            case 4:notEmpl = daneGUS.srednietech;break;
            case 5:notEmpl = daneGUS.wyzsze;break;
            case 6:notEmpl = daneGUS.wyzsze;break;
            default:break;
        }
        procent = parseFloat(daneGUS.procent)/100;
        notEmpl = parseFloat(notEmpl)/100;


        // stan cywilny 

        switch (finanse.sc)
        {
            case 1:isSingiel=1;break;
            case 2:isMarried=1;break;
            case 3:isMarried=1;break
            case 4: isV=1;break;
            case 5:isDivorced=1;break;
            default:break
        }
        // //console.log("DANE GUS:", daneGUS);
        // Ilość rat:

        countRat = parseInt(users[a].rat)/420;

        //posty z FB:
        //// console.log(users[a].posts);
        if (IsJsonString(users[a].posts) && users[a].posts.length!==0)
        {
            data = JSON.parse(users[a].posts);
            var FB = extractData();
            // //console.log("FB", FB);
            isMostFreq = FB.Popularne;
            isYoungDict = FB.Czymlody;
            isTravelWords = FB.Podroz;
        }

        // składanie odpowiedzi do kupy
        switch(users[a].sukces)
        {
            case "1":toOut=[1,0,0,0,0,0,0,0,0];break;
            case "2":toOut=[0,1,0,0,0,0,0,0,0];break;
            case "3":toOut=[0,0,1,0,0,0,0,0,0];break;
            case "4":toOut=[0,0,0,1,0,0,0,0,0];break;
            case "5":toOut=[0,0,0,0,1,0,0,0,0];break;
            case "6":toOut=[0,0,0,0,0,1,0,0,0];break;
            case "7":toOut=[0,0,0,0,0,0,1,0,0];break;
            case "8":toOut=[0,0,0,0,0,0,0,1,0];break;
            case "9":toOut=[0,0,0,0,0,0,0,0,1];break;
            default:break;
        }
        maxQ = parseFloat(users[a].kwota)/(parseFloat(users[a].punkty) * 80);
        ToAi.push({input:[isMostFreq, isYoungDict, isTravelWords, wiek,zarobki, notEmpl, procent, isSingiel, isMarried, isDivorced, isV, countRat, maxQ], output:toOut});
    }
    // //console.log("BEST KOZAK", kozak);
    ////console.log("TOAI", ToAi);
    return ToAi;
}
function version()
{
    return ("1.0");
}

function askIndywisualny(gus, user, kre, kozak)
{
    var daneGUS;
    
    listaPowiatow(gus);
   
    ////console.log("GUS, DATA:",gus, user, kre);
    //console.log("USER",user);
    //console.log("UF",user.finanse);
    var finanse = JSON.parse(user.finanse);
    var adres = JSON.parse(user.adres);
    if (powiaty.includes(adres.powiat.toLowerCase())) {daneGUS = szukajDanych(gus, adres.powiat);}
    else if (powiaty.includes(adres.wojewodztwo.toLowerCase())){daneGUS = szukajDanych(gus, adres.wojewodztwo);}
    else
    {daneGUS = szukajDanych(gus, "POLSKA");}
    var isMostFreq, isYoungDict, isTravelWords, wiek,zarobki, notEmpl, procent, isSingiel =0, isMarried =0, isDivorced = 0, isV =0, countRat, maxQ;
    //console.log("KOZAK",kozak);
    zarobki = kozak/parseInt(user.hb);
    wiek = calculateWiek(finanse.du);
    switch(finanse.wk)
        {
            case 1:notEmpl = daneGUS.gimnaz; break;
            case 2:notEmpl = daneGUS.zasadnicze; break;
            case 3:notEmpl = daneGUS.srednieog;break;
            case 4:notEmpl = daneGUS.srednietech;break;
            case 5:notEmpl = daneGUS.wyzsze;break;
            case 6:notEmpl = daneGUS.wyzsze;break;
            default:break;
        }
        procent = parseFloat(daneGUS.procent)/100;
        notEmpl = parseFloat(notEmpl)/100;


        // stan cywilny 

        switch (finanse.sc)
        {
            case 1:isSingiel=1;break;
            case 2:isMarried=1;break;
            case 3:isMarried=1;break
            case 4: isV=1;break;
            case 5:isDivorced=1;break;
            default:break
        }
        // //console.log("DANE GUS:", daneGUS);
        // Ilość rat:

        countRat = parseInt(kre.rat)/420;
        maxQ = parseFloat(kre.kwota)/(parseFloat(user.hb) * 80);
        //posty z FB:
        //// console.log(users[a].posts);
        if (IsJsonString(user.posts) && user.posts.length!==0)
        {
            data = JSON.parse(user.posts);
            //console.log("POSTY", data);
            var FB = extractData();
            // //console.log("FB", FB);
            isMostFreq = FB.Popularne;
            isYoungDict = FB.Czymlody;
            isTravelWords = FB.Podroz;
        }
        else
        {
            isMostFreq = 0;
            isYoungDict = 0;
            isTravelWords = 0;
        }
    var odp = [isMostFreq, isYoungDict, isTravelWords, wiek,zarobki, notEmpl, procent, isSingiel, isMarried, isDivorced, isV, countRat, maxQ];
    //console.log("INPUT", odp);
    return odp;
}
module.exports = {
    getUser,
    getUsers,
    extractData,
    data,
    words,
    mostFrequentWords,
    youngDisctionary,
    travelWords,
    prepareData,
    version,
    askIndywisualny,

}
